import React from 'react';
import {SmartIntTrigger} from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {UI} from 'src/engrator-core';
import { SoftwareName } from '../../software-name';
import { GitLabStepContext, GitLabStepContextData } from '../steps/common';
import {SuiteType} from '../../../generic/integrations/suite-type';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
    integrationType?: SuiteType;
};
type State = {};

export class GitLabSartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.GitLab,
            artifactName: '',
            className: '',
            configuration: {
                project: undefined,
                query: ''
            },
            connectionId: this.props.connectionId
        };
        if (this.props.integrationType === SuiteType.Git) {
            this.onGitIntegrationBuilt();
        }
    }

    render() {
        if (this.props.integrationType === SuiteType.Git) {
            return <></>;
        }
        return <React.Fragment>
            { !this.smartIntTrigger.configuration.project && <GitLabStepContext
                connectionId={this.props.connectionId}
                onDataChanged={this.onDataChanged.bind(this)}
            /> }
            { this.smartIntTrigger.configuration.project && <UI.FormGroup>
                <UI.LabelValue
                    label={`Project`}
                    value={this.smartIntTrigger.configuration.project.name}
                />
            </UI.FormGroup>
            }
        </React.Fragment>
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private onDataChanged(data: GitLabStepContextData) {
        if (data.project) {
            this.smartIntTrigger.artifactName = data.project.name;
            this.smartIntTrigger.configuration.project = data.project;
            this.props.onConfigured(this.smartIntTrigger!);
            this.forceUpdate();
        }
    }

    private onGitIntegrationBuilt(): void {
        this.smartIntTrigger.artifactName = 'gitrepository';
        this.props.onConfigured(this.smartIntTrigger!);
        this.forceUpdate();
    }
}
