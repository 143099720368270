import React from 'react';

import {EntityFormHelper, UI} from 'src/engrator-core';
import {fetchOptionsForProperty} from '../rest-api';
import {FormError} from '../../../engrator-core/form-error.type';
import {OptionSourceValue} from './option-source-value.type';
import {SoftwareName} from '../../../software';
import {StepConfigurationFetcher} from '../step-configuration-fetcher.type';

type Props = {
    property: any;
    softwareName: SoftwareName;
    onOptionChanged: (option: OptionSourceValue) => void;
    connectionId: number;
    stepConfigurationFetcher: StepConfigurationFetcher;
};

type State = {
    isLoading: boolean;
    error: FormError;
}

export class SelectableMapping extends React.Component<Props, State> {
    private options: UI.DropdownOption[] = [];
    private entityFormHelper: EntityFormHelper;

    constructor(props: Props) {
        super(props);
        this.state = { error: {}, isLoading: false };
        this.entityFormHelper= new EntityFormHelper((propertyName, newValue, additionalArgument?: OptionSourceValue) => {
            this.props.onOptionChanged(additionalArgument!);
        });
    }

    componentDidMount(): void {
        this.setState({ isLoading: true }, () => {
            fetchOptionsForProperty(this.props.softwareName, this.props.connectionId, this.props.property.id, this.props.stepConfigurationFetcher())
                .then((options) => {
                    this.options = options;
                    this.setState({ isLoading: false})
                })
                .catch(error => {
                    this.setState({ isLoading: false, error: {[error.fieldName]: error.message} })
                });
        });
    }

    render() {
        return <React.Fragment>
            <UI.Loader appearance={'darkgray'} visible={ this.state.isLoading } />
            { this.state.error?.general && <UI.Message appearance={ 'error' }>{ this.state.error?.general}</UI.Message> }
            { this.options.length > 0 && <UI.Dropdown options={ this.options } onChange={ this.entityFormHelper.onChangeHandler('option') } /> }
        </React.Fragment>
    }
}