import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';

import {WrikeStepKey} from '../wrike-step-key.type';
import {WrikeStatusTransitionEditStep} from './wrike-status-transition-edit-step';
import {WrikeStatusTransitionNewStep} from './wrike-status-transition-new-step';
import {WrikeStatusTransitionConfiguration} from './wrike-status-transition-configuration';

export const WrikeStatusTransitionPipelineStep: PipelineStep<WrikeStatusTransitionConfiguration> = {
    addStepComponentClass: WrikeStatusTransitionNewStep,
    editStepComponentClass: WrikeStatusTransitionEditStep,
    specification: {
        action: 'Change task status',
        description: 'Move Wrike task from one status to another',
        key: WrikeStepKey.WrikeStatusTransition,
        software: SoftwareName.Wrike,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as WrikeStatusTransitionConfiguration;
        return `Change task status`;
    }
}