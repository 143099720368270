import React from 'react';
import { SoftwareLogotype } from '../../../../../../designer/step-creator/software-logotype';
import {ImportValidateResult} from './rest-api';
import { UI } from 'src/engrator-core';

type Props = {
    validationResult: ImportValidateResult;
    leftConnectionName: string;
    rightConnectionName: string;

};

type State = {
};

export class IntegrationImportConnectionsList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <React.Fragment>
            <UI.Message appearance={'info'}>You are importing integration with the connections listed below (connections will be created during integration import)
            </UI.Message>
            <div className={`flex row two-columns`}>
                <div className={`left`}>
                    <SoftwareLogotype softwareName={ this.props.validationResult.leftSoftwareName } />
                    <p className={`flex`}><span className={`font-medium mr-5`}>Connection:</span> {this.props.leftConnectionName}</p>
                </div>
                <div className={`right`}>
                    <SoftwareLogotype softwareName={ this.props.validationResult.rightSoftwareName } />
                    <p className={`flex`}><span className={`font-medium mr-5`}>Connection:</span> {this.props.rightConnectionName}</p>
                </div>
            </div>
        </React.Fragment>
    }
}
