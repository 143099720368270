import React, {ChangeEvent} from 'react';

import {UI} from 'src/engrator-core';
import OtherArtifactPropertyPicker from './OtherArtifactPropertyPicker';
import {ArtifactDirection, PropertyDefinition} from './../artifacts';
import {OtherArtifactPropertySource} from '../artifacts/property-source';
import {PropertySourceType} from '../artifacts/property-source/property-source-type';
import {ConfiguredProperty} from '../artifacts/data-artifact';
import {Condition} from '../conditions';
import {SelectableMapping} from './mapping-creator';
import {OptionSourceValue} from './mapping-creator/option-source-value.type';
import {SoftwareName} from '../../software';
import {StepConfigurationFetcher} from './step-configuration-fetcher.type';
import {ArtifactsAutocomplete, InputChangeListener} from '../../engrator-core/ui';

type PropertyMappingCreatorProps = {
    propertiesToMap: Promise<Array<PropertyDefinition>>,
    availableArtifacts: Array<Object>,
    configuredProperties: Array<ConfiguredProperty>;
    addPropertyMapping: (propertyMapping: any) => void,
    showOnlyArtifactsPicker?: boolean
    direction: ArtifactDirection;
    softwareName: SoftwareName;
    connectionId: number;
    stepConfigurationFetcher: StepConfigurationFetcher;
};

type PropertyMappingCreatorState = {
    predefinedValue?: string | null,
    otherArtifact: any,
    option?: OptionSourceValue;
    propertiesToMapOptions: Array<{ value: string, label: string }>,
    properties: Array<PropertyDefinition>,
    property: any,
    isLoading: boolean,
    artifactId?: string,
    showConditionField: boolean;
};

export default class PropertyMappingCreator extends React.Component<PropertyMappingCreatorProps, PropertyMappingCreatorState> {
    otherArtifact: any;
    private showOnlyArtifactsPicker: boolean;
    private sourceConfigurationVisible: boolean;
    private condition: string = '';
    private inputListener: InputChangeListener;

    constructor(props: PropertyMappingCreatorProps) {
        super(props);
        this.otherArtifact = null;
        this.showOnlyArtifactsPicker = this.props.showOnlyArtifactsPicker || false;
        this.sourceConfigurationVisible = this.props.direction === ArtifactDirection.In;
        this.state = {
            predefinedValue: null,
            property: null,
            otherArtifact: null,
            isLoading: false,
            properties: [],
            propertiesToMapOptions: [],
            showConditionField: false
        };
        let inputListener: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
        this.inputListener = {
            subscribe: ((listener: (value: string, event: ChangeEvent<HTMLInputElement>) => void) => {
                inputListener = listener;
            }),
            notify: (newValue: string, event: ChangeEvent<HTMLInputElement>) => {
                inputListener(newValue, event);
            }
        }
    }

    componentDidMount() {
        this.updateProperties();
    }

    updateProperties() {
        this.setState({ isLoading: true }, () => {
            this.props.propertiesToMap.then((properties: Array<PropertyDefinition>) => {
                this.setState({
                    isLoading: false,
                    properties,
                    propertiesToMapOptions: properties.map((propertyDefinition) => ({
                        value: propertyDefinition.id,
                        label: propertyDefinition.name
                    })).filter(this.isPropertyNotYetMapped)
                }, () => {
                    this.onPropertiesUpdated();
                });
            }).catch(error => {
                this.setState({ isLoading: false });
            });
        });
    }

    private onPropertiesUpdated() {
        if (this.props.direction === ArtifactDirection.Out && this.props.configuredProperties.length === 0) {
            console.log('could pick automatically');
            this.state.propertiesToMapOptions.forEach((prop: any) => {
                const finalProperty = this.state.properties.filter((property: PropertyDefinition) => property.id === prop.value)[0];
                if (finalProperty) {
                    this.props.addPropertyMapping({
                        property: finalProperty,
                        predefinedValue: '',
                        otherArtifact: '',
                        option: '',
                        condition: ''
                    });
                }
            })
            this.forceUpdate();
        }
    }

    isPropertyNotYetMapped(): boolean {
        return true;
    }

    componentDidUpdate(prevProps: PropertyMappingCreatorProps) {
        if (JSON.stringify(prevProps.propertiesToMap) !== JSON.stringify(this.props.propertiesToMap)) {
            this.updateProperties();
        }
    }

    setProperty(propertyId: any) {
        const property = this.state.properties.filter((property: PropertyDefinition) => property.id === propertyId)[0];
        this.setState({ property });
    }

    addPropertyMapping(): void {
        this.props.addPropertyMapping({
            property: this.state.property,
            predefinedValue: this.state.predefinedValue,
            otherArtifact: this.state.otherArtifact,
            option: this.state.option,
            condition: this.condition
        });
        this.setState({
            property: null,
            predefinedValue: null,
            otherArtifact: null,
            option: undefined,
            artifactId: ''
        });
    }

    otherArtifactPickerChangeHandler(artifactId: string, propertyId: string): void {
        const artifactSource: any = this.props.availableArtifacts.filter((artifact: any) => artifact.id === artifactId)[0];
        if (!artifactSource) {
            this.setState({ artifactId: artifactSource});
            return;
        }
        if (!propertyId) {
            this.setState({ artifactId: artifactSource});
        }
        const propertyDefinition: ConfiguredProperty = artifactSource.propertyDefinitions.filter(
            (propertyDefinition: ConfiguredProperty) => propertyDefinition.property.id === propertyId
        )[0];
        if (!propertyDefinition) return;
        const otherArtifact: OtherArtifactPropertySource = {
            artifactId,
            propertyDefinition: propertyDefinition.property,
            condition: this.condition,
            sourceType: PropertySourceType.OtherArtifact
        };
        this.setState({ otherArtifact, predefinedValue: null });
    }

    render() {
        if (this.state.isLoading) {
            return <UI.Loader appearance={'blue'}  visible={true}/>;
        }
        const isButtonEnabled = (this.sourceConfigurationVisible)
            ? (this.state.property && (this.state.predefinedValue || this.state.otherArtifact || this.state.option))
            : this.state.property;
        const showSelectable = this.props.direction === ArtifactDirection.In && this.state.property && this.state.property.options?.selectable;
        const showFixed = (showSelectable) ? false : this.state.property && !this.state.artifactId && !this.showOnlyArtifactsPicker;
        const canHaveCondition = this.state.property && this.props.direction === ArtifactDirection.In;
        const showOtherArtifact = (showSelectable) ? false : this.state.property && !this.state.predefinedValue;

        return <div className="property-mapping-creator">
            <section className="pick-property">
                <UI.Dropdown
                    label="Select property"
                    options={ this.state.propertiesToMapOptions }
                    onChange={ (value: any) => this.setProperty(value) }
                />
            </section>
            { this.sourceConfigurationVisible &&
            <div className="selection">
                <React.Fragment>
                    { showOtherArtifact &&
                            <section className="artifact-picker">
                                <label>Use other artifact property value</label>
                                <OtherArtifactPropertyPicker
                                    otherArtifactPickerChangeHandler={this.otherArtifactPickerChangeHandler.bind(this)}
                                    artifacts={this.props.availableArtifacts}
                                />
                            </section>
                    }
                    { showSelectable && <SelectableMapping
                        stepConfigurationFetcher={ this.props.stepConfigurationFetcher }
                        connectionId={ this.props.connectionId }
                        softwareName={ this.props.softwareName }
                        onOptionChanged={ this.onOptionChangedHandler.bind(this)} property={ this.state.property } />
                    }
                    { showFixed && <section>
                        <UI.Input
                            label={`Type fixed value`}
                            onChange={ this.onFixedValueChanged.bind(this) }/>
                        <ArtifactsAutocomplete
                            availableArtifacts={ this.props.availableArtifacts }
                            listener={ this.inputListener }
                        />
                    </section>
                    }
                    { canHaveCondition && <UI.Checkbox
                        label={`Conditional field (set only if)`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                        onChange={ this.switchConditionFieldsVisibility.bind(this) }
                    /> }
                    { canHaveCondition && this.state.showConditionField && <Condition
                        additionalDescription={`You can specify condition which if not met will prevent from setting this field.`}
                        title={ `` }
                        isRequired={ false }
                        onConditionChanged={ this.onConditionChanged.bind(this) }
                    /> }
                </React.Fragment>
            </div>
            }
            <div className="button-bottom">
                <UI.Button
                    disabled={ !isButtonEnabled }
                    text="Add"
                    onClick={ () => this.addPropertyMapping() }
                />
            </div>
        </div>;
    }

    private onFixedValueChanged(value: string, event: any) {
        this.inputListener.notify(value, event);
        this.setState({predefinedValue: value});
    }
    private switchConditionFieldsVisibility() {
        const showField = !this.state.showConditionField;
        this.setState({ showConditionField: showField });
    }

    private onOptionChangedHandler(option: OptionSourceValue) {
        this.setState({ option });
    }

    private onConditionChanged(condition: string) {
        this.condition = condition;
    }
}