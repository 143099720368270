import React from 'react';

import { SmartIntTrigger } from 'src/app/integration/smartints/definition/smart-int-trigger.type';
import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { GitHubSmartIntsEditTrigger } from './github-smart-ints-edit-trigger';
import { GitHubSmartIntsTrigger } from './github-smart-ints-trigger';
import {SuiteType} from '../../../generic/integrations/suite-type';

export class GitHubSmartintsSupport extends AppSmartIntsSupport {
    getTriggerConfigurationComponent(connectionId: number, onConfigured: (configuration: SmartIntTrigger) => void, type?: SuiteType): any {
        return <GitHubSmartIntsTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
            integrationType={type}
        />;
    }

    getEditTriggerConfigurationComponent(connectionId: number, trigger: SmartIntTrigger, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <GitHubSmartIntsEditTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
            trigger={trigger}
        />;
    }

    hasTriggerConfigurationComponent(): boolean {
        return true;
    }

    supportsAttachmentsStorage(): boolean {
        return true;
    }
}
