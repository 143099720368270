import {PipelineStep} from 'src/generic/pipeline-step';
import {SoftwareName} from 'src/software/software-name';
import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';

import {AzureStepKey} from '../azure-step-key.type';
import {AzureSearchItemsConfiguration} from './azure-search-items-configuration';
import {AzureSearchItemsNewStep} from './azure-search-items-new-step';
import {AzureSearchItemsEditStep} from './azure-search-items-edit-step';


export const AzureSearchItemsPipelineStep :  PipelineStep<AzureSearchItemsConfiguration> = {
    addStepComponentClass: AzureSearchItemsNewStep,
    editStepComponentClass: AzureSearchItemsEditStep,
    specification: {
        action: 'Search Azure Items',
        description: 'Search items in specified project',
        key: AzureStepKey.AzureSearchItems,
        software: SoftwareName.Azure,
        stepType: StepType.Trigger
    },
    preferredNameForArtifact: `AzureItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AzureSearchItemsConfiguration;
        return `Search for ${stepConfiguration.itemType.name} items in ${stepConfiguration.project.name}`;
    }
}