import {StatusRule} from './status-rule.type';

export class StatusRulesManager {
    private readonly rules: StatusRule[];
    private onRulesChangeCallback: () => void;

    constructor(rules: StatusRule[], onRulesChangeCallback: () => void) {
        this.rules = rules;
        this.onRulesChangeCallback = onRulesChangeCallback;
    }

    addRule(rule: StatusRule) {
        this.rules.push(rule);
        this.onRulesChangeCallback();
    }

    removeRule(rule: StatusRule) {
        const index = this.rules.indexOf(rule);
        if (index >= 0) {
            this.rules.splice(index, 1);
            this.onRulesChangeCallback();
        }
    }

    getRules() {
        return this.rules;
    }
}