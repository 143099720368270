import React from 'react';
import {UI} from 'src/engrator-core';
import { FormError } from 'src/engrator-core/form-error.type';
import { deleteIntegration } from './rest-api';

type Props = {
    integrationId: number;
    closeHandler: () => void;
    deleteSuccessHandler: () => void;
};

type State = {
    error?: FormError;
    isLoading: boolean,
}

export class IntegrationDeletePopUp extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isLoading: false };
    }

    render() {
        return <div className={`persistence-loader`}>
            <div className={`box`}>
                { this.state.isLoading && <React.Fragment>
                    <h3>Deleting...</h3>
                    <div className={`loader`}><UI.Loader appearance={'darkgray'} visible={true}/></div>
                </React.Fragment>
                }
                { !this.state.isLoading && <React.Fragment>
                    <h3>Are you sure you want to delete this integration?</h3>
                    <UI.Button 
                        onClick={ this.onDeleteClick.bind(this) } 
                        appearance="elementary"
                        text={ `Click to confirm` }
                    />
                </React.Fragment>
                } 
                { this.state.error && <div>
                    <p>Sorry, your integration failed to delete due to following reason:</p>
                    <UI.Message appearance={'error'}>
                        { this.state.error.message }
                    </UI.Message>
                </div> }
                { !this.state.isLoading && <UI.Button 
                    appearance="link-inline" 
                    text={`Cancel`}
                    onClick={ () => this.props.closeHandler() }
                />
                }
            </div>
        </div>
    }

    private onDeleteClick() {
        this.setState({ isLoading: true, error: undefined }, () => {
            deleteIntegration(this.props.integrationId)
                .then(() => {
                    this.props.deleteSuccessHandler();
                })
                .catch((error: FormError) => {
                    this.setState({ error, isLoading: false });
                })
        });
    }
}
