import React, {Component, useEffect} from 'react';

import { FieldError, UI } from 'src/engrator-core';
import { FormError } from '../../../../../engrator-core/form-error.type';
import { Message } from '../../../../../engrator-core/ui';
import { SmartIntIntegration } from '../../../../../generic';
import { JiraLicenseInfo } from '../../../integration-suites/details/integration-details-model';
import { fetchIntegrationDetails } from '../../../integration-suites/details/rest-api';
import SmartIntsBuilder from '../smart-ints-builder';
import { InvalidLicenseWarningBar } from './invalid-license-warning-bar';
import {IntegrationCreatedCongrats} from '../../../../engagement';
import {useGlobalState} from '../../../../global-store';
import {MainMenuItem, VerticalMenu} from '../../../../global-store/create-global-state';
import {getIntegrationVerticalMenu} from '../integration-vertical-menu';
import {IntegrationProvider} from '../integration-context';

type State = {
    error?: FormError;
};
type Props = {
    id?: number;
}
export class SmartIntsDetailsPageCmp extends Component<Props, State> {
    private integration?: SmartIntIntegration;
    private jiraLicenseInfo?: JiraLicenseInfo;
    private shouldShowLicenseWarning?: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const id = this.props.id!;
        fetchIntegrationDetails(id).then(detailsModel => {
            // remove id to so it will send CREATE request to backend
            this.jiraLicenseInfo = detailsModel.jiraLicenseInfo;
            this.shouldShowLicenseWarning = detailsModel.shouldShowLicenseWarning;
            this.integration = new SmartIntIntegration(
                detailsModel.id,
                detailsModel.name,
                detailsModel.configuration,
                detailsModel.settings,
                detailsModel.migrationConfiguration,
                detailsModel.accessLevel,
                detailsModel.ownerId,
                detailsModel.owner,
                detailsModel.createdAt,
                detailsModel.shareAccess
            );
            this.forceUpdate();
        }).catch((error: FieldError) => {
            const errorMessage = `Integration could not be fetched: ${error.message}`;
            this.setState({error: {['general']: errorMessage}});
        })
    }

    render() {
        if (this.state.error) {
            return <div className={`centralized`}>
                <Message appearance={`error-message`} message={this.state.error.general} />
            </div>
        }
        if (!this.integration) {
            return <div className={`centralized`}>
                <UI.Loader visible={true} appearance={'darkgray'}/>
                <span>Loading</span>
            </div>
        }
        return (
            <React.Fragment>
                {this.jiraLicenseInfo && this.jiraLicenseInfo.active === false && this.shouldShowLicenseWarning && <InvalidLicenseWarningBar/>}
                <IntegrationCreatedCongrats />
                <IntegrationProvider
                    integration={ this.integration }
                >
                    <SmartIntsBuilder integration={this.integration}/>
                </IntegrationProvider>
            </React.Fragment>
        )
    }
}

export function SmartIntsDetailsPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    const integrationId = props.match.params.id;
    useEffect(() => {
        fetchIntegrationDetails(integrationId)
            .then(detailsModel => {
                const integration = new SmartIntIntegration(
                    detailsModel.id,
                    detailsModel.name,
                    detailsModel.configuration,
                    detailsModel.settings,
                    detailsModel.migrationConfiguration,
                    detailsModel.accessLevel
                );
                const menu: VerticalMenu = getIntegrationVerticalMenu(integrationId, integration);
                setMainMenuItem(MainMenuItem.Workflows);
                setMenu(menu, integration);
            });
    }, []);
    return <SmartIntsDetailsPageCmp
        id={ props.match.params.id}
    />
}
