import createGlobalState, {MainMenuItem} from './create-global-state';

export const { useGlobalState, setGlobalState } = createGlobalState({
    menu: {
        header: '',
        items: []
    },
    breadcrumbs: [],
    currentUrl: '',
    mainMenuItem: MainMenuItem.Home
});
