import React from 'react';

import {UI} from 'src/engrator-core';

import {SystemApiKey} from './system-api-key.type';

export function createSystemApiKeysTableGridData(errors: SystemApiKey[], editApiKey: (systemApiKey: SystemApiKey) => void): UI.TableGridData {
    return {
        data: errors.map(systemApiKey => [
            <UI.Button onClick={ () => editApiKey(systemApiKey)} text={ `#${systemApiKey.id}` } appearance={'link-inline'} />,
            <UI.Button onClick={ () => editApiKey(systemApiKey)} text={ `${systemApiKey.name}` } appearance={'link-inline'} />,
            systemApiKey.key,
            systemApiKey.createByUsername,
            systemApiKey.createdAt,
            <UI.EntityStatus status={ systemApiKey.status as unknown as 'Enabled' | 'Disabled' } />
        ]),
        headers: ['ID', 'Name', 'Key', 'Created By', 'Created At', 'Status'],
    };
}