import React from 'react';
import {JiraProjectIssuetypePicker, JiraProjectIssuetypePickerData} from '../steps/common';
import {SmartIntTrigger} from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {UI} from 'src/engrator-core';
import {SoftwareName} from '../../software-name';
import {SuiteType} from '../../../generic/integrations/suite-type';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
    type?: SuiteType;
};
type State = {};

export class JiraSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.Jira,
            artifactName: '',
            className: '',
            configuration: {
                project: undefined,
                jql: ''
            },
            connectionId: this.props.connectionId
        };
        if (this.props.type === SuiteType.Git) {
            this.onGitIntegrationSelected();
        }
    }

    render() {
        return <React.Fragment>
            {this.props.type !== SuiteType.Git && !this.smartIntTrigger.configuration.project &&
                <JiraProjectIssuetypePicker
                    connectionId={this.props.connectionId}
                    onDataChanged={this.onDataChanged.bind(this)}
                    configureIssueType={false}
                />}
            {this.smartIntTrigger.configuration.project && <UI.FormGroup>
                <UI.LabelValue
                    label={`Project`}
                    value={this.smartIntTrigger.configuration.project.name}
                />
            </UI.FormGroup>
            }
        </React.Fragment>
    }

    private switchFetchRenderedFields(newValue: string) {
        this.smartIntTrigger.configuration.fetchRenderedFields = (newValue === 'true') ? true : false;
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.jql = newValue;
    }

    private onDataChanged(data: JiraProjectIssuetypePickerData) {
        if (data.project) {
            this.smartIntTrigger.artifactName = data.project.name;
            this.smartIntTrigger.configuration.project = data.project;
            this.props.onConfigured(this.smartIntTrigger!);
            this.forceUpdate();
        }
    }

    private onGitIntegrationSelected() {
        this.smartIntTrigger.artifactName = '';
        this.props.onConfigured(this.smartIntTrigger!);
        this.forceUpdate();
    }
}
