import { ConnectionModel } from './../connection-model';
import {AuthMethod} from '../../../../generic/connections/auth-method.type';

function getAuthMethodName(authMethod: string) {
    if (authMethod === AuthMethod.BasicAuth) {
        return 'Basic Auth';
    } else if (authMethod === AuthMethod.BearerToken) {
        return 'Bearer Token';
    } else if (authMethod === AuthMethod.OAuth) {
        return 'OAuth 2.0';
    }
    return authMethod;
}

export function createConnectionListItem(itemData: any): ConnectionModel {
    return {
        id: itemData.id,
        name: itemData.name,
        url: itemData.url,
        login: itemData.login,
        softwareName: itemData.softwareName,
        authMethod: itemData.authMethod,
        authData: itemData.authData,
        azureCollection: itemData.azureCollection,
        authMethodHumanName: getAuthMethodName(itemData.authMethod),
        owner: itemData.owner,
        shareAccess: itemData.shareAccess,
        accessLevel: itemData.accessLevel
    };
}