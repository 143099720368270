import React from 'react';
import {SmartIntPlugin} from './plugins';
import {RemoveButton, SettingsButton} from '../../../../../engrator-core/ui';

type Props = {
    plugin: SmartIntPlugin;
    configureHandler: () => void;
    removeHandler: () => void;
};
type State = {
    showConfigureModal: boolean;
};

export class PluginComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {showConfigureModal: false};
    }

    render() {
        return <div className={`plugin-cmp`}>
            <div className={`name`}>
                {this.props.plugin.name}
                <p>{ this.props.plugin.description }</p>
            </div>
            <div>
                <RemoveButton
                    onClick={() => this.props.removeHandler()}
                />
                <SettingsButton
                    onClick={() => this.props.configureHandler()}
                />
            </div>
        </div>;
    }

    private configurePluginClicked() {
    }
}
