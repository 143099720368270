import React, {Component} from 'react';
import {UI} from 'src/engrator-core';
import {ArchiverTabBar, ArchiverTabBarItems} from '../tabbar';

type State = {
};
type Props = {
};

export class ArchivedItemsConfigurationPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { };
    }

    componentDidMount() {
    }

    render() {
        return <UI.Page>
            <ArchiverTabBar
                allItemsCount={ undefined }
                exportToCSVHandler={ undefined }
                currentItem={ArchiverTabBarItems.Configuration}
            />
        </UI.Page>;
    }
}