import React from 'react';

import { EditPipelineStepComponentProps } from 'src/generic';
import { ArtifactDirection } from 'src/generic/artifacts';
import { GetintArchiverStepContextDisplay } from '../common';
import { GetintArchiverCreateItemConfiguration } from './monday-create-item-configuration';

type Props = {} & EditPipelineStepComponentProps;
type State = {};

export class GetintArchiverCreateItemEditStep extends React.Component<Props, State> {
    private configuration: GetintArchiverCreateItemConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as GetintArchiverCreateItemConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
        this.props.showArtifactBuilder(ArtifactDirection.In);
    }

    render() {
        return <React.Fragment>
            <GetintArchiverStepContextDisplay context={this.configuration.context}/>
        </React.Fragment>;
    }
}
