import React from 'react';
import {Component} from 'react';
import {ArchivedItemCommentData} from '../rest-api';

type State = {};
type Props = {
    comment: ArchivedItemCommentData;
};

export class ArchivedItemComment extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    close() {

    }

    render() {
        return <React.Fragment>
            <div className={`comment`}>
                <div className={`comment-header`}>
                    <span>{ this.props.comment.author }</span> added a comment - <span>{ this.props.comment.dateTime }</span>
                </div>
                <div className={`content html-content`} dangerouslySetInnerHTML={{ __html: this.props.comment.content }}>
                </div>
            </div>
        </React.Fragment>;
    }
}