import { axiosWrapper, FieldError } from '../../../engrator-core';
import { DropdownOption } from '../../../engrator-core/ui';

export const ENDPOINT_URL = '/connectors/monday';


export function fetchWorkspaces(connectionId: number): Promise<DropdownOption[]> {
    return new Promise((resolve, reject) => {
        try {
            const url = '/connectors/freshservice/meta/' + connectionId + '/workspaces';
            axiosWrapper.get(url)
                .then((data: any) => {
                    const options = data.map((option: any) => ({
                        value: option.id,
                        label: option.name,
                        object: {
                            id: option.id,
                            name: option.name
                        }
                    }));
                    resolve(options);
                })
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}