import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {AzureFindItemConfiguration} from './azure-find-item-configuration';
import {EntityFormHelper, UI} from 'src/engrator-core';
import {ArtifactDirection} from '../../../../generic/artifacts';
import {AzureProjectItemTypePicker, AzureProjectItemTypePickerData} from '../common';

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class AzureFindItemNewStep extends React.Component<Props, State> {
    private configuration: AzureFindItemConfiguration;
    private entityFormHelper: EntityFormHelper;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration as AzureFindItemConfiguration;
        this.entityFormHelper = this.getEntityFormHelper();
    }

    render() {
        return <React.Fragment>
            { this.props.formError?.general && <UI.Message
                appearance={`error`}
            >{ this.props.formError.general}</UI.Message>
            }
            <AzureProjectItemTypePicker
                connectionId={ this.props.context.connectionId }
                onDataChanged={ this.projectPickerDataChanged.bind(this) }
                formError={ this.props.formError }
            />
            <UI.FormGroup
                label={`Item Key`}
                isRequired={ true }
                description={`Provide key of the item that should be found. You can use variables e.g. properties from other Artifacts.`}
                error={ this.props.formError?.id }>
                <UI.Input onChange={ this.entityFormHelper.onChangeHandler('id') } />
            </UI.FormGroup>
        </React.Fragment>;
    }

    private getEntityFormHelper() {
        return new EntityFormHelper((propertyName: 'id', newValue) => {
            this.configuration[propertyName] = newValue;
            this.configurationChanged();
        });
    }

    private projectPickerDataChanged(data: AzureProjectItemTypePickerData) {
        if (data.itemType) {
            this.configuration.itemType = data.itemType;
        }
        if (data.project) {
            this.configuration.project = data.project;
        }
        this.configurationChanged();
    }

    private configurationChanged() {
        if (this.configuration.project && this.configuration.itemType && this.configuration.id) {
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }
}