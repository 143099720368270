import React from 'react';
import { UI } from 'src/engrator-core';
import { TimeDisplay } from 'src/engrator-core/ui';
import { TenantInfo } from '../tenants';
import { TenantLinks } from '../tenants/tenant-links';
import { disableTenant, fetchClusterTenants } from './../rest-api';
import { ListFilters, TenantsListFilters } from './../tenants/tenants-list-filters';

type Props = {};
type State = {
    showCreateTenantPopUp: boolean;
    setTenantProperties: string;
};

export class TenantsLicenses extends React.Component<Props, State> {
    private tenants: TenantInfo[] = [];

    constructor(props: Props) {
        super(props);
        this.load();
        this.state = {
            setTenantProperties: '',
            showCreateTenantPopUp: false,
        };
    }

    render() {
        return <div>
            {this.tenants && <React.Fragment>
                <UI.Header
                    title={'Tenants Licenses'}
                    buttons={[]}
                />
                <TenantsListFilters search={this.filterList.bind(this)}/>
                <table className="styled-table" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Created</th>
                            <th>Last act.</th>
                            <th>Int / Conn</th>
                            <th>Licenses</th>
                            <th>State</th>
                            <th>Disable</th>
                        </tr>
                    </thead>
                    {this.tenants.filter(tenant => tenant.visible)
                        .map((tenant, index) => <tr key={index}>
                            <td>
                                <p><a href={tenant.url} target="_blank">{tenant.tenantName}</a></p>
                                <p>{tenant.jiraUrl} | {tenant.preferredApps} | {tenant.uniqueId} </p>
                                <TenantLinks jiraUrl={tenant.jiraUrl}/>
                            </td>
                            <td><TimeDisplay dateTime={tenant.createdAt}/></td>
                            <td><TimeDisplay dateTime={tenant.activity?.lastActivityDateTime}/></td>
                            <td>{tenant.activity?.integrationsCount} / {tenant.activity?.connectionsCount}</td>
                            <td>
                                {tenant.activity?.jiraLicenses.map((license, index) => {
                                    return <p
                                        key={index}>{String('paid: ' + license.paid) + ', active: ' + String(license.active) + ', ' + license.errorMessage + ', lastcheck: ' + license.lastCheckTs + ', in.since: ' + license.jiraLicenseInactiveSince}</p>;
                                })}
                            </td>
                            <td>{tenant.state}</td>
                            <td style={{ width: '140px' }} className={`actions`}>
                                {tenant.state === 'Enabled' &&
								<UI.Button appearance="secondary" onClick={() => this.disable(tenant)} text="Disable"/>}
                            </td>
                        </tr>)}
                </table>
            </React.Fragment>}
        </div>;
    }

    private filterList(filters: ListFilters) {
        this.tenants.forEach((tenant) => {
            tenant.visible = true;
            if (filters.query && !(tenant.jiraUrl.toLowerCase()
                .indexOf(filters.query.toLowerCase()) > -1 || tenant.url.toLowerCase()
                .indexOf(filters.query.toLowerCase()) > -1)) {
                tenant.visible = false;
            }
            if (!filters.showDisabled && tenant.state === 'Disabled') {
                tenant.visible = false;
            }
        });
        this.forceUpdate();
    }

    private load() {
        const headers: any = {};
        const additionalRequestData = {
            headers,
            timeout: 300 * 1000,
        };
        return fetchClusterTenants()
            .then(response => {
                this.tenants = response;
                this.tenants.forEach((tenant) => {
                    tenant.visible = true;
                    tenant.jiraUrl = tenant.jiraUrl || '';
                    console.log(tenant.activity?.jiraLicenses);
                });
                this.filterList({
                    query: '',
                    showDisabled: false,
                });
            })
            .catch(error => {
                console.log(error);
                alert(JSON.stringify(error));
            });
    }

    private closePopUps() {
        this.setState({setTenantProperties: '', showCreateTenantPopUp: false});
    }

    private onTenantCreated() {
        this.load();
    }

    private setTenantProperty(tenant: TenantInfo): void {
        this.setState({setTenantProperties: tenant.tenantName});
    }

    private disable(tenant: TenantInfo) {
        if (!window.confirm('Are you sure you want to disable this tenant?')) {
            return;
        }
        disableTenant(
            tenant.tenantName,
        )
            .then((result) => {
                if (result) {
                    tenant.state = 'Disabled';
                    this.forceUpdate();
                }
            });
    }
}
