import {useGlobalState} from '../../../global-store';
import React, {useEffect, useState} from 'react';
import {SmartIntIntegration} from '../../../../generic';
import {fetchIntegrationDetails} from '../../integration-suites/details/rest-api';
import {getIntegrationVerticalMenu} from './integration-vertical-menu';
import {MainMenuItem} from '../../../global-store/create-global-state';
import {GitRepositoriesListPageCmp} from '../../git/git-repositories-list-page';

export function IntegrationGitRepositoriesPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [integration, setIntegration] = useState<SmartIntIntegration>();
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    const id = props.match.params.integrationId;
    useEffect(() => {
        fetchIntegrationDetails(id)
            .then(detailsModel => {
                const integration = new SmartIntIntegration(
                    detailsModel.id,
                    detailsModel.name,
                    detailsModel.configuration,
                    detailsModel.settings,
                    detailsModel.migrationConfiguration,
                    detailsModel.accessLevel
                );
                const menu = getIntegrationVerticalMenu(id, integration);
                setMenu(menu);
                setMainMenuItem(MainMenuItem.Workflows);
                setIntegration(integration);
            });
    }, []);
    return <> { integration &&
        <GitRepositoriesListPageCmp
            integrationId={id}
        />
    }</>
}