export enum ArtifactDirection {
    In = 'In',
    Out = 'Out'
}

export function getDirectionName(direction: ArtifactDirection) {
    if (direction === ArtifactDirection.In) {
        return 'In';
    }
    return 'Out';
}