import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';
import {ZendeskStepKey} from '../zendesk-step-key.type';
import {ZendeskCreateItemConfiguration} from './zendesk-create-item-configuration';
import {ZendeskCreateItemEditStep} from './zendesk-create-item-edit-step';
import {ZendeskCreateItemNewStep} from './zendesk-create-item-new-step';

export const ZendeskCreateItemPipelineStep: PipelineStep<ZendeskCreateItemConfiguration> = {
    addStepComponentClass: ZendeskCreateItemNewStep,
    editStepComponentClass: ZendeskCreateItemEditStep,
    specification: {
        action: 'Create Zendesk Ticket',
        description: 'Create Zendesk ticket',
        key: ZendeskStepKey.ZendeskCreateItem,
        software: SoftwareName.Zendesk,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `createdTicket`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ZendeskCreateItemConfiguration;
        return `Create ticket`;
    }
}