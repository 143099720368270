import {axiosWrapper, GetintUserWorkspace, GetintWorkspace} from '../../../engrator-core';
import {DropdownOption, EntityStatusValue} from '../../../engrator-core/ui';

const ENDPOINT_URL = '/system/users';

export enum GetintUserRole {
    ClusterAdmin = 'ClusterAdmin',
    Normal = 'Normal',
    Support = 'Support',
}

export enum GetintNewUserEnvironment {
    JiraCloud = 'JiraCloud',
    JiraServer = 'JiraServer',
    Cluster = 'Cluster'
}

export type GetintShortUser = {
    email: string;
    username: string;
    role: GetintUserRole;
    id: number;
    workspacesCount: number;
    status: EntityStatusValue;
};

export type AddUserToWorkspaceData = {
    userId: number;
    role?: GetintUserRole;
    workspaceId?: string;
};

export type EditMetaData = {
    availableWorkspaces: GetintWorkspace[];
    userWorkspaces: GetintUserWorkspace[];
    roles: GetintUserRole[];
};

export type GetintUser = {
    email: string;
    username: string;
    isSsoUser: boolean;
    password: string;
    repeatPassword: string;
    role?: GetintUserRole;
}

export function getAvailableRoles(): DropdownOption[] {
    return [{
        value: GetintUserRole.Normal,
        label: GetintUserRole.Normal
    },{
        value: GetintUserRole.Support,
        label: GetintUserRole.Support
    }, {
        value: GetintUserRole.ClusterAdmin,
        label: GetintUserRole.ClusterAdmin
    }];
}

export function createEmptyGetintUser(): GetintUser {
    return {
        email: '',
        isSsoUser: false,
        password: '',
        repeatPassword: '',
        username: ''
    };
}

export function createUser(env: GetintNewUserEnvironment, user: GetintUser): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}`, {mode: env, user})
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}`)
                .then((data: any) => {
                    resolve({
                        headers: ['Id', 'Username', 'Email', 'Role', 'Workspaces', 'Status', 'Actions'],
                        data: data
                    });
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function changeUserStatus(userId: number, newStauts: EntityStatusValue): Promise<any> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.put(`${ENDPOINT_URL}/${userId}`, { status: newStauts })
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function changeUserPassword(userId: number, newPassword: string, repeatedPassword: string): Promise<any> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.put(`${ENDPOINT_URL}/${userId}`, { newPassword, repeatedPassword })
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchEditMetaData(userId: number): Promise<EditMetaData> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/${userId}/data`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function addUserToWorkspace(data: AddUserToWorkspaceData): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}/workspace/access`, data)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function deleteUserFromWorkspace(userId: number, workspaceId: string): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.delete(`${ENDPOINT_URL}/workspace/${ workspaceId}/access/${ userId }`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}