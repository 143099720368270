import React from 'react';

import './SceneContainer.css';
import RightPanel from './../right-panel/RightPanel';
import SnippetsListScene from './SnippetsListScene';
import {SceneComponentType, SceneManager, SceneTransitionPopUpData, SceneTransitionType} from './../scene-manager';
import SecondaryScene from './SecondaryScene';
import {DesignerEventType, EventsBus, PipelineIntegrationStep, StepedIntegration} from 'src/generic';
import {GenericSoftwareStep, IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {IntegrationPipelineSettings} from '../../../app/integration/integration-suites';
import {PipelineSettings} from '../../../app/integration/integration-suites/details/pipeline-settings-type';

type SceneContainerProps = {
    integration: StepedIntegration,
    artifactsFactory: any,
    eventsBus: EventsBus
};

type SceneContainerState = {
    showRightPanel: boolean,
    rightPanel: any,
    SecondaryScene: any,
    sceneComponents: {[id: number]: any}
};

export class SceneContainer extends React.Component<SceneContainerProps, SceneContainerState> {
    state: SceneContainerState;
    sceneManager: SceneManager;
    stepToEdit?: PipelineIntegrationStep;

    constructor(props: SceneContainerProps) {
        super(props);
        this.state = {
            showRightPanel: false,
            rightPanel: null,
            SecondaryScene: null,
            sceneComponents: {}
        };
        this.sceneManager = new SceneManager(this.updateSceneComponentHandler.bind(this), this.props.eventsBus);
        this.props.eventsBus.subscribe(DesignerEventType.StepsChangedEvent, (eventData: any) => {
            if (this.stepToEdit && eventData.configuration) {
                this.stepToEdit.updateConfiguration(eventData.configuration as IntegrationStepConfiguration);
            }
            this.sceneManager.clearScene();
            this.forceUpdate();
        });
        this.props.eventsBus.subscribe(DesignerEventType.ShowSettings, () => {
            this.showSettings();
        });
    }

    componentDidUpdate(prevProps: SceneContainerProps, prevState: any) {
        // if (prevProps.popUpToPresent !== this.props.popUpToPresent) {
        //     const transitionData: SceneTransitionPopUpData = Object.assign({
        //         popUpComponent: this.props.popUpToPresent
        //     }, this.getTransitionData());
        //     this.sceneManager.transition(SceneTransitionType.PopUp, transitionData);
        // }
    }

    showSettings() {
        this.sceneManager.transition(SceneTransitionType.ViewForRightPanel, Object.assign(
            this.getTransitionData(),{
                rightPanelView: <IntegrationPipelineSettings
                    settings={ this.props.integration.settings }
                    closeHandler={ () => this.sceneManager.clearScene() }
                    persistSettingsHandler={ (settings: PipelineSettings) => this.props.integration.settings = settings }
                />
            }
        ));
    }

    updateSceneComponentHandler(sceneElementType: SceneComponentType, element: any): void {
        console.log('update scene component', sceneElementType, element);
        const { sceneComponents } = this.state;
        sceneComponents[sceneElementType] = element;
        this.setState({ sceneComponents });
    }

    addStepHandler() {
        const stepsFilter = (this.props.integration.getSteps().length === 0)
            ? ((step: GenericSoftwareStep) => {
                return step.getStepSpecification().stepType === StepType.Trigger
            })
            : ((step: GenericSoftwareStep) => {
                return step.getStepSpecification().stepType !== StepType.Trigger
            });
        this.sceneManager.transition(SceneTransitionType.AddStep, Object.assign({
            addStephandler: (step: any) => {
                this.props.integration.addStep(step);
                this.sceneManager.clearScene();
            },
            stepsFilter
        },
        this.getTransitionData()
        ));
    }

    openDebuggingReportHandler(debuggingOutput: any) {
        const transitionData: SceneTransitionPopUpData = Object.assign({
            popUpComponent: <div>Report ofdebugging</div>
        }, this.getTransitionData());
        // this.sceneManager.transition(SceneTransitionType.PopUp, transitionData);
    }

    editStepHandler(step: PipelineIntegrationStep) {
        const deleteStepHandler = (this.props.integration.isLastStep(step))
            ? () => {
                this.props.integration.deleteStep(step);
            } : undefined;
        this.stepToEdit = step;
        this.sceneManager.transition(SceneTransitionType.EditStep, Object.assign({
            stepToEdit: step,
            deleteStepHandler
        }, this.getTransitionData()));
    }

    getTransitionData() {
        return {
            rightPanelView: null,
            stepsFactory: this.props.integration.getStepsFactory(),
            connectionFactories: this.props.integration.getConnectionFactories(),
            artifactsFactory: this.props.artifactsFactory
        };
    }

    render() {
        const mainClassNames = 'SceneContainer ' + ((this.state.sceneComponents[SceneComponentType.RightPanel]) ? 'right-panel-active' : '');
        return <div className={ mainClassNames }>
            <div>
                { this.state.sceneComponents[SceneComponentType.MainScene] && <SecondaryScene
                    onCloseHandler={ () => this.sceneManager.clearScene() } >
                    { this.state.sceneComponents[SceneComponentType.MainScene] }
                </SecondaryScene>
                }
                <React.Fragment>
                    <SnippetsListScene
                        openDebuggingReportHandler={ this.openDebuggingReportHandler.bind(this) }
                        eventsBus={ this.props.eventsBus }
                        steps={ this.props.integration.getSteps() }
                        onStepEdit={ (step:any) => this.editStepHandler(step) }
                        addStepClicked={ this.addStepHandler.bind(this) }
                    />
                </React.Fragment>
            </div>
            <div className="shadowing"></div>
            { this.state.sceneComponents[SceneComponentType.RightPanel] && <React.Fragment>
                <RightPanel close={ () => this.sceneManager.clearScene() }>
                    { this.state.sceneComponents[SceneComponentType.RightPanel] }
                </RightPanel>
            </React.Fragment> }
        </div>;
    }
}
