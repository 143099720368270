import {DataArtifact, PropertyDefinition, PropertySource, ArtifactsFactory, ArtifactDirection} from './../artifacts';
import { ConfiguredProperty } from '../artifacts/data-artifact';
import { PropertySourceType } from '../artifacts/property-source/property-source-type';
import { StepArtifact } from '../software/step';
import { Artifacts } from 'src/generic';

export type OnPropertiesChangeListener = (properties: Array<ConfiguredProperty>, persistedArtifactBuilder: any) => void;

export class DataArtifactBuilder {
    configuredProperties: Array<ConfiguredProperty>;
    artifactsFactory: ArtifactsFactory;
    dataArtifactToBuild: DataArtifact;
    onPropertiesChangeListener: OnPropertiesChangeListener;
    private direction: ArtifactDirection;
    private buildTime: Date;

    constructor(direction: Artifacts.ArtifactDirection, dataArtifactToBuild: DataArtifact, artifactsFactory: ArtifactsFactory, onPropertiesChangeListener: OnPropertiesChangeListener) {
        if (!dataArtifactToBuild) {
            throw '`dataArtifactToBuild` should be not null';
        }
        if (!artifactsFactory) {
            throw '`artifactsFactory` should be not null';
        }
        this.direction = direction;
        this.dataArtifactToBuild = dataArtifactToBuild;
        this.artifactsFactory = artifactsFactory;
        this.configuredProperties = this.dataArtifactToBuild.getConfiguredProperties();
        this.onPropertiesChangeListener = onPropertiesChangeListener;
        this.buildTime = new Date();
    }

    getBuildTime(): Date {
        return this.buildTime;
    }

    async getAvailableProperties(): Promise<Array<PropertyDefinition>> {
        const allPropertiesDefinitions = await this.dataArtifactToBuild.getPropertiesDefinitions();
        const idsOfPropertiesAlreadySetUp = this.configuredProperties.map((confguredProperty: ConfiguredProperty) => confguredProperty.property.id);
        return allPropertiesDefinitions.filter(
            (propertyDefinition: PropertyDefinition) => idsOfPropertiesAlreadySetUp.indexOf(propertyDefinition.id) === -1
        );
    }

    setUpProperty(propertyToSet: any, propertySource: any) {
        this.configuredProperties.push({
            property: propertyToSet,
            source: propertySource
        });
        this.onPropertiesChangeListener(this.configuredProperties, this.persist());
    }

    getDirection(): Artifacts.ArtifactDirection {
        return this.direction;
    }


    getArtifactName(): string {
        return this.dataArtifactToBuild.getName();
    }

    setArtifactName(newName: string) {
        this.dataArtifactToBuild.setName(newName);
        this.onPropertiesChangeListener(this.configuredProperties, this.persist());
    }

    removeProperty = (configuredProperty: ConfiguredProperty) => {
        const index = this.configuredProperties.indexOf(configuredProperty);
        if (index >= 0) {
            this.configuredProperties.splice(index, 1);
            this.onPropertiesChangeListener(this.configuredProperties, this.persist());
        }
    };

    getConfiguredProperties() {
        return this.configuredProperties;
    }

    getArtifactsForPropertySource = () => this.artifactsFactory.getAvailableArtifacts();

    getAvailableArtifactsWithProperties = () => {
        const idsOfPropertiesFromOtherArtifacts = this.configuredProperties
            .filter((property: ConfiguredProperty) => property.source.sourceType === PropertySourceType.OtherArtifact)
            .map((property: ConfiguredProperty) => ({
                artifactId: (property.source as PropertySource.OtherArtifactPropertySource).artifactId,
                propertyId: (property.source as PropertySource.OtherArtifactPropertySource).propertyDefinition.id
            }));
        const availableArtifacts: Array<Object> = [];
        const artifacts = this.artifactsFactory.getAvailableArtifacts();
        artifacts.forEach((artifact: StepArtifact) => {
            const availableArtifact: any = {
                id: artifact.name,
                propertyDefinitions: []
            };
            artifact.properties.forEach((property: any) => {
                const isMappedAlready = idsOfPropertiesFromOtherArtifacts
                    .filter(obj => obj.artifactId === availableArtifact.id && obj.propertyId === property.id)
                    .length > 0;
                if (!isMappedAlready) {
                    availableArtifact.propertyDefinitions.push(property);
                }
            });
            availableArtifacts.push(availableArtifact);
        });
        return availableArtifacts;
    };

    persist(): any {
        return {
            name: this.dataArtifactToBuild.getName(),
            properties: this.configuredProperties.map((prop) => ({
                property: prop.property,
                source: prop.source,
                isValidDataType: false
            }))
        };
    }

    restore(persistedState: any): void {
        // persistedState.forEach((prop: any) => {
        //     const propertySource = (prop.source.artifactId)
        //     ? newFunction(prop)
        //     : new PropertySource.PredefinedPropertySource(prop.source.value)
        //     this.setUpProperty(
        //         new PropertyDefinition(prop.property.id, prop.property.name, prop.property.dataType),
        //         propertySource
        //     )
        // });
    }
}
