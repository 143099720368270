import React from 'react';
import {
    NavLink as ReactNavLink
} from 'react-router-dom';

type NavLinkProps = {
    path: string,
    appearance?: 'dark';
    dataSel?: string;
};

export class NavLink extends React.Component<NavLinkProps> {
    render() {
        const className = (this.props.appearance) ? this.props.appearance : '';
        return <span data-sel={ this.props.dataSel } className={`engrator-nav-link ${className}`}>
            <ReactNavLink to={ this.props.path }>{ this.props.children }</ReactNavLink>
        </span>;
    }
}