import React from 'react';
import { UI } from 'src/engrator-core';
import { importConnectionScript} from './rest-api';
import {FormError} from '../../../../../engrator-core/form-error.type';
import {ConnectionModel} from '../../connection-model';

type Props = {
    connection: ConnectionModel;
    closeHandler: (refresh: boolean) => void;
};

type State = {
    isLoading: boolean;
    script: string;
    encryptionKey: string;
    connectionName: string;
    isKeyVisible: boolean;
    error?: FormError;
    connectionImported: boolean;
};

export class ConnectionImport extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isKeyVisible: false,
            isLoading: false,
            encryptionKey: '',
            script: '',
            connectionName: '',
            connectionImported: false
        };
    }

    render() {
        return <UI.FullScreenModal
            showPrimaryBtn={ !this.state.connectionImported }
            header={`Import connection`}
            primaryBtnHandler={ () => this.applyClicked() }
            closeBtnHandler={ () => this.closeClicked() }
            primaryBtnText={`Import`}
            isPrimaryDisabled={ !this.state.connectionName || !this.state.script || !this.state.encryptionKey }
        >
            { this.state.connectionImported && <UI.Message appearance={'success'}>Connection was successfully imported.</UI.Message> }
            { this.state.error && <UI.Message appearance={'error'}>
                <p>Something went wrong: { this.state.error.message }</p>
            </UI.Message> }
            { !this.state.connectionImported && <React.Fragment>
                <UI.Form>
                    <UI.FormGroup
                        label={`Name`}
                        isRequired={ true }
                        description={`Name`}>
                        <UI.Input
                            type={ `text` }
                            onChange={(newValue) => this.setState({ connectionName: newValue })}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Script`}
                        isRequired={ true }
                        description={`Script`}>
                        <UI.Textarea
                            rows={10}
                            onChange={(newValue) => this.setState({ script: newValue })}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Encryption key`}
                        isRequired={ true }
                        description={`Key that will be used to encrypt credentials data`}>
                        <UI.Input
                            type={ (this.state.isKeyVisible) ? 'text' : 'password' }
                            onChange={(newValue) => this.setState({ encryptionKey: newValue })}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup>
                        <UI.Checkbox
                            label={`Show encryption key`}
                            onChange={ () => this.switchKeyVisibility() }
                            checkedValue={`true`}
                            uncheckedValue={`false`}
                        />
                    </UI.FormGroup>
                </UI.Form>
            </React.Fragment> }
        </UI.FullScreenModal>
    }

    private async switchKeyVisibility() {
        await this.setState({ isKeyVisible: !this.state.isKeyVisible });
    }

    private async applyClicked(): Promise<boolean> {
        if (this.state.encryptionKey && this.state.script) {
            try {
                await this.setState({ isLoading: true });
                const script = await importConnectionScript(this.state.connectionName, this.state.script, this.state.encryptionKey);
                await this.setState({ isLoading: false, connectionImported: true });
                return false;
            } catch (error) {
                this.setState({ isLoading: false, error });
            }
        }
        return false;
    }
    private closeClicked() {
        this.props.closeHandler(this.state.connectionImported);
    }
}
