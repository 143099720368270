import React, {useEffect} from 'react';
import 'src/styles/css/app.css';

import { AppRenderer } from './app-renderer';
import {useGlobalState} from './global-store';
import {AuthToken, switchBodyScroll} from '../engrator-core';

function App() {
    const [, setCurrentUrl] = useGlobalState('currentUrl');
    useEffect(() => {
        let previousUrl = '';

        const observer = new MutationObserver(() => {
            if (window.location.href !== previousUrl) {
                // console.log(`URL changed from ${previousUrl} to ${window.location.href}`);
                previousUrl = window.location.href;
                checkIfShouldUsePostMessageForAuth();
                setCurrentUrl(previousUrl);
                // When page url change, remove property noscroll which is disabling vertical scrolling.
                // Otherwise when POPUP is visible and scroll is disabled, if user press BACK arrow in browser
                // he will not get vertical scrolling enabled
                switchBodyScroll(true);
            }
        });
        const config = { subtree: true, childList: true };
        checkIfShouldUsePostMessageForAuth();
        // start observing change
        observer.observe(document, config);
    }, []);
    return <AppRenderer />;
}

function checkIfShouldUsePostMessageForAuth(): void {
    if (window.location.href.indexOf('?usePostMessage=true') > 0) {
        AuthToken.setToUsePostMessage();
    }
}
export default App;
