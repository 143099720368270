import React from 'react';

import {EditPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper, UI} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';
import {WrikeSearchItemsConfiguration} from './wrike-search-items-configuration';
import {WrikeStepContextDisplay} from '../common';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class WrikeSearchItemsEditStep extends React.Component<Props, State> {
    private configuration: WrikeSearchItemsConfiguration;
    private entityFormHelper: EntityFormHelper;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as WrikeSearchItemsConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'customJQL', newValue) => {
            this.configuration[propertyName] = newValue;
        });
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <WrikeStepContextDisplay context={ this.configuration.context } />
            <UI.FormGroup>
                <UI.Checkbox
                    checkedValue={ 'true' }
                    uncheckedValue={ 'false' }
                    defaultValue={ this.configuration.onlyNewChanges.toString() }
                    label="Only tasks that changed from last integration"
                    onChange={ (newValue) => { this.onlyNewChanged(newValue) }   } />
            </UI.FormGroup>
        </React.Fragment>;
    }

    private onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }
}