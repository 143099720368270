import React, { useState, KeyboardEvent, useEffect } from 'react';
import { UI } from 'src/engrator-core';
import { NewConnectionData } from 'src/connections';
import { ConnectionModel } from 'src/app/integration/connections/connection-model';
import {AuthMethod} from '../../generic/connections/auth-method.type';

type Props = {
    setCredential: (field: string, value: string) => void;
    handleSecondStepOnKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
    newConnectionData: NewConnectionData;
    connectionToEdit?: ConnectionModel;
};

type VisibleFields = {
    clientId: boolean;
    clientSecret: boolean;
    password: boolean;
};

export default function JiraOauth({
    setCredential,
    handleSecondStepOnKeyDown,
    newConnectionData,
    connectionToEdit
}: Props) {
    const NEW_CONNECTION_DEFAULT_VALUES = {
        oAuth: {
            clientId: newConnectionData.authCredentials?.clientId,
            clientSecret: newConnectionData.authCredentials?.clientSecret,
            login: newConnectionData.authCredentials?.usernmae,
            password: newConnectionData.authCredentials?.password
        }
    };

    const [authMethod, setAuthMethod] = useState<AuthMethod>(AuthMethod.OAuth);
    const [, setInitialAuthMethod] = useState<AuthMethod>(AuthMethod.BasicAuth);
    const [visibleFields, setVisibleFields] = useState<VisibleFields>({clientId: false, clientSecret: false, password: false});

    useEffect(() => {
        if (connectionToEdit) {
            setAuthMethod(connectionToEdit.authMethod as AuthMethod);
            setInitialAuthMethod(connectionToEdit.authMethod as AuthMethod);
        } else {
            setVisibleFields({
                clientId: true,
                clientSecret: true,
                password: true
            });
        }
    }, []);

    useEffect(() => {
        newConnectionData.authMethod = authMethod;
        if (newConnectionData.auth) {
            newConnectionData.auth = {
                method: authMethod
            };
        }
    }, [authMethod]);

    function showField(field: 'clientId' | 'clientSecret' | 'password'): void {
        setVisibleFields((prevFields: VisibleFields) => ({
            ...prevFields,
            [field]: !prevFields[field]
        }));
    }

    function shouldShowSetButton(field: 'clientId' | 'clientSecret' | 'password'): boolean {
        if (connectionToEdit) {
            return !visibleFields[field];
        }
        return false;
    }

    console.log('x', visibleFields);

    return (
        <>
            {authMethod === AuthMethod.OAuth && (
                <>
                    <UI.FormGroup
                        label={'Client ID'}
                        isRequired={true}
                        description={'Provide client id'}
                        dataSel={`clientId`}
                    >
                        { (visibleFields.clientId) ? <UI.Input
                            onChange={newValue => setCredential('clientId', newValue)}
                            onKeyDown={e => handleSecondStepOnKeyDown(e)}
                            defaultValue={
                                connectionToEdit
                                    ? ''
                                    : NEW_CONNECTION_DEFAULT_VALUES.oAuth.clientId
                            }
                        /> : <p>{connectionToEdit?.authData?.clientId}</p> }
                        { (shouldShowSetButton('clientId')) && <UI.Button
                            text={`Edit client id`}
                            appearance={`secondary`}
                            onClick={() => showField('clientId')}
                        /> }
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={'Client secret'}
                        isRequired={true}
                        description={'Provide client secret'}
                        dataSel={`clientSecret`}
                    >
                        <React.Fragment>
                            { (visibleFields.clientSecret) ? <UI.Input
                                onChange={newValue => setCredential('clientSecret', newValue)}
                                onKeyDown={e => handleSecondStepOnKeyDown(e)}
                                type={'password'}
                                defaultValue={
                                    connectionToEdit
                                        ? ''
                                        : NEW_CONNECTION_DEFAULT_VALUES.oAuth.clientSecret
                                }
                            /> : <p>{ connectionToEdit?.authData?.clientSecret}</p>}
                            { (shouldShowSetButton('clientSecret')) && <UI.Button
                                text={`Edit client secret`}
                                appearance={`secondary`}
                                onClick={() => showField('clientSecret')}
                            /> }
                        </React.Fragment>
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={'Email address'}
                        isRequired={true}
                        description={'Provide email address'}
                        dataSel={`login`}
                    >
                        <React.Fragment>
                            <UI.Input
                                onChange={newValue => setCredential('login', newValue)}
                                onKeyDown={e => handleSecondStepOnKeyDown(e)}
                                defaultValue={
                                    connectionToEdit
                                        ? connectionToEdit.authData?.login
                                        : NEW_CONNECTION_DEFAULT_VALUES.oAuth.login
                                }
                            />
                        </React.Fragment>
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={'Personal access token'}
                        isRequired={true}
                        description={'Provide personal access token generated from email you provided above'}
                        dataSel={`password`}
                    >
                        <React.Fragment>
                            { (visibleFields.password) && <UI.Input
                                onChange={newValue => setCredential('password', newValue)}
                                onKeyDown={e => handleSecondStepOnKeyDown(e)}
                                type={'password'}
                                defaultValue={
                                    connectionToEdit
                                        ? ''
                                        : NEW_CONNECTION_DEFAULT_VALUES.oAuth.password
                                }
                            /> }
                            { (shouldShowSetButton('password')) && <UI.Button
                                text={`Edit token`}
                                appearance={`secondary`}
                                onClick={() => showField('password')}
                            /> }
                        </React.Fragment>
                    </UI.FormGroup>
                </>
            )}
        </>
    );
}
