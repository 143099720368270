import React from 'react';

import {EditPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper, UI} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';
import {JiraSearchIssuesConfiguration} from './jira-search-issues-configuration';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class JiraSearchIssuesEditStep extends React.Component<Props, State> {
    private configuration: JiraSearchIssuesConfiguration;
    private entityFormHelper: EntityFormHelper;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as JiraSearchIssuesConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'customJQL', newValue) => {
            this.configuration[propertyName] = newValue;
        });
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup>
                <UI.LabelValue label={`Project`} value={ this.configuration.project.name } />
            </UI.FormGroup>
            <UI.FormGroup>
                <UI.LabelValue label={`Issue Type`} value={ this.configuration.issueType.name } />
            </UI.FormGroup>
            <UI.FormGroup>
                <UI.Input defaultValue={ this.configuration.customJQL } label="Customize JQL" onChange={ this.entityFormHelper.onChangeHandler('customJQL')   }/>
            </UI.FormGroup>
            <UI.FormGroup>
                <UI.Checkbox
                    checkedValue={ 'true' }
                    uncheckedValue={ 'false' }
                    defaultValue={ this.configuration.onlyNewChanges.toString() }
                    label="Only issues that changed from last integration"
                    onChange={ (newValue) => { this.onlyNewChanged(newValue) }   } />
            </UI.FormGroup>
        </React.Fragment>;
    }

    private onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }
}