import React from 'react';

import {EditPipelineStepComponentProps} from 'src/generic';
import {ArtifactDirection} from 'src/generic/artifacts';
import {JiraStatusTransitionConfiguration} from './jira-status-transition-configuration';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class JiraStatusTransitionEditStep extends React.Component<Props, State> {
    private configuration: JiraStatusTransitionConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as JiraStatusTransitionConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
        this.props.showArtifactBuilder(ArtifactDirection.In);
    }

    render() {
        return <React.Fragment>
        </React.Fragment>;
    }
}