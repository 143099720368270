import { Software } from 'src/generic';
import { AsanaSoftware } from './asana/asana-software';
import { AzureSoftware } from './azure/azure-software';
import { GitHubSoftware } from './github/github-software';
import { GitLabSoftware } from './gitlab/gitlab-software';
import { IntHubSoftware } from './inthub';
import { JiraSoftware } from './jira/jira-software';
import { MondaySoftware } from './monday/monday-software';
import { ServiceNowSoftware } from './servicenow/servicenow-software';
import { WrikeSoftware } from './wrike/wrike-software';
import { ZendeskSoftware } from './zendesk/zendesk-software';
import { FreshdeskSoftware } from './freshdesk/freshdesk-software';
import { FreshserviceSoftware } from './freshservice/freshservice-software';
import {GetintArchiverSoftware} from './archiver/archiver-software';
import {SalesforceSoftware} from './salesforce/salesforce-software';
import {HubspotSoftware} from './hubspot/hubspot-software';
import {ClickUpSoftware} from './clickup/clickup-software';
import {NotionSoftware} from './notion/notion-software';
import {AirtableSoftware} from './airtable/airtable-software';
import {TrelloSoftware} from './trello/trello-software';
import {EasyvistaSoftware} from './easyvista/easyvista-software';
import {ShotgridSoftware} from './shotgrid/shotgrid-software';
export const softwareListToRegister: Array<Software> = [
    new JiraSoftware(),
    new TrelloSoftware(),
    new IntHubSoftware(),
    new AsanaSoftware(),
    new AzureSoftware(),
    // new GitHubSoftware(),
    // new MondaySoftware(),
    new ServiceNowSoftware(),
    new ZendeskSoftware(),
    new GitLabSoftware(),
    new GetintArchiverSoftware(),
    new WrikeSoftware(),
    new GitHubSoftware(),
    new MondaySoftware(),
    new FreshdeskSoftware()
    , new FreshserviceSoftware()
    , new SalesforceSoftware()
    , new HubspotSoftware()
    , new ClickUpSoftware()
    , new NotionSoftware()
    , new AirtableSoftware()
    , new EasyvistaSoftware()
    , new ShotgridSoftware()
];
