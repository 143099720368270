import React from 'react';
import {ISoftwareFactory, SoftwareName} from '../../../../../software';
import {UI} from 'src/engrator-core';
import {SmartIntTrigger} from '../../definition/smart-int-trigger.type';
import {Connection} from '../../../../../connections';
import {DropdownOption} from 'src/engrator-core/ui';
import {validateTrigger} from '../rest-api';
import {AppSmartIntsSupport} from '../../app-smart-ints-support';
import {getTrailData} from '../../../../engagement/rest-api';
import {ConnectionSetupVideo} from '../../../../../generic/connections/connection-setup-video';
import {BreadcrumbStep, ConnectionStep} from 'src/engrator-core/ui/breadcrumbs';
import {IntegrationContext} from '../../pages/integration-context';
import {SmartIntIntegration} from '../../../../../generic';
import {AuthMethod} from '../../../../../generic/connections/auth-method.type';
import {SuiteType} from '../../../../../generic/integrations/suite-type';

type State = {
    connectionsOptions?: DropdownOption[];
    showCreateConnection: boolean;
    isLoading: boolean;
    isLoadingConnections: boolean;
    showNewConnectionForm: boolean;
    isConfigured: boolean;
};

type Props = {
    softwareName: SoftwareName | undefined;
    onConnectionSetUp: (connectionId: number, trigger: SmartIntTrigger) => void;
    softwareFactory: ISoftwareFactory;
    connectionId?: number;
    disableGuide?: boolean;
    currentStep?: BreadcrumbStep;
    onChangeStepHandler?: (value: ConnectionStep) => void;
}

export class SelectConnectionDetailsStep extends React.Component<Props, State> {
    static contextType = IntegrationContext;
    private smartIntsSupport: AppSmartIntsSupport;
    private connectionFactory: any;
    private pickedConnection: Connection | undefined;
    private configuration: SmartIntTrigger | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoadingConnections: false,
            isLoading: false,
            showCreateConnection: false,
            isConfigured: false,
            showNewConnectionForm: false
        };
        const software = this.props.softwareFactory.getSoftwareByName(this.props.softwareName!)!;
        this.smartIntsSupport = software.getSmartIntsSupport()!;
        this.connectionFactory = software.getConnectionFactory();
    }

    componentDidMount() {
        this.fetchConnections();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.currentStep && this.props.currentStep && prevProps.currentStep.index === ConnectionStep.Configure && this.props.currentStep.index === ConnectionStep.ConnectApp) {
            this.pickedConnection = undefined;
            this.setState({ isLoadingConnections: false,
                isLoading: false,
                showCreateConnection: false,
                isConfigured: false,
                showNewConnectionForm: false }, () => {
                this.fetchConnections();
                this.forceUpdate()
            })
        }
        if (prevProps.currentStep && this.props.currentStep && prevProps.currentStep.index === ConnectionStep.CreateConnection && this.props.currentStep.index === ConnectionStep.ConnectApp) {
            this.setState({ showNewConnectionForm: false })
        }
        if (prevState.showNewConnectionForm === false && this.state.showNewConnectionForm === true && this.props.onChangeStepHandler) {
            this.props.onChangeStepHandler(ConnectionStep.CreateConnection);
        }
    }

    private fetchConnections() {
        let preferredAuthMethod: AuthMethod | undefined = undefined;
        if (this.context && this.props.softwareName === SoftwareName.Jira) {
            const {getIntegrationType} = this.context;
            if (getIntegrationType() === SuiteType.Git) {
                preferredAuthMethod = AuthMethod.OAuth;
            }
        }
        if (this.props.connectionId) {
            this.setConnection('', { 
                id: this.props.connectionId,
                login: '',
                url: '',
                name: '',
                softwareName: SoftwareName.Jira
            });
            return;
        }
        this.setState({ isLoadingConnections: true }, () => {
            this.connectionFactory.fetchConnections(preferredAuthMethod)
                .then((connections: Connection[]) => {
                    const connectionsOptions: DropdownOption[] = connections.map((connection: any) => ({
                        ...connection,
                        value: String(connection.id),
                        label: connection.name,
                        object: connection,
                        available: connection.available
                    }));
                    this.setState({
                        showNewConnectionForm: connectionsOptions.length === 0,
                        connectionsOptions,
                        isLoadingConnections: false
                    });
                });
        });
    }


    render() {
        const { integration } = this.context;
        const options = this.state.connectionsOptions || [];
        if (!this.pickedConnection) {
            return <div className={`connection-details-step`}>
                <UI.Message appearance={'info'}>
                    <span>Getint uses REST API to communicate with the applications. Use a user with the needed rights, to enable Getint to perform actions (e.g. create, update).</span>
                </UI.Message>
                { this.state.isLoadingConnections && <UI.Loader visible={true} appearance={'darkgray'} /> }
                { !!this.state.connectionsOptions && <span className={`loaded`}>&nbsp;</span> }
                { !this.state.showNewConnectionForm &&  !!this.state.connectionsOptions && !this.state.isLoadingConnections && this.props.currentStep && this.props.currentStep.index === ConnectionStep.ConnectApp && <div className={`configure-connection`}>
                    <UI.Form>
                        <UI.FormGroup
                            label={`Select connection`}
                            description={``}
                            isRequired={true}
                        >
                            {/*{ this.state.connectionsOptions && this.state.connectionsOptions.length === 0 && <UI.Message */}
                            {/*    appearance='info'*/}
                            {/*>No connections found.</UI.Message> }*/}
                            { !this.state.connectionsOptions && <UI.Loader visible={true} appearance={'darkgray'} /> }
                            { this.state.connectionsOptions && this.state.connectionsOptions.length > 0 && <React.Fragment>
                                <UI.Dropdown
                                    options={options}
                                    onChange={this.setConnection.bind(this)}
                                    isConnections={true}
                                />
                            </React.Fragment> }

                            <br/>
                            { this.props.softwareName !== SoftwareName.GetintArchiver && !this.state.showNewConnectionForm && <UI.Button
                                className={`create-new-btn`}
                                onClick={() => this.createConnectionClicked() }
                                appearance={'secondary'} text={`Create New Connection`}
                            /> }

                            {/*    </RightPanelContent>*/}
                            {/*</RightPanel> }*/}
                        </UI.FormGroup>
                    </UI.Form>
                    <div className={`info`}>
                        <ConnectionSetupVideo softwareName={this.props.softwareName!}/>
                    </div>
                </div> }
                { this.state.showNewConnectionForm && <React.Fragment>
                    { this.connectionFactory.getConnectionComponent(() => this.onConnectionAddedHandler() ) }
                </React.Fragment> }
            </div>;
        }
        if (this.pickedConnection && this.props.currentStep && this.props.currentStep.index === ConnectionStep.Configure) {
            const isConfigured = (this.configuration?.configuration) && !this.state.isConfigured;
            return <div className={`connection-details-step configure-connection`}>
                <UI.Form>
                    {this.pickedConnection!.name && <UI.Message appearance={'info'}>
                        Specify further settings, to e.g. narrow down the scope to a given project, or query
                    </UI.Message>  }
                    {this.pickedConnection.name && <UI.LabelValue
                        label={`Connection`}
                        value={this.pickedConnection!.name}
                    />}
                    {this.smartIntsSupport.getTriggerConfigurationComponent(
                        this.pickedConnection!.id,
                        (configuration: SmartIntTrigger) => this.onTriggerConfigured(configuration),
                        (integration as SmartIntIntegration).suiteType
                    )}

                    <UI.ButtonsBar primary={<UI.Button
                        disabled={ !isConfigured }
                        onClick={this.done.bind(this)}
                        isLoading={this.state.isLoading}
                        text={`Connect`}
                    />}/>
                </UI.Form>
                { !this.props.disableGuide && <div className={`info`}>
                    <ConnectionSetupVideo softwareName={this.props.softwareName!}/>
                </div> }
            </div>
        }
        return <React.Fragment></React.Fragment>;
    }

    private onConnectionAddedHandler() {
        this.setState({ showNewConnectionForm: false}, () => {
            if (this.props.onChangeStepHandler) {
                this.props.onChangeStepHandler(ConnectionStep.ConnectApp);
            }
            this.fetchConnections();
        });
    }
    private onTriggerConfigured(configuration: SmartIntTrigger) {
        this.configuration = configuration;
        this.forceUpdate();
    }

    private setConnection(value: string, object?: Connection) {
        this.pickedConnection = object;
        if (!this.smartIntsSupport.hasTriggerConfigurationComponent()) {
            this.configuration = {
                app: this.props.softwareName!,
                connectionId: this.pickedConnection!.id,
                artifactName: this.props.softwareName!.toLocaleLowerCase() + 'Item',
                configuration: undefined,
                className: ''
            }
        }
        if (this.props.onChangeStepHandler) {
            this.props.onChangeStepHandler(ConnectionStep.Configure);
        }
        this.forceUpdate();
    }

    private createConnectionClicked() {
        // Attach smartlook to record sessions only if this is saas
        getTrailData()
            .then(data => {
                if (data.isSaas || data.saas) {
                    (window as any)['addSmartLook']();
                }
            });
        this.setState({showNewConnectionForm: true})
    }

    private done() {
        if (this.pickedConnection && this.configuration) {
            this.setState({ isLoading: true }, () => {
                validateTrigger(this.props.softwareName!, this.configuration!).then(() => {
                    this.props.onConnectionSetUp(
                        this.pickedConnection!.id,
                        this.configuration!
                    );
                    this.setState({ isLoading: false, isConfigured: true });
                }).catch(() => {
                    this.setState({ isLoading: false });
                });
            });
        }
    }
}
