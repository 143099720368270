import React, { ChangeEvent, KeyboardEvent } from 'react';

type InputProps = {
    onChange: (newValue: string, event?: ChangeEvent<HTMLInputElement>) => void,
    onBlur?: () => void;
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
    type?: string,
    name?: string,
    label?: string,
    description?: string,
    min?: number;
    max?: number;
    defaultValue?: string,
    placeholder?: string,
    isRequired?: boolean
    icon?: any;
    disabled?: boolean;
    inputRef?: any;
};

type InputState = {
    disabled?: boolean;
    value: string
};

export class Input extends React.Component<InputProps, InputState> {
    constructor(props: InputProps) {
        super(props);
        this.state = {
            disabled: (this.props.disabled === true),
            value: (props.defaultValue !== undefined) ? props.defaultValue : ''
        };
    }

    componentDidUpdate(prevProps: InputProps) {
        if ((this.props.defaultValue !== prevProps.defaultValue)) {
            this.setState({
                value: this.props.defaultValue || ''
            });
        } else if (this.props.disabled !== prevProps.disabled) {
            this.setState({
                disabled: (this.props.disabled === true)
            });
        }
    }

    inputValueChanged(newValue: string, event: ChangeEvent<HTMLInputElement>): void {
        if ((this.props.min && +newValue < this.props.min) || (this.props.max && +newValue > this.props.max)) return;
        this.setState({ value: newValue });
        this.props.onChange(newValue, event);
    }

    render() {
        const attributes: any = {
            type: (this.props.type) ? this.props.type : 'text'
        };
        if (this.props.name !== undefined) {
            attributes['name'] = this.props.name;
        }
        if (this.props.placeholder !== undefined) {
            attributes['placeholder'] = this.props.placeholder;
        }
        if (this.props.min !== undefined) {
            attributes['min'] = this.props.min;
        }
        if (this.props.max !== undefined) {
            attributes['max'] = this.props.max;
        }
        if (this.props.disabled) {
            attributes['disabled'] = 'disabled';
        }
        const additionalClass = (this.props.icon) ? 'with-icon' : '';
        return <div className={`ui-input ${ additionalClass }`}>
            {this.props.label &&
            <label>{this.props.label} {this.props.isRequired === true && <span className={`required`}>*</span>}</label>}
            {this.props.description && <p>{ this.props.description }</p> }
            { this.props.icon && <React.Fragment>{ this.props.icon }</React.Fragment> }
            <input ref={this.props.inputRef} value={this.state.value} {...attributes}
                onChange={(event) => this.inputValueChanged(event.target.value, event)}
                onBlur={this.props.onBlur ? () => this.props.onBlur!() : () => {}}
                onKeyDown={this.props.onKeyDown ? (event) => this.props.onKeyDown!(event) : () => {}}/>
        </div>;
    }
}
