import React from 'react';

import {EntityFormHelper, UI} from 'src/engrator-core';
import {NewStatusRule} from './new-status-rule';
import {FormError} from '../../../engrator-core/form-error.type';
import {DropdownOption} from '../../../engrator-core/ui';
import {StepArtifact, StepSpecification} from '../../../generic/software/step';
import {StatusTransitionConfiguration} from './status-transition-configuration.type';
import {StatusRulesManager} from './status-rules-manager';
import {SoftwareName} from '../../software-name';
import {ArtifactsFactory} from '../../../generic/artifacts';
import {StatusRuleRenderer} from './status-rule-renderer';

type Props = {
    formError?: FormError;
    availableArtifacts: StepArtifact[];
    softwareName: SoftwareName;
    connectionId: number;
    stepSpecification: StepSpecification;
    artifactsFactory: ArtifactsFactory;
    onConfigurationChanged: (configuration: StatusTransitionConfiguration) => void;
};

type State = {
    showForm: boolean;
};

export class StatusTransition extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private artifactsOptions: DropdownOption[];
    private configuration: StatusTransitionConfiguration = {
        targetArtifact: '',
        rules: []
    };
    private statusRulesManager: StatusRulesManager;
    private artifactStepConfigurationData: any | undefined;

    constructor(props: Props) {
        super(props);
        this.state = { showForm: false };
        this.entityFormHelper = this.getEntityFormHelper();
        this.artifactsOptions = this.props.availableArtifacts.map(artifact => ({ label: artifact.getName(), value: artifact.getName(), object: artifact }));
        this.statusRulesManager = new StatusRulesManager([], () => {
            this.setState({ showForm: false });
            this.configuration.rules = this.statusRulesManager.getRules();
            this.props.onConfigurationChanged(this.configuration);
        });
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                description={``}
                isRequired={ true }
                label={`Select artifact for which you want to change status`}
                error={ this.props.formError?.targetArtifact }
            >
                { !this.configuration.targetArtifact && <UI.Dropdown options={ this.artifactsOptions } onChange={ this.entityFormHelper.onChangeHandler('targetArtifact')} /> }
                { this.configuration.targetArtifact && <span>{ this.configuration.targetArtifact }</span> }
            </UI.FormGroup>
            { this.statusRulesManager.getRules().map((rule, index: number) =>
                <StatusRuleRenderer key={index} rule={rule}/>
            )}
            { this.configuration.targetArtifact && <React.Fragment>
                { this.state.showForm && <NewStatusRule
                    connectionId={ this.props.connectionId }
                    stepSpecification={ this.props.stepSpecification }
                    artifactsFactory={ this.props.artifactsFactory }
                    softwareName={ this.props.softwareName }
                    artifactStepConfigurationData={ this.artifactStepConfigurationData }
                    onAddHandler={ (rule) => this.statusRulesManager.addRule(rule) }
                /> }
                { !this.state.showForm && <UI.Button onClick={ () => { this.switchFormVisibility()} } text={`Configure New Status`} appearance={'secondary'} /> }
            </React.Fragment> }
        </React.Fragment>;
    }

    private switchFormVisibility() {
        this.setState({ showForm: !this.state.showForm });
    }

    private getEntityFormHelper(): EntityFormHelper {
        return new EntityFormHelper((propertyName: 'targetArtifact', newValue, stepArtifact?: StepArtifact) => {
            this.artifactStepConfigurationData = stepArtifact?.getStepConfigurationData();
            this.configuration[propertyName] = newValue;
            this.forceUpdate();
        });
    }
}