import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { SoftwareName } from '../../software-name';
import {fetchWorkspaces} from '../../freshdesk/smart-ints/rest-api';
import {DropdownOption} from '../../../engrator-core/ui';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};

type State = {
    isLoadingWorkspaces: boolean;
    workspaces: DropdownOption[];
    error?: any;
};

export class FreshserviceSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.Freshservice,
            artifactName: '',
            className: '',
            configuration: {
                query: '',
            },
            connectionId: this.props.connectionId,
        };
        this.state = { isLoadingWorkspaces: false, workspaces: [] };
        this.onDataChanged({});
    }

    private setWorkspace(newValue: string, obj: any) {
        this.smartIntTrigger.configuration.workspace = obj;
    }


    async componentDidMount() {
        await this.setState({ isLoadingWorkspaces: true });
        try {
            const workspaces = await fetchWorkspaces(this.smartIntTrigger.connectionId);
            await this.setState({workspaces, isLoadingWorkspaces: false});
        } catch (error) {
            await this.setState({error, isLoadingWorkspaces: false});
        }
    }

    render() {
        return <React.Fragment>
            { this.state.error && <UI.Message
                message={ this.state.error }
                appearance={'error-message'} /> }
            <UI.FormGroup
                dataSel={`workspace`}
                label={`Workspace`}
                description={`Optionally you can specify workspace`}
                isRequired={false}>
                { this.state.isLoadingWorkspaces && <UI.Loader visible={true} appearance={'darkgray'} /> }
                { !this.state.isLoadingWorkspaces && <UI.Dropdown options={ this.state.workspaces } onChange={ (workspaceId: any, obj: any) => this.setWorkspace(workspaceId, obj)   }/> }
            </UI.FormGroup>
            {/*<UI.FormGroup*/}
            {/*    label={`Additional query`}*/}
            {/*    description={``}*/}
            {/*>*/}
            {/*    <UI.Input label={``} onChange={ this.onJqlInputChanged.bind(this)} />*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>;
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private onDataChanged(data: any) {
        if (data) {
            this.smartIntTrigger.artifactName = 'task';
            this.props.onConfigured(this.smartIntTrigger!);
            this.forceUpdate();
        }
    }
}
