import {
    Artifacts,
    EventsBus,
    GenericIntegration,
    SmartIntIntegration,
    StepedIntegration,
    Steps,
    StepsFactory
} from './../../generic';
import {save} from './rest-api';

export function convertIntegrationObjectToBackendData(integration: GenericIntegration) {
    const savedIntegration = {
        name: integration.getName(),
        configuration: (integration as SmartIntIntegration).definition,
        settings: integration.settings,
        migrationConfiguration: (integration as SmartIntIntegration).migrationConfiguration,
        ownerId: (integration as SmartIntIntegration).ownerId,
        shareAccess: (integration as SmartIntIntegration).shareAccess,
    };
    return savedIntegration;
}

export function saveIntegration(integrationId: number | undefined, integration: GenericIntegration): Promise<number> {
    const savedIntegration = convertIntegrationObjectToBackendData(integration);
    return save(integrationId, savedIntegration);
}

export function restoreIntegration(savedIntegration: any, artifactsFactory: Artifacts.ArtifactsFactory, softwareFactory: any, stepsFactory: StepsFactory, eventsBus: EventsBus): StepedIntegration {
    const integration: StepedIntegration = new StepedIntegration(savedIntegration.name, artifactsFactory, stepsFactory, eventsBus);
    integration.settings = savedIntegration.settings;
    savedIntegration.configuration.steps?.forEach((stepConfiguration: any) => {
        const genericSoftwareStep: Steps.GenericSoftwareStep = integration.getStepsFactory().getStepBySpecification(stepConfiguration.stepSpecification);
        if (genericSoftwareStep) {
            const integrationStep = genericSoftwareStep.getIntegrationStep(stepConfiguration);
            integration.addStep(integrationStep);
        }
    });
    return integration;
}
