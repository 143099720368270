import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { SoftwareName } from '../../software-name';
import {DropdownOption} from '../../../engrator-core/ui';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {
    isLoading: boolean;
    spacesOptions?: DropdownOption[];
    foldersOptions?: DropdownOption[];
};

export class EasyVistaSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false
        };
        this.smartIntTrigger = {
            app: SoftwareName.EasyVista,
            artifactName: '',
            className: '',
            configuration: {
                query: '',
            },
            connectionId: this.props.connectionId,
        };
        this.props.onConfigured(this.smartIntTrigger);
    }

    render() {
        return <React.Fragment>
            { this.state.isLoading && <UI.Loader visible={ true } appearance={'darkgray'} /> }
        </React.Fragment>;
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }
}
