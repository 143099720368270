import React from 'react';
import {Component} from 'react';
import {ArchivedItemAttachmentData } from '../rest-api';
import { axiosWrapper} from '../../../engrator-core';

type State = {};
type Props = {
    attachments: ArchivedItemAttachmentData[];
};

export class ArchivedItemAttachments extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    close() {

    }

    render() {
        return <React.Fragment>
            <h2 className={`attachments-header`}>Attachments ({ this.props.attachments.length })</h2>
            <div className={`attachments`}>
                { this.props.attachments.map((attachment, index) =>
                    <span>
                        <a
                            key={ index }
                            onClick={ () => axiosWrapper.downloadFile('/' + attachment.downloadUrl, attachment.fileName) } target={`_blank`}>{ attachment.fileName }</a> ,
                    </span>
                )}
            </div>
        </React.Fragment>;
    }
}