import {axiosWrapper} from 'src/engrator-core';
import {SystemLicenseInfo} from '../../system/license/rest-api';

const ENDPOINT_URL = '/settings/jira-server';
type GetSettingResponse = {
    properties: {[key in JiraServerSetting]: string}
};

export enum JiraServerSetting {
    DedicatedLicense = 'DedicatedLicense',
    CleanUpMaxDaysNumber = 'CleanUpMaxDaysNumber'
}

export function saveSetting(name: JiraServerSetting, value: string): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            const data = { propertyName: name, value };
            axiosWrapper.post(`${ENDPOINT_URL}`, data)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchLicenseInfo(): Promise<SystemLicenseInfo> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/dedicated-license`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function getSetting(name: JiraServerSetting): Promise<string> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}`)
                .then((data: any) => {
                    const properties = (data as GetSettingResponse).properties;
                    resolve(properties[name]);
                    // resolve(
                    //     (data as GetSettingResponse).properties.filter(p => p.propertyName === name)[0]?.value
                    // );
                    // resolve(data.properties[name]);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchSystemLogs(): Promise<string> {
    return new Promise((resolve, reject) => {
        try {
            let systemApiLogsUrl = `/system/api/logs`;
            axiosWrapper.get(`${ENDPOINT_URL}${systemApiLogsUrl}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}