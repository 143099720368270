import React from 'react';
import { UI } from 'src/engrator-core';
import { MondayStepContextData } from './monday-step-context';

type Props = {
    context: MondayStepContextData;
}

type State = {}

export class MondayStepContextDisplay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            {this.props.context.board && <UI.LabelValue label={`Repository`} value={this.props.context.board.name}/>}
        </React.Fragment>;
    }
}
