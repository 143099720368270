import React, {Component} from 'react';
import {FormError} from '../../../engrator-core/form-error.type';
import { UI } from 'src/engrator-core';
import {
    createEmptyGetintUser,
    createUser,
    getAvailableRoles,
    GetintNewUserEnvironment,
    GetintUser
} from './users-management-rest-api';

type State = {
    isLoading: boolean;
    error?: FormError;
    getintUser: GetintUser;
    saved: boolean;
};

type Props = {
    closeHandler: () => Promise<boolean>;
    mode: GetintNewUserEnvironment;
    refreshList: () => void;
}

export class SystemUsersCreate extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            saved: false,
            getintUser: createEmptyGetintUser(),
            isLoading: false
        };
    }

    onChange(field: 'username' | 'email' | 'password' | 'repeatPassword', value: string): void {
        const getintUser = this.state.getintUser;
        getintUser[field] = value;
        this.setState({ getintUser });
    }

    changeRole(role: any) {
        const getintUser = this.state.getintUser;
        getintUser.role = role;
        this.setState({ getintUser });
    }

    changeRequiresSso() {
        const getintUser = this.state.getintUser;
        getintUser.isSsoUser = !getintUser.isSsoUser;
        this.setState({ getintUser });
    }

    render() {
        return (
            <UI.FullScreenModal
                header={`Create user`}
                primaryBtnText={`Create`}
                closeBtnHandler={ () => this.props.closeHandler() }
                primaryBtnHandler={ () => this.createClicked() }
            >
                { this.props.mode === GetintNewUserEnvironment.JiraServer && <UI.Form useMaxWidthClass={true}>
                    <UI.Message appearance={'info'}>Invite your team mates to this workspace. If they are not the jira admin users share with them app url so they can access it <a>https://a4321dja.live.getint.io</a>.</UI.Message>
                    <React.Fragment>
                        <UI.FormGroup
                            label={`Jira username`}
                            isRequired={ true }
                            description={``}
                        >
                            <UI.Input
                                defaultValue={`${ '' }`}
                                type={'text'}
                                placeholder={`Provide number of days`}
                                onChange={ (newValue: string) => {} }
                            />
                        </UI.FormGroup>
                        <UI.FormGroup
                            label={`Role`}
                            isRequired={ true }
                            description={``}
                        >
                            <UI.Dropdown
                                options={[]}
                                onChange={ (newValue: string) => {} }
                            />
                        </UI.FormGroup>
                    </React.Fragment>

                    <UI.ButtonsBar
                        secondary={ [(this.state.error) ? <UI.Message appearance={'success'}>Settings saved!</UI.Message> : <React.Fragment />] }
                        primary={ <UI.Button
                            disabled={ false }
                            text={`Invite`}
                            onClick={ () => {} }
                            isLoading={ false }
                        /> }
                    />
                </UI.Form> }

                { this.props.mode === GetintNewUserEnvironment.JiraCloud && <UI.Form useMaxWidthClass={true}>
                    <UI.Message appearance={'info'}>Invite your team mates to this workspace. Invited user will be emailed with your workspace url (https://a4321dja.live.getint.io) and use a generated code to sign in to workspace.</UI.Message>
                    <React.Fragment>
                        <UI.FormGroup
                            label={`E-mail address`}
                            isRequired={ true }
                            description={``}
                        >
                            <UI.Input
                                defaultValue={`${ '' }`}
                                type={'text'}
                                placeholder={`Provide number of days`}
                                onChange={ (newValue: string) => {} }
                            />
                        </UI.FormGroup>
                        <UI.FormGroup
                            label={`Role`}
                            isRequired={ true }
                            description={`Set the duration for storing integration logs. Older logs are automatically deleted after the defined period.`}
                        >
                            <UI.Dropdown
                                options={[]}
                                onChange={ (newValue: string) => {} }
                            />
                        </UI.FormGroup>
                    </React.Fragment>

                    <UI.ButtonsBar
                        secondary={ [(this.state.error) ? <UI.Message appearance={'success'}>Settings saved!</UI.Message> : <React.Fragment />] }
                        primary={ <UI.Button
                            disabled={ false }
                            text={`Invite`}
                            onClick={ () => {} }
                            isLoading={ false }
                        /> }
                    />
                </UI.Form> }

                { this.props.mode === GetintNewUserEnvironment.Cluster && <UI.Form useMaxWidthClass={true}>
                    <UI.Message appearance={'info'}>Add user to Getint instance deployment ...</UI.Message>
                    { this.state.error && <UI.Message appearance={'error'} message={ this.state.error.message } /> }
                    <React.Fragment>
                        <UI.FormGroup
                            label={`E-mail address`}
                            isRequired={ true }
                            description={``}
                        >
                            <UI.Input
                                defaultValue={`${ '' }`}
                                type={'text'}
                                placeholder={`E-mail address`}
                                onChange={ (newValue: string) => this.onChange('email', newValue) }
                            />
                        </UI.FormGroup>
                        <UI.FormGroup
                            label={`Require users to sign in with SSO`}
                            isRequired={ true }
                            description={``}
                        >
                            <UI.Checkbox
                                checkedValue={`true`}
                                uncheckedValue={`false`}
                                defaultValue={`${ '' }`}
                                onChange={ (newValue: string) => this.changeRequiresSso() }
                            />
                        </UI.FormGroup>
                        { !this.state.getintUser.isSsoUser && <React.Fragment>
                            <UI.FormGroup
                                label={`Password`}
                                isRequired={ true }
                                description={``}
                            >
                                <UI.Input
                                    defaultValue={`${ '' }`}
                                    type={'password'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => this.onChange('password', newValue) }
                                />
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Repeat password`}
                                isRequired={ true }
                                description={``}
                            >
                                <UI.Input
                                    defaultValue={`${ '' }`}
                                    type={'password'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => this.onChange('repeatPassword', newValue) }
                                />
                            </UI.FormGroup>
                        </React.Fragment> }
                        <UI.FormGroup
                            label={`Role`}
                            isRequired={ true }
                            description={`Set the duration for storing integration logs. Older logs are automatically deleted after the defined period.`}
                        >
                            <UI.Dropdown
                                options={ getAvailableRoles() }
                                onChange={ (newValue: string) => this.changeRole(newValue) }
                            />
                        </UI.FormGroup>
                    </React.Fragment>
                </UI.Form> }
            </UI.FullScreenModal>
        );
    }

    async createClicked(): Promise<boolean> {
        try {
            await createUser(this.props.mode, this.state.getintUser);
            this.setState({ saved: true }, () => {
                this.props.refreshList();
            });
            return true;
        } catch (error) {
            this.setState({ error });
            return false;
        }
    }
}