import React from 'react';
import { UI } from 'src/engrator-core';
import {acceptTerms, shouldAcceptTerms} from './../rest-api';
import {ExpiredInfo} from '../expired-info';

type Props = {};
type State = {
    shouldAcceptTerms: boolean;
    isLoading: boolean;
    agreeFlagChecked: boolean;
    isExpired: boolean;
    showCheckboxClickError: boolean;
};

export class AcceptTerms extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isExpired: false,
            agreeFlagChecked: false,
            shouldAcceptTerms: false,
            isLoading: false,
            showCheckboxClickError: false
        };
    }

    componentDidMount() {
        shouldAcceptTerms().then((usageCheck) => {
            this.setState({ isExpired: usageCheck.isExpired, shouldAcceptTerms: usageCheck.shouldAcceptTerms });
        })
            .catch((error) => {
                console.log(error);
            });
    }

    acceptTermsClicked(): void {
        if (this.state.agreeFlagChecked === false) {
            return this.setState({ showCheckboxClickError: true });
        }
        this.setState({ isLoading: true }, () => {
            acceptTerms().then(() => {
                this.setState({ shouldAcceptTerms: false });
            }).catch((err) => {
                console.log(err);
            })
        });
    }

    switchAgreeFlag(): void {
        this.setState({ agreeFlagChecked: !this.state.agreeFlagChecked });
    }

    render() {
        if (this.state.isExpired) {
            return <ExpiredInfo />;
        }
        if (!this.state.shouldAcceptTerms) {
            return <React.Fragment></React.Fragment>;
        }
        return <UI.Modal
            highestIndex={ true }
            isWide={ true }
            header={`Standard End User License Agreement (EULA)`}
            isLeftAligned={ true }
        >
            <div className={`eula-modal`}>
                <p>This End-User license agreement (EULA) is a legal agreement between (a) you (either an individual or a single entity) and (b) GETINT WASIUK WIZMUR-SZYMCZAK SPÓŁKA JAWNA that governs your use of any Software Product, installed on or made available by GETINT WASIUK WIZMUR-SZYMCZAK SPÓŁKA JAWNA. </p>
                <p>Please read it carefully <a target={`_blank`} href={`https://getint.io/terms/eula`}>here</a></p>
                <p>By clicking “I agree”, or by taking any step to install or use the software product, you (1) represent that you are the legal age, and you are fully authorised by your employer to enter into this contract and (2) you agree to be bounded by the terms of this EULA, and our privacy policy. If you do not accept the EULA terms or privacy policy, do not use the software product.</p>
                <UI.Checkbox
                    label={ <span><span style={{ color: 'red' }}>*</span> I agree to the I’ve read, and I agree to the <a target={`_blank`} href={`https://getint.io/terms/eula`}>EULA</a> and <a target={`_blank`} href={`https://getint.io/privacy-policy`}>PRIVACY POLICY</a></span>}
                    onChange={ () => this.switchAgreeFlag() }
                    checkedValue={`true`}
                    uncheckedValue={`false`}
                />
                { this.state.showCheckboxClickError && <UI.Message appearance={'error'}><p>Please click checkbox above</p></UI.Message> }
                <UI.Button
                    isLoading={ this.state.isLoading }
                    onClick={ () => this.acceptTermsClicked() }
                    text={`Yes I confirm and accept`}
                />
            </div>
        </UI.Modal>;
    }
}
