import React, { useEffect} from 'react';
import {useGlobalState} from '../../global-store';
import {jiraServerSettingsVerticalMenu} from './jiraserver-settings-vertical-menu';
import {MainMenuItem} from '../../global-store/create-global-state';
import { DataStoragePageCmp} from '../../system';

export function JiraServerSettingsPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMenu(jiraServerSettingsVerticalMenu);
        setMainMenuItem(MainMenuItem.Settings);
    }, []);
    return <DataStoragePageCmp />
}