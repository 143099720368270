import {PipelineStep} from 'src/generic/pipeline-step';
import {SoftwareName} from 'src/software/software-name';
import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';

import {JiraStepKey} from '../jira-step-key.type';
import {JiraSearchIssuesConfiguration} from './jira-search-issues-configuration';
import {JiraSearchIssuesNewStep} from './jira-search-issues-new-step';
import {JiraSearchIssuesEditStep} from './jira-search-issues-edit-step';


export const JiraSearchIssuesPipelineStep :  PipelineStep<JiraSearchIssuesConfiguration> = {
    addStepComponentClass: JiraSearchIssuesNewStep,
    editStepComponentClass: JiraSearchIssuesEditStep,
    specification: {
        action: 'Search Jira Issues',
        description: 'Search issues in specified project',
        key: JiraStepKey.JiraSearchItems,
        software: SoftwareName.Jira,
        stepType: StepType.Trigger
    },
    preferredNameForArtifact: `jiraIssue`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as JiraSearchIssuesConfiguration;
        return `Search for ${stepConfiguration.issueType.name} issues in ${stepConfiguration.project.name}`;
    }
}