export type NewAlert = {
    name: string;
    forFailedRuns: boolean;
    forFailedSyncs: boolean;
    forWarnLogs: boolean;
    forMissingOptions: boolean;
    slackWebhookUrl: string;
    webhookContent: string;
    webhookUrl: string;
    webhookHeaders: { name: string, value: string }[];
    channel?: AlertChannel;
    emailChannelTo?: string;
    emailChannelSmtpPort?: string;
    emailChannelSmtpHost?: string;
    emailChannelSmtpUsername?: string;
    emailChannelSmtpPassword?: string;
    emailChannelFrom?: string;
    emailChannelSubjectPrefix?: string;
    emailChannelSsl?: boolean;
    emailChannelTls?: boolean;
}

export enum AlertChannel {
    Email = 'Email',
    Slack = 'Slack',
    Webhook = 'Webhook',
    GetintEmail = 'GetintEmail',
    JiraSmtp = 'JiraSmtp'
}

export function getAlertChannelName(channel: AlertChannel): string {
    if (channel === AlertChannel.Email) {
        return 'Custom SMTP (Email)';
    } else if (channel === AlertChannel.GetintEmail) {
        return 'Email (sent by Getint Cloud)';
    } else if (channel === AlertChannel.JiraSmtp) {
        return 'Jira Default SMTP';
    }
    return channel;
}

export function createEmptyNewAlert(): NewAlert {
    return {
        webhookContent: '', webhookUrl: '',
        webhookHeaders: [],
        forMissingOptions: true,
        forWarnLogs: true,
        forFailedRuns: true, forFailedSyncs: true, name: '', slackWebhookUrl: '', emailChannelSsl: true
    }
}