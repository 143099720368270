import {NewConnectionData} from '../../connections';
import {axiosWrapper} from '../../engrator-core';
import {SoftwareName} from '../../software';
import {AuthMethod} from './auth-method.type';

const ENDPOINT_URL = '/integration/connections';

export type VerifyUrlConnectionGuide = {
    usernameLabel: string;
    usernameDescription: string;
    passwordLabel: string;
    passwordDescription: string;
    passwordFieldId: string;
    authMethod: AuthMethod;
    additionalData?: any;
};

export type ConnectionFirstStepGuide = {
    requireUrl: boolean;
    showUrl: boolean;
    urlDescription: string;
}

type VerifyUrlResponse = {
    correctedUrl: string;
    connectionGuide: VerifyUrlConnectionGuide;
}


export const createConnection = (conncetion: NewConnectionData): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL, conncetion)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function verifyConnectionUrl(url: string, app: SoftwareName): Promise<VerifyUrlResponse> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + '/verify-url', { url, app })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function getNewConnectionFirstStepGuide(app: SoftwareName): Promise<ConnectionFirstStepGuide> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + '/first-step-guide', { app })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}