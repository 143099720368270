import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';

import {ZendeskStepKey} from '../zendesk-step-key.type';
import {ZendeskStatusTransitionEditStep} from './zendesk-status-transition-edit-step';
import {ZendeskStatusTransitionNewStep} from './zendesk-status-transition-new-step';
import {ZendeskStatusTransitionConfiguration} from './zendesk-status-transition-configuration';

export const ZendeskStatusTransitionPipelineStep: PipelineStep<ZendeskStatusTransitionConfiguration> = {
    addStepComponentClass: ZendeskStatusTransitionNewStep,
    editStepComponentClass: ZendeskStatusTransitionEditStep,
    specification: {
        action: 'Change item status',
        description: 'Move Zendesk item from one status to another',
        key: ZendeskStepKey.ZendeskStatusTransition,
        software: SoftwareName.Zendesk,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ZendeskStatusTransitionConfiguration;
        return `Change item status`;
    }
}