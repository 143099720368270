import { axiosWrapper } from 'src/engrator-core';
import {ReportName} from './report-name.type';
import {ReportData} from './report-data.type';
import {softwareListToRegister} from '../../software';
import {combineFiltersIntoQuery} from './run-results/rest-api';

const ENDPOINT_URL = '/reporting';

export type BulkResyncItem = {
    id: number;
    triggeredBy: string;
    artifactId: number;
}

export const fetchReportData = (reportName: ReportName, currentPage: number, filtersData?: any): Promise<ReportData> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}/${reportName}?page=${currentPage}`, filtersData)
                .then((data: any) => {
                    resolve(data as ReportData);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function fetchBulkResyncList(reportName: ReportName, currentPage: number, filtersData?: any): Promise<BulkResyncItem[]> {
    return new Promise((resolve, reject) => {
        try {
            const filtersQuery = combineFiltersIntoQuery(filtersData);
            axiosWrapper.post(`${ENDPOINT_URL}/${reportName}/bulk-resync/list?page=${currentPage}`, filtersQuery)
                .then((data: any) => {
                    resolve(data as BulkResyncItem[]);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function bulkResyncItems(reportName: ReportName, artifactIds: number[]): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`/resync/bulk`, { ids: artifactIds})
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

/**
 * Returns window url with removed hash when its used by jira server app
 */
export function getParsedWindowUrl(): string {
    let url = window.location.href;
    for (const software of softwareListToRegister) {
        url = url.replace(
            'jira-' + software.getName().toLowerCase() + '#',
            'jira-' + software.getName().toLowerCase()
        );
    }
    return url;
}

