import React from 'react';
import {SmartIntTrigger} from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {UI} from 'src/engrator-core';
import { GitLabStepContextData } from '../steps/common';

type Props = {
    connectionId: number;
    trigger: SmartIntTrigger;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class GitLabSmartIntsEditTrigger extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup>
                <UI.LabelValue
                    label={`Project`}
                    value={this.props.trigger.configuration.project.name}
                />
            </UI.FormGroup>
            <UI.FormGroup
                label={`Additional Query`}
                description={`You can provide additional query params which will be appended to the url generated when searching for issues in GitLab`}
            >
                <UI.Input defaultValue={ this.props.trigger.configuration.query } label={``} onChange={ this.onJqlInputChanged.bind(this)} />
            </UI.FormGroup>
        </React.Fragment>
    }

    private onJqlInputChanged(newValue: string) {
        this.props.trigger.configuration.query = newValue;
    }

    private onDataChanged(data: GitLabStepContextData) {
        if (data.project) {
            this.forceUpdate();
        }
    }
}
