import {PipelineStep} from 'src/generic';
import {
    ZendeskCreateCommentsPipelineStep,
    ZendeskCreateItemPipelineStep,
    ZendeskSearchItemsPipelineStep,
    ZendeskUpdateItemPipelineStep,
    ZendeskFindItemPipelineStep,
    ZendeskStatusTransitionPipelineStep
} from './steps';

export const ZendeskPipelineSteps: PipelineStep<any>[] = [
    ZendeskSearchItemsPipelineStep,
    ZendeskCreateItemPipelineStep,
    ZendeskCreateCommentsPipelineStep,
    ZendeskUpdateItemPipelineStep,
    ZendeskFindItemPipelineStep,
    ZendeskStatusTransitionPipelineStep
];