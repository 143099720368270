import React from 'react';

import {FieldError, UI} from 'src/engrator-core';
import {Condition} from '../../../generic/conditions';
import {StatusRule} from './status-rule.type';
import {SoftwareName} from '../../software-name';
import {fetchStatuses} from './rest-api';
import {DropdownOption} from '../../../engrator-core/ui';
import {ArtifactBuilderScene, DataArtifactBuilder} from '../../../generic/artifact-builder';
import {PipelineStepArtifact} from '../../../generic/pipeline-step';
import {ArtifactDirection, ArtifactsFactory} from '../../../generic/artifacts';
import {ArtifactBuilder} from '../../../generic';
import {StepSpecification} from '../../../generic/software/step';

type Props = {
    onAddHandler: (rule: StatusRule) => void;
    softwareName: SoftwareName;
    artifactStepConfigurationData: any;
    connectionId: number;
    artifactsFactory: ArtifactsFactory;
    stepSpecification: StepSpecification;
};

type State = {
    error?: string;
    showArtifactBuilder: boolean;
    statuses: DropdownOption[];
};

export class NewStatusRule extends React.Component<Props, State> {
    private rule: StatusRule = {
        condition: '',
        properties: [],
        status: {
            name: '',
            id: ''
        }
    };
    private inDataArtifactBuilder?: DataArtifactBuilder;
    private inDataArtifact?: any;

    constructor(props: Props) {
        super(props);
        this.state = { showArtifactBuilder: false, statuses: [] };
    }

    componentDidMount(): void {
        fetchStatuses(this.props.softwareName, this.props.connectionId, this.props.artifactStepConfigurationData)
            .then((statuses) => this.setState({ statuses, error: ''}))
            .catch((error: FieldError) => {
                this.setState({ error: `Failed to fetch statuses: ${error.message}` })
            });
    }

    render() {
        return <React.Fragment>
            { this.state.error && <UI.Message appearance={'error'}>{ this.state.error }</UI.Message> }
            <UI.FormGroup
                label={`Select status`}
                isRequired={true}
            >
                <UI.Dropdown options={ this.state.statuses } onChange={ this.onStatusChanged.bind(this) } />
            </UI.FormGroup>
            <Condition isRequired={ true } onConditionChanged={ this.onConditionChanged.bind(this) } />
            { this.state.showArtifactBuilder && <React.Fragment>
                <UI.FormGroup
                    error={ undefined }
                    label="Configure Fields"
                    isRequired={false}
                    description="You can define also additional fields that will be updated">
                    <ArtifactBuilderScene
                        stepConfigurationFetcher={ this.getStepConfigurationFetcher.bind(this) }
                        connectionId={ this.props.connectionId }
                        dataArtifactBuilder={this.inDataArtifactBuilder!}
                        softwareName={ this.props.softwareName }
                    />
                </UI.FormGroup>
            </React.Fragment> }

            <UI.ButtonsBar primary={ <UI.Button
                disabled={ !this.rule.condition || !this.rule.status.id }
                onClick={ () => this.props.onAddHandler(this.rule!) }
                text={`Add`} />
            } />
        </React.Fragment>;
    }

    private onConditionChanged(newValue: string) {
        this.rule.condition = newValue;
        this.forceUpdate();
    }

    private getStepConfigurationFetcher() {
        return this.props.artifactStepConfigurationData;
    }

    private onStatusChanged(newValue: string, additionalObject?: any) {
        const configuration = {
            stepSpecification: this.props.stepSpecification,
            connectionId: this.props.connectionId,
            data: Object.assign(
                {},
                this.props.artifactStepConfigurationData
            )
        };
        const artifactToBuild = new PipelineStepArtifact(
            ArtifactDirection.In,
            '',
            configuration,
            []
        );
        this.inDataArtifactBuilder = new ArtifactBuilder.DataArtifactBuilder(
            ArtifactDirection.In,
            artifactToBuild,
            this.props.artifactsFactory,
            (properties: Array<any>, persistedArtifactBuilder: any) => {
                this.inDataArtifact = persistedArtifactBuilder;
            }
        );
        this.rule.status = {
            id: additionalObject?.value,
            name: additionalObject?.label,
        };
        this.setState({ showArtifactBuilder: true });
    }
}