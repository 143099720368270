import React from 'react';
import {
    ConfigurationComponent,
    GenericSoftwareStep,
    IntegrationStep,
    IntegrationStepConfiguration,
    StepSpecification
} from '../software/step';
import {ArtifactDirection, ArtifactsFactory, DataArtifact, PropertyDefinition} from '../artifacts';
import {StepSnippet} from '../step-snippet';
import {Steps} from '../index';
import {StepRightPanelComponent} from './step-right-panel-component';
import {fetchArtifactProperties} from './rest-api';
import {SoftwareName} from '../../software';
import {SoftwareLogo} from '../../designer/step-creator/software-logo';
import {FormError} from '../../engrator-core/form-error.type';
import {ConfiguredProperty} from '../artifacts/data-artifact';

export type PipelineStepConfiguration = {};

export interface PipelineStep<T extends PipelineStepConfiguration> {
    specification: StepSpecification;
    addStepComponentClass: any;
    editStepComponentClass: any;
    getSnippetTitle(configuration: IntegrationStepConfiguration): string;
    preferredNameForArtifact: string;
    requiresConnectionPicker?: boolean;
}

export type ComponentContext = {
    connectionId: number;
    artifactsFactory: ArtifactsFactory;
    stepSpecification: StepSpecification
}
export class PipelineGenericStep<T extends PipelineStepConfiguration> implements GenericSoftwareStep {
    private piepelineStep: PipelineStep<T>;

    constructor(pipelineStep: PipelineStep<T>) {
        this.piepelineStep = pipelineStep;
    }

    getConfigurationComponent(connectionId: number, artifactsFactory: any, addStepHandler: (pipelineIntegrationStep: PipelineIntegrationStep) => void, stepSpecification: StepSpecification): ConfigurationComponent {
        let newStepConfigurationComponent = this.getNewStepConfigurationComponent({
            connectionId,
            artifactsFactory,
            stepSpecification
        }, (configuration: IntegrationStepConfiguration) => {
            addStepHandler(this.getIntegrationStep(configuration));
        });
        return {
            rightPanel: newStepConfigurationComponent, scene: undefined
        }
    }

    getNewStepConfigurationComponent(context: ComponentContext, addStepHandler: (configuration: IntegrationStepConfiguration) => void) {
        const Component = this.piepelineStep.addStepComponentClass;
        return <StepRightPanelComponent
            context={ context}
            isEdit={ false }
            configuration={ {} }
            pipelineStep={ this.piepelineStep }
            addStepHandler={ addStepHandler }
            componentClass={ this.piepelineStep.addStepComponentClass }
        />;
        // return <UI.RightPanelContent
        //         header={`${ this.piepelineStep.specification.action }`}>
        //     <Component
        //         context={ context}
        //         addStepHandler={ addStepHandler }
        //         showArtifactBuilder={ }
        //     />
        // </UI.RightPanelContent>
    }

    getFlowStepSnippet(configuration: any): any {
        return <StepSnippet
            stepType={ Steps.StepType.Action }
            debuggingState={ null }
            softwareName={ configuration.stepSpecification.software }
            software={ <SoftwareLogo softwareName={ configuration.stepSpecification.software} /> }
            inArtifactDefinition={ null }
            outArtifactDefinition={ configuration.outDataArtifact?.name }
            title={`${this.piepelineStep.getSnippetTitle(configuration)}`} >
        </StepSnippet>;
    }

    getIntegrationStep(configuration: IntegrationStepConfiguration): PipelineIntegrationStep {
        return new PipelineIntegrationStep(this.piepelineStep, configuration);
    }

    getStepSpecification(): StepSpecification {
        return this.piepelineStep.specification;
    }

    isRequiringConnectionId(): boolean {
        return this.piepelineStep.requiresConnectionPicker === undefined ||
            this.piepelineStep.requiresConnectionPicker === true;
    }
}


export class PipelineIntegrationStep implements IntegrationStep {
    configuration: IntegrationStepConfiguration;
    private readonly pipelineStep: PipelineStep<PipelineStepConfiguration>;

    constructor(pipelineStep: PipelineStep<PipelineStepConfiguration>, configuration: IntegrationStepConfiguration) {
        this.pipelineStep = pipelineStep;
        this.configuration = configuration;
    }

    getConfiguration(): IntegrationStepConfiguration {
        return this.configuration as unknown as IntegrationStepConfiguration;
    }

    getEditStepComponent(artifactsFactory: ArtifactsFactory, deleteStepHandler: () => void, afterSaveHandler: () => void): any {
        const Component = this.pipelineStep.editStepComponentClass;
        const context = {
            connectionId: this.configuration.connectionId,
            artifactsFactory,
            stepSpecification: this.configuration.stepSpecification
        };
        const copiedConfiguration = JSON.parse(JSON.stringify(this.configuration));
        return <StepRightPanelComponent
            isEdit={ true }
            configuration={ copiedConfiguration }
            context={ context }
            pipelineStep={ this.pipelineStep }
            addStepHandler={ afterSaveHandler }
            componentClass={ Component }
            deleteStepHandler={ deleteStepHandler }
        />;
    }

    getInArtifact(): DataArtifact | null {
        return null;
    }

    getOutArtifact(): DataArtifact | null {
        if (this.getConfiguration().outDataArtifact) {
            return new PipelineStepArtifact(
                ArtifactDirection.Out,
                this.getConfiguration().outDataArtifact.name,
                this.getConfiguration(),
                this.getConfiguration().outDataArtifact.properties
            )
        }
        return null;
    }

    getSoftwareName() : SoftwareName {
        return this.pipelineStep.specification.software as SoftwareName;
    }

    getSnippetComponent(): any {
        return <StepSnippet
            stepType={ Steps.StepType.Action }
            debuggingState={ null }
            softwareName={ this.configuration.stepSpecification.software as SoftwareName }
            software={ <SoftwareLogo softwareName={ this.configuration.stepSpecification.software} /> }
            inArtifactDefinition={ null }
            outArtifactDefinition={ this.configuration.outDataArtifact?.name }
            title={ this.pipelineStep.getSnippetTitle(this.configuration) } >
        </StepSnippet>;
    }

    updateConfiguration(configuration: IntegrationStepConfiguration) {
        this.configuration.data = configuration.data;
        this.configuration.outDataArtifact = configuration.outDataArtifact;
        this.configuration.inDataArtifact = configuration.inDataArtifact;
    }
}

export type NewPipelineStepComponentProps = {
    context: ComponentContext,
    configuration: PipelineStepConfiguration,
    showArtifactBuilder: (direction: ArtifactDirection) => void,
    addStepHandler: (pipelineIntegrationStep: PipelineIntegrationStep) => void,
    formError?: FormError
}

export type EditPipelineStepComponentProps = {
    configuration: IntegrationStepConfiguration;
    validateStep: () => void;
    formError?: FormError;
    showArtifactBuilder: (direction: ArtifactDirection) => void,

}

export class PipelineStepArtifact extends DataArtifact {
    private configuration: any;

    constructor(direction: ArtifactDirection, name: string, configuration: PipelineStepConfiguration, configuredProperties?: Array<ConfiguredProperty>) {
        super(direction, name);
        this.configuration = configuration;
        this.configureProperties = (configuredProperties) ? configuredProperties : [];
    }
    getPreferredName(): string {
        return '';
    }

    getPropertiesDefinitions(): Promise<Array<PropertyDefinition>> {
        return fetchArtifactProperties(
            this.configuration.stepSpecification.software as SoftwareName,
            this.configuration.stepSpecification.key,
            this.direction,
            this.configuration
        )
    }

}
