import { Steps, Debugging } from './../../generic';

export class IntegrationDebugging {
    steps: any[];
    processStep: any;
    onStepDebugged: Debugging.OnStepDebugged;

    constructor(steps: Array<Steps.DebuggingStep>, onStepDebugged: Debugging.OnStepDebugged, processStep: any) {
        this.steps = steps;
        this.processStep = processStep;
        this.onStepDebugged = onStepDebugged;
    }

    async startDebugging(): Promise<Debugging.DebuggingResult> {
        return new Promise(async(resolve, reject) => {
            let stepIndex = 0;
            for (const step of this.steps) {
                try {
                    await this.onStepDebugged({ stepIndex, status: Debugging.StepDebuggingStatus.InProgress, output: {}});
                    await this.processStep(step);
                    await this.onStepDebugged({ stepIndex, status: Debugging.StepDebuggingStatus.Success, output: {}});
                } catch (err) {
                    await this.onStepDebugged({ stepIndex, status: Debugging.StepDebuggingStatus.Error, output: {}});
                    resolve({
                        status: Debugging.DebuggingResultStatus.Error
                    });
                }
                stepIndex++;
            }
            resolve({
                status: Debugging.DebuggingResultStatus.Success
            });
        });
    }
}