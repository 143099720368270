import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { Software } from 'src/generic';
import { createGenericConnectionFactory } from 'src/generic/connections';
import { AuthMethod } from 'src/generic/connections/auth-method.type';
import { SoftwareName } from '../software-name';

import {EasyVistaSmartintsSupport} from './smart-ints';


export class EasyvistaSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.EasyVista, [AuthMethod.BasicAuth]);
    }

    getName(): string {
        return 'EasyVista';
    }

    getID() {
        return 'EasyVista';
    }

    getAvailableSteps(): object[] {
        return [];
    }

    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new EasyVistaSmartintsSupport();
    }
}
