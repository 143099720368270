import { DropdownOption } from '../../../../../engrator-core/ui';
import { SmartIntDefinitionTypeMapping } from '../../definition/smart-int-definition--type-mapping.type';
import { createDefaultAttachemntsConfiguration, getDefaultHierarchySyncConfiguration, SmartIntConfigurationType } from '../../definition/smart-int-definition-configuration.type';
import { SmartIntDirection } from '../../definition/smart-int-direction.type';
import { PropertyMapping } from '../property-mapping.type';
import { getDefaults } from '../components/value-mappings/default-mappping.type';

export function createTypeMapping(typeOnLeftSide: DropdownOption, typeOnRightSide: DropdownOption, leftAppName: string, rightAppName: string): SmartIntDefinitionTypeMapping {
    const properties: PropertyMapping[] = [];

    return {
        direction: SmartIntDirection.Both,
        propertiesConfiguration: {
            properties,
            configurationType: SmartIntConfigurationType.PropertyMappings
        },
        commentsConfiguration: {
            asPrivate: { left: false, right: false },
            filters: { left: {conditions: [], mode: 'Pass'}, right: {conditions: [], mode: 'Pass'} },
            insertOriginalAuthor: true, insertOriginalCreationDate: true, disablePairingComment: true,
            insertItemId: true,
            supportUsersMentionsInJira: false,
            enabled: true,
            configurationType: SmartIntConfigurationType.Comments,
            conditions: { left: '', right: '' }
        },
        hierarchyConfiguration: getDefaultHierarchySyncConfiguration(),
        attachmentsConfiguration: {
            configurationType: SmartIntConfigurationType.Attachments,
            enabled: false,
            onlyPublicCommentsAttachments: {
                left: false,
                right: false
            },
            insertInPublicComment: {
                left: false,
                right: false
            },
            left: createDefaultAttachemntsConfiguration(),
            right: createDefaultAttachemntsConfiguration()
        },
        left: {
            id: typeOnLeftSide.value,
            name: typeOnLeftSide.label,
            internal: typeOnLeftSide.object
        },
        right: {
            id: typeOnRightSide.value,
            name: typeOnRightSide.label,
            internal: typeOnRightSide.object
        },
        advancedConfiguration: {
            configurationType: SmartIntConfigurationType.Advanced,
            postCreate: {
                left: '',
                right: ''
            }
        },
        statusTransitionConfiguration: {
            statusFieldsIds: { left: '', right: ''},
            configurationType: SmartIntConfigurationType.StatusTransition,
            enabled: true,
            groups: [],
            defaults: getDefaults()
        },
        status: 'Enabled'
    }
}

export function isTypeMappingEnabled(typeMapping: SmartIntDefinitionTypeMapping): boolean {
    // Its enabled ifstatus is not disabled
    return typeMapping.status !== 'Disabled';
}
