import { DropdownOption } from 'src/engrator-core/ui';
import { SmartIntTrigger } from './definition/smart-int-trigger.type';
import {SuiteType} from '../../../generic/integrations/suite-type';

export abstract class AppSmartIntsSupport {
    abstract getTriggerConfigurationComponent(connectionId: number, onConfigured: (configuration: SmartIntTrigger) => void, integrationType?: SuiteType): any;
    abstract getEditTriggerConfigurationComponent(connectionId: number, trigger: SmartIntTrigger, onConfigured: (configuration: SmartIntTrigger) => void): any;
    abstract hasTriggerConfigurationComponent(): boolean;

    public fetchSmartIntTypes() {
    }

    public validateTriggerStep() {
    }

    public isSupportingHierarchyEpics(): boolean {
        return true;
    }

    public isSupportingHierarchySubtasks(typeName: string): boolean {
        return true;
    }

    public getEpicRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
        return Promise.resolve([]);
    }

    public getSubtasksRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
        return Promise.resolve([]);
    }

    public supportsAttachmentsStorage(): boolean {
        return false;
    }

}
