import React from 'react';
import { UI } from 'src/engrator-core';
import { ProgressSteps } from './progress-steps';

type Props = {};
type State = {
    isBoxVisible: boolean;
};

export class OnboardingProgress extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isBoxVisible: false
        };
    }

    render() {
        return <div className={`onboarding-progress`}>
            <div className={`progress-box ${((this.state.isBoxVisible) ? 'visible' : '')}`}>
                <h1>
                    Getint.io QuickStart
                    <span 
                        onClick={ () => this.switchBoxVisibility() }
                        className={`close-btn`}>
                        <UI.Icon icon={'close'} />
                    </span>
                </h1>
                <ProgressSteps />
                <UI.Button
                    appearance={`link-inline`}
                    onClick={ () => this.switchBoxVisibility() }
                    text={`Close Quickstart`}
                />
            </div>
            {/* <div className={`progress-btn`} onClick={ this.switchBoxVisibility.bind(this) }>
                <div className={`progress-bar`}>
                    <div className={`full`}></div>
                    <div className={`completed`}></div>
                </div>
                Progress 1 / 3
            </div> */}
            {/*<div className={`progress-btn no-progress`} onClick={ this.switchBoxVisibility.bind(this) }>*/}
            {/*    /!* <div className={`progress-bar`}>*/}
            {/*        <div className={`full`}></div>*/}
            {/*        <div className={`completed`}></div>*/}
            {/*    </div> *!/*/}
            {/*    Quick Start*/}
            {/*</div>*/}
        </div>;
    }

    switchBoxVisibility() {
        this.setState({ isBoxVisible: !this.state.isBoxVisible });
    }
}