import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';

import {JiraCreateIssueConfiguration} from './jira-create-issue-configuration';
import {JiraProjectIssuetypePicker, JiraProjectIssuetypePickerData} from '../common';

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class JiraCreateIssueNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: JiraCreateIssueConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as JiraCreateIssueConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: '', newValue) => {
        });
    }

    render() {
        return <React.Fragment>
            <JiraProjectIssuetypePicker
                formError={ this.props.formError }
                connectionId={ this.props.context.connectionId }
                onDataChanged={ this.onProjectIssueTypeDataChanged.bind(this) } />
        </React.Fragment>
    }

    private onProjectIssueTypeDataChanged(data: JiraProjectIssuetypePickerData) {
        this.configuration.project = data.project!;
        this.configuration.issueType = data.issueType!;
        if (this.configuration.project && this.configuration.issueType) {
            this.props.showArtifactBuilder(ArtifactDirection.In);
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }
}
