import React, { Component } from 'react';
import { UI } from 'src/engrator-core';
import { ConnectionModel } from '../connection-model';
import { debugEndpoint, DebugResult } from '../rest-api';


type Props = {
    connection: ConnectionModel;
    closeHandler: () => void;
};
type State = {
    isLoading: boolean;
    formError: { [key: string]: string };
    data: {
        endpointUrl: string;
    };
    debugResult?: DebugResult;
};

export class ConnectionDebugger extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { data: {endpointUrl: ''}, isLoading: false, formError: {} };

    }

    async onSendRequestClicked(): Promise<boolean> {
        await this.setState({debugResult: undefined, isLoading: true});
        try {
            const result = await debugEndpoint(this.props.connection.id, this.state.data.endpointUrl)
            try {
                // Try to format if it is json
                result.data = JSON.stringify(JSON.parse(result.data!), undefined, 4);
            } catch (e) {}
            this.setState({debugResult: result, isLoading: false});
            return Promise.resolve(false);
        } catch (e) {
            this.setState({isLoading: false});
            return Promise.resolve(false);
        }
    }


    render() {
        return <UI.FullScreenModal
            isPrimaryDisabled={ !this.state.data.endpointUrl }
            primaryBtnHandler={ () => this.onSendRequestClicked() }
            primaryBtnText={`Send Request`}
            closeBtnHandler={ () => this.props.closeHandler() }
            className={`connection-debugger`}
            header={ `Debugging Connection #${ this.props.connection.id }`}
        >
            <UI.Form errors={ [] }  error={ this.state.formError['general'] } className={this.state.debugResult ? 'success-result' : ''}>
                {/* { this.state.successMessage && <UI.Message appearance={"success"}>{ this.state.successMessage }</UI.Message> } */}
                <UI.FormGroup>
                    <UI.LabelValue 
                        label="Software" 
                        value={ this.props.connection.softwareName } 
                    />
                </UI.FormGroup>
                <UI.FormGroup>
                    <UI.LabelValue 
                        label="Main Url"
                        value={<a target={`blank`} href={this.props.connection.url}>{this.props.connection.url}</a>}
                    />
                </UI.FormGroup>
                <UI.FormSection
                    label={`Details`}
                >
                    <UI.FormGroup
                        label={`Endpoint Url`}
                        error={ this.state.formError['endpointUrl'] }
                        isRequired={true}>
                        <UI.Input 
                            placeholder={`Endpoint Url`}
                            defaultValue={ this.state.data.endpointUrl } 
                            onChange={ (newValue: string) => { this.changeProperty('endpointUrl', newValue) }   }
                        />
                    </UI.FormGroup>
                    { this.state.debugResult && <UI.FormGroup>
                        {this.state.debugResult.data && <UI.Textarea
                            // className={`response`}
                            // label="Response"
                            defaultValue={this.state.debugResult.data}
                            rows={15}
                            onChange={ () => {} }
                        />}
                        {this.state.debugResult.error &&
						<UI.Message appearance={'error'}>{this.state.debugResult.error}</UI.Message>}
                    </UI.FormGroup> }
                </UI.FormSection>
            </UI.Form>
        </UI.FullScreenModal>
    }

    changeProperty(propertyName: 'endpointUrl', newValue: string) {
        const data = this.state.data;
        data[propertyName] = newValue;
        this.setState({ data });
    }
}
