import React, {Component} from 'react';
import {
    CanBeUsedForAutoCreationResponse, OptionsAutoCreationResolverConfig
} from '../rest-api';
import {UI} from 'src/engrator-core';
import {ResolverId} from './resolvers';
import {JiraSprintsResolverConfig} from './resolvers/jira-sprints-resolver-config';
import {AzureIterationPathResolverConfig} from './resolvers/azure-iteration-path-resolver-config';

type Props = {
    resolverConfig?: OptionsAutoCreationResolverConfig;
    canBeUsedConfig: CanBeUsedForAutoCreationResponse;
};
type State = {
    isEnabled: boolean;
    isLoading: boolean;
}

export class SharedMappingsAutoCreationResolverConfig extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            isEnabled: !!this.props.resolverConfig?.resolverId
        };
    }

    async componentDidMount() {
    }

    render() {
        if (!this.props.canBeUsedConfig.resolverId) {
            return <div>Options auto-creation is not supported for this field</div>;
        }
        return (
            <div>
                <UI.Checkbox
                    label={`Enable options auto-creation`}
                    defaultValue={`${this.state.isEnabled}`}
                    onChange={() => this.enabledClicked()}
                    checkedValue={`true`}
                    uncheckedValue={`false`}
                />
                {this.state.isEnabled && <React.Fragment>
                    { (this.props.resolverConfig && this.props.resolverConfig.resolverId === ResolverId.JiraSprints) && <JiraSprintsResolverConfig
                        resolverConfig={ this.props.resolverConfig }
                        canBeUsedConfig={ this.props.canBeUsedConfig }
                        updateConfigDataHandler={ (data) => this.updateConfigDataHandler(data) }
                    />}
                    { (this.props.resolverConfig && this.props.resolverConfig.resolverId === ResolverId.AzureIterationPaths) && <AzureIterationPathResolverConfig
                        resolverConfig={ this.props.resolverConfig }
                        canBeUsedConfig={ this.props.canBeUsedConfig }
                        updateConfigDataHandler={ (data) => this.updateConfigDataHandler(data) }
                    />}
                </React.Fragment>}
            </div>
        );
    }

    private enabledClicked() {
        const isEnabled = !this.state.isEnabled;
        if (isEnabled) {
            this.props.resolverConfig!.resolverId = this.props.canBeUsedConfig.resolverId;
        } else {
            this.props.resolverConfig!.resolverId = undefined;
        }
        this.setState({isEnabled});
    }

    private async updateConfigDataHandler(data: Object): Promise<void> {
        if (this.props.resolverConfig) {
            this.props.resolverConfig.configData = data;
        }
    }
}
