import React from 'react';

import {EntityFormHelper, UI} from '../../engrator-core';
import {BasicAuthData} from './basic-auth-data.type';
import {FormError} from '../../engrator-core/form-error.type';

type Props = {
    auth: any,
    formError: FormError
};
type State = {
    formError: { [key: string]: string }
};

export class BasicAuth extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private auth: any;
    private basicAuthData: BasicAuthData = {
        login: '', password: ''
    };

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = { formError: {} };
        this.auth = this.props.auth;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'login' | 'password', newValue: string) => {
            this.basicAuthData[propertyName] = newValue;
            this.auth.login = this.basicAuthData.login;
            this.auth.password = this.basicAuthData.password;
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.formError !== prevProps.formError) {
            this.forceUpdate();
        }
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                label={`Login`}
                dataSel={`username`}
                error={ this.props.formError['login'] }
                description={``}
                isRequired={true}>
                <UI.Input onChange={ this.entityFormHelper.onChangeHandler('login') }/>
            </UI.FormGroup>
            <UI.FormGroup
                dataSel={`password`}
                label={`Password`}
                error={ this.props.formError['password'] }
                isRequired={true}>
                <UI.Input type="password" onChange={ this.entityFormHelper.onChangeHandler('password')   }/>
            </UI.FormGroup>
        </React.Fragment>
    }
}