import React from 'react';
import { UI } from 'src/engrator-core';
import { importScript, importScriptWithConnections, ImportValidateResult, ScriptToImport, validateImportedScript, validateImportedScriptWithConnections } from './rest-api';
import {FormError} from '../../../../../../engrator-core/form-error.type';
import {IntegrationImportConnections} from './integration-import-conncetions';
import { IntegrationImportConnectionsList } from './integration-import-connections-list';
import { DropdownOption } from 'src/engrator-core/ui';

type Props = {
    closeHandler: () => void;
};

type State = {
    isLoading: boolean;
    stepIndex: number;
    script?: string;
    error?: FormError;
    validateResult?: ImportValidateResult;
    scriptToImport: ScriptToImport;
    createdIntegrationId?: number;
    isWithConnections?: boolean;
    encryptionKey: string;
    isKeyVisible: boolean;
    dropdownOptionsLeft: DropdownOption[],
    dropdownOptionsRight: DropdownOption[]
};

export class IntegrationImport extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            stepIndex: 1,
            scriptToImport: {
                script: '',
                name: '',
                rightConnectionId: 0,
                leftConnectionId: 0
            },
            encryptionKey: '',
            isKeyVisible: false,
            dropdownOptionsLeft: [],
            dropdownOptionsRight: []
        };
    }

    render() {
        let isDisabled = false;
        let btnText = '';
        const showPrimaryBtn = (!this.state.createdIntegrationId);
        if (this.state.stepIndex === 1) {
            isDisabled = !this.state.script || this.state.isLoading || (this.state.isWithConnections as boolean && !this.state.encryptionKey);
            btnText = 'Next';
        } else if (this.state.stepIndex === 2) {
            isDisabled =  this.state.isLoading || !this.state.scriptToImport.name || !this.state.scriptToImport.script || !this.state.scriptToImport.leftConnectionId || !this.state.scriptToImport.rightConnectionId;
            btnText = 'Import';
        }
        return <UI.FullScreenModal
            closeBtnHandler={ () => this.closeClicked() }
            primaryBtnHandler={ () => this.primaryClicked() }
            primaryBtnText={ btnText }
            showPrimaryBtn={ showPrimaryBtn }
            isPrimaryDisabled={ isDisabled }
            header={`Import integration ${this.state.isWithConnections ? 'with connections' : ''}`}
        >
            { this.state.error && <UI.Message appearance={'error'}>
                <p>Something went wrong: { this.state.error.message }</p>
            </UI.Message> }
            { !this.state.validateResult && <React.Fragment>
                <p>Please paste internal script</p>
                <UI.Textarea onChange={ (script) => this.setState({ script, isWithConnections: script.includes('connections') }) }  rows={ 20 } />
            </React.Fragment> }
            { this.state.validateResult && !this.state.createdIntegrationId && <UI.Form>
                <UI.FormGroup
                    label={`Provide integration name`}
                    isRequired={ true }
                >
                    <UI.Input
                        onChange={ this.integrationNameChanged.bind(this) }
                    />
                </UI.FormGroup>
                {!this.state.isWithConnections ? <IntegrationImportConnections
                    leftConnections={this.state.dropdownOptionsLeft}
                    rightConnections={this.state.dropdownOptionsRight}
                    leftConnectionId={this.state.scriptToImport.leftConnectionId}
                    rightConnectionId={this.state.scriptToImport.rightConnectionId}
                    validationResult={ this.state.validateResult! }
                    connectionIdSelectedHandler={ this.connectionIdSelectedHandler.bind(this) }
                /> : <IntegrationImportConnectionsList leftConnectionName={this.state.scriptToImport.leftConnectionName!}
                    rightConnectionName={this.state.scriptToImport.rightConnectionName!} 
                    validationResult={ this.state.validateResult! } />}
            </UI.Form> }
            {this.state.isWithConnections && this.state.stepIndex === 1 && <UI.Form>
                <UI.FormGroup
                    label={`Encryption key`}
                    isRequired={ true }
                    description={`Key that will be used to encrypt credentials data`}>
                    <UI.Input
                        type={ (this.state.isKeyVisible) ? 'text' : 'password' }
                        onChange={(newValue) => this.setState({ encryptionKey: newValue })}
                    />
                </UI.FormGroup>
                <UI.FormGroup>
                    <UI.Checkbox
                        label={`Show encryption key`}
                        onChange={ () => this.switchKeyVisibility() }
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                    />
                </UI.FormGroup>
            </UI.Form>}
            { this.state.createdIntegrationId && <UI.Message appearance={'info'}>
                <p>Your integration has been imported successfully!</p>
                <p>Please notice, that integration is <b>DISABLED</b> at the moment. You can enable it in Settings section.</p>
                <UI.NavLink path={`/app/integration/smart-ints/${ this.state.createdIntegrationId }/edit`}>Go to integration</UI.NavLink>
            </UI.Message> }
        </UI.FullScreenModal>
    }

    private async switchKeyVisibility() {
        await this.setState({ isKeyVisible: !this.state.isKeyVisible });
    }

    private primaryClicked(): Promise<boolean> {
        if (this.state.stepIndex === 1) {
            if (this.state.isWithConnections) {
                return this.validateImportWithConnections();
            }
            return this.validateImport();
        }
        return this.importClicked();
    }

    private validateImportWithConnections(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true, error: undefined}, () => {
                validateImportedScriptWithConnections(this.state.script!, this.state.encryptionKey)
                    .then(result => {
                        const scriptToImport = this.state.scriptToImport;
                        const scriptParsed = JSON.parse(this.state.script!)
                        scriptToImport.script = scriptParsed;
                        scriptToImport.leftConnectionName = scriptParsed.connections.left.name;
                        scriptToImport.rightConnectionName = scriptParsed.connections.right.name;
                        scriptToImport.leftConnectionId = 1;
                        scriptToImport.rightConnectionId = 1;
                        this.setState({stepIndex: 2, isLoading: false, scriptToImport, validateResult: result});
                        resolve(false);
                    }).catch(error => {
                        this.setState({isLoading: false, error})
                        resolve(false);
                    });
            });
        });
    }

    private validateImport(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true}, () => {
                validateImportedScript(this.state.script!)
                    .then(result => {
                        const scriptToImport = this.state.scriptToImport;
                        const scriptParsed = JSON.parse(this.state.script!)
                        scriptToImport.script = scriptParsed;
                        const dropdownOptionsLeft: DropdownOption[] = result.rawLeftConnections.map((connection: any) => ({
                            ...connection,
                            value: connection.id,
                            label: connection.name
                        }));
                        const dropdownOptionsRight: DropdownOption[] = result.rawRightConnections.map((connection: any) => ({
                            ...connection,
                            value: connection.id,
                            label: connection.name
                        }));
                        this.setState({stepIndex: 2, error: undefined, isLoading: false, scriptToImport, validateResult: result, dropdownOptionsLeft, dropdownOptionsRight});
                        resolve(false);
                    }).catch(error => {
                        this.setState({isLoading: false, error})
                        resolve(false);
                    });
            });
        });
    }

    private importClicked(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.setState({error: undefined, isLoading: true}, async () => {
                if (!this.state.isWithConnections) {
                    try {
                        const createdIntegrationId = await importScript(this.state.scriptToImport);
                        this.setState({isLoading: false, createdIntegrationId});
                        resolve(false);
                    } catch (error) {
                        this.setState({isLoading: false, error: error as FormError});
                        resolve(false);
                    }
                } else {
                    try {
                        const obj = await importScriptWithConnections(this.state.scriptToImport, this.state.encryptionKey);
                        this.setState({isLoading: false, createdIntegrationId: obj.newIntegrationId});
                        resolve(false);
                    } catch (error) {
                        this.setState({isLoading: false, error: error as FormError});
                        resolve(false);
                    }
                }
            });
        });
    }

    private connectionIdSelectedHandler(side: 'left' | 'right', connectionId: number) {
        const scriptToImport = this.state.scriptToImport;
        if (side === 'left') {
            scriptToImport.leftConnectionId = connectionId;
        }
        if (side === 'right') {
            scriptToImport.rightConnectionId = connectionId;
        }
        this.setState({ scriptToImport });
    }

    private integrationNameChanged(newValue: string) {
        const scriptToImport = this.state.scriptToImport;
        scriptToImport.name = newValue;
        if (this.state.isWithConnections) {
            const scriptParsed = JSON.parse(this.state.script!)
            scriptParsed.integrationDetails.name = newValue;
            scriptToImport.script = scriptParsed;
        }
        this.setState({ scriptToImport });
    }

    private closeClicked() {
        this.props.closeHandler();
    }
}
