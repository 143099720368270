import { PropertySourceType } from './property-source-type';
import {PredefinedPropertySource} from './predefined-property-source';
import PropertyDataType from '../property-data-type';
import {OtherArtifactPropertySource} from './other-artifact-property-source';
import {ArtifactDirection} from '../artifact-direction';
import {OptiontPropertySource} from './option-property-source';

export type PropertySource = {
    condition: string;
    sourceType: PropertySourceType;
};

export function createPropertySource(propertyMapping: any, artifactDirection: ArtifactDirection): PropertySource {
    if (propertyMapping.predefinedValue) {
        const predefinedPropertySource: PredefinedPropertySource = {
            value: propertyMapping.predefinedValue,
            condition: propertyMapping.condition,
            dataType: PropertyDataType.String,
            sourceType: PropertySourceType.Predefined
        };
        return predefinedPropertySource;
    } else if (propertyMapping.otherArtifact) {
        const otherArtifactPropertySource: OtherArtifactPropertySource = {
            artifactId: propertyMapping.otherArtifact.artifactId,
            condition: propertyMapping.condition,
            propertyDefinition: propertyMapping.otherArtifact.propertyDefinition,
            sourceType: PropertySourceType.OtherArtifact
        };
        return otherArtifactPropertySource;
    } else if (propertyMapping.option) {
        const propertySource: OptiontPropertySource = {
            id: propertyMapping.option.id,
            label: propertyMapping.option.label,
            condition: propertyMapping.condition,
            sourceType: PropertySourceType.Option
        };
        return propertySource;
    } else if (artifactDirection === ArtifactDirection.Out) {
        return {
            condition: '',
            sourceType: PropertySourceType.Step
        };
    }
    throw new Error('Unknown property source');
}