import {axiosWrapper} from 'src/engrator-core';

export type AccountAccessDTO = {
    approvedAt: string;
    expiresAt: string;
    statusHumanFormat: string;
    status: 'Revoked' | 'Active' | 'Expired';
};

const ENDPOINT_URL = '/settings/account-access';

export async function fetchAccessHistory(): Promise<AccountAccessDTO[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function revokeAccess(): Promise<AccountAccessDTO> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.delete(`${ENDPOINT_URL}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function approveAccess(): Promise<AccountAccessDTO> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}`, {})
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}