import React, {Component, useEffect} from 'react';

import {FieldError, UI} from 'src/engrator-core';

import {fetchSystemApiKeys} from './rest-api';
import {createSystemApiKeysTableGridData} from './system-api-table-grid-data';
import RightPanel from '../../../designer/container/right-panel/RightPanel';
import {CreateApiKeyForm} from './create-api-key-form';
import {ApiKeyDetails} from './api-key-details';
import {SystemApiKey} from './system-api-key.type';
import {useGlobalState} from '../../global-store';
import {getSystemVerticalMenu} from '../system-vertical-menu';
import {MainMenuItem} from '../../global-store/create-global-state';

type State = {
    isLoading: boolean,
    error?: FieldError,
    rightPanelState: RightPanelState
}

enum RightPanelState {
    Hidden,
    CreateApiKey,
    EditApiKey
}

class SystemApiKeysPageCmp extends Component<{}, State> {
    private tableGridData?: UI.TableGridData;
    private systemApiKeyToEdit?: SystemApiKey;

    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: true, rightPanelState: RightPanelState.Hidden
        };
    }

    componentDidMount(): void {
        this.fetchDataFromApi();
    }

    render() {
        return (
            <UI.Page>
                <UI.ButtonsBar
                    primary={ <UI.Button onClick={() => this.createApiKeyClicked() }  text={`Create Api Key`} /> } />
                { this.state.isLoading && <UI.Loader appearance={'darkgray'} visible={true} /> }
                { this.state.error && <UI.Message appearance={'error'} message={ this.state.error?.message } /> }
                { !this.state.isLoading && this.tableGridData && <div>
                    <UI.TableGrid
                        data={ this.tableGridData!.data }
                        headers={ this.tableGridData!.headers }
                    />
                    { this.state.rightPanelState === RightPanelState.CreateApiKey && <RightPanel
                        close={ this.closeRightPanel.bind(this) }>
                        <CreateApiKeyForm onKeyAdded={ () => this.fetchDataFromApi() }  />
                    </RightPanel> }
                    { this.state.rightPanelState === RightPanelState.EditApiKey && <RightPanel
                        close={ this.closeRightPanel.bind(this) }>
                        <ApiKeyDetails
                            onSystemApiKeyUpdated={ () => this.fetchDataFromApi() }
                            apiKey={ this.systemApiKeyToEdit! }
                            closeHandler={ this.closeRightPanel.bind(this) } />
                    </RightPanel> }
                </div> }
            </UI.Page>
        )
    }

    private createApiKeyClicked() {
        this.setState({ rightPanelState: RightPanelState.CreateApiKey });
    }

    private editApiKey(systemApiKey: SystemApiKey) {
        this.systemApiKeyToEdit = systemApiKey;
        this.setState({ rightPanelState: RightPanelState.EditApiKey });
    }

    private closeRightPanel() {
        this.setState({ rightPanelState: RightPanelState.Hidden });
    }

    private fetchDataFromApi() {
        this.setState({ rightPanelState: RightPanelState.Hidden, isLoading: true }, () => {
            fetchSystemApiKeys()
                .then(apiKeys => {
                    this.tableGridData = createSystemApiKeysTableGridData(apiKeys, this.editApiKey.bind(this));
                    this.setState({isLoading: false});
                })
                .catch(error => this.setState({isLoading: false, error}));
        });
    }
}

export function SystemApiKeysPage() {
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    const [, setMenu] = useGlobalState('menu');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.SystemSettings);
        setMenu(getSystemVerticalMenu());
    }, []);
    return <SystemApiKeysPageCmp />
}