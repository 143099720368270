import React from 'react';
import { Side } from 'src/generic';
import { Teams } from './functions';
import { UI } from 'src/engrator-core';

type Props = {
    side: Side;
    teams: Teams;
    handleAcceptTeam: (side: Side) => void;
    setState: React.Dispatch<React.SetStateAction<any>>;
};

export default function TeamConfiguration({ side, teams, handleAcceptTeam, setState }: Props) {
    function handleInput(newValue: string): void {
        const newTeams = { ...teams };
        newTeams[side].organizationId = newValue;
        setState({ teams: newTeams });
    }
    return (
        <div className={side === Side.Left ? 'left' : 'right'}>
            <h3 className={`teams-side-title`}>Configure {side === Side.Left ? 'left' : 'right'} team field</h3>
            {!teams[side].accepted && (
                <div className={`teams-form`}>
                    <UI.Input
                        label={`Organization ID`}
                        onChange={(val: string) => handleInput(val)}
                        defaultValue={teams[side].organizationId}
                    />
                    <UI.Button
                        text={'Accept'}
                        onClick={() => handleAcceptTeam!(side)}
                        isLoading={teams[side].acceptedLoading}
                    />
                    {teams[side].error && (
                        <UI.Message appearance="error-message" message={teams[side].error} classes={`margin-top-md`} />
                    )}
                </div>
            )}
            {teams[side].accepted && (
                <p>
                    <span className={`font-bold`}>Organization ID:</span> {teams[side].organizationId}
                </p>
            )}
        </div>
    );
}
