import {axiosWrapper, FieldError} from 'src/engrator-core';
import {WrikeFolder, WrikeSpace} from './common';

export type WrikeItemStatus = {
    permalink: string;
    title: string;
    customStatusId: string;
}

export const ENDPOINT_URL = '/connectors/wrike';

export const fetchSpaces = (connectionId: number): Promise<WrikeSpace[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/spaces')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchFolders = (connectionId: number, spaceId: string): Promise<WrikeFolder[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/spaces/' + spaceId + '/folders')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export async function discoverWrikeStatuses(connectionId: number, folderId: string): Promise<WrikeItemStatus[]> {
    const statuses = await axiosWrapper
        .get(ENDPOINT_URL + '/meta/' + connectionId + '/statuses/discover/' + folderId) as WrikeItemStatus[];
    return statuses;
}