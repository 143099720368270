import { axiosWrapper } from 'src/engrator-core';
import {createRunDetails, RunDetails} from './run-details';
import {createPerformedFlow} from './performed-flow';

const ENDPOINT_URL = '/reporting/suite-run';

export type FlowsResponse = {
    headers: string[];
    data: any[];
    pageNumber: number;
    totalPages: number;

}
export const fetchRunDetails = (runId: number): Promise<RunDetails> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/' + runId)
                .then((data: any) => {
                    resolve(createRunDetails(data));
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchRunLogs = (runId: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/' + runId + '/logs')
                .then((logs: any) => {
                    resolve(logs as string);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export async function shareSupportAccess(runId: number): Promise<boolean> {
    return await axiosWrapper.post(ENDPOINT_URL + '/' + runId + '/support-access', {}) as boolean;
}

export function downloadRunLogs(runId: number) {
    return new Promise((resolve, reject) => {
        axiosWrapper.postWithWholeResponse(ENDPOINT_URL + '/' + runId + '/logs/download', {}, { responseType: 'arraybuffer'})
            .then( (response: any) => {
                const filename = response.headers?.filename;
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'run_' + runId  + '.zip'); //or any other extension
                document.body.appendChild(link);
                link.click();
                resolve();
            } )
            .catch( (error) => reject(error) );
    });
}

export const fetchPerformedFlows = (runId: number, page: number, filtersData: any): Promise<FlowsResponse> => {
    return new Promise((resolve, reject) => {
        try {
            const filtersQuery = combineFiltersIntoQuery(filtersData);
            axiosWrapper.post('/reporting/PerformedFlows?page=' + page, filtersQuery)
                .then((data: any) => {
                    resolve({
                        headers: ['ID', 'Triggered By', 'Synced with', 'Mode', 'Execution Time', 'Subitems', 'Status', 'Actions'],
                        data: data.data.map(createPerformedFlow),
                        pageNumber: data.currentPage,
                        totalPages: data.pages
                    });
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const downloadReportingData = (runId: number, filtersData: any): Promise<FlowsResponse> => {
    return new Promise((resolve, reject) => {
        try {
            const filtersQuery = combineFiltersIntoQuery(filtersData);
            axiosWrapper.post('/reporting/PerformedFlows/download', filtersQuery)
                .then((response: any) => {
                    const url = window.URL.createObjectURL(new Blob([response], { type: 'text/plain' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'run_' + runId  + '.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function combineFiltersIntoQuery(filtersData: any): any {
    const copyOfFilters = JSON.parse(JSON.stringify(filtersData));
    for (const key of Object.keys(copyOfFilters)) {
        const value = copyOfFilters[key];
        if (value.selectedValues !== undefined && Array.isArray(value.selectedValues)) {
            value.selectedValues = value.selectedValues.join(',');
        }
    }
    return copyOfFilters;
}