import React from 'react';
import {TimeDisplay} from './time-display';

type Props = {
    startTime: string,
    formattedExecutionTime?: string
};

export class ExecutionTime extends React.Component<Props> {
    render() {
        return <div className={`engrator-ui-execution-time`}>
            <TimeDisplay dateTime={this.props.startTime}/> <br/>
            {this.props.formattedExecutionTime &&
                <span className={`time`}> {this.props.formattedExecutionTime} </span>
            }
        </div>;
    }
}