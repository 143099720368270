import React from 'react';

type Props = {
    typesNumber: number;
};
type State = {};

export class CurvyLines extends React.Component<Props , State> {
    componentDidMount() {
        this.draw();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.typesNumber !== prevProps.typesNumber) {
            this.draw();
        }
    }

    draw() {
        try {
            const theCanvas = document.getElementById('lines-canvas') as any;
            const ctx = theCanvas.getContext('2d');
            ctx.reset();
            ctx.clearRect(0, 0, ctx.width, ctx.height);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#ccc';
            if (this.props.typesNumber === 0) {
                return;
            }
            // Left sisde
            const middleOfLogoY = 160;
            const leftStartX = 25;
            ctx.beginPath();
            ctx.moveTo(0, middleOfLogoY);
            ctx.lineTo(leftStartX, middleOfLogoY);
            ctx.stroke();
            let y = 0;
            for (let i = 0; i < this.props.typesNumber; i++) {
                y = 15 + i * 70;
                // Left
                ctx.beginPath();
                ctx.moveTo(leftStartX, y);
                ctx.lineTo(100, y);
                ctx.stroke();
            }
            ctx.beginPath();
            ctx.moveTo(leftStartX, Math.max(middleOfLogoY, y));
            ctx.lineTo(leftStartX, 15);
            ctx.stroke();
            // Right side
            const rightXStart = 680;
            ctx.beginPath();
            ctx.moveTo(700, middleOfLogoY);
            ctx.lineTo(rightXStart, middleOfLogoY);
            ctx.stroke();
            for (let i = 0; i < this.props.typesNumber; i++) {
                y = 15 + i * 70;
                // Left
                ctx.beginPath();
                ctx.moveTo(rightXStart, y);
                ctx.lineTo(600, y);
                ctx.stroke();
            }
            ctx.beginPath();
            ctx.moveTo(rightXStart, Math.max(middleOfLogoY, y));
            ctx.lineTo(rightXStart, 15);
            ctx.stroke();
        } catch (e) {
        }
    }
    render() {
        return <canvas
            width="705"
            height="400"
            style={ { 'left': 140, 'position': 'absolute'} } id="lines-canvas">
        </canvas>;
    }
}