import React from 'react';

import { SceneTransitionType, SceneComponentType, SceneTransitionData } from './';
import { StepPicker } from './../../step-creator';
import { EventsBus, DesignerEventType } from 'src/generic';
import {IntegrationStepConfiguration} from '../../../generic/software/step';

const createStepPicker = (data: SceneTransitionData) => {
    return <StepPicker 
        stepFactory={ data.stepsFactory }
        addStep={ data.addStephandler } 
        connectionFactories={ data.connectionFactories }
        artifactsFactory={ data.artifactsFactory }
        stepsFilter={ data.stepsFilter } />;
};

const createStepEdition = (data: SceneTransitionData, eventBus: EventsBus) => {
    const EditStepComponent = () => data.stepToEdit.getEditStepComponent(data.artifactsFactory, data.deleteStepHandler, (configuration: IntegrationStepConfiguration) => {
        eventBus.notify(DesignerEventType.StepsChangedEvent, {
            configuration
        });
    });
    return <EditStepComponent />;
};

export type SceneTransitionComponent = {
    element: any,
    componentType: SceneComponentType
};

export class SceneTransition {
    setSceneElement: (sceneElementType: SceneComponentType, element: any) => void;
    eventBus: EventsBus;

    constructor(setSceneElement: (sceneElementType: SceneComponentType, element: any) => void, eventBus: EventsBus) {
        this.setSceneElement = setSceneElement;
        this.eventBus = eventBus;
    }

    performTransition(transitionType: SceneTransitionType, data: SceneTransitionData) {
        if (transitionType === SceneTransitionType.AddStep) {
            this.setSceneElement(SceneComponentType.RightPanel, createStepPicker(data));
            return;
        }
        if (transitionType === SceneTransitionType.EditStep) {
            this.setSceneElement(SceneComponentType.RightPanel, createStepEdition(data, this.eventBus));
            return;
        }
        if (transitionType === SceneTransitionType.ViewForRightPanel) {
            this.setSceneElement(SceneComponentType.RightPanel, data.rightPanelView);
            return;
        }
        throw new Error('Wrong transition type');
    }
}
