import {PipelineStep} from 'src/generic';
import {
    GitHubCreateCommentsPipelineStep,
    GitHubCreateItemPipelineStep,
    GitHubSearchItemsPipelineStep,
    GitHubUpdateItemPipelineStep,
    GitHubFindItemPipelineStep,
    GitHubStatusTransitionPipelineStep
} from './steps';

export const GitHubPipelineSteps: PipelineStep<any>[] = [
    GitHubSearchItemsPipelineStep,
    GitHubCreateItemPipelineStep,
    GitHubCreateCommentsPipelineStep,
    GitHubUpdateItemPipelineStep,
    GitHubFindItemPipelineStep,
    GitHubStatusTransitionPipelineStep
];