import {PipelineStep} from 'src/generic/pipeline-step';
import {SoftwareName} from 'src/software/software-name';
import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';

import {GitHubStepKey} from '../github-step-key.type';
import {GitHubSearchItemsConfiguration} from './github-search-items-configuration';
import {GitHubSearchItemsNewStep} from './github-search-items-new-step';
import {GitHubSearchItemsEditStep} from './github-search-items-edit-step';


export const GitHubSearchItemsPipelineStep :  PipelineStep<GitHubSearchItemsConfiguration> = {
    addStepComponentClass: GitHubSearchItemsNewStep,
    editStepComponentClass: GitHubSearchItemsEditStep,
    specification: {
        action: 'Search GitHub Issues',
        description: 'Search issues in specified project/repository',
        key: GitHubStepKey.GitHubSearchItems,
        software: SoftwareName.GitHub,
        stepType: StepType.Trigger
    },
    preferredNameForArtifact: `GitHubItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitHubSearchItemsConfiguration;
        return `Search for issues in ${stepConfiguration.context.repository?.name}`;
    }
}