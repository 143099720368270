import { axiosWrapper } from 'src/engrator-core';
import { IntegrationThreadState } from './integration-threads/integration-thread-state.type';

const ENDPOINT_URL = '/analysis';

export const fetchIntegrationThreadsStates = (): Promise<IntegrationThreadState[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/threads/state`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const stopRun = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/threads/stop-run`)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

