import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';

import {ServiceNowStepKey} from '../servicenow-step-key.type';
import {ServiceNowStatusTransitionEditStep} from './servicenow-status-transition-edit-step';
import {ServiceNowStatusTransitionNewStep} from './servicenow-status-transition-new-step';
import {ServiceNowStatusTransitionConfiguration} from './servicenow-status-transition-configuration';

export const ServiceNowStatusTransitionPipelineStep: PipelineStep<ServiceNowStatusTransitionConfiguration> = {
    addStepComponentClass: ServiceNowStatusTransitionNewStep,
    editStepComponentClass: ServiceNowStatusTransitionEditStep,
    specification: {
        action: 'Change item status',
        description: 'Move ServiceNow item from one status to another',
        key: ServiceNowStepKey.ServiceNowStatusTransition,
        software: SoftwareName.ServiceNow,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ServiceNowStatusTransitionConfiguration;
        return `Change item status`;
    }
}