import React from 'react';

import softwareFactory, {ISoftwareFactory} from '../../../../software/software-factory';
import SoftwareList from '../../../../designer/step-creator/SoftwareList';
import {SmartIntDefinitionApps} from '../definition/smart-int-definition-apps.type';

type Props = {
    setConfigurationHandler: (configuration: SmartIntDefinitionApps) => void;
    softwareFactory: ISoftwareFactory;
};
type State = {
};

export class SelectAppsStep extends React.Component<Props, State> {
    private readonly softwareNames: string[];
    private configuration: SmartIntDefinitionApps = {
        left: '',
        right: ''
    };

    constructor(props: Props) {
        super(props);
        this.softwareNames = softwareFactory.getAppsSupportingSmartInts().map(software => software.getName());
    }

    render() {
        return <div className={`select-apps-step step`}>
            <div className={`lists`}>
                <SoftwareList
                    onSelect={ (software: string) => this.softwareSelectedHandler(software, 'left') }
                    selectedSoftware={ this.configuration.left }
                    list={ this.softwareNames } />
                <SoftwareList
                    onSelect={ (software: string) => this.softwareSelectedHandler(software, 'right') }
                    selectedSoftware={ this.configuration.right }
                    list={ this.softwareNames } />
            </div>
        </div>;
    }

    private softwareSelectedHandler(softwareName: string, side: 'left' | 'right') {
        this.configuration[side] = softwareName;
        this.forceUpdate();
        if (this.configuration.left && this.configuration.right) {
            this.props.setConfigurationHandler(this.configuration);
        }
    }
}
