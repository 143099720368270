import React from 'react';

import {isJiraServerNativeApp, UI} from '../../../engrator-core';
import {ArtifactVersionPreviewLink} from '../artifacts-versions';
import {PerformedFlow} from './performed-flow';
import {downloadReportingData, fetchPerformedFlows, FlowsResponse, shareSupportAccess} from './rest-api';
import {SubItems} from '../sub-items';
import {ReportingFilters} from '../reporting-filters';
import {ReportName} from '../report-name.type';
import {BulkResync} from '../bulk-resync/bulk-resync';
import {FilterName} from '../filter-name.type';
import {getOppositeSide} from '../../../generic';
import {SyncedItemsContextMenu} from '../synced-items-context-menu';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Icon20} from '../../../engrator-core/ui';

type RunPerformedFlowsTableProps = {
    canShareSupportAccess: boolean;
    isSharedWithSupport: boolean;
    runId: number;
    suiteId: number;
} & RouteComponentProps;
type State = {
    isSharedWithSupport: boolean;
    isSendingShareAccess: boolean;
    response?: FlowsResponse;
}

class RunPerformedFlowsTableCmp extends React.Component<RunPerformedFlowsTableProps, State> {
    private filtersData: any;

    constructor(props: Readonly<RunPerformedFlowsTableProps>) {
        super(props);
        this.filtersData = {
            [FilterName.RunId]: this.props.runId
        };
        this.state = {
            isSharedWithSupport: this.props.isSharedWithSupport,
            isSendingShareAccess: false
        };
    }

    componentDidMount() {
        this.loadData(0, this.filtersData);
    }

    private async shareWithSupportClicked(): Promise<void> {
        await this.setState({isSendingShareAccess: true});
        try {
            const isSharedWithSupport = await shareSupportAccess(this.props.runId);
            await this.setState({isSendingShareAccess: false, isSharedWithSupport });
        } catch (e) {
            await this.setState({isSendingShareAccess: false});
        }
    }

    render() {
        if (!this.state.response) {
            return <UI.Loader visible={true} appearance={'darkgray'}/>
        }
        return <React.Fragment>
            <div className={`flows-header flex row two-columns`}>
                <div>
                    <h3>Performed syncs during run</h3>
                </div>
                <div>
                    {!isJiraServerNativeApp && <UI.ButtonsBar
                        secondary={[
                            <BulkResync
                                filtersData={this.filtersData}
                            />,
                            <UI.Button
                                appearance={'secondary'}
                                onClick={() => this.exportToCSV()}
                                text={`Export to CSV`}/>,
                            (this.props.canShareSupportAccess) ? <React.Fragment>
                                { this.state.isSharedWithSupport && <UI.Button
                                    isLoading={ this.state.isSendingShareAccess }
                                    appearance={'danger'}
                                    onClick={() => this.shareWithSupportClicked()}
                                    text={ `Revoke support access` }/> }
                                { !this.state.isSharedWithSupport && <UI.Button
                                    isLoading={ this.state.isSendingShareAccess }
                                    appearance={'secondary'}
                                    onClick={() => this.shareWithSupportClicked()}
                                    text={ `Share with support` }/> }
                            </React.Fragment>: <React.Fragment />
                        ]}
                    />}
                </div>
            </div>
            {!isJiraServerNativeApp && <ReportingFilters
                reportName={ReportName.PerformedFlows}
                initialFilters={this.filtersData}
                onSearchHandler={(filtersData) => {
                    this.loadData(0, filtersData)
                }}/>}
            <UI.TableGrid
                data={this.state.response.data.map((performedFlow: PerformedFlow) => [
                    <UI.NavLink
                        path={`/app/reporting/suite-run/${performedFlow.runId}/flow/${performedFlow.id}/details`}>#{performedFlow.id}</UI.NavLink>,
                    <ArtifactVersionPreviewLink lookUpData={
                        {
                            itemId: performedFlow.triggeredByDescription,
                            suiteId: this.props.suiteId,
                            side: performedFlow.triggerSide
                        }
                    }/>,
                    <ArtifactVersionPreviewLink lookUpData={
                        {
                            itemId: performedFlow.syncedWith,
                            suiteId: this.props.suiteId,
                            side: getOppositeSide(performedFlow.triggerSide)
                        }
                    }/>,
                    ((performedFlow.flowMode) ? <span
                        className={`flow-mode ${performedFlow.flowMode.toLowerCase()}`}>{performedFlow.flowMode}</span> : ''),
                    <UI.ExecutionTime startTime={performedFlow.formattedStartTime}
                        formattedExecutionTime={performedFlow.formattedExecutionTime}/>,
                    <SubItems
                        performedFlow={performedFlow}
                        subItemsData={performedFlow.subItemsData as any}/>,
                    <div className={`flow-status`}>
                        <React.Fragment>
                            { performedFlow.status === 'Success' && <UI.Icon icon={'status-success'}/> }
                            { performedFlow.status !== 'Success' && <UI.Icon icon={'status-failure'}/> }
                        </React.Fragment>
                        { performedFlow.breakStatus === 'NotMet' && <UI.Tooltip position={'right'} message={`One of items did not met filters parameters. Check details for more info.`} icon={ Icon20.Filter } /> }
                    </div>,
                    <SyncedItemsContextMenu onSelected={(item) => {
                        if (item.id === 'details') {
                            this.goToDetails(performedFlow)
                        }
                    }
                    } listItem={performedFlow}/>
                ])}
                headers={this.state.response.headers}
            />
            {/*<UI.Table*/}
            {/*    emptyTableHolder={`No flows were performed (no data found to sync)`}*/}
            {/*    dataSource={() => fetchPerformedFlows(this.props.runId)}*/}
            {/*    rowDecorator={(performedFlow: PerformedFlow) => [*/}
            {/*        <UI.NavLink*/}
            {/*            path={`/app/reporting/suite-run/${performedFlow.runId}/flow/${performedFlow.id}`}>#{performedFlow.id}</UI.NavLink>,*/}
            {/*        <ArtifactVersionPreviewLink itemId={performedFlow.triggeredByDescription}/>,*/}
            {/*        <UI.ExecutionTime startTime={performedFlow.formattedStartTime}*/}
            {/*                          formattedExecutionTime={performedFlow.formattedExecutionTime}/>,*/}
            {/*        performedFlow.persistedArtifacts,*/}
            {/*        <SubItems subItemsData={ performedFlow.subItemsData as any} />,*/}
            {/*        (performedFlow.status === 'Success') ? <UI.Icon icon={'status-success'}/> :*/}
            {/*            <UI.Icon icon={'status-failure'}/>,*/}
            {/*    ]}*/}
            {/*/>*/}
            <UI.Pagination currentPage={this.state.response.pageNumber} totalPages={this.state.response.totalPages}
                onPageChangeHandler={this.onPageChangeHandler.bind(this)}/>
        </React.Fragment>
    }

    private loadData(pageNumber: number, filtersData: any) {
        filtersData[FilterName.RunId] = this.props.runId;
        this.filtersData = filtersData;
        this.setState({response: undefined}, () => {
            fetchPerformedFlows(this.props.runId, pageNumber, filtersData)
                .then((response) => {
                    this.setState({response});
                })
        });
    }

    private goToDetails(performedFlow: PerformedFlow): void {
        (this.props as any).history.push(`/app/reporting/suite-run/${performedFlow.runId}/flow/${performedFlow.id}/details`);
    }

    private exportToCSV() {
        downloadReportingData(this.props.runId, this.filtersData)
            .then()
    }

    private onPageChangeHandler(newPageNumber: number) {
        this.loadData(newPageNumber, this.filtersData);
    }

    private getShareBtnText(): string {
        return (this.state.isSharedWithSupport)
            ? `Revoke support access`
            : 'Share with support';
    }
}

export default withRouter(RunPerformedFlowsTableCmp);
