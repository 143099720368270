import React from 'react';

import {EditPipelineStepComponentProps} from 'src/generic';
import {UI} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';

import {GitLabUpdateItemConfiguration} from './gitlab-update-item-configuration';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class GitLabUpdateItemEditStep extends React.Component<Props, State> {
    private configuration: GitLabUpdateItemConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as GitLabUpdateItemConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
        this.props.showArtifactBuilder(ArtifactDirection.In);
    }

    render() {
        return <React.Fragment>
            <UI.LabelValue label={`Target Artifact`} value={ this.configuration.targetArtifact } />
        </React.Fragment>;
    }
}