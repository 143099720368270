import {Side} from '../../../generic';
import {BreakStatus} from '../flows/flow-details.type';

export type PerformedFlow = {
    missingOptionsCount: number;
    warningLogsCount: number;
    id: number,
    runId: number,
    breakStatus: BreakStatus;
    suiteId: number,
    triggeredByDescription: string,
    triggerSide: Side,
    syncedWith: string,
    startTime: string,
    endTime: string,
    formattedExecutionTime: string,
    formattedStartTime: string,
    persistedArtifacts: number,
    subItemsData: any,
    flowMode: string,
    status: 'Success' | 'Failure'
};

export function createPerformedFlow(dataApi: any): PerformedFlow {
    return {
        id: dataApi.id,
        triggeredByDescription: dataApi.triggeredByDescription,
        triggerSide: dataApi.triggerSide,
        syncedWith: dataApi.syncedWithItemId,
        startTime: dataApi.startTime,
        endTime: dataApi.endTime,
        flowMode: dataApi.flowMode,
        status: dataApi.status,
        formattedStartTime: dataApi.formattedStartTime,
        formattedExecutionTime: dataApi.formattedExecutionTime,
        persistedArtifacts: dataApi.persistedArtifacts,
        subItemsData: dataApi.subItemsData,
        runId: dataApi.runId,
        suiteId: dataApi.suiteId,
        warningLogsCount: dataApi.warningLogsCount,
        missingOptionsCount: dataApi.missingOptionsCount,
        breakStatus: dataApi.breakStatus
    }
}
