import React from 'react';
import {SuiteType} from '../../../generic/integrations/suite-type';
import {UI} from 'src/engrator-core';
import {Icon20} from '../../../engrator-core/ui';

export type SyncStats = {
    issuesCount?: number;
    pullRequestsCount?: number;
    branchesCount?: number;
    commitsCount?: number;
    repositoriesCount?: number;
};

export function RunSyncStats(props: { suiteType: SuiteType, performedFlows?: number, erroredFlows?: number, stats: SyncStats }) {
    console.log(props);
    if (props.suiteType !== SuiteType.Git) {
        return <div className={`run-sync-stats`}>
            { props.performedFlows ? <UI.Tag transparentBg={true} name={`syncs: ${props.performedFlows}`}/> : <></> }
            { props.erroredFlows ? <UI.Tag transparentBg={true} name={`failed: ${props.erroredFlows}`}/> : <></> }
            { props.erroredFlows === 0 && props.performedFlows === 0 ? '-' : <></> }
        </div>;
    }
    return <div className={`run-sync-stats`}>
        {props.suiteType === SuiteType.Git && <div className={`git-items`}>
            <div className={(props.stats.repositoriesCount === 0) ? `empty` : ''}>
                <UI.Icon icon20={Icon20.Git}/> {props.stats.repositoriesCount}
            </div>
            <div className={(props.stats.branchesCount === 0) ? `empty` : ''}>
                <UI.Icon icon20={Icon20.CodeBranch}/> {props.stats.branchesCount}
            </div>
            <div className={(props.stats.commitsCount === 0) ? `empty` : ''}><UI.Icon
                icon20={Icon20.CodeCommit}/> {props.stats.commitsCount}
            </div>
            <div className={(props.stats.pullRequestsCount === 0) ? `empty` : ''}>
                <UI.Icon icon20={Icon20.CodePullRequest}/> {props.stats.pullRequestsCount}
            </div>
        </div>}
    </div>;
}