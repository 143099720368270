import { getDefaults } from '../../visual/components/value-mappings/default-mappping.type';
import {ValueMappingsGroup, ValueMappingsGroupWithDefaultMappings} from '../../visual/components/value-mappings/value-mappings-group.type';
import { DefaultsPropertyMapping } from '../../visual/property-mapping.type';

let optionsCopy: ValueMappingsGroupWithDefaultMappings = {
    groups: [],
    defaults: getDefaults()
};

export function copyMappedOptions(groups: ValueMappingsGroup[], defaultsMapping: DefaultsPropertyMapping): void {
    const newObj = {
        groups: JSON.parse(JSON.stringify(groups)),
        defaults: defaultsMapping
    }
    optionsCopy = newObj;
}

export function areSomeMappingsCopied(): boolean {
    return optionsCopy.groups.length > 0;
}

export function reuseCopiedOptions(): ValueMappingsGroupWithDefaultMappings {
    return optionsCopy;
}
