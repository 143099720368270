import React from 'react';
import {FieldError, UI} from 'src/engrator-core';
import {AppsSupport} from '../visual-integration-designer';
import {DropdownOption} from '../../../../../engrator-core/ui';
import {SmartIntDefinition} from '../../definition';
import {FieldsSummary} from './fields-summary';

type State = {
    isLoading: boolean;
    loadingError?: any;
    error?: FieldError;
    leftAppLinks?: DropdownOption[];
    rightAppLinks?: DropdownOption[];
};

type Props = {
    closeHandler: () => void;
    applyHandler: () => void;
    appSupport: AppsSupport;
    multiFieldsUsage: boolean;
    definition: SmartIntDefinition;
}

export class FieldsManagerWindow extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <UI.FullScreenModal
            header={`Fields manager`}
            primaryBtnHandler={() => this.applyClicked()}
            showPrimaryBtn={false}
            maximized={ true }
            primaryBtnText={`Apply`}
            closeBtnHandler={() => this.closeClicked()}
        >
            <div className={`fields-manager`}>
                <FieldsSummary
                    definition={ this.props.definition }
                    multiFieldsUsage={ this.props.multiFieldsUsage }
                    appSupport={ this.props.appSupport }
                />
            </div>
        </UI.FullScreenModal>
    }

    private async applyClicked(): Promise<boolean> {
        this.props.closeHandler();
        return true;
    }

    private async closeClicked(): Promise<boolean> {
        this.props.closeHandler();
        return true;
    }
}