import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper, UI} from 'src/engrator-core';
import {AsanaSearchItemsConfiguration} from './asana-search-items-configuration';
import {IntegrationStepConfiguration} from 'src/generic/software/step';
import {AsanaWorkspaceProjectPicker, AsanaWorkspaceProjectPickerData} from '../common';
import {ArtifactDirection} from '../../../../generic/artifacts';

type Props = {
} & NewPipelineStepComponentProps;
type State = {
    formError: { [key: string]: string },
    canConfigureArtifact: boolean,
    isLoadingJql: boolean,
    isAdding: boolean
};

export class AsanaSearchItemsNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: AsanaSearchItemsConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as AsanaSearchItemsConfiguration;
        this.configuration.onlyNewChanges = true;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'boardId' | 'listId', newValue) => {
            // this.configuration[propertyName] = newValue;
            // this.checkIfArtifactCanBeCreated();
            this.onConfigurationChanged();
        });
        this.state = { isLoadingJql: false, formError: {}, canConfigureArtifact: false, isAdding: false };
    }

    onFormChange(elementThatChanged: 'customJQL', newValue: string) {
        this.configuration[elementThatChanged] = newValue;
    }

    private onConfigurationChanged() {
        // this.configuration.project = data.project!;
        // this.configuration.itemType = data.itemType!;
    }

    render() {
        return <div className={`connectors-asana-search-items-step`}>
            <AsanaWorkspaceProjectPicker
                formError={ this.props.formError }
                connectionId={ this.props.context.connectionId }
                onDataChanged={ this.onWorkspaceProjectDataChange.bind(this) }
            />
            <UI.FormGroup
                label={`Changed from last integration`}
                description={`If checked, only items that changed since last integration will be picked up.`}
            >
                <UI.Checkbox defaultValue={'true'} onChange={ this.onlyNewChanged.bind(this) } checkedValue={ 'true' } uncheckedValue={ 'false '}/>
            </UI.FormGroup>
        </div>
    }

    onWorkspaceProjectDataChange(data: AsanaWorkspaceProjectPickerData) {
        if (data.workspace) {
            this.configuration.workspace = data.workspace;
        }
        if (data.project) {
            this.configuration.project = data.project;
        }
        if (this.configuration.workspace && this.configuration.project) {
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }

    onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }

    private getStepConfiguration(configuration: AsanaSearchItemsConfiguration): IntegrationStepConfiguration {
        return {
            data: configuration,
            connectionId: this.props.context.connectionId,
            stepSpecification: this.props.context.stepSpecification,
            inDataArtifact: null,
            outDataArtifact: null,
            rules: []
        };
    }
}
