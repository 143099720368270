import { axiosWrapper } from 'src/engrator-core';
import {SoftwareName} from '../../software';
import {DropdownOption} from '../../engrator-core/ui';

const ENDPOINT_URL = '/archiver';

export type FiltersNames = 'app' | 'belongsTo' | 'integrationId' | 'query' | 'page';

export type ArchivedItemsFiltersData = { [key in FiltersNames]? : string };

export type ArchivedItemShortData = {
    fields: { propertyId: string; name: string; value: string }[];
    id: number;
    itemId: string;
    itemType: string;
    app: SoftwareName;
    belongsTo: string;
}

export type ArchivedItemHeader = {
    label: string;
    fieldName: string;
}

export type ArchivedItemsResponse = {
    items: ArchivedItemShortData[];
    headers: ArchivedItemHeader[];
    totalPages: number;
    currentPage: number;
    totalElements: number;
}

export type ArchivedItemsFiltersOptionsResponse = {
    app: DropdownOption[];
    belongsTo: DropdownOption[];
    integrations: DropdownOption[];
}

export type ArchivedItemData = {
    title: string;
    description: string;
    fields: { name: string; value: string }[];
    comments: ArchivedItemCommentData[];
    attachments: ArchivedItemAttachmentData[];
    id: number;
    itemId: string;
    app: SoftwareName;
    belongsTo: string;
    archivedAt: string;
}
export type ArchivedItemCommentData = {
    author: string;
    dateTime: string;
    content: string;
}
export type ArchivedItemAttachmentData = {
    fileName: string;
    fileUrl: string;
    downloadUrl: string;
}

export type ArchivedItemDataResponse = {
    itemData: ArchivedItemData;
}

export const searchArchivedItems = (filtersData: ArchivedItemsFiltersData): Promise<ArchivedItemsResponse> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}/search`, filtersData)
                .then((data: any) => {
                    resolve(data as ArchivedItemsResponse);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function exportToCSV(filtersData: ArchivedItemsFiltersData, onlyIds?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            let url = `${ENDPOINT_URL}/csv`;
            if (onlyIds) {
                url += `?onlyIds=true`
            }
            axiosWrapper.post(url, filtersData)
                .then((response: any) => {
                    const url = window.URL.createObjectURL(new Blob([response], { type: 'text/plain' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'archiver.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export const getFiltersOptions = (): Promise<ArchivedItemsFiltersOptionsResponse> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/filters/options`)
                .then((data: any) => {
                    resolve(data as ArchivedItemsFiltersOptionsResponse);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const getItemData = (itemData: ArchivedItemShortData): Promise<ArchivedItemDataResponse> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/item/${ itemData.id }`)
                .then((data: any) => {
                    resolve(data as ArchivedItemDataResponse);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

