import { Side } from 'src/generic';
import { SmartIntTrigger } from 'src/app/integration/smartints/definition/smart-int-trigger.type';
import { testAql, fetchValueMappingsSchemas } from '../value-mappings-options-fetcher';
import {PropertyMapping, DefaultsPropertyMapping} from '../../../property-mapping.type';
import { ValueMappingsGroup } from '../value-mappings-group.type';
import { DropdownOption } from 'src/engrator-core/ui';
import { FormError } from 'src/engrator-core/form-error.type';
import { PropertyDataType } from 'src/generic/artifacts';

export type AssetsSideAttributes = {
    isConfiguration: boolean;
    schemas: DropdownOption[];
    schemasLoading: boolean;
    selectedSchema?: string;
    currentAql?: string;
    canStoreMultiple: boolean;
    accepted: boolean;
    acceptedLoading: boolean;
    aqlError?: FormError;
}

export type Assets = {
    isBothSides: boolean;
    [Side.Left]: AssetsSideAttributes;
    [Side.Right]: AssetsSideAttributes;
}

type OnMappingsChangedHandler = (groups: ValueMappingsGroup[], defaultMappings?: DefaultsPropertyMapping, sharedMappingId?: number, assetsAqlLeft?: string, assetsAqlRight?: string, assetsCanStoreMultipleLeft?: boolean, assetsCanStoreMultipleRight?: boolean) => void;

export async function fetchAssetsSchemas(assetsFromState: Assets, setState: React.Dispatch<React.SetStateAction<any>>, leftTrigger: SmartIntTrigger, rightTrigger: SmartIntTrigger) {
    const assets = {...assetsFromState};
    assets[Side.Left].schemasLoading = true;
    assets[Side.Right].schemasLoading = true;
    setState({assets})
    const assetsSchemasLeft = await fetchValueMappingsSchemas(leftTrigger);
    const assetsSchemasRight = await fetchValueMappingsSchemas(rightTrigger);
    const assetsAfterFetch = {...assetsFromState};
    assetsAfterFetch[Side.Left].schemas = assetsSchemasLeft;
    assetsAfterFetch[Side.Right].schemas = assetsSchemasRight;
    assetsAfterFetch[Side.Left].schemasLoading = false;
    assetsAfterFetch[Side.Right].schemasLoading = false;
    return assetsAfterFetch;
}

export async function assetsReload(assetsFromState: Assets, setState: React.Dispatch<React.SetStateAction<any>>, leftTrigger: SmartIntTrigger, rightTrigger: SmartIntTrigger) {
    const assetsAfterFetch = await fetchAssetsSchemas(assetsFromState, setState, leftTrigger, rightTrigger);
    setState({ assets: assetsAfterFetch, isLoadingSharedMapping: false });
}
export async function assetsSetupOnMount(assetsFromState: Assets, setState: React.Dispatch<React.SetStateAction<any>>, mapping: PropertyMapping, leftTrigger: SmartIntTrigger, rightTrigger: SmartIntTrigger) {
    const assetsAfterFetch = await fetchAssetsSchemas(assetsFromState, setState, leftTrigger, rightTrigger);
    if (mapping.left.options?.internalType === PropertyDataType.Assets && !mapping.options.left.assetsAql) {
        assetsAfterFetch[Side.Left].isConfiguration = true;
    }
    if (mapping.right.options?.internalType === PropertyDataType.Assets && !mapping.options.right.assetsAql) {
        assetsAfterFetch[Side.Right].isConfiguration = true;
    }
    if (assetsAfterFetch[Side.Left].isConfiguration && assetsAfterFetch[Side.Right].isConfiguration) {
        assetsAfterFetch.isBothSides = true;
    }
    setState({assets: assetsAfterFetch})
}

export async function assetsAfterAccepted(assetsFromState: Assets, setState: React.Dispatch<React.SetStateAction<any>>, groups: ValueMappingsGroup[], defaults: DefaultsPropertyMapping, onMappingsChangedHandler: OnMappingsChangedHandler, fetchData: () => Promise<void>, sharedMappingId?: number) {
    const assets = {...assetsFromState};
    let resultLeft;
    let resultRight;
    if (assetsFromState[Side.Left].selectedSchema) {
        resultLeft = `objectSchemaId = ${assetsFromState[Side.Left].selectedSchema}${assetsFromState[Side.Left].currentAql ? ` AND ${assetsFromState[Side.Left].currentAql}` : ''}`;
    }
    if (assetsFromState[Side.Right].selectedSchema) {
        resultRight = `objectSchemaId = ${assetsFromState[Side.Right].selectedSchema}${assetsFromState[Side.Right].currentAql ? ` AND ${assetsFromState[Side.Right].currentAql}` : ''}`;
    }
    assets[Side.Left].isConfiguration = false;
    assets[Side.Right].isConfiguration = false;
    assets[Side.Left].accepted = false;
    assets[Side.Right].accepted = false;
    setState({assets})
    onMappingsChangedHandler(groups, defaults, sharedMappingId, resultLeft, resultRight, assetsFromState[Side.Left].canStoreMultiple, assetsFromState[Side.Right].canStoreMultiple)
    await fetchData();
}

export function findSchema(side: Side, id: string, assetsFromState: Assets): string {
    return assetsFromState[side].schemas.find(el => el.value === id)!.label;
}

export async function handleAcceptAql(side: Side, assetsFromState: Assets, setState: React.Dispatch<React.SetStateAction<any>>, leftTrigger: SmartIntTrigger, rightTrigger: SmartIntTrigger): Promise<void> {
    try {
        const assets = {...assetsFromState};
        assets[side].aqlError = undefined;
        assets[side].acceptedLoading = true;
        setState({assets});
        if (assetsFromState[side].currentAql!) {
            await testAql(side === side ? leftTrigger : rightTrigger, assetsFromState[side].currentAql!);
        }
        const assetsAfterTest = {...assetsFromState};
        assetsAfterTest[side].accepted = true;
        assetsAfterTest[side].acceptedLoading = false;
        setState({assets: assetsAfterTest});
    } catch (err) {
        const assets = {...assetsFromState};
        assets[side].aqlError = err;
        assets[side].acceptedLoading = false;
        setState({assets});
    }
}

export function handleEditAql(assetsFromState: Assets, setState: React.Dispatch<React.SetStateAction<any>>, scrollDown: () => void): void {
    let currentAqlLeft: string | string[] = assetsFromState[Side.Left].currentAql!;
    let currentAqlRight: string | string[] = assetsFromState[Side.Right].currentAql!;
    let selectedAssetsSchemaLeft = assetsFromState[Side.Left].selectedSchema;
    let selectedAssetsSchemaRight = assetsFromState[Side.Right].selectedSchema;
    //Removing objectSchemaId from the currentAql to get only string necessary for AQL Input
    if (/^objectSchemaId/.test(currentAqlLeft)) {
        currentAqlLeft = currentAqlLeft.split(` AND `);
        selectedAssetsSchemaLeft = currentAqlLeft.shift()?.split('=')[1].trim();
        currentAqlLeft = currentAqlLeft.join('');
    }
    if (/^objectSchemaId/.test(currentAqlRight)) {
        currentAqlRight = currentAqlRight.split(` AND `);
        selectedAssetsSchemaRight = currentAqlRight.shift()?.split('=')[1].trim();
        currentAqlRight = currentAqlRight.join('');
    }
    const assets = {...assetsFromState};
    if (selectedAssetsSchemaLeft) {
        assets[Side.Left].isConfiguration = true;
    }
    if (selectedAssetsSchemaRight) {
        assets[Side.Right].isConfiguration = true;
    }
    if (assets[Side.Left].isConfiguration && assets[Side.Right].isConfiguration) {
        assets.isBothSides = true;
    }
    assets[Side.Left].selectedSchema = selectedAssetsSchemaLeft;
    assets[Side.Left].currentAql = currentAqlLeft;
    assets[Side.Right].selectedSchema = selectedAssetsSchemaRight;
    assets[Side.Right].currentAql = currentAqlRight;
    setState({assets})
    scrollDown();
}

export function clearAql(assetsFromState: Assets, setState: React.Dispatch<React.SetStateAction<any>>, mapping: PropertyMapping, defaults: DefaultsPropertyMapping, onMappingsChangedHandler: OnMappingsChangedHandler, sharedMappingId?: number): void {
    const assets = {...assetsFromState, };
    if (assets[Side.Left].selectedSchema || mapping.options.left.assetsAql?.includes('objectSchemaId')) {
        assets[Side.Left].isConfiguration = true;
    }
    if (assets[Side.Right].selectedSchema || mapping.options.right.assetsAql?.includes('objectSchemaId')) {
        assets[Side.Right].isConfiguration = true;
    }
    if (assets[Side.Left].isConfiguration && assets[Side.Right].isConfiguration) {
        assets.isBothSides = true;
    }
    assets[Side.Left].accepted = false;
    assets[Side.Right].accepted = false;
    assets[Side.Left].selectedSchema = undefined;
    assets[Side.Right].selectedSchema = undefined;
    assets[Side.Left].currentAql = '';
    assets[Side.Right].currentAql = '';
    assets[Side.Left].canStoreMultiple = false;
    assets[Side.Right].canStoreMultiple = false;
    setState({groups: [], assets})
    onMappingsChangedHandler([], defaults, sharedMappingId, '', '');
}

export function createEmptyAssets(): Assets {
    return {
        isBothSides: false,
        [Side.Left]: {
            isConfiguration: false,
            schemas: [],
            schemasLoading: false,
            currentAql: '',
            canStoreMultiple: false,
            accepted: false,
            acceptedLoading: false,
        },
        [Side.Right]: {
            isConfiguration: false,
            schemas: [],
            schemasLoading: false,
            currentAql: '',
            canStoreMultiple: false,
            accepted: false,
            acceptedLoading: false
        }
    }
}