import {PipelineStep} from 'src/generic/pipeline-step';
import {SoftwareName} from 'src/software/software-name';
import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';

import {ZendeskStepKey} from '../zendesk-step-key.type';
import {ZendeskSearchItemsConfiguration} from './zendesk-search-items-configuration';
import {ZendeskSearchItemsNewStep} from './zendesk-search-items-new-step';
import {ZendeskSearchItemsEditStep} from './zendesk-search-items-edit-step';


export const ZendeskSearchItemsPipelineStep :  PipelineStep<ZendeskSearchItemsConfiguration> = {
    addStepComponentClass: ZendeskSearchItemsNewStep,
    editStepComponentClass: ZendeskSearchItemsEditStep,
    specification: {
        action: 'Search Zendesk Tickets',
        description: 'Search tickets in specified workspace',
        key: ZendeskStepKey.ZendeskSearchItems,
        software: SoftwareName.Zendesk,
        stepType: StepType.Trigger
    },
    preferredNameForArtifact: `zendeskTicket`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ZendeskSearchItemsConfiguration;
        return `Search for tickets in ${stepConfiguration.organization.name}`;
    }
}