import {Software} from 'src/generic';
import {AppSmartIntsSupport} from 'src/app/integration/smartints/app-smart-ints-support';
import {createGenericConnectionFactory} from 'src/generic/connections';
import {SoftwareName} from '../software-name';
import {AuthMethod} from 'src/generic/connections/auth-method.type';
import {PipelineGenericStep} from 'src/generic/pipeline-step';
import {GitLabPipelineSteps} from './gitlab-pipeline-steps';
import { GitLabSmartIntsSupport } from './smart-ints';

export class GitLabSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.GitLab, [AuthMethod.BearerToken])
    }
    getName(): string {
        return 'GitLab';
    }
    getID() {
        return 'GitLab';
    }
    getAvailableSteps(): object[] {
        return GitLabPipelineSteps.map(pipelineStep => new PipelineGenericStep(pipelineStep));
    }
    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new GitLabSmartIntsSupport();
    }
}
