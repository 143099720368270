import React, {useEffect} from 'react';

import {UI} from 'src/engrator-core';
import {fetchFlowDetails} from './rest-api';
import {FlowDetails} from './flow-details.type';
import {FlowStep, flowStepsFactory} from './flow-steps-factory';
import {FlowStepsDiagram} from './flow-steps-diagram';
import {Header} from './header';
import {FlowRightPanel} from './right-panel/flow-right-panel';
import { FlowsNavBarItem} from './flows-nav-bar';
import {FlowLogs} from './flow-logs';
import {LoadingPageHeader} from './loading-page-header';
import {getParsedWindowUrl} from '../rest-api';
import {useGlobalState} from '../../global-store';
import {getSyncVerticalMenu} from './sync-vertical-menu';
import {MainMenuItem} from '../../global-store/create-global-state';
import {FlowReducedStepsDiagram} from './flow-reduced-steps-diagram';

type Props = {
    flowId: number;
    selectedNavItem: FlowsNavBarItem;
};

type State = {
    isLoadingFlowDetails: boolean,
    flowDetails?: FlowDetails,
    flowStep?: FlowStep,
    selectedStepIndex?: number;
    selectedNavItem: FlowsNavBarItem;
};

export class FlowPageCmp extends React.Component<Props, State> {
    private stepsList?: FlowStep[];

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoadingFlowDetails: true,
            flowDetails: undefined,
            selectedNavItem: this.props.selectedNavItem
        };
    }

    componentDidMount(): void {
        fetchFlowDetails(this.props.flowId).then(flowDetails => {
            this.stepsList = flowStepsFactory(flowDetails.runDetails, flowDetails.stepExecutions, flowDetails.flow.flowSteps?.steps);
            this.setState({flowDetails, isLoadingFlowDetails: false});
            const url = new URL(getParsedWindowUrl());
            const stepIndex = url.searchParams.get('stepIndex');
            if (stepIndex) {
                this.onStepClickHandler(this.stepsList![parseInt(stepIndex) - 1], parseInt(stepIndex) - 1);
            }
        });
    }

    onStepClickHandler(flowStep: FlowStep, index: number) {
        // Removing this for now
        // this.setState({flowStep: flowStep, selectedStepIndex: index})
    }

    render() {
        return (
            <UI.Page className={`flow-details-page`}>
                {this.state.isLoadingFlowDetails && <LoadingPageHeader
                    id={ this.props.flowId}
                />}
                {this.state.flowDetails && <React.Fragment>
                    {this.state.selectedNavItem === FlowsNavBarItem.Logs && <FlowLogs
                        flowId={this.state.flowDetails.flow.id}
                        runId={this.state.flowDetails.flow.runId}
                    />}
                    { this.state.selectedNavItem === FlowsNavBarItem.Overview && <div className={`flex row two-columns steps-list-background`}>
                        <div className={`right`}>
                            {this.state.selectedNavItem === FlowsNavBarItem.Overview && this.state.flowDetails.flow.flowSteps?.steps && <FlowStepsDiagram
                                selectedStepIndex={this.state.selectedStepIndex}
                                steps={this.stepsList!}
                                onStepClickHandler={this.onStepClickHandler.bind(this)}
                            />}
                            {this.state.selectedNavItem === FlowsNavBarItem.Overview && this.state.flowDetails.flow.flowSteps?.reducedSteps && <FlowReducedStepsDiagram
                                steps={this.state.flowDetails.flow.flowSteps.reducedSteps!}
                            />}
                            {this.state.flowStep && <FlowRightPanel
                                close={() => this.clearRightPanelData()}
                                flowStep={this.state.flowStep}
                                stepIndex={this.state.selectedStepIndex!}
                            />}
                        </div>
                        <div className={`left`}>
                            <Header flowDetails={this.state.flowDetails!}/>
                        </div>
                    </div> }
                </React.Fragment>}
            </UI.Page>
        )
    }

    private clearRightPanelData() {
        this.setState({flowStep: undefined, selectedStepIndex: undefined});
    }

    private switchTab(newTabItem: FlowsNavBarItem) {
        this.setState({selectedNavItem: newTabItem, flowStep: undefined});
    }
}

export function FlowPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Reporting);
        getSyncVerticalMenu(props.match.params.id, props.match.params.flowId).then(setMenu);
    }, []);
    return <FlowPageCmp
        flowId={ props.match.params.flowId}
        selectedNavItem={ FlowsNavBarItem.Overview }
    />
}