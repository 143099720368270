import React from 'react';
import { switchBodyScroll } from '../index';


type Props ={
    isLeftAligned?: boolean;
    header?: string;
    highestIndex?: boolean;
    isWide?: boolean;
    isFullScreen?: boolean;
}

export class Modal extends React.Component<Props> {
    componentDidMount() {
        switchBodyScroll(false);
    }

    componentWillUnmount() {
        switchBodyScroll(true);
    }

    render() {
        let boxClasses = ((this.props.isLeftAligned) ? 'left-aligned' : '');
        let mainClasses = ((this.props.highestIndex) ? 'highest-index' : '');
        if (this.props.isWide) {
            boxClasses += ' wide';
        }
        if (this.props.isFullScreen) {
            mainClasses += ' fullscreen';
        }
        return <div className={`modal persistence-loader ${ mainClasses }`}>
            <div className={`anim-fade-in box ${ boxClasses }`}>
                { this.props.header && <h3>{ this.props.header }</h3> }
                { this.props.children }
            </div>
        </div>;
    }
}

