import { MigrationConfiguration } from '../../smartints/migration/migration-configuration.type';
import { PipelineSettings } from './pipeline-settings-type';
import {ShareAccessOption} from '../../../../software/generic/connections-rest-api';

export type JiraLicenseInfo = {
    active: boolean;
    errorMessage: string;
};

export enum IntegrationAccessLevel {
    'None' = 'None',
    'Read' = 'Read',
    'Write' = 'Write',
    'CanDelete' = 'CanDelete'
}

export type IntegrationDetailsModel = {
    id: number,
    name: string,
    configuration: any,
    settings: PipelineSettings,
    migrationConfiguration?: MigrationConfiguration;
    jiraLicenseInfo?: JiraLicenseInfo;
    shouldShowLicenseWarning?: boolean;
    accessLevel: IntegrationAccessLevel;
    ownerId?: number,
    owner?: {
        identifier: string;
    },
    createdAt?: string;
    shareAccess: ShareAccessOption;
};

export function createIntegrationDetailsModel(itemData: any): IntegrationDetailsModel {
    return {
        id: itemData.id,
        name: itemData.name,
        configuration: itemData.configuration,
        settings: itemData.settings as PipelineSettings,
        migrationConfiguration: itemData.migrationConfiguration,
        jiraLicenseInfo: itemData.jiraLicenseInfo,
        shouldShowLicenseWarning: itemData.hasRun,
        accessLevel: itemData.accessLevel,
        ownerId: itemData.ownerId,
        owner: itemData.owner,
        createdAt: itemData.createdAt,
        shareAccess: itemData.shareAccess
    };
}
