import React from 'react';
import {copyObject, UI} from 'src/engrator-core';
import {DropdownOption} from '../../../../../engrator-core/ui';
import {FILTER_OPERATOR, ItemFilter, NewFilter, requiresValue} from './rest-api';
import {PropertyDataType, PropertyDefinition} from '../../../../../generic/artifacts';
import {availableOperators} from './operators';

export type FilterField = {
    id: string;
    name: string;
    dataType: PropertyDataType;
};

type State = {
    newFilter: NewFilter;
    valuesNumber: number[];
};

type Props = {
    addFilterHandler: (filter: ItemFilter) => void;
    fields: FilterField[];
    fieldLabel?: string;
    operatorLabel?: string;
}


export class AddFilter extends React.Component<Props, State> {
    private operatorOptions: DropdownOption[];
    private readonly fieldsOptions: DropdownOption[];

    constructor(props: Props) {
        super(props);
        this.state = { newFilter: {}, valuesNumber: [0] };
        this.operatorOptions = copyObject(availableOperators);
        this.fieldsOptions = this.props.fields.map(field => ({ value: field.id, label: field.name, object: field }));
    }

    private addClicked() {
        this.props.addFilterHandler(Object.assign({}, this.state.newFilter) as ItemFilter);
        this.setState({ newFilter: {}, valuesNumber: [0] });
    }

    render() {
        return <div className={`add-entry-row`}>
            <UI.Form>
                <div className={`controls`}>
                    <UI.Dropdown
                        defaultValue={ this.state.newFilter.field?.id }
                        isRequired={ true }
                        label={ this.props.fieldLabel || 'Field'}
                        options={ this.fieldsOptions }
                        onChange={ (value: string, obj: PropertyDefinition) => this.setField(obj) }
                    />
                    <UI.Dropdown
                        defaultValue={ this.state.newFilter.operator }
                        isRequired={ true }
                        label={this.props.operatorLabel || 'Operator'}
                        options={ this.operatorOptions }
                        onChange={ (value: FILTER_OPERATOR) => this.setOperator(value) }
                    />
                    { requiresValue(this.state.newFilter.operator) && <div>
                        { this.state.valuesNumber.map((nr) => <UI.Input
                            key={ nr }
                            defaultValue={ this.state.newFilter.value }
                            isRequired={ nr === 0 }
                            label={`Value`}
                            onChange={ (value) => this.setValue(nr, value) }
                        />) }
                    </div> }
                    <UI.Button
                        disabled={ !this.isFormValid() }
                        onClick={ () => this.addClicked() }
                        text={`Add Filter`}
                    />
                </div>
            </UI.Form>
        </div>
    }

    private isFormValid(): boolean {
        // If basic info is not provided
        const hasBasic = this.state.newFilter.field && this.state.newFilter.operator;
        if (!hasBasic) {
            return false;
        }
        // If operator requires value but its not defined
        if (requiresValue(this.state.newFilter.operator)) {
            // If it is CONTAINS ONE OF, there must be at least one value in multiValues property
            if (this.state.newFilter.operator === FILTER_OPERATOR.CONTAINS_ONE_OF) {
                if (this.state.newFilter.multiValues?.filter(value => !!value).length === 0) {
                    return false;
                }
            } else if (!this.state.newFilter.value) {
                return false;
            }
        }
        return true;
    }

    private setOperator(value: FILTER_OPERATOR) {
        const newFilter = this.state.newFilter;
        newFilter.operator = value;
        this.setState({ newFilter });
    }

    private setValue(index: number, value: string) {
        const newFilter = this.state.newFilter;
        const valuesNumber = this.state.valuesNumber;
        if (this.state.newFilter.operator === FILTER_OPERATOR.CONTAINS_ONE_OF) {
            if (!newFilter.multiValues) {
                newFilter.multiValues = [];
            }
            newFilter.multiValues[index] = value;
            const length = valuesNumber.length;
            if (newFilter.multiValues[length - 1]) {
                valuesNumber.push(length);
            }
        } else {
            newFilter.value = value;
        }
        this.setState({ newFilter, valuesNumber });
    }

    private setField(value: FilterField) {
        const newFilter = this.state.newFilter;
        const isObject = (value.dataType === PropertyDataType.IdLabelArray || value.dataType === PropertyDataType.IdLabel || value.dataType == PropertyDataType.Collection);
        this.operatorOptions = (!isObject)
            ? copyObject(availableOperators)
            : copyObject(availableOperators.filter(op => op.label !== 'equals' && op.label !== 'not equals'));
        if (value.dataType === PropertyDataType.Date) {
            this.operatorOptions.push({label: 'is after', value: 'is after'});
            this.operatorOptions.push({label: 'is before', value: 'is before'});
        }
        newFilter.field = value;
        this.setState({ newFilter });
    }
}