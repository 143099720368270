import React from 'react';

import {SoftwareName} from '../../software';
import video from './video.png';
import doc from './doc.png';
import {HelpCallButton} from '../../app/engagement/help-call-button';

type Props = {
    softwareName: SoftwareName;
};

type State = {
    videoUrl?: string;
    docUrl?: string;
};

export class ConnectionSetupVideo extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            videoUrl: this.getVideoUrl(),
            docUrl: this.getDocUrl(),
        }
    }

    render() {
        return <div className={`engagement-apps-sync-guide relative-position `}>
            <div className={`inner`}>
                { this.state.videoUrl && <a target={`blank`} className={`anim-fade-in${this.state.docUrl ? ' yt-guide-margin-bottom-m' : ''}`} href={ this.state.videoUrl }>
                    <img src={video}/>
                    Watch connection setup
                </a> }
                { this.state.docUrl && <a target={`blank`} href={ this.state.docUrl } className={`anim-fade-in`}>
                    <img src={ doc } height={ 24 }/>
                    Read documentation
                </a> }
                <HelpCallButton
                    blink={true}
                    // bookDemoPageClicked={ () => this.bookDemoPageClicked() }
                />
            </div>
        </div>;
    }

    private bookDemoPageClicked() {
        (window as any).open('https://getint.io/demo?src=app', '_blank').focus();
    }

    getVideoUrl(): string | undefined {
        switch (this.props.softwareName) {
        case SoftwareName.Jira:
            return 'https://docs.getint.io/guides/quickstart/connection#jira';
        case SoftwareName.Asana:
            return 'https://www.youtube.com/watch?v=5Ml9hdha57U';
        case SoftwareName.Airtable:
            return 'https://www.youtube.com/watch?v=t2VVnNq0EZI';
        case SoftwareName.Azure:
            return 'https://www.youtube.com/watch?v=WCAQBVhU8vg';
        case SoftwareName.ClickUp:
            return 'https://www.youtube.com/watch?v=fT0Rd_ikXsU';
        case SoftwareName.Freshdesk:
            return 'https://www.youtube.com/watch?v=AXpUq3NEhqE';
        case SoftwareName.GitLab:
            return 'https://www.youtube.com/watch?v=JqOHsYnVX6M';
        case SoftwareName.GitHub:
            return 'https://www.youtube.com/watch?v=Af2CAXZ-7dQ';
        case SoftwareName.Hubspot:
            return 'https://www.youtube.com/watch?v=EsXLv5VsyOg';
        case SoftwareName.Monday:
            return 'https://www.loom.com/share/da2bbdaa0f0049609567868de98753e1';
        case SoftwareName.Notion:
            return 'https://www.youtube.com/watch?v=EpbjAtGoEQE';
        case SoftwareName.Salesforce:
            return 'https://www.youtube.com/watch?v=B12LpDCZlPU';
        case SoftwareName.ServiceNow:
            return 'https://www.youtube.com/watch?v=Iythq-HdHsI';
        case SoftwareName.Trello:
            return 'https://www.youtube.com/watch?v=esiUOXBizsU';
        case SoftwareName.Wrike:
            return 'https://www.youtube.com/watch?v=HgyRhch-rgM';
        case SoftwareName.Zendesk:
            return 'https://www.youtube.com/watch?v=Unnp9UrFCAw';
        }
    }

    getDocUrl(): string | undefined {
        switch (this.props.softwareName) {
        case SoftwareName.Jira:
            return 'https://docs.getint.io/guides/quickstart/connection#jira';
        case SoftwareName.Asana:
            return 'https://docs.getint.io/guides/quickstart/connection#asana';
        case SoftwareName.Airtable:
            return 'https://docs.getint.io/guides/quickstart/connection#airtable';
        case SoftwareName.Azure:
            return 'https://docs.getint.io/guides/quickstart/connection#azure-devops';
        case SoftwareName.ClickUp:
            return 'https://docs.getint.io/guides/quickstart/connection#clickup';
        case SoftwareName.Freshdesk:
            return 'https://docs.getint.io/guides/quickstart/connection#freshdesk';    
        case SoftwareName.Freshservice:
            return 'https://docs.getint.io/guides/quickstart/connection#freshservice';   
        case SoftwareName.GitLab:
            return 'https://docs.getint.io/guides/quickstart/connection#gitlab';
        case SoftwareName.GitHub:
            return 'https://docs.getint.io/guides/quickstart/connection#github';
        case SoftwareName.Hubspot:
            return 'https://docs.getint.io/guides/quickstart/connection#hubspot';
        case SoftwareName.Monday:
            return 'https://docs.getint.io/guides/quickstart/connection#monday.com';
        case SoftwareName.Notion:
            return 'https://docs.getint.io/guides/quickstart/connection#notion';
        case SoftwareName.Salesforce:
            return 'https://docs.getint.io/guides/quickstart/connection#salesforce';
        case SoftwareName.ServiceNow:
            return 'https://docs.getint.io/guides/quickstart/connection#servicenow';
        case SoftwareName.Trello:
            return 'https://docs.getint.io/guides/quickstart/connection#trello';
        case SoftwareName.Wrike:
            return 'https://docs.getint.io/guides/quickstart/connection#wrike';
        case SoftwareName.Zendesk:
            return 'https://docs.getint.io/guides/quickstart/connection#zendesk';
        }
    }
}
