import { axiosWrapper } from 'src/engrator-core';
import {SoftwareName} from '../../../../../../software';
import {DropdownOption} from '../../../../../../engrator-core/ui';
import { Connection } from 'src/connections';

const ENDPOINT_URL = '/integration/suites';

export type ImportValidateResult = {
    leftSoftwareName: SoftwareName;
    leftConnections: DropdownOption[];
    rawLeftConnections: Connection[];
    rightSoftwareName: SoftwareName;
    rightConnections: DropdownOption[];
    rawRightConnections: Connection[];
};

export type ScriptToImport = {
    name: string;
    leftConnectionId: number;
    leftConnectionName?: string;
    rightConnectionId: number;
    rightConnectionName?: string;
    script: string;
};

export type ScriptToImportWithConnections = {
    name: string;
    leftConnectionId: number;
    rightConnectionId: number;
    script: string;
    encryptionKey: string;
};

export type ScriptWithConnectionsToImport = {
    newIntegrationId: number;
}

export function getScriptForExport(integrationId: number): Promise<string>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + `/${integrationId}/export`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export async function getScriptForExportWithConnections(integrationId: number, encryptionKey: string): Promise<string>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/${integrationId}/exportWithConnections`,{ encryptionKey })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function validateImportedScript(script: string): Promise<ImportValidateResult>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/import/validate`, { script: JSON.parse(script) })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function validateImportedScriptWithConnections(script: string, encryptionKey: string): Promise<ImportValidateResult>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/importWithConnections/validate`, { script: JSON.parse(script), encryptionKey })
                .then((data: any) => {
                    axiosWrapper.post(ENDPOINT_URL + `/import/validate`, { script: JSON.parse(script) })
                        .then((data: any) => {
                            resolve(data);
                        })
                        .catch(error => reject(error));
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function importScript(scriptToImport: ScriptToImport): Promise<number>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/import`, scriptToImport )
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function importScriptWithConnections(scriptToImport: ScriptToImport, encryptionKey: string): Promise<ScriptWithConnectionsToImport>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/importWithConnections`, {...scriptToImport, encryptionKey} )
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}
