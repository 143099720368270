import React from 'react';
import {UI} from 'src/engrator-core';
import {hasUserCompleted, registerUserActivityAction, UserActivityAction} from '../system/user-activity';

type Props = {
    maxWidth?: number;
    closeable?: boolean;
    action?: UserActivityAction;
    styles?: {[key: string]: string};
};
type State = {
    shouldBeDisplayed: boolean;
};

export class GuideTooltip extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            shouldBeDisplayed: false
        };
    }

    componentDidMount() {
        if (!this.props.action) {
            this.setState({ shouldBeDisplayed: true });
        } else {
            // Check if action was completed already by user
            hasUserCompleted(this.props.action)
                .then(result => {
                    // If did not complete yet action - dismissed it
                    if (!result) {
                        this.setState({shouldBeDisplayed: true});
                    }
                });
        }
    }

    render() {
        if (!this.state.shouldBeDisplayed) {
            return <React.Fragment/>;
        }
        const styles: any = (this.props.styles) ? this.props.styles : {};
        if (this.props.maxWidth) {
            styles['max-width'] = this.props.maxWidth + 'px';
        }
        return <div className={`engagement guide-tooltip`} style={ {...styles} }>
            { this.props.children }
            { !!this.props.closeable && <span
                className={`close-icon`}
                onClick={() => this.closeClicked() }><UI.Icon icon={'close'}/>
            </span> }
        </div>;
    }

    private closeClicked() {
        this.setState({ shouldBeDisplayed: false }, () => {
            if (this.props.action) {
                registerUserActivityAction(this.props.action);
            }
        });
    }
}
