import React from 'react';

import { UI } from 'src/engrator-core';
import { getFieldsConfigurationWarning, getStatuConfigurationWarning } from '../../configuration/configuration-warning-checker';
import { SmartIntDefinitionTypeMapping } from '../../definition/smart-int-definition--type-mapping.type';
import {isAttachmentsSyncSupported, isHierarchySyncSupported, isStatusSyncSupported} from './enabled-features';
import {AppsSupport} from '../visual-integration-designer';

export enum TypeMappingNavBarItemName {
    Fields = 'Fields',
    CommentsAttachments = 'Comments & Attachments',
    Comments = 'Comments',
    Status = 'Status',
    Advanced = 'Advanced',
    Hierarchy = 'Hierarchy'
}

type Props = {
    appSupport: AppsSupport;
    mapping: SmartIntDefinitionTypeMapping;
    selectedItem: TypeMappingNavBarItemName;
    onChange: (tabItemName: TypeMappingNavBarItemName) => void;
}

export class TypeMappingNavBar extends React.Component<Props> {
    render() {
        const statusWarning = getStatuConfigurationWarning(this.props.mapping);
        const statusTabIcon = (statusWarning) ? <UI.Icon icon="exclamation-triangle" /> : undefined;
        const fieldsWarning = getFieldsConfigurationWarning(this.props.mapping);
        const fieldsTabIcon = (fieldsWarning) ? <UI.Icon icon="exclamation-triangle" /> : undefined;
        return <React.Fragment>
            <div>
                <UI.TabBar>
                    <UI.TabButton
                        onClick={() => this.props.onChange(TypeMappingNavBarItemName.Fields)}
                        text={TypeMappingNavBarItemName.Fields}
                        icon={ fieldsTabIcon }
                        isSelected={this.props.selectedItem === TypeMappingNavBarItemName.Fields}/>
                    { isStatusSyncSupported(this.props.mapping, this.props.appSupport.leftApp, this.props.appSupport.rightApp) && <UI.TabButton
                        icon={ statusTabIcon }
                        onClick={() => this.props.onChange(TypeMappingNavBarItemName.Status)}
                        text={TypeMappingNavBarItemName.Status}
                        isSelected={this.props.selectedItem === TypeMappingNavBarItemName.Status}/> }
                    <UI.TabButton
                        onClick={() => this.props.onChange(TypeMappingNavBarItemName.CommentsAttachments)}
                        text={ (isAttachmentsSyncSupported(this.props.appSupport.leftApp, this.props.appSupport.rightApp) ? TypeMappingNavBarItemName.CommentsAttachments : TypeMappingNavBarItemName.Comments) }
                        isSelected={this.props.selectedItem === TypeMappingNavBarItemName.CommentsAttachments}/>
                    <UI.TabButton
                        onClick={() => this.props.onChange(TypeMappingNavBarItemName.Advanced)}
                        text={TypeMappingNavBarItemName.Advanced}
                        isSelected={this.props.selectedItem === TypeMappingNavBarItemName.Advanced}/>
                    { isHierarchySyncSupported(this.props.appSupport.leftApp, this.props.appSupport.rightApp) && <UI.TabButton
                        onClick={() => this.props.onChange(TypeMappingNavBarItemName.Hierarchy)}
                        text={TypeMappingNavBarItemName.Hierarchy}
                        isSelected={this.props.selectedItem === TypeMappingNavBarItemName.Hierarchy}/> }
                </UI.TabBar>
            </div>
        </React.Fragment>
    }
}
