import React, { Component } from 'react';

import { FieldError, UI } from 'src/engrator-core';
import { AlertChannel } from '../create/new-alert.type';
import { sendTestEmail, updateAlert } from '../rest-api';
import { DocumentationLink } from '../../../../engrator-core/ui';
import { NotificationsWebhooksHeader } from '../create/notifications-webhooks-header';
import { NotificationAlert, WebhookHeaders } from '../notification-alert.type';
import { AlertPropertyToChange, NotificationFlag } from '../create/notifications-create.page';

type Props = {
    closeHandler: (refresh: boolean) => void;
    alertToEdit: NotificationAlert;
};
type State = {
    error?: FieldError;
    testEmailError?: FieldError;
    testSuccess: boolean;
    alert: NotificationAlert;
    isSendingTestEmail: boolean;
    isLoading: boolean;
    showNotifications: boolean;
    showPasswordBox: boolean;
};

export class NotificationsEditPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            isSendingTestEmail: false,
            testSuccess: false,
            alert: this.props.alertToEdit,
            showNotifications: false,
            showPasswordBox: false
        };
    }

    render() {
        return (
            <UI.FullScreenModal
                primaryBtnHandler={() => this.doneClicked()}
                closeBtnHandler={() => this.closeClicked()}
                error={this.state.error?.message}
                primaryBtnText={`Update`}
                header={`Edit Alert`}
                className={`create-alert`}
            >
                <UI.Form>
                    <UI.Message appearance={'info'}>
                        Receive notifications about failed integration/migration runs or syncs directly on the selected
                        channel. Getint will send notifications every 5 minutes.
                    </UI.Message>
                    <UI.Message appearance={'warning'}>
                        Created alerts will be applied to all integrations / types mappings / fields mappings.
                    </UI.Message>
                    <UI.FormGroup label={`Name`} isRequired={true}>
                        <UI.Input
                            placeholder={`Alert name (will be included in notifications)`}
                            onChange={(newValue: string) => this.changeName('name', newValue)}
                            defaultValue={this.state.alert.name}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup label={`Alerts about`} isRequired={true}>
                        <UI.Checkbox
                            defaultValue={this.state.alert.forFailedRuns.toString()}
                            uncheckedValue={`false`}
                            checkedValue={`true`}
                            label={`Failed runs`}
                            onChange={() => this.changeFlag(NotificationFlag.forFailedRuns)}
                        />
                        <UI.Checkbox
                            defaultValue={this.state.alert.forFailedSyncs.toString()}
                            uncheckedValue={`false`}
                            checkedValue={`true`}
                            label={`Failed syncs`}
                            onChange={() => this.changeFlag(NotificationFlag.forFailedSyncs)}
                        />
                        <UI.Checkbox
                            defaultValue={this.state.alert.forWarnLogs.toString()}
                            uncheckedValue={`false`}
                            checkedValue={`true`}
                            label={`Warning logs`}
                            onChange={() => this.changeFlag(NotificationFlag.forWarnLogs)}
                        />
                        <UI.Checkbox
                            defaultValue={this.state.alert.forMissingOptions.toString()}
                            uncheckedValue={`false`}
                            checkedValue={`true`}
                            label={`Missing options mappings`}
                            onChange={() => this.changeFlag(NotificationFlag.forMissingOptions)}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup label={`Channel`} isRequired={true}>
                        <p>{this.state.alert.channel}</p>
                    </UI.FormGroup>
                    {this.state.alert.channel === AlertChannel.Webhook && (
                        <UI.FormGroup>
                            <UI.Input
                                isRequired={true}
                                label={`Webhook url`}
                                placeholder={`Webhook url`}
                                defaultValue={this.state.alert.webhookUrl}
                                onChange={newValue => this.changeName('webhookUrl', newValue)}
                            />
                            <UI.Textarea
                                isRequired={true}
                                rows={5}
                                defaultValue={this.state.alert.webhookContent}
                                label={`Webhook body (JSON)`}
                                placeholder={`Webhook body`}
                                onChange={newValue => this.changeName('webhookContent', newValue)}
                            />
                            
                            <NotificationsWebhooksHeader allHeaders={ this.state.alert.webhookHeaders } saveAllHeadersHandler={(allHeaders: WebhookHeaders[]) => this.saveAllHeadersHandler(allHeaders)} />
                        </UI.FormGroup>
                    )}
                    {this.state.alert.channel === AlertChannel.Slack && (
                        <UI.FormGroup>
                            <UI.Message appearance={'info'}>
                                <span>
                                    How to create Slack notifications with Incoming Webhooks{' '}
                                    <DocumentationLink
                                        text={`Read more here`}
                                        url={`https://api.slack.com/messaging/webhooks`}
                                    />
                                </span>
                            </UI.Message>
                            <UI.Input
                                placeholder={`Slack webhook url`}
                                onChange={newValue => this.changeName('slackWebhookUrl', newValue)}
                                defaultValue={this.state.alert.slackWebhookUrl}
                            />
                        </UI.FormGroup>
                    )}
                    {this.state.alert.channel === AlertChannel.GetintEmail && (
                        <UI.FormGroup>
                            <UI.Input
                                placeholder={`Recipients`}
                                isRequired={true}
                                label={`Recipients (comma separated email addresses)`}
                                onChange={newValue => this.changeName('emailChannelTo', newValue)}
                                defaultValue={this.state.alert.emailChannelTo}
                            />
                            <UI.Input
                                placeholder={`Subject prefix`}
                                label={`Subject prefix`}
                                onChange={newValue => this.changeName('emailChannelSubjectPrefix', newValue)}
                                defaultValue={this.state.alert.emailChannelSubjectPrefix}
                            />
                        </UI.FormGroup>
                    )}
                    {this.state.alert.channel === AlertChannel.JiraSmtp && (
                        <UI.FormGroup>
                            <UI.Input
                                placeholder={`Recipients`}
                                isRequired={true}
                                label={`Recipients (comma separated email addresses)`}
                                onChange={newValue => this.changeName('emailChannelTo', newValue)}
                                defaultValue={this.state.alert.emailChannelTo}
                            />
                            <UI.Input
                                placeholder={`Subject prefix`}
                                label={`Subject prefix`}
                                onChange={newValue => this.changeName('emailChannelSubjectPrefix', newValue)}
                                defaultValue={this.state.alert.emailChannelSubjectPrefix}
                            />
                        </UI.FormGroup>
                    )}
                    {this.state.alert.channel === AlertChannel.Email && (
                        <UI.FormGroup>
                            <UI.Input
                                placeholder={`SMTP Host`}
                                isRequired={true}
                                label={`SMTP Host`}
                                onChange={newValue => this.changeName('emailChannelSmtpHost', newValue)}
                                defaultValue={this.state.alert.emailChannelSmtpHost}
                            />
                            <UI.Input
                                placeholder={`SMTP Port`}
                                isRequired={true}
                                label={`SMTP Port`}
                                onChange={newValue => this.changeName('emailChannelSmtpPort', newValue)}
                                defaultValue={this.state.alert.emailChannelSmtpPort}
                            />
                            <UI.Input
                                placeholder={`Username`}
                                isRequired={true}
                                label={`SMTP Username`}
                                onChange={newValue => this.changeName('emailChannelSmtpUsername', newValue)}
                                defaultValue={this.state.alert.emailChannelSmtpUsername}
                            />
                            {!this.state.showPasswordBox ? (
                                <div className="passwordButtonContainer">
                                    <UI.LabelValue label={`SMTP Password`} value={''} />
                                    <UI.Button
                                        appearance={'secondary'}
                                        text={`Edit SMTP Password`}
                                        onClick={() => this.setState({ showPasswordBox: true })}
                                    />
                                </div>
                            ) : (
                                <UI.Input
                                    placeholder={`Password`}
                                    isRequired={false}
                                    label={`SMTP Password`}
                                    type={'password'}
                                    onChange={newValue => this.changeName('emailChannelSmtpPassword', newValue)}
                                />
                            )}
                            <UI.Checkbox
                                defaultValue={this.state.alert.emailChannelSsl.toString()}
                                label={`SSL`}
                                onChange={() => this.switchSsl()}
                                checkedValue={`true`}
                                uncheckedValue={`false`}
                            />
                            <UI.Checkbox
                                defaultValue={this.state.alert.emailChannelTls.toString()}
                                label={`StartTLS`}
                                onChange={() => this.switchTls()}
                                checkedValue={`true`}
                                uncheckedValue={`false`}
                            />
                            <UI.Input
                                placeholder={`From address`}
                                isRequired={true}
                                label={`From address (email address of sender)`}
                                onChange={newValue => this.changeName('emailChannelFrom', newValue)}
                                defaultValue={this.state.alert.emailChannelFrom}
                            />
                            <UI.Input
                                placeholder={`Recipients`}
                                isRequired={true}
                                label={`Recipients (comma separated email addresses)`}
                                onChange={newValue => this.changeName('emailChannelTo', newValue)}
                                defaultValue={this.state.alert.emailChannelTo}
                            />
                            <UI.Input
                                placeholder={`Subject prefix`}
                                label={`Subject prefix`}
                                onChange={newValue => this.changeName('emailChannelSubjectPrefix', newValue)}
                                defaultValue={this.state.alert.emailChannelSubjectPrefix}
                            />
                            <br />
                            <div className={`flex row two-cols`}>
                                <div className={`left`}>
                                    <UI.Button
                                        disabled={this.state.isLoading}
                                        isLoading={this.state.isLoading}
                                        appearance={'secondary'}
                                        onClick={() => this.sendTestEmailClicked()}
                                        text={`Send test email`}
                                    />
                                </div>
                                <div className={`right info`}>
                                    {this.state.testEmailError && (
                                        <UI.Message appearance={'error'} message={this.state.testEmailError.message} />
                                    )}
                                    {this.state.testSuccess && (
                                        <UI.Message appearance={'success'}>Test email sent successfully </UI.Message>
                                    )}
                                </div>
                            </div>
                        </UI.FormGroup>
                    )}
                </UI.Form>
            </UI.FullScreenModal>
        );
    }

    switchSsl() {
        const alert = this.state.alert;
        alert.emailChannelSsl = !alert.emailChannelSsl;
        this.setState({ alert });
    }

    switchTls() {
        const alert = this.state.alert;
        alert.emailChannelTls = !alert.emailChannelTls;
        this.setState({ alert });
    }

    async sendTestEmailClicked() {
        this.setState({ testSuccess: false, isSendingTestEmail: true, testEmailError: undefined, isLoading: true }, async () => {
            try {
                await sendTestEmail(this.state.alert)
                this.setState({ testSuccess: true, isSendingTestEmail: false, isLoading: false });
            } catch (error) {
                this.setState({ isSendingTestEmail: false, testEmailError: error as FieldError, isLoading: false });
            }
        });
    }

    changeFlag(property: NotificationFlag) {
        const alert = this.state.alert;
        alert[property] = !alert[property];
        this.setState({ alert });
    }

    changeName(
        property: AlertPropertyToChange,
        newValue: string
    ) {
        const alert = this.state.alert;
        alert[property] = newValue;
        this.setState({ alert });
    }

    doneClicked(): Promise<boolean> {
        return new Promise(resolve => {
            updateAlert(this.state.alert)
                .then(() => {
                    resolve(true);
                    this.props.closeHandler(true);
                })
                .catch(error => {
                    this.setState({ error });
                    resolve(false);
                });
        });
    }

    closeClicked() {
        this.props.closeHandler(false);
    }

    saveAllHeadersHandler(allHeaders: WebhookHeaders[]) {
        const alert = this.state.alert;
        alert.webhookHeaders = allHeaders;
        this.setState({ alert })
    }

    
}
