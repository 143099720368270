import {GetintUserRole} from '../app/system/users/users-management-rest-api';
import {EntityStatusValue} from './ui';

const COOKIE_NAME = 'OAUTH_TOKEN';
const WORKSPACE = 'GETINT_WORKSPACE';

export const isJiraServerNativeApp = process.env.REACT_APP_JIRA_TYPE === 'Server';
export const CLUSTER_ADMIN_WORKSPACE_LANDING_PAGE = '/app/cluster/tenants/list';
export const GENERAL_WORKSPACE_LANDING_PAGE = '/app/dashboard';

export type GetintWorkspace = {
    name: string;
    id: string;
}

export type TokenData = {
    user: {
        id: number;
        username: string;
        email: string;
        name: string;
        role: GetintUserRole;
        status: EntityStatusValue;
    },
    workspacesAccess: GetintUserWorkspace[];
    token: string;
    defaultRole: string;

};

export type GetintUserWorkspace = {
    workspaceName: string;
    workspaceId: string;
    role: GetintUserRole;
}

export const CLUSTER_ADMIN_WORKSPACE: GetintUserWorkspace = {
    workspaceName: 'Getint Administration',
    workspaceId: 'getint_cluster_admin',
    role: GetintUserRole.ClusterAdmin
}
let usePostMessage = false;

export const AuthToken = {
    exists: () => {
        if (isJiraServerNativeApp) {
            return true;
        }
        if (extractTokenIfItsInIframeDeployed()) {
            return true;
        }
        return localStorage.getItem(COOKIE_NAME) !== null;
        // return !!cookies.load(COOKIE_NAME);
    },
    get: (): string | null => {
        if (extractTokenIfItsInIframeDeployed()) {
            return extractTokenIfItsInIframeDeployed();
        }
        if (localStorage.getItem(COOKIE_NAME)) {
            const tokenData = JSON.parse(localStorage.getItem(COOKIE_NAME)!) as TokenData;
            return tokenData.token;
        }
        // if (cookies.load(COOKIE_NAME)) {
        //     const tokenData = cookies.load(COOKIE_NAME);
        //     return tokenData.token;
        // }
        return null;
    },
    getWorkspace: (): GetintUserWorkspace | undefined => {
        if (localStorage.getItem(WORKSPACE)) {
            return JSON.parse(localStorage.getItem(WORKSPACE)!) as GetintUserWorkspace;
        }
        if (localStorage.getItem(COOKIE_NAME)) {
            const tokenData = JSON.parse(localStorage.getItem(COOKIE_NAME)!) as TokenData;
            if (tokenData && tokenData.workspacesAccess && tokenData.workspacesAccess[0]) {
                return tokenData.workspacesAccess[0];
            }
        }
    },
    getWorkspaces: (): GetintUserWorkspace[] => {
        if (localStorage.getItem(COOKIE_NAME)) {
            const tokenData = JSON.parse(localStorage.getItem(COOKIE_NAME)!);
            const workspaces: GetintUserWorkspace[] = tokenData.workspacesAccess || [];
            if (AuthToken.isClusterAdmin()) {
                workspaces.push(CLUSTER_ADMIN_WORKSPACE);
            }
            return workspaces;
        }
        return [];
    },
    setWorkspace: (workspace: GetintUserWorkspace) => {
        localStorage.setItem(WORKSPACE, JSON.stringify(workspace));
    },
    user: () => {
        if (localStorage.getItem(COOKIE_NAME)) {
            const tokenData = JSON.parse(localStorage.getItem(COOKIE_NAME)!);
            return tokenData?.user;
        }
        return null;
    },
    isClusterAdmin: () => {
        if (extractTokenIfItsInIframeDeployed()) {
            return false;
        }
        const user = AuthToken.user();
        if (user && user.role === 'ClusterAdmin') {
            return true;
        }
        return false;
    },
    save: (token: TokenData) => {
        localStorage.setItem(COOKIE_NAME, JSON.stringify(token));
        // cookies.save(COOKIE_NAME, JSON.stringify(token), { path: '/' });
    },
    remove: () => {
        iframeToken = '';
        localStorage.removeItem(COOKIE_NAME);
        localStorage.removeItem(WORKSPACE);
        // cookies.remove(COOKIE_NAME, { path: '/' });
    },
    setToUsePostMessage() {
        usePostMessage = true;
    }
}

let iframeToken = '';

export function setIframeToken(token: string): void {
    iframeToken = token;
}

function extractTokenIfItsInIframeDeployed(): string | null {
    return iframeToken || document.body.getAttribute('jbt');
}

/**
 * Whether its an app deployed within an iframe. Mainly for Jira Cloud apps and Getint Cloud
 */
export function isInIframeApp(): boolean {
    return !!extractTokenIfItsInIframeDeployed() || usePostMessage;
}
