import React from 'react';
import {EntityFormHelper, FieldError, UI} from 'src/engrator-core';
import {FormError} from 'src/engrator-core/form-error.type';
import {DropdownOption} from 'src/engrator-core/ui';
import { fetchTables } from './../rest-api';
import {GitHubRepositoryDTO} from './github-repository-dto.type';

export type GitHubStepContextData = {
    repository?: GitHubRepositoryDTO;
};

type Props = {
    connectionId: number;
    onDataChanged: (data: GitHubStepContextData, isValid: boolean) => void;
    formError?: FormError;
}

type State = {
    formError: FormError;
    isLoadingRepositories: boolean;
}

export class GitHubStepContext extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private repositoryOptions: DropdownOption[] = [];
    private contextData : GitHubStepContextData = {};

    constructor(props: Props) {
        super(props);
        this.state = {formError: {}, isLoadingRepositories: true };
        this.entityFormHelper = new EntityFormHelper((propertyName: 'repository', newValue, additionalArgument?: any) => {
            if (!additionalArgument) {
                return;
            }
            if (propertyName === 'repository') {
                this.contextData.repository = additionalArgument as GitHubRepositoryDTO;
            }
            const isValid = (!!this.contextData.repository);
            this.props.onDataChanged(this.contextData, isValid);
            this.forceUpdate();
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.forceUpdate();
        }
    }

    componentDidMount(): void {
        fetchTables(this.props.connectionId)
            .then((organizations: GitHubRepositoryDTO[]) => {
                this.repositoryOptions = organizations.map(repository => ({
                    label: repository.name,
                    value: repository.id,
                    object: repository
                }));
                this.setState({isLoadingRepositories: false});
            })
            .catch((error: FieldError) => {
                this.setState({ isLoadingRepositories: false, formError: { ['pickerError']: error.message  } });
            });
    }

    render() {
        return <React.Fragment>
            { this.state.formError.pickerError && <UI.Message appearance={'error'}>{ this.state.formError.pickerError}</UI.Message> }
            <UI.FormGroup
                label={`Repository`}
                error={ this.props.formError?.repository }
                description={``}
                isRequired={true}>
                { this.state.isLoadingRepositories && <UI.Loader visible={true} appearance={'darkgray'} /> }
                { !this.state.isLoadingRepositories && <UI.Dropdown options={ this.repositoryOptions } onChange={ this.entityFormHelper.onChangeHandler('repository')   }/> }
            </UI.FormGroup>
        </React.Fragment>;
    }
}