import React from 'react';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { UI } from 'src/engrator-core';

type Props = {
    connectionId: number;
    trigger: SmartIntTrigger;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class ServiceNowSmartIntsEditTrigger extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                label={`Custom query`}
                description={`You can provide custom query which will be appended to the one generated when searching for ServiceNow items`}
            >
                <UI.Input defaultValue={this.props.trigger.configuration.jql} label={``}
                    onChange={this.onJqlInputChanged.bind(this)}/>
            </UI.FormGroup>
        </React.Fragment>
    }

    private onJqlInputChanged(newValue: string) {
        this.props.trigger.configuration.jql = newValue;
    }
}
