import React from 'react';
import {switchBodyScroll, UI} from '../index';
import Breadcrumbs, { BreadcrumbsSymbol, filterOptions, BreadcrumbStep, ConnectionStep } from './breadcrumbs';


type Props ={
    hideFooter?: boolean;
    depth?: number;
    isLeftAligned?: boolean;
    header?: string;
    maximized?: boolean;
    primaryBtnHandler?: () => Promise<boolean>;
    closeBtnHandler?: () => void;
    highestIndex?: boolean;
    isPrimaryDisabled?: boolean;
    showPrimaryBtn?: boolean;
    showSecondaryBtn?: boolean;
    error?: string;
    primaryBtnText?: string;
    secondaryBtn?: any;
    hideCloseBtn?: boolean;
    className?: string;
    breadcrumbs?: boolean;
    currentStep?: BreadcrumbStep;
    allSteps?: BreadcrumbStep[];
    onChangeStepHandler?: (step: ConnectionStep) => void;
    breadcrumbsSymbol?: BreadcrumbsSymbol;
    filterOptions?: filterOptions;
    onClose?: () => void;
}

type State = {
    isLoading: boolean;
}

export class FullScreenModal extends React.Component<Props, State> {
    private wrapperRef: HTMLDivElement | null = null;

    constructor(props: Readonly<Props>) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.state = { isLoading: false };
    }

    setWrapperRef(node: HTMLDivElement) {
        this.wrapperRef = node;
    }

    componentDidMount() {
        switchBodyScroll(false);
        setTimeout(() => {
            this.wrapperRef?.querySelector('.inner')?.classList.add('visible');
        }, 0);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.error !== prevProps.error ||
            this.props.showPrimaryBtn !== prevProps.showPrimaryBtn ||
            this.props.isPrimaryDisabled !== prevProps.isPrimaryDisabled) {
            this.forceUpdate();
        }
    }

    render() {
        let maxClass = (this.props.maximized) ? 'maximized' : ''
        if (this.props.hideFooter) {
            maxClass += ' hide-footer';
        }
        const secondaryBtns = [];

        if (this.props.secondaryBtn) {
            secondaryBtns.push(this.props.secondaryBtn);
        }
        if (this.props.closeBtnHandler && (this.props.hideCloseBtn === undefined || !this.props.hideCloseBtn)) {
            secondaryBtns.push(<UI.Button
                appearance={'secondary'}
                onClick={() => this.closeClicked()}
                text={`Close`}
            />);
        }
        const noFooterBtnsClass = (secondaryBtns.length == 0 && this.props.showPrimaryBtn === false)
            ? 'no-btns'
            : '';
        return <div
            className={`anim-slide fullscreen-modal ${ this.props.className || '' } ${ maxClass } depth-${ this.props.depth || '' } ${ noFooterBtnsClass }`}
            ref={ this.setWrapperRef }
        >
            { this.props.closeBtnHandler && <div className={`close-clickable`}
                onClick={ () => this.closeClicked() }
            ></div> }
            <div className={`inner`}>
                { this.props.breadcrumbs && this.props.onChangeStepHandler && <Breadcrumbs filterOptions={this.props.filterOptions} breadcrumbsSymbol={this.props.breadcrumbsSymbol || ' / '} currentStep={this.props.currentStep!} allSteps={this.props.allSteps || []} onChangeStepHandler={(step: ConnectionStep) => this.props.onChangeStepHandler!(step)}/>}
                <h3>
                    { this.props.header }
                    { this.props.closeBtnHandler && <span
                        className={`close-icon`}
                        onClick={() => this.closeClicked() }><UI.Icon icon={'close'}/>
                    </span> }
                </h3>
                <div className={`box`}>
                    { this.props.error && <UI.Message appearance={`error-message`}>
                        {this.props.error}
                    </UI.Message> }
                    { this.props.children }
                </div>
                {this.props.showPrimaryBtn === false && this.props.showSecondaryBtn === false ? null : <UI.ButtonsBar
                    primary={ (this.props.showPrimaryBtn === false) ? undefined : <UI.Button
                        onClick={ () => {
                            this.primaryClicked();
                        } }
                        disabled={ this.props.isPrimaryDisabled === true }
                        isLoading={ this.state.isLoading }
                        text={ this.props.primaryBtnText || `Apply`}
                    /> }
                    secondary={ this.props.showSecondaryBtn === false ? [] : secondaryBtns }
                />}
            </div>
        </div>;
    }

    private closeClicked() {
        switchBodyScroll(true);
        this.wrapperRef?.querySelector('.inner')?.classList.add('disappear');
        setTimeout(() => {
            this.props.closeBtnHandler!();
        }, 200);
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private primaryClicked() {
        this.setState({ isLoading: true }, () => {
            if (!this.props.primaryBtnHandler) return;
            this.props.primaryBtnHandler()
                .then((result) => {
                    if (result) {
                        this.closeClicked();
                    } else {
                        this.setState({ isLoading: false });
                    }
                })
        });
    }
}

