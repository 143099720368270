import {VerticalMenu} from 'src/app/global-store/create-global-state';

export function getSystemVerticalMenu(): VerticalMenu {
    const menu = {
        header: 'System',
        items: [{
            name: 'General',
            path: '/app/system/general'
        }, {
            name: 'Data storage',
            path: '/app/system/data-storage'
        },
        // {
        //     name: 'Users',
        //     path: '/app/system/users'
        // },
        {
            name: 'Custom properties',
            path: '/app/system/custom-properties'
        }, {
            name: 'Sync jobs',
            path: '/app/system/integration-threads'
        }, {
            name: 'System logs',
            path: '/app/system/logs'
        }, {
            name: 'Api keys',
            path: '/app/system/api-keys'
        }, {
            name: 'License',
            path: '/app/system/license'
        }]
    };
    return menu;
}