import {PipelineStep} from 'src/generic/pipeline-step';
import {SoftwareName} from 'src/software/software-name';
import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';

import {ServiceNowStepKey} from '../servicenow-step-key.type';
import {ServiceNowSearchItemsConfiguration} from './servicenow-search-items-configuration';
import {ServiceNowSearchItemsNewStep} from './servicenow-search-items-new-step';
import {ServiceNowSearchItemsEditStep} from './servicenow-search-items-edit-step';


export const ServiceNowSearchItemsPipelineStep :  PipelineStep<ServiceNowSearchItemsConfiguration> = {
    addStepComponentClass: ServiceNowSearchItemsNewStep,
    editStepComponentClass: ServiceNowSearchItemsEditStep,
    specification: {
        action: 'Search items',
        description: 'Search items in specified table',
        key: ServiceNowStepKey.ServiceNowSearchItems,
        software: SoftwareName.ServiceNow,
        stepType: StepType.Trigger
    },
    preferredNameForArtifact: `ServiceNowItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ServiceNowSearchItemsConfiguration;
        return `Search for items in ${stepConfiguration.context.table?.name}`;
    }
}