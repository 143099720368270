import React from 'react';

import {FieldError, UI} from 'src/engrator-core';
import { ConnectionFactory } from './../../connections/connection-factory';
import './connection-dropdown.css';
import {Connection} from 'src/connections';
import {RightPanelContent} from '../../engrator-core/ui/right-panel-content';
import { SoftwareLogo } from './software-logo';

type ConnectionDropdownProps = {
    software: any,
    connectionFactory: ConnectionFactory,
    connectionPickedHandler: ((id: number) => void),
    onConnectionChangedHandler?: ((id: number) => void)
};

type ConnectionDropdownState = {
    dropdownOptions: any,
    connectionId?: number,
    showCreateConnection: boolean,
    error? :FieldError
}

export class ConnectionDropdown extends React.Component<ConnectionDropdownProps, ConnectionDropdownState> {
    connectionId: number | undefined;

    constructor(props: ConnectionDropdownProps) {
        super(props);
        this.state = {
            dropdownOptions: [],
            connectionId: undefined,
            showCreateConnection: false
        }
    }

    componentDidMount(): void {
        this.fetchConnections();
    }

    fetchConnections(): void {
        this.props.connectionFactory.fetchConnections()
            .then(connectionsList => {
                const dropdownOptions: any = connectionsList.map((connection: Connection) => ({
                    ...connection,
                    value: connection.id,
                    label: connection.name
                }));
                this.setState({
                    showCreateConnection: false,
                    dropdownOptions: dropdownOptions
                });
            })
            .catch(error => this.setState({ error }))
    }

    confirmConnection() {
        this.props.connectionPickedHandler(this.connectionId as number);
    }

    setConnectionId(value: number): void {
        this.connectionId = value;
        this.setState({ connectionId: this.connectionId });
        if (this.props.onConnectionChangedHandler) {
            this.props.onConnectionChangedHandler(this.connectionId);
        }
    }

    render() {
        return <RightPanelContent
            header={ <React.Fragment>
                <SoftwareLogo softwareName={ this.props.software } />
                            Select { this.props.software } connnection
            </React.Fragment>
            }
            footer={<UI.Button disabled={ !this.state.connectionId } onClick={ () => this.confirmConnection() } text="Next" />}
        >
            { this.state.error && <UI.Message appearance={'error'}>{ this.state.error?.message}</UI.Message> }
            { this.state.dropdownOptions.length > 0 && <div className="connection-dropdown">
                <UI.FormGroup
                    label={`Available connections`}
                    description={`New step will use selected connection to communicate with ` + this.props.software}
                    isRequired={true}
                >
                    <UI.Dropdown
                        options={ this.state.dropdownOptions }
                        onChange={this.setConnectionId.bind(this)}
                        isConnections={true}
                    />
                </UI.FormGroup>
            </div> }
            { !this.state.showCreateConnection && <UI.FormGroup
                label={`Missing connection?`}
                description={`You can add a new connection to the above list. Click below button and fill the form.`}
            ><UI.Button
                    appearance={'secondary'}
                    onClick={() => this.setState({ showCreateConnection: true })}
                    text={`Create New`} />
            </UI.FormGroup> }
            { this.state.showCreateConnection && 
                    <UI.FormSection
                        label={`Create new connection`}
                    >
                        { this.props.connectionFactory.getConnectionComponent(this.fetchConnections.bind(this)) }
                    </UI.FormSection>
            }
        </RightPanelContent>;
    }
}