import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper, UI} from 'src/engrator-core';
import {ZendeskSearchItemsConfiguration} from './zendesk-search-items-configuration';
import {IntegrationStepConfiguration} from 'src/generic/software/step';
import {ZendeskOrganizationPicker, ZendeskOrganizationPickerData} from '../common';
import {ArtifactDirection} from '../../../../generic/artifacts';

type Props = {
} & NewPipelineStepComponentProps;
type State = {
    formError: { [key: string]: string },
    canConfigureArtifact: boolean,
    isLoadingJql: boolean,
    isAdding: boolean
};

export class ZendeskSearchItemsNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: ZendeskSearchItemsConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as ZendeskSearchItemsConfiguration;
        this.configuration.onlyNewChanges = true;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'boardId' | 'listId', newValue) => {
            // this.configuration[propertyName] = newValue;
            // this.checkIfArtifactCanBeCreated();
            // this.onConfigurationChanged();
        });
        this.state = { isLoadingJql: false, formError: {}, canConfigureArtifact: false, isAdding: false };
    }

    private onConfigurationChanged(data: ZendeskOrganizationPickerData) {
        if (data.organization) {
            this.configuration.organization = data.organization;
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }

    render() {
        return <div className={`connectors-zendesk-search-items-step`}>
            <ZendeskOrganizationPicker
                formError={ this.props.formError }
                connectionId={ this.props.context.connectionId}
                onDataChanged={ this.onConfigurationChanged.bind(this) } />
            <UI.FormGroup
                label={`Changed from last integration`}
                description={`If checked, only items that changed since last integration will be picked up.`}
            >
                <UI.Checkbox defaultValue={'true'} onChange={ this.onlyNewChanged.bind(this) } checkedValue={ 'true' } uncheckedValue={ 'false '}/>
            </UI.FormGroup>
        </div>
    }

    onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }

    private getStepConfiguration(configuration: ZendeskSearchItemsConfiguration): IntegrationStepConfiguration {
        return {
            data: configuration,
            connectionId: this.props.context.connectionId,
            stepSpecification: this.props.context.stepSpecification,
            inDataArtifact: null,
            outDataArtifact: null,
            rules: []
        };
    }
}
