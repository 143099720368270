import { axiosWrapper } from 'src/engrator-core';
import { IntegrationDetailsModel, createIntegrationDetailsModel } from './integration-details-model';
import {SmartIntDefinitionTypeMapping} from '../../smartints/definition/smart-int-definition--type-mapping.type';
import {getDefaultStatusTransitionConfiguration} from '../../smartints/definition/smart-int-definition-configuration.type';
import {FILTER_SET_NAME} from '../../smartints/visual/filtering/rest-api';
import {SmartIntTrigger} from '../../smartints/definition/smart-int-trigger.type';

const ENDPOINT_URL = '/integration/suites';

export const fetchIntegrationDetails = (id: number): Promise<IntegrationDetailsModel> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/' + id)
                .then((data: any) => {
                    const integration = createIntegrationDetailsModel(data);
                integration.configuration?.types?.forEach((typeMapping: SmartIntDefinitionTypeMapping) => {
                    if (typeMapping.statusTransitionConfiguration === undefined) {
                        typeMapping.statusTransitionConfiguration = getDefaultStatusTransitionConfiguration();
                    }
                });
                fixIntegrationModel(integration.configuration?.triggers?.left);
                fixIntegrationModel(integration.configuration?.triggers?.right);
                resolve(integration);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export async function rescheduleMigration(suiteId: number, runId: number): Promise<void>  {
    await axiosWrapper.get(ENDPOINT_URL + '/' + suiteId + '/reschedule-migration/' + runId);
}

export function fixIntegrationModel(trigger?: SmartIntTrigger) {
    if (trigger && trigger.itemsFilters && (trigger.itemsFilters.sets as any)['new']) {
        trigger.itemsFilters.sets[FILTER_SET_NAME.NEW] = (trigger.itemsFilters.sets as any).new;
        delete (trigger.itemsFilters.sets as any).new;
    }
}