import { Side } from 'src/generic';
import { PropertyDataType } from 'src/generic/artifacts';
import { PropertyMapping, DefaultsPropertyMapping } from '../../../property-mapping.type';
import { ValueMappingsGroup } from '../value-mappings-group.type';
import { SmartIntTrigger } from 'src/app/integration/smartints/definition/smart-int-trigger.type';
import { testTeamsOrganizationId } from '../value-mappings-options-fetcher';

export type TeamsSideAttributes = {
    isConfiguration: boolean;
    organizationId: string;
    accepted: boolean;
    acceptedLoading: boolean;
    error?: any;
};

type OnMappingsChangedHandler = (
    groups: ValueMappingsGroup[],
    defaultMappings?: DefaultsPropertyMapping,
    sharedMappingId?: number,
    assetsAqlLeft?: string,
    assetsAqlRight?: string,
    assetsCanStoreMultipleLeft?: boolean,
    assetsCanStoreMultipleRight?: boolean,
    organizationIdLeft?: string,
    organizationIdRight?: string
) => void;

export type Teams = {
    isBothSides: boolean;
    [Side.Left]: TeamsSideAttributes;
    [Side.Right]: TeamsSideAttributes;
};

export async function teamsSetupOnMount(
    teamsFromState: Teams,
    setState: React.Dispatch<React.SetStateAction<any>>,
    mapping: PropertyMapping
): Promise<void> {
    if (mapping.left.options?.internalType === PropertyDataType.Teams && !mapping.options.left.organizationId) {
        teamsFromState[Side.Left].isConfiguration = true;
    }
    if (mapping.right.options?.internalType === PropertyDataType.Teams && !mapping.options.right.organizationId) {
        teamsFromState[Side.Right].isConfiguration = true;
    }
    if (teamsFromState[Side.Left].isConfiguration && teamsFromState[Side.Right].isConfiguration) {
        teamsFromState.isBothSides = true;
    }
    setState({ teams: teamsFromState });
}

export async function teamsAfterAccepted(
    teamsFromState: Teams,
    setState: React.Dispatch<React.SetStateAction<any>>,
    groups: ValueMappingsGroup[],
    defaults: DefaultsPropertyMapping,
    onMappingsChangedHandler: OnMappingsChangedHandler,
    fetchData: () => Promise<void>,
    sharedMappingId?: number
): Promise<void> {
    const teams = { ...teamsFromState };
    let resultLeft;
    let resultRight;
    if (teamsFromState[Side.Left].organizationId) {
        resultLeft = teamsFromState[Side.Left].organizationId;
    }
    if (teamsFromState[Side.Right].organizationId) {
        resultRight = teamsFromState[Side.Right].organizationId;
    }
    teams[Side.Left].isConfiguration = false;
    teams[Side.Right].isConfiguration = false;
    teams[Side.Left].accepted = false;
    teams[Side.Right].accepted = false;
    setState({ teams });
    onMappingsChangedHandler(
        groups,
        defaults,
        sharedMappingId,
        undefined,
        undefined,
        undefined,
        undefined,
        resultLeft,
        resultRight
    );
    await fetchData();
}

export async function handleAcceptTeam(
    side: Side,
    teamsFromState: Teams,
    setState: React.Dispatch<React.SetStateAction<any>>,
    leftTrigger: SmartIntTrigger,
    rightTrigger: SmartIntTrigger
): Promise<void> {
    try {
        if (!teamsFromState[side].organizationId) {
            const teams = {...teamsFromState}
            teams[side].error = 'Input cannot be empty.'
            setState({ teams });
            return;
        }
        const teams = { ...teamsFromState };
        teams[side].error = undefined;
        teams[side].acceptedLoading = true;
        setState({ teams });
        if (teamsFromState[side].organizationId!) {
            await testTeamsOrganizationId(
                side === Side.Left ? leftTrigger : rightTrigger,
                teamsFromState[side].organizationId!
            );
        }
        const teamsAfterTest = { ...teamsFromState };
        teamsAfterTest[side].accepted = true;
        teamsAfterTest[side].acceptedLoading = false;
        setState({ teams: teamsAfterTest });
    } catch (err) {
        const teams = { ...teamsFromState };
        teams[side].error = err;
        teams[side].acceptedLoading = false;
        setState({ teams });
    }
}

export function handleEditTeams(
    teamsFromState: Teams,
    setState: React.Dispatch<React.SetStateAction<any>>,
    scrollDown: () => void
): void {
    let organizationIdLeft: string | string[] = teamsFromState[Side.Left].organizationId!;
    let organizationIdRight: string | string[] = teamsFromState[Side.Right].organizationId!;
    const teams = { ...teamsFromState };
    if (organizationIdLeft) {
        teams[Side.Left].isConfiguration = true;
    }
    if (organizationIdRight) {
        teams[Side.Right].isConfiguration = true;
    }
    if (teams[Side.Left].isConfiguration && teams[Side.Right].isConfiguration) {
        teams.isBothSides = true;
    }
    teams[Side.Left].organizationId = organizationIdLeft;
    teams[Side.Right].organizationId = organizationIdRight;
    setState({ teams });
    scrollDown();
}

export function clearTeams(
    teamsFromState: Teams,
    setState: React.Dispatch<React.SetStateAction<any>>,
    mapping: PropertyMapping,
    defaults: DefaultsPropertyMapping,
    onMappingsChangedHandler: OnMappingsChangedHandler,
    sharedMappingId?: number
): void {
    const teams = { ...teamsFromState };
    if (teams[Side.Left].organizationId) {
        teams[Side.Left].isConfiguration = true;
    }
    if (teams[Side.Right].organizationId) {
        teams[Side.Right].isConfiguration = true;
    }
    if (teams[Side.Left].isConfiguration && teams[Side.Right].isConfiguration) {
        teams.isBothSides = true;
    }
    teams[Side.Left].accepted = false;
    teams[Side.Right].accepted = false;
    teams[Side.Left].organizationId = '';
    teams[Side.Right].organizationId = '';
    setState({ groups: [], teams });
    onMappingsChangedHandler([], defaults, sharedMappingId, '', '', false, false, '', '');
}
