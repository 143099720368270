import {PipelineStep} from 'src/generic';
import {
    JiraCreateCommentsPipelineStep,
    JiraCreateIssuePipelineStep,
    JiraSearchIssuesPipelineStep,
    JiraStatusTransitionPipelineStep,
    JiraUpdateIssuePipelineStep,
    JiraFindIssuePipelineStep
} from './steps';

export const JiraPipelineSteps: PipelineStep<any>[] = [
    JiraSearchIssuesPipelineStep,
    JiraCreateIssuePipelineStep,
    JiraFindIssuePipelineStep,
    JiraUpdateIssuePipelineStep,
    JiraStatusTransitionPipelineStep,
    JiraCreateCommentsPipelineStep
];