import {
    API_URL,
    AuthToken,
    CLUSTER_ADMIN_WORKSPACE,
    CLUSTER_ADMIN_WORKSPACE_LANDING_PAGE,
    FieldError, GENERAL_WORKSPACE_LANDING_PAGE, TokenData
} from 'src/engrator-core';
import axios from 'axios';
import {extractErrorFromResponse} from '../../engrator-core/errors';

const ENDPOINT_URL = `${API_URL}/login`;
const ENDPOINT_WITH_KEY_URL = `${API_URL}/login`;

const INVALID_LOGIN_PASSWORD: FieldError = {
    fieldName: 'general',
    message: 'Username / Password is invalid'
};

export type LoginData = {
    username: string,
    password: string
};

export const me = (authorizationToken: string) => {
    const headers = {
        'Authorization': authorizationToken,
        // 'X-Tenant': '8db10b43'
    };
    return axios.get(API_URL + '/account/me', { headers });
};

export const signInWithKey = (apiKey: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(ENDPOINT_WITH_KEY_URL, { apiKey })
                .then((response: any) => {
                    const token = response.headers.authorization;
                    const defaultRole = response.headers.role;
                    saveToken(token, defaultRole, reject);
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        return reject(INVALID_LOGIN_PASSWORD);
                    }
                    reject(extractErrorFromResponse(error));
                });
        } catch (error) {
            reject(error);
        }
    });
};

export const signIn = (loginData: LoginData): Promise<string[]> => {
    return new Promise((resolve, reject) => {
        try {
            let headers: any = {
                // 'X-Tenant': '8db10b43'
            };
            axios.post(ENDPOINT_URL, loginData, { headers })
                .then((response: any) => {
                    const token = response.headers.authorization;
                    const defaultRole = response.headers.role;
                    saveToken(token, defaultRole, reject)
                        .then((workspaces) => {
                            resolve(workspaces);
                        });
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        return reject(INVALID_LOGIN_PASSWORD);
                    }
                    reject(extractErrorFromResponse(error));
                });
        } catch (error) {
            reject(error);
        }
    });
};

async function saveToken(token: string, defaultRole: string, reject: (reason?: any) => void): Promise<string[]> {
    try {
        const response = await me(token);
        const tokenData: TokenData = {
            user: response.data.user,
            workspacesAccess: response.data.workspacesAccess,
            token: token,
            defaultRole
        };
        AuthToken.save(tokenData);
        if (AuthToken.isClusterAdmin() && response.data.workspacesAccess.length == 0) {
            window.location.href = CLUSTER_ADMIN_WORKSPACE_LANDING_PAGE;
            AuthToken.setWorkspace(CLUSTER_ADMIN_WORKSPACE);
            return [];
        } else {
            if (response.data.workspacesAccess[0]) {
                window.location.href = GENERAL_WORKSPACE_LANDING_PAGE;
            }
            return [];
        }
    } catch (error) {
        reject(extractErrorFromResponse(error));
        return [];
    }
}

async function getUserWorkspaces(): Promise<string[]> {
    const token = AuthToken.get();
    if (token) {
        const response = await me(token);
        return response.data.workspacesAccess;
    }
    return [];
}