import React from 'react';
import {UI} from 'src/engrator-core';
import {getAvailablePlugins, SmartIntPlugin} from './plugins';
import {AppsSupport} from '../../visual/visual-integration-designer';
import {PluginComponent} from './plugin-component';
import {DropdownOption, Icon20} from '../../../../../engrator-core/ui';
import {PluginConfig} from './plugin-config';
import {SmartIntTrigger} from '../../definition/smart-int-trigger.type';

type Props = {
    closeHandler: () => void;
    applyHandler: (plugins: SmartIntPlugin[]) => void;
    appSupport: AppsSupport;
    plugins: SmartIntPlugin[];
    triggers: { left: SmartIntTrigger, right: SmartIntTrigger };
};
type State = {
    plugins: SmartIntPlugin[];
    availablePluginsOptions: DropdownOption[];
    pluginToConfigure?: SmartIntPlugin;
    selectedPlugin?: SmartIntPlugin;
};

export class PluginsConfigurationComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const availablePlugins = getAvailablePlugins(this.props.appSupport.leftApp, this.props.appSupport.rightApp);
        this.state ={
            plugins: this.props.plugins || [],
            availablePluginsOptions: availablePlugins.map((plugin) => ({ value: plugin.id, label: plugin.name, object: plugin }) )
        };
    }

    render() {
        return <UI.FullScreenModal
            maximized={true}
            header={`Plugins`}
            closeBtnHandler={ () => this.props.closeHandler() }
            primaryBtnHandler={ () => this.applyClicked() }
            className={`integration-plugins`}
        >
            <UI.Form>
                <UI.Message appearance={'info'}>
                    Our 'Plugins' feature is in its Alpha stage, and we're actively working on improvements to provide you with a seamless experience! We'd love to hear your thoughts and feedback to help us enhance this feature. Please share your insights with us!
                </UI.Message>
                <UI.Dropdown
                    label={`Select plugin`}
                    options={ this.state.availablePluginsOptions }
                    onChange={ (value: string, object: SmartIntPlugin) => this.setState({ selectedPlugin: object }) }
                />
                <UI.ButtonsBar primary={ <UI.Button
                    disabled={ !this.state.selectedPlugin }
                    icon={ <UI.Icon icon20={ Icon20.CirclePlusWhite } /> }
                    text={`Add plugin`}
                    onClick={ () => this.addPluginClicked(this.state.selectedPlugin) }
                /> } />
            </UI.Form>
            <div className={`plugins-list`}>
                { this.state.plugins.map((plugin, index) => <PluginComponent
                    plugin={ plugin }
                    removeHandler={ () => this.removePlugin(index) }
                    configureHandler={ () => this.configurePlugin(plugin) }
                />) }
            </div>
            { this.state.pluginToConfigure && <PluginConfig
                appSupport={ this.props.appSupport }
                plugin={ this.state.pluginToConfigure}
                triggers={ this.props.triggers }
                closeHandler={ () => this.setState({ pluginToConfigure: undefined })}
                configureHandler={ () => {} }
            /> }
        </UI.FullScreenModal>;
    }

    private removePlugin(index: number): void {
        const plugins = this.state.plugins;
        plugins.splice(index, 1);
        this.setState({ plugins });
    }

    private configurePlugin(plugin: SmartIntPlugin): void {
        this.setState({ pluginToConfigure: plugin });
    }

    private async applyClicked(): Promise<boolean> {
        this.props.applyHandler(this.state.plugins);
        return true;
    }

    private addPluginClicked(plugin?: SmartIntPlugin): void {
        if (plugin) {
            const plugins = this.state.plugins;
            plugins.push(plugin);
            this.setState({ plugins });
        }
    }
}
