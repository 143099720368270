import { TenantInfo } from './tenants-list';

export enum Health {
    Sick = 'Sick',
    Healthy = 'Healthy',
    Unknown = 'Unknown',
    Symptoms = 'Symptoms',
    Starter = 'Starter'
}

export function getTenantHealth(tenant: TenantInfo): Health {
    if (!tenant.activity) {
        return Health.Unknown;
    }
    if (tenant.activity) {
        if (tenant.activity.lastRuns && tenant.activity.lastRuns.failed > 1) {
            return Health.Sick;
        } else if (tenant.activity.lastFlows && tenant.activity.lastFlows.failed > 0) {
            if (tenant.activity.lastFlows.successful > 0) {
                return Health.Symptoms;
            } else {
                return Health.Sick;
            }
        } else if (tenant.activity.connectionsCount > 0 && tenant.activity.persistedItemsCount === 0) {
            return Health.Starter;
        }
        else if (tenant.activity.persistedItemsCount > 0) {
            return Health.Healthy;
        }
    }

    return Health.Unknown;
}