import React from 'react';
import { UI } from 'src/engrator-core';

type Props = {
    empty: { left: string, right: string };
};

type State = {
    isEditMode: boolean;
};

export class EmptyHandler extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = { isEditMode: false };
    }

    render() {
        return <UI.FormGroup
            label={`Provide values which will be used instead of empty values`}>
            <div className={`row-two-cols empty-value-handler`}>
                <div className={`left`}>
                    <UI.Input defaultValue={this.props.empty.left}
                        onChange={(newValue) => this.onChange('left', newValue)}/>
                </div>
                <div className={`right`}>
                    <UI.Input defaultValue={this.props.empty.right}
                        onChange={(newValue) => this.onChange('right', newValue)}/>
                </div>
            </div>
        </UI.FormGroup>;
    }

    private onChange(side: 'left' | 'right', newValue: string) {
        this.props.empty[side] = newValue;
    }
}
