import React from 'react';

type State = {
};

type Props = {
    // shouldBlink: boolean;
    name: string;
    className: string;
}
export class TypeBox extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {  };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        // if (this.props.shouldBlink !== prevProps.shouldBlink) {
        //     this.forceUpdate();
        // }
    }

    render() {
        // let classes = `select-type-box not-picked`;
        // if (this.props.shouldBlink) {
        //     classes += ` anim-blinking`;
        // }
        return <div className={`type-box ${this.props.className}`} onClick={ () => this.switch() }>
            { this.props.name }
        </div>
    }

    switch() {
        this.setState({  })
    }
}