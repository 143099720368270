import React from 'react';
import {UI} from 'src/engrator-core';
import {ArtifactVersionPreviewLink} from '../artifacts-versions';
import {SubItems, SubItemsMode} from '../sub-items';

import {FlowDetails} from './flow-details.type';
import {getOppositeSide} from '../../../generic';
import {PerformedFlow} from '../run-results/performed-flow';

type Props = {
    flowDetails: FlowDetails
};

type State = {};

export class Header extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <div className={`page-header`}>
            <UI.LabelValue
                className={`label-value time`}
                label={`Sync ID `}
                value={<div className={`time-execution`}>
                    <div className={`sync-id`}>#{this.props.flowDetails.flow.id}</div>
                    <UI.ExecutionTime
                        startTime={this.props.flowDetails.flow.formattedStartTime}
                        formattedExecutionTime={this.props.flowDetails.flow.formattedExecutionTime}
                    /></div>}
            />
            <UI.LabelValue
                className={`label-value`}
                label={`Integration`}
                value={<UI.NavLink
                    path={`/app/integration/smart-ints/${this.props.flowDetails.runDetails.suiteId}/edit`}>{this.props.flowDetails.runDetails.suiteName} </UI.NavLink>}
            />
            <UI.LabelValue
                className={`label-value run-id`}
                label={`Run ID `}
                value={<UI.NavLink
                    path={`/app/reporting/suite-run/${this.props.flowDetails.runDetails.id}/details`}>#{this.props.flowDetails.runDetails.id}</UI.NavLink>}
            />
            <UI.LabelValue
                className={`label-value`}
                label={`Status `}
                value={
                    <React.Fragment>
                        <UI.ExecutionStatus status={this.props.flowDetails.flow.status}/>
                        {this.props.flowDetails.flow.failureReason && <div className={`failure-reason`}>
                            <UI.Message appearance="error">
                                <p>{this.getErrorDetails()}</p>
                            </UI.Message>
                            <p><UI.NavLink
                                path={`/app/reporting/suite-run/${this.props.flowDetails.runDetails.id}/flow/${this.props.flowDetails.flow.id}/logs`}>(Check
                                logs to find out more)</UI.NavLink></p>
                        </div>}
                        {this.props.flowDetails.flow.breakStatus === 'NotMet' && <React.Fragment>
                            <br/><br/><UI.Message
                                appearance={`warning`}
                            >Filters parameters were not met and items were not synced. If necessary review logs and your filters selections.</UI.Message>
                        </React.Fragment>}
                    </React.Fragment>}
            />
            {this.props.flowDetails.flow.flowMode && <UI.LabelValue
                className={`label-value`}
                label={`Mode: `}
                value={
                    <span
                        className={`flow-mode ${this.props.flowDetails.flow.flowMode.toLowerCase()}`}>{this.props.flowDetails.flow.flowMode}</span>}/>}
            <UI.LabelValue
                className={`label-value`}
                label={`Triggered By `}
                value={<ArtifactVersionPreviewLink
                    lookUpData={
                        {
                            itemId: this.props.flowDetails.flow.triggeredByDescription,
                            suiteId: this.props.flowDetails.runDetails.suiteId,
                            side: this.props.flowDetails.flow.triggerSide
                        }
                    }
                />}
            />
            {this.props.flowDetails.flow.syncedWithItemId && <UI.LabelValue
                className={`label-value`}
                label={`Synced With `}
                value={<ArtifactVersionPreviewLink
                    appName={this.props.flowDetails.flow.syncedWithAppName}
                    lookUpData={
                        {
                            itemId: this.props.flowDetails.flow.syncedWithItemId,
                            suiteId: this.props.flowDetails.runDetails.suiteId,
                            side: getOppositeSide(this.props.flowDetails.flow.triggerSide)
                        }
                    }
                />}
            />}

            {this.props.flowDetails.flow.subItemsData && <SubItems
                mode={SubItemsMode.All}
                performedFlow={this.props.flowDetails.flow as any as PerformedFlow}
                subItemsData={this.props.flowDetails.flow.subItemsData}
            />}
            {/* <UI.LabelValue className={`label-value`} label={`Persisted Artifacts: `} value={ this.props.flowDetails.flow.persistedArtifacts + '' } /> */}
        </div>
    }

    private getErrorDetails(): string | undefined {
        const maxLengthToShow = 600;
        if (this.props.flowDetails.flow.failureReason && this.props.flowDetails.flow.failureReason.length > maxLengthToShow) {
            return this.props.flowDetails.flow.failureReason?.substring(0, maxLengthToShow) + '...';
        }
        return this.props.flowDetails.flow.failureReason;
    }
}
