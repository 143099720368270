import React from 'react';

type Props = {
}

export class MarginTop extends React.Component<Props> {
    render() {
        return <div className={`margin-top`}>
            {this.props.children}
        </div>;
    }
}
