import React from 'react';
import {UI} from 'src/engrator-core';
import {SmartIntTrigger} from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {SoftwareName} from '../../software-name';
import {fetchProjects, ShotgridProjectDTO} from './rest-api';
import {DropdownOption} from '../../../engrator-core/ui';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {
    isLoading: boolean;
    projects?: DropdownOption[];
    error?: any;
};

export class ShotgridSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false
        };
        this.smartIntTrigger = {
            app: SoftwareName.Shotgrid,
            artifactName: '',
            className: '',
            configuration: {
                query: '',
            },
            connectionId: this.props.connectionId,
        };
        this.onDataChanged();
    }

    async componentDidMount() {
        await this.setState({isLoading: true});
        try {
            const results = await fetchProjects(this.props.connectionId);
            const projects: DropdownOption[] = results.map(s => ({
                value: `${s.id}`,
                label: s.name,
                object: s
            }));
            this.setState({isLoading: false, projects});
        } catch (error) {
            this.setState({isLoading: false, error});
        }
    }

    render() {
        return <React.Fragment>
            { this.state.error && <UI.Message appearance={'error-message'} message={ this.state.error } /> }
            {this.state.projects && <UI.FormGroup
                label={`Project`}
                isRequired={false}
            >
                <UI.Dropdown
                    options={this.state.projects}
                    onChange={(value: string, obj: ShotgridProjectDTO) => this.setProject(obj)}
                />
            </UI.FormGroup>}
            {this.state.isLoading && <UI.Loader visible={true} appearance={'darkgray'}/>}
        </React.Fragment>;
    }

    private setProject(project: ShotgridProjectDTO) {
        this.smartIntTrigger.configuration.project = project;
        this.smartIntTrigger.artifactName = 'task';
        this.props.onConfigured(this.smartIntTrigger!);
        this.onDataChanged();
    }

    private onDataChanged() {
        this.props.onConfigured(this.smartIntTrigger!);
        this.forceUpdate();
    }
}
