import React from 'react';
import { UI } from 'src/engrator-core';
import { DefaultMapping, DefaultMappingMode } from './default-mappping.type';

type Props = {
    defaultMapping: DefaultMapping;
};

type State = {
    defaultMappingMode: string;
};

export class DefaultMappingDisplay extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            defaultMappingMode: this.props.defaultMapping.mode === DefaultMappingMode.LabelFromOtherSide ? 'Use label from other side' : this.props.defaultMapping.mode === DefaultMappingMode.ValueFromOtherSide ? 'Use value from other side' : this.props.defaultMapping.mode
        }
    }

    render() {
        return <div>
            <UI.LabelValue label={`Mode: `} value={this.state.defaultMappingMode}/>
            {this.props.defaultMapping.value && <UI.LabelValue
                label={`Value: `}
                value={`${this.props.defaultMapping.label} (${this.props.defaultMapping.value})`}
            />}
        </div>;
    }
}
