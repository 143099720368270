import React from 'react';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { UI } from 'src/engrator-core';
import { SoftwareName } from '../../software-name';
import { GitHubStepContext, GitHubStepContextData } from '../steps/common';
import {SuiteType} from '../../../generic/integrations/suite-type';

type Props = {
    connectionId: number;
    integrationType?: SuiteType;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class GitHubSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.GitHub,
            artifactName: '',
            className: '',
            configuration: {
                repository: undefined,
                query: ''
            },
            connectionId: this.props.connectionId
        };
        if (this.props.integrationType === SuiteType.Git) {
            this.onGitIntegrationBuilt();
        }
    }

    render() {
        if (this.props.integrationType === SuiteType.Git) {
            return <></>;
        }
        return <React.Fragment>
            {!this.smartIntTrigger.configuration.project && <GitHubStepContext
                connectionId={this.props.connectionId}
                onDataChanged={this.onDataChanged.bind(this)}
            />}
            {this.smartIntTrigger.configuration.repository && <UI.FormGroup>
                <UI.LabelValue
                    label={`Repository`}
                    value={this.smartIntTrigger.configuration.repository.name}
                />
            </UI.FormGroup>
            }
            {/*<UI.FormGroup*/}
            {/*    label={`Additional Query`}*/}
            {/*    description={`You can provide additional query params which will be appended to the url generated when searching for issues in Asana`}*/}
            {/*>*/}
            {/*    <UI.Input label={``} onChange={this.onJqlInputChanged.bind(this)}/>*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private onDataChanged(data: GitHubStepContextData) {
        if (data.repository) {
            this.smartIntTrigger.configuration.repository = data.repository;
            this.smartIntTrigger.artifactName = 'issue';
            this.props.onConfigured(this.smartIntTrigger!);
            this.forceUpdate();
        }
    }

    private onGitIntegrationBuilt(): void {
        this.smartIntTrigger.artifactName = 'gitrepository';
        this.props.onConfigured(this.smartIntTrigger!);
        this.forceUpdate();
    }
}
