import React from 'react';
import {PropertyDataType, PropertyDefinition} from '../../../../generic/artifacts';
import {UI} from 'src/engrator-core';
import {DropdownOption} from '../../../../engrator-core/ui';

type Props = {
    closeHandler: (object?: DropdownOption) => void;
};
type State = {
    property: PropertyDefinition;
}

export class AddPropertyManually extends React.Component<Props, State> {
    private options: DropdownOption[] = [
        {label: 'Text', value: PropertyDataType.String},
        {label: 'Single choice list', value: PropertyDataType.IdLabel},
    ];

    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            property: {
                id: '',
                name: '',
                dataType: PropertyDataType.String
            }
        };
    }

    render() {
        return <div className={`modal persistence-loader confirmation-modal`}>
            <div className={`box wide`}>
                <h3>Add field manually</h3>
                <UI.Form>
                    <UI.Message appearance={'info'}>
                        If the field you’re looking for is not available for mapping, that means it’s not on the create screen, or is not supported by GetInt. Try adding it manually here.
                    </UI.Message>
                    <UI.FormGroup
                        label={`Id`}
                        isRequired={true}
                    >
                        <UI.Input
                            placeholder={`Id`}
                            onChange={(newValue: string) => this.changeProperty('id', newValue)}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Name`}
                        isRequired={true}
                    >
                        <UI.Input
                            placeholder={`Name`}
                            onChange={(newValue: string) => this.changeProperty('name', newValue)}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Data type`}
                        isRequired={true}
                    >
                        <UI.Dropdown
                            disableEmptyOption={true}
                            options={this.options}
                            onChange={(newValue: any) => this.changeDataType('dataType', newValue)}
                        />
                    </UI.FormGroup>
                    <UI.ButtonsBar primary={
                        <UI.Button
                            disabled={ !this.isValidForm() }
                            onClick={ () => this.addClicked() }
                            text={`Add`}
                        />
                    } secondary={[
                        <UI.Button appearance={'secondary'} onClick={ () => this.closeClicked() } text={`Close`} />
                    ]} />
                </UI.Form>
            </div>
        </div>;
    }

    private isValidForm(): boolean {
        return !!(this.state.property.id && this.state.property.name && this.state.property.dataType);
    }

    private addClicked() {
        const object = {
            'value': this.state.property.id,
            'label': this.state.property.name,
            'object': {
                'id': this.state.property.id,
                'name': this.state.property.name,
                'dataType': this.state.property.dataType,
                'options': {
                    'selectable': this.state.property.dataType === PropertyDataType.IdLabel,
                    'internalType': '',
                    'internalFieldId': '',
                    'readonly': false,
                    'additionalInfo': ''
                },
                'internalType': '',
                'internalFieldId': '',
                'finalFieldId': 'assignee'
            }
        };
        this.props.closeHandler(object);
    }

    private closeClicked() {
        this.props.closeHandler();
    }

    private changeProperty(property: 'id' | 'name', newValue: string) {
        const definition = this.state.property;
        definition[property] = newValue;
        this.setState({property: definition});
    }

    private changeDataType(property: 'dataType', newValue: PropertyDataType) {
        const definition = this.state.property;
        definition[property] = newValue;
        this.setState({property: definition});
    }
}
