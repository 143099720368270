import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';
import {GitHubStepContext, GitHubStepContextData, GitHubStepContextDisplay} from '../common';

import {GitHubCreateItemConfiguration} from './github-create-item-configuration';

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class GitHubCreateItemNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: GitHubCreateItemConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as GitHubCreateItemConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: '', newValue) => {
            // this.onConfigurationChanged();
        });
    }

    render() {
        return <React.Fragment>
            { !this.configuration.context && <GitHubStepContext 
                connectionId={ this.props.context.connectionId } 
                onDataChanged={ this.stepContextDataChanged.bind(this) }
            /> }
            { this.configuration.context && <GitHubStepContextDisplay context={ this.configuration.context }  /> }
        </React.Fragment>
    }

    private stepContextDataChanged(contextData: GitHubStepContextData, isValid: boolean) {
        if (isValid) {
            this.configuration.context = contextData;
            this.props.showArtifactBuilder(ArtifactDirection.In);
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }
}
