import {axiosWrapper} from 'src/engrator-core';
import {UserActivityAction} from '../system/user-activity';

const ENDPOINT_URL = '/user-activity/trial-data';

export type TrialData = {
    isSaas: boolean;
    saas: boolean;
    onTrial: boolean;
    linkToBuy: string;
    daysLeft: number;
    contactSales: boolean;
};

export type UsageCheck = {
    shouldAcceptTerms: boolean;
    isExpired: boolean;
}

export function contactSales(email: string, message: string): Promise<void> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                axiosWrapper.post(`${ENDPOINT_URL}/sales`, {email, message})
                    .then(() => {
                        resolve();
                    })
                    .catch(error => reject(error));
            } catch (error) {
                reject(error);
            }
        }, 300);
    });
}

export function getTrailData(): Promise<TrialData> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                axiosWrapper.get(`${ENDPOINT_URL}`)
                    .then((data: any) => {
                        resolve(data as TrialData);
                    })
                    .catch(error => reject(error));
            } catch (error) {
                reject(error);
            }
        }, 300);
    });
}


export function shouldAcceptTerms(): Promise<UsageCheck> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`/user-activity/should-accept-terms`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    })
}

export function hasCompletedAction(action: UserActivityAction): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`/user-activity/completed/${ action }`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    })
}

export function acceptTerms(): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`/user-activity/accept-terms`)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    })
}

