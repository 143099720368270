import React from 'react';

import './SoftwareList.css';
import {SoftwareLogotype} from './software-logotype';

type SoftwareListProps = {
    onSelect: any;
    selectedSoftware: string,
    list: Array<string>
};

export default class SoftwareList extends React.Component<SoftwareListProps> {
    constructor(props: SoftwareListProps) {
        super(props);
    }

    render() {
        return <div className="software-list">
            { this.props.list.sort().map((software, idx) => 
                <div 
                    className={ (software === this.props.selectedSoftware) ? 'selected': ''} 
                    key={idx} 
                    onClick={ () => this.props.onSelect(software) }>
                    <SoftwareLogotype softwareName={ software } />
                </div>
            )}
        </div>
    }
}