import React, {Component, useEffect} from 'react';
import { UI } from 'src/engrator-core';
import { FormError } from '../../../engrator-core/form-error.type';
import { Footer } from '../../footer';

import { fetchIntegrationThreads, IntegrationThreadInfo } from './rest-api';
import {useGlobalState} from '../../global-store';
import {getSystemVerticalMenu} from '../system-vertical-menu';
import {MainMenuItem} from '../../global-store/create-global-state';

type State = {
    isLoading: boolean;
    error?: FormError;
    threads: IntegrationThreadInfo[];
};

class SystemIntegrationThreadsPageCmp extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: true,
            threads: []
        };
    }

    componentDidMount(): void {
        fetchIntegrationThreads()
            .then((threads) => {
                this.setState({ isLoading: false, threads });
            });
    }

    render() {
        return (
            <UI.Page>
                { this.state.isLoading && <UI.Loader visible={true} appearance={'darkgray'}/>}
                { !this.state.isLoading && <UI.TableGrid
                    data={ this.state.threads?.map((thread) => [
                        thread.id, thread.name, <UI.EntityStatus status={ thread.status } />
                    ]) }
                    headers={ ['Id', 'Name', 'Status'] }
                /> }
                <Footer/>
            </UI.Page>
        );
    }
}

export function SystemIntegrationThreadsPage() {
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    const [, setMenu] = useGlobalState('menu');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.SystemSettings);
        setMenu(getSystemVerticalMenu());
    }, []);
    return <SystemIntegrationThreadsPageCmp />
}