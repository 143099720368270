// import theme from "prism-react-renderer/themes/*";
import React, {Component, Fragment} from 'react';
import Highlight, {defaultProps, Language, PrismTheme} from 'prism-react-renderer';
import Editor from 'react-simple-code-editor';
// import { Editor as MonacoEditor } from '@monaco-editor/react';

// Original: https://github.com/dracula/visual-studio-code
// Converted automatically using ./tools/themeFromVsCode
const theme: PrismTheme = {
    plain: {
        color: '#9CDCFE',
        backgroundColor: '#1E1E1E'
    },
    styles: [{
        types: ['prolog'],
        style: {
            color: 'rgb(0, 0, 128)'
        }
    }, {
        types: ['comment'],
        style: {
            color: 'rgb(106, 153, 85)'
        }
    }, {
        types: ['builtin', 'changed', 'keyword'],
        style: {
            color: 'rgb(86, 156, 214)'
        }
    }, {
        types: ['number', 'inserted'],
        style: {
            color: 'rgb(181, 206, 168)'
        }
    }, {
        types: ['constant'],
        style: {
            color: 'rgb(100, 102, 149)'
        }
    }, {
        types: ['attr-name', 'variable'],
        style: {
            color: 'rgb(156, 220, 254)'
        }
    }, {
        types: ['deleted', 'string', 'attr-value'],
        style: {
            color: 'rgb(206, 145, 120)'
        }
    }, {
        types: ['selector'],
        style: {
            color: 'rgb(215, 186, 125)'
        }
    }, {
        // Fix tag color
        types: ['tag'],
        style: {
            color: 'rgb(78, 201, 176)'
        }
    }, {
        // Fix tag color for HTML
        types: ['tag'],
        languages: ['markup'],
        style: {
            color: 'rgb(86, 156, 214)'
        }
    }, {
        types: ['punctuation', 'operator'],
        style: {
            color: 'rgb(212, 212, 212)'
        }
    }, {
        // Fix punctuation color for HTML
        types: ['punctuation'],
        languages: ['markup'],
        style: {
            color: '#808080'
        }
    }, {
        types: ['function'],
        style: {
            color: 'rgb(220, 220, 170)'
        }
    }, {
        types: ['class-name'],
        style: {
            color: 'rgb(78, 201, 176)'
        }
    }, {
        types: ['char'],
        style: {
            color: 'rgb(209, 105, 105)'
        }
    }]
};

const styles = {
    root: {
        boxSizing: 'border-box',
        fontFamily: '"Dank Mono", "Fira Code", monospace',
        ...theme.plain
    } as React.CSSProperties
}

type EditorProps = {
    onChange: (newValue: string) => void;
    defaultValue: string;
    language?: Language;
}

export class CodeEditor extends Component<EditorProps> {
    state = {code: ''};

    constructor(props: EditorProps) {
        super(props);
        this.state = {code: this.props.defaultValue};
    }

    onValueChange = (code: any) => {
        this.setState({code});
        this.props.onChange(code);
    }

    highlight = (code: any) => (
        <Highlight {...defaultProps} theme={theme} code={code} language={ this.props.language || 'javascript' }>
            {({className, style, tokens, getLineProps, getTokenProps}) => (
                <Fragment>
                    {tokens.map((line, i) => (
                        <div {...getLineProps({line, key: i})}>
                            {line.map((token, key) => <span {...getTokenProps({token, key})} />)}
                        </div>
                    ))}
                </Fragment>
            )}
        </Highlight>
    )

    render() {
        return (
            <React.Fragment>
                {/*<MonacoEditor*/}
                {/*    defaultLanguage={'javascript'}*/}
                {/*    defaultValue={ this.state.code }*/}
                {/*    onChange={ (value, event) => this.onValueChange(value) }*/}
                {/*/>*/}
                <Editor
                    value={this.state.code}
                    onValueChange={this.onValueChange}
                    highlight={this.highlight}
                    padding={10}
                    placeholder={`Write custom JavaScript code`}
                    style={styles.root}
                />
            </React.Fragment>
        )
    }
}
