import React from 'react';
import {
    createEmptyFilters,
    IntegrationFilters,
    isAnyFilterUsed
} from './rest-api';
import {DropdownFilter, InputFilter} from '../../../../reporting/filters';
import {DropdownOption} from '../../../../reporting/filters/dropdown-option.type';
import {EntityStatusValue} from '../../../../../engrator-core/ui';
import { UI } from 'src/engrator-core';
import { SoftwareName } from 'src/software';

type Props = {
    onFiltersChanged: (newFiltersValues: IntegrationFilters) => void;
    defaultFilters: IntegrationFilters;
    groups: string[];
    owners: string[];
    allAppsOnPage: DropdownOption[];
};

type State = {
    groups: string[];
    filters: IntegrationFilters;
    owners: string[];
};

const STATUSES: DropdownOption[] = [{
    label: 'Disabled',
    value: 'Disabled',
    isSelected: false
}, {
    label: 'Enabled',
    value: 'Enabled',
    isSelected: false
}];

export class IntegrationsListFiltering extends React.Component<Props, State> {
    private nameTimeout?: NodeJS.Timeout;
    constructor(props: Props) {
        super(props);
        this.state = {
            filters: this.props.defaultFilters,
            groups: this.props.groups,
            owners: this.props.owners
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(this.props.defaultFilters) !== JSON.stringify(prevProps.defaultFilters)) {
            this.setState({ filters: this.props.defaultFilters });
        }
    }

    render() {
        if (!this.state.groups || this.state.groups.length === 1) {
            return <React.Fragment/>
        }
        return <div className="filtering-container">
            <InputFilter
                filterChangeHandler={ (filter) => this.onNameFilterChanged(filter['query']) }
                title={`Search by name`}
                placeholder={`Search by name`}
                defaultValue={ this.state.filters.query }
            />
            <DropdownFilter
                isSingleSelect={true}
                defaultValues={{ selectedValues: this.state.filters.selectedGroupName }}
                title={`Group`}
                options={ this.state.groups.map(g => ({
                    value: g,
                    label: g,
                    isSelected: false
                }))}
                listenForChanges={ true }
                filterChangeHandler={(filter) => this.onSelectedHandler(filter['selectedValues'])}
            />
            <DropdownFilter
                isSingleSelect={ true }
                filterChangeHandler={(filter) => this.onStatusFilterChanged(filter['selectedValues'][0])}
                title={`Status`}
                options={ STATUSES }
                listenForChanges={ true }
                defaultValues={{ selectedValues: (this.state.filters.selectedStatus) ? [this.state.filters.selectedStatus] : [] }}
            />
            <DropdownFilter
                isSingleSelect={ true }
                defaultValues={{ selectedValues: this.state.filters.selectedOwner }}
                title={`Owner`}
                options={ this.state.owners.map(o => ({
                    value: o,
                    label: o,
                    isSelected: false
                }))}
                listenForChanges={ true }
                filterChangeHandler={(filter) => this.onOwnerFilterChanged(filter['selectedValues'])}
            />
            <div className={`filtering-last-container`}>
                {this.props.allAppsOnPage.length > 0 && <DropdownFilter
                    isSingleSelect={ false }
                    filterChangeHandler={(filter) => this.onAppsFilterChanged(filter['selectedValues'])}
                    title={`Apps`}
                    options={ this.props.allAppsOnPage }
                    listenForChanges={ true }
                    defaultValues={{ selectedValues: (this.state.filters.selectedApps) ? this.state.filters.selectedApps.split(',') : [] }}
                    onClearHandler={ () => this.onAppsFilterChanged([])}
                />}
                { isAnyFilterUsed(this.state.filters) && <UI.Button
                    text={`Clear`}
                    appearance={'link-inline'}
                    onClick={ () => this.clearFilters() }
                /> }
            </div>
        </div>
    }

    private async clearFilters() {
        await this.setState({ filters: createEmptyFilters() });
        this.props.onFiltersChanged(this.state.filters);
    }

    private async onSelectedHandler(groupName: string) {
        const filters = this.state.filters;
        filters.selectedGroupName = groupName;
        await this.setState({ filters })
        this.props.onFiltersChanged(filters);
    }

    private async onStatusFilterChanged(newStatus?: EntityStatusValue) {
        const filters = this.state.filters;
        filters.selectedStatus = newStatus;
        await this.setState({ filters })
        this.props.onFiltersChanged(filters);
    }

    private async onOwnerFilterChanged(owner: string) {
        const filters = this.state.filters;
        filters.selectedOwner = owner;
        await this.setState({ filters })
        this.props.onFiltersChanged(filters);
    }

    private async onAppsFilterChanged(newApp?: SoftwareName[]) {
        const filters = this.state.filters;
        filters.selectedApps = newApp?.join(',');
        await this.setState({ filters })
        this.props.onFiltersChanged(filters);
    }

    private async onNameFilterChanged(query: string) {
        if (this.nameTimeout) {
            clearInterval(this.nameTimeout);
        }
        this.nameTimeout = setTimeout(() => {
            const filters = this.state.filters;
            filters.query = query;
            this.setState({ filters }, () => {
                this.props.onFiltersChanged(filters);
            });
        }, 1000);
    }
}
