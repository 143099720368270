import React from 'react';
import {EntityFormHelper, UI} from 'src/engrator-core';
import {DropdownOption} from '../../../engrator-core/ui';
import {StepArtifact} from '../../../generic/software/step';
import {FormError} from '../../../engrator-core/form-error.type';
import {CreateCommentsAction} from './create-comments-action.type';
import {CreateCommentsConfiguration} from './create-comments-configuration.type';

type Props = {
    availableArtifacts: StepArtifact[];
    formError?: FormError;
    onConfigurationChanged: (newConfiguration: CreateCommentsConfiguration) => void;
};
type State = {};


export class CreateCommentsStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: CreateCommentsConfiguration;
    private actionOptions: DropdownOption[];
    private artifactsOptions: DropdownOption[];

    constructor(props: Props) {
        super(props);
        this.configuration = {
            targetArtifact: '',
            predefinedBody: ''
        };
        this.actionOptions = [
            // { label: 'Using comments from other artifact', value: CreateCommentsAction.OtherArtifacts },
            { label: 'Create one comment with defined body', value: CreateCommentsAction.Predefined }
        ];
        this.artifactsOptions = this.props.availableArtifacts.map(artifact => ({ label: artifact.getName(), value: artifact.getName() }));
        console.log(this.props.availableArtifacts);
        this.entityFormHelper = this.getEntityFormHelper();
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                description={``}
                isRequired={ true }
                label={`Select artifact for which you want to create comment(s)`}
                error={ this.props.formError?.targetArtifact }
            >
                <UI.Dropdown options={ this.artifactsOptions } onChange={ this.entityFormHelper.onChangeHandler('targetArtifact')} />
            </UI.FormGroup>
            { this.configuration.targetArtifact && <UI.FormGroup
                description={``}
                isRequired={ true }
                label={`How to create comment(s)`}
                error={ this.props.formError?.action }
            >
                <UI.Dropdown options={ this.actionOptions } onChange={ this.entityFormHelper.onChangeHandler('action')} />
            </UI.FormGroup>
            }
            { this.configuration.action === CreateCommentsAction.Predefined && <UI.FormGroup
                description={``}
                isRequired={ true }
                error={ this.props.formError?.content }
                label={`Provide comment content`}
            >
                <UI.Input onChange={ this.entityFormHelper.onChangeHandler('predefinedBody')} />
            </UI.FormGroup>
            }
        </React.Fragment>;
    }

    private getEntityFormHelper(): EntityFormHelper {
        return new EntityFormHelper((propertyName: 'targetArtifact' | 'action' | 'predefinedBody', newValue) => {
            this.configuration[propertyName] = newValue;
            if (propertyName === 'targetArtifact' || propertyName === 'action') {
                this.configuration['predefinedBody'] = '';
            }
            if (propertyName === 'targetArtifact') {
                delete this.configuration['action'];
            }
            this.props.onConfigurationChanged(this.configuration);
            this.forceUpdate();
        });
    }
}