import React from 'react';
import { UI } from 'src/engrator-core';
import { createTenant } from '../rest-api';

type Props = {
    onTenantCreated: () => void;
    close: () => void;
};
type State = {
    error?: any;
    tenantInfo?: any;
    isCreating: boolean;
};

export class TenantsCreate extends React.Component<Props, State> {
    private data = {
        tenantName: '',
        tenantUniqueId: '',
        jiraUrl: '',
        preferredApps: '',
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            isCreating: false
        };
    }

    render() {
        return <div className="persistence-loader engagement-popup">
            <div className={`box wide less-padding left-aligned`}>
                {this.state.tenantInfo && <UI.Form
                    errors={[]}
                >
                    <UI.LabelValue
                        label="Tenant name"
                        value={this.state.tenantInfo.tenantName}
                    />
                    <UI.ButtonsBar
                        primary={<React.Fragment/>}
                        secondary={[
                            <UI.Button text="Close" appearance="secondary" onClick={() => this.close()}/>,
                        ]}
                    />
                </UI.Form>}
                {!this.state.tenantInfo &&
				<UI.Form
				    errors={[]}
				>
				    {this.state.error && <UI.Message appearance="error">
				        {this.state.error.message || this.state.error.errorMessage}
				    </UI.Message>}
				    <UI.FormSection
				        label={`Create Workspace`}
				    >
				        <UI.FormGroup
				            isRequired={true}
				            label="Name"
				        >
				            <UI.Input placeholder="Name" onChange={(newValue) => {
				                this.data.tenantName = newValue;
				            }}/>
				        </UI.FormGroup>
				        <UI.FormGroup
				            isRequired={true}
				            label="Unique Id"
				        >
				            <UI.Input placeholder="Unique Id" onChange={(newValue) => {
				                this.data.tenantUniqueId = newValue;
				            }}/>
				        </UI.FormGroup>
				        <UI.FormGroup
				            label="Jira Url"
				        >
				            <UI.Input placeholder="Jira Url" onChange={(newValue) => {
				                this.data.jiraUrl = newValue;
				            }}/>
				        </UI.FormGroup>
				        <UI.FormGroup
				            label="Preferred Apps"
				        >
				            <UI.Input placeholder="Preferred Apps" onChange={(newValue) => {
				                this.data.preferredApps = newValue;
				            }}/>
				        </UI.FormGroup>
				    </UI.FormSection>
				    <UI.ButtonsBar
				        primary={<UI.Button isLoading={this.state.isCreating} text="Create"
				            onClick={() => this.createClicked()}/>}
				        secondary={[
				            <UI.Button text="Close" appearance="secondary" onClick={() => this.close()}/>,
				        ]}
				    />
				</UI.Form>
                }
            </div>
        </div>;
    }

    private close() {
        if (this.state.tenantInfo) {
            this.props.onTenantCreated();
        }
        this.setState({ tenantInfo: undefined }, () => {
            this.props.close();
        });
    }

    private createClicked() {
        this.setState({ error: undefined, isCreating: true }, () => {
            createTenant(this.data)
                .then((tenantInfo) => {
                    this.setState({ tenantInfo, isCreating: false })
                })
                .catch((error) => {
                    this.setState({ error, isCreating: false });
                });
        });
    }
}
