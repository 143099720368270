import React, {Component, useEffect} from 'react';
import {UI} from 'src/engrator-core';
import { FormError } from '../../../engrator-core/form-error.type';
import {Footer} from '../../footer';

import { fetchLicenseInfo, setNewLicense, SystemLicenseInfo } from './rest-api';
import {useGlobalState} from '../../global-store';
import {getSystemVerticalMenu} from '../system-vertical-menu';
import {MainMenuItem} from '../../global-store/create-global-state';

type State = {
    isLoading: boolean;
    showLicenseEdit: boolean;
    license?: SystemLicenseInfo;
    newLicense: string,
    error?: FormError;
};

class SystemLicensePageCmp extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            showLicenseEdit: false,
            isLoading: true,
            newLicense: ''
        };
    }

    componentDidMount(): void {
        this.fetchLicense();
    }

    render() {
        return (
            <UI.Page>
                {this.state.isLoading && <UI.Loader visible={true} appearance={'darkgray'}/>}
                {this.state.license && <UI.Tile>
                    { !this.state.showLicenseEdit && <UI.Form>
                        <UI.LabelValue
                            label={`Is trial: `}
                            value={`${this.state.license.trial}`}
                        />
                        <UI.LabelValue
                            label={`Expiration date:`}
                            value={`${this.state.license.expirationDate}`}
                        />
                        <UI.LabelValue
                            label={`Apps urls to sync:`}
                            value={`${this.state.license.urls}`}
                        />
                    </UI.Form> }
                    <br/>{ !this.state.showLicenseEdit && <UI.Button onClick={ this.showLicenseEditForm.bind(this) } text={`Edit`} /> }
                    { this.state.showLicenseEdit && <UI.Form>
                        { this.state.error && <UI.Message appearance={'error'} message={ this.state.error.message } /> }
                        <UI.FormGroup
                            label={`New license`}
                            description={`Paste into below field new license received from getint.io`}
                        >
                            <UI.Textarea onChange={ (newLicense) => this.setState({ newLicense })} rows={ 10 } />
                        </UI.FormGroup>
                        <UI.ButtonsBar primary={
                            <UI.Button onClick={ () => this.submitNewLicense() } text={`Submit`} />
                        } secondary={[<UI.Button appearance={'secondary'} onClick={ () => this.cancelNewLicense() } text={`Cancel`} />]}/>
                    </UI.Form> }
                </UI.Tile>}
                <Footer/>
            </UI.Page>
        )
    }

    cancelNewLicense(): void {
        this.setState({ newLicense: '', showLicenseEdit: false });
    }

    fetchLicense(): void {
        fetchLicenseInfo()
            .then((license) => {
                this.setState({isLoading: false, license});
            }).catch((err) => {
                this.setState({isLoading: false});
            });
    }

    submitNewLicense(): void {
        setNewLicense(this.state.newLicense).then(() => {
            this.setState({ newLicense: '', showLicenseEdit: false }, () => {
                this.fetchLicense();
            });
        }).catch((formError) => {
            this.setState({ error: formError });
        });
    }

    showLicenseEditForm() {
        this.setState({ showLicenseEdit: true });
    }
}

export function SystemLicensePage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.SystemSettings);
        setMenu(getSystemVerticalMenu());
    }, []);
    return <SystemLicensePageCmp />
}