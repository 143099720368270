import React from 'react';
import { EntityFormHelper, UI } from 'src/engrator-core';
import { DropdownOption } from 'src/engrator-core/ui';
import { NewPipelineStepComponentProps } from 'src/generic';
import { ArtifactDirection } from 'src/generic/artifacts';
import { SoftwareName } from 'src/software/software-name';

import { GetintArchiverUpdateItemConfiguration } from './monday-update-item-configuration';

type Props = {} & NewPipelineStepComponentProps;
type State = {};

export class GetintArchiverUpdateItemNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: GetintArchiverUpdateItemConfiguration;
    private artifactsOptions: DropdownOption[];

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as GetintArchiverUpdateItemConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'targetArtifact', newValue, additionalArgument?: any) => {
            if (propertyName === 'targetArtifact' && additionalArgument) {
                this.configuration[propertyName] = newValue;
            }
            this.configurationChanged();
        });
        this.artifactsOptions = this.props.context
            .artifactsFactory
            .getAvailableOutArtifactsForSoftware(SoftwareName.GetintArchiver)
            .map(artifact => ({label: artifact.getName(), value: artifact.getName(), object: artifact}));
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                description={``}
                isRequired={true}
                label={`Select artifact to update`}
                error={this.props.formError?.targetArtifact}
            >
                <UI.Dropdown options={this.artifactsOptions} onChange={this.entityFormHelper.onChangeHandler('targetArtifact')}/>
            </UI.FormGroup>
        </React.Fragment>;
    }

    private configurationChanged() {
        if (this.configuration.targetArtifact) {
            const artifactConnectionId = this.props.context.artifactsFactory.getConnectionIdForArtifact(this.configuration.targetArtifact);
            if (artifactConnectionId) {
                this.props.context.connectionId = artifactConnectionId;
            }
        }
        if (this.configuration.targetArtifact) {
            this.props.showArtifactBuilder(ArtifactDirection.In);
        }
        this.forceUpdate();
    }
}
