import { axiosWrapper } from 'src/engrator-core';
import {SoftwareName} from '../../../software';

const ENDPOINT_URL = '/integration/git-repositories';

export type GitRepositoryListItemDto = {
    id: string;
    integrationId: string;
    name: string;
    url: string;
    syncedAt: string;
    app: SoftwareName;
    branchesCount: number;
    commitsCount: number;
    pullRequestsCount: number;
}

export type Integration = {
    id: number;
    name: string;
};

export function fetchGitRepositories(integrationId?: number): Promise<GitRepositoryListItemDto[]> {
    return new Promise((resolve, reject) => {
        try {
            const url = ENDPOINT_URL + '?integrationId=' + (integrationId || '');
            axiosWrapper.get(url)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}