import React, {Component, useEffect} from 'react';

import { UI } from 'src/engrator-core';
import { fetchRunDetails } from './rest-api';
import { RunDetails } from './run-details';
import RunPerformedFlowsTable from './run-performed-flows-table';
import { IntegrationEditLink } from '../../integration/integration-edit-link';
import {useGlobalState} from '../../global-store';
import {MainMenuItem} from '../../global-store/create-global-state';
import {getRunVerticalMenu} from './run-vertical-menu';
import {SuiteType} from '../../../generic/integrations/suite-type';


type RunResultsPageState = {
    runDetails?: RunDetails;
    isLoading: boolean;
};
type Props = {
    runId: number;
}

class RunResultsPageCmp extends Component<Props, RunResultsPageState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            runDetails: undefined,
            isLoading: true
        };
    }

    componentDidMount(): void {
        fetchRunDetails(this.props.runId)
            .then((runDetails: RunDetails) => {
                this.setState({ isLoading: false, runDetails: runDetails });
            });
    }

    render() {
        return (
            <UI.Page className={`run-results`}>
                { this.state.runDetails !== undefined && <React.Fragment>
                    <div className={`header-tile`}>
                        <UI.Tile>
                            <div>
                                <UI.ExecutionStatus status={this.state.runDetails.status}/>
                                <p><IntegrationEditLink id={this.state.runDetails?.suiteId}
                                    runId={this.state.runDetails?.id}
                                    name={this.state.runDetails?.pipelineName}
                                    suiteType={this.state.runDetails?.suiteType}/></p>
                                <UI.ExecutionTime
                                    startTime={this.state.runDetails.formattedStartTime}
                                    formattedExecutionTime={this.state.runDetails.formattedExecutionTime}/>
                            </div>
                            <div>
                                <UI.LabelValue
                                    className={`label-value`}
                                    label={`Found Triggers: `}
                                    value={ this.state.runDetails.foundTriggers.toString() }
                                />
                                <UI.LabelValue
                                    className={`label-value`}
                                    label={`Performed Syncs: `}
                                    value={ `${this.state.runDetails.performedFlows} (${this.state.runDetails.failedFlows})` }
                                />
                                <UI.LabelValue
                                    className={`label-value`}
                                    label={`Synced Items: `}
                                    value={ this.state.runDetails.persistedArtifacts.toString() }
                                />
                                { this.state.runDetails.runMode && <span className={`flow-mode run-mode ${ this.state.runDetails.runMode.toLowerCase() }`}>{ this.state.runDetails.runMode }</span> }
                            </div>
                            <div>
                                { this.state.runDetails.failureReason && <div className={`failure-reason`}>
                                    <UI.Message appearance="error">
                                        <p>Error details:</p>{ this.state.runDetails.failureReason }
                                    </UI.Message>
                                </div> }
                            </div>
                        </UI.Tile>
                    </div>
                    <br/>
                    { this.state.runDetails.suiteType !== SuiteType.Git && <RunPerformedFlowsTable
                        isSharedWithSupport={ this.state.runDetails.isSharedWithSupport }
                        canShareSupportAccess={ this.state.runDetails.canShareSupportAccess }
                        runId={ this.state.runDetails.id }
                        suiteId={ this.state.runDetails.suiteId }
                    /> }
                </React.Fragment> }
            </UI.Page>
        )
    }
}

export function RunResultsPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Reporting);
        const id = props.match.params.id;
        getRunVerticalMenu(props.match.params.id).then(menu => {
            setMenu(menu);
        });
    }, []);
    return <RunResultsPageCmp
        runId={ props.match.params.id }
    />
}