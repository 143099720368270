import React from 'react';

import {UI} from 'src/engrator-core';
import {FilterChangeHandler} from './filter-change-handler.type';

type Props = {
    filterChangeHandler: FilterChangeHandler,
    title: string,
    placeholder: string,
    defaultValue: string
};

type State = {
};

export class InputFilter extends React.Component<Props, State> {
    private filterValue = {
        query: '',
    };

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
    }

    filterChangeHandler(inputId: 'query', newValue: string) {
        this.filterValue[inputId] = newValue;
        this.props.filterChangeHandler(this.filterValue);
    }

    render() {
        return (<div className={`search-filter filter`}>
            {/*<label className={`title`}>{ this.props.title }</label>*/}
            <UI.Input
                defaultValue={ this.props.defaultValue } placeholder={ this.props.placeholder } type={'text'} onChange={ (newValue) => this.filterChangeHandler('query', newValue) } />
        </div>)
    }
}