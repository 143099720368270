import React, {Component} from 'react';

import {UI} from 'src/engrator-core';
import {CanBeUsedForAutoCreationResponse, OptionsAutoCreationResolverConfig} from '../../rest-api';
import {DropdownOption} from '../../../../../engrator-core/ui';

type Props = {
    updateConfigDataHandler: (data: any) => Promise<void>;
    resolverConfig: OptionsAutoCreationResolverConfig;
    canBeUsedConfig: CanBeUsedForAutoCreationResponse;
};
type State = {
    pathsOptions?: DropdownOption[];
    defaultIteration?: AzureIterationPath;
}
type AzureIterationPath = {
    id: string;
    name: string;
};

const PATH_NAME: string = 'path';

export class AzureIterationPathResolverConfig extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.setBoardsOptions();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    }

    private async setBoardsOptions(): Promise<void> {
        const { canBeUsedConfig, resolverConfig } = this.props;
        const pathConfigOptions = canBeUsedConfig.configurationOptions.find(o => o.name === PATH_NAME);
        let defaultIteration: AzureIterationPath | undefined = undefined;
        const pathsOptions = pathConfigOptions?.options.map(b => ({
            label: b.name,
            value: b.id,
            object: b,
        }));
        if (pathConfigOptions?.options.length) {
            const { id, name } = pathConfigOptions.options[0];
            defaultIteration = { id, name };
        }
        if (resolverConfig.configData) {
            const { pathId, pathName } = resolverConfig.configData;
            defaultIteration = { id: pathId, name: pathName };
        }
        await this.setState({ defaultIteration, pathsOptions });
        await this.updateConfigData();
    }

    render() {
        return (
            <UI.FormGroup
                label={`Parent iteration path`}
                description={`Provide iteration path name under which new iterations should be created.`}
            >
                {this.state.pathsOptions && <UI.Dropdown
                    defaultValue={this.state.defaultIteration?.id}
                    options={this.state.pathsOptions}
                    onChange={(value: string, path: AzureIterationPath) => this.setPath(path)}
                    placeholder={`Default Jira Board`}
                />}
            </UI.FormGroup>
        );
    }

    private async updateConfigData() {
        const data = {
            pathId: this.state.defaultIteration?.id,
            pathName: this.state.defaultIteration?.name,
        };
        await this.props.updateConfigDataHandler(data);
    }

    private async setPath(path: AzureIterationPath): Promise<void> {
        await this.setState({ defaultIteration: path });
        await this.updateConfigData();
    }
}
