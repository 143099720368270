import React from 'react';
import { Side } from 'src/generic';
import { UI } from 'src/engrator-core';
import { Assets } from './functions';

type Props = {
    side: Side;
    assets: Assets;
    findSchema: (key: Side, id: string) => string;
    handleAcceptAql: (side: Side) => Promise<void>;
    setState: React.Dispatch<React.SetStateAction<any>>;
};

export default function AssetConfiguration({ assets, findSchema, handleAcceptAql, side, setState }: Props) {
    const handleDropdown = (newValue: string): void => {
        const newAssets = { ...assets };
        newAssets[side].selectedSchema = newValue;
        setState({ assets: newAssets });
    };

    const handleInput = (newValue: string): void => {
        const newAssets = { ...assets };
        newAssets[side].currentAql = newValue;
        setState({ assets: newAssets });
    };

    const handleCheckbox = (): void => {
        const newAssets = { ...assets };
        newAssets[side].canStoreMultiple = !newAssets[side].canStoreMultiple;
        setState({ assets: newAssets });
    };

    return (
        <>
            <div className={side === Side.Left ? 'left' : 'right'}>
                <h3 className={`assets-side-title`}>Configure {side === Side.Left ? 'left' : 'right'} assets field</h3>
                {!assets[side].schemasLoading && !assets[side].accepted && (
                    <UI.Dropdown
                        className={`margin-bottom-md`}
                        label={`Select Schema`}
                        options={assets[side].schemas}
                        onChange={(newValue: string) => handleDropdown(newValue)}
                        defaultValue={assets[side].selectedSchema}
                    />
                )}
                {assets[side].selectedSchema && !assets[side].accepted && !assets[side].schemasLoading && (
                    <div className={`assets-form`}>
                        <UI.Input
                            label={`AQL`}
                            onChange={(val: string) => handleInput(val)}
                            defaultValue={assets[side].currentAql}
                        />
                        <UI.Checkbox
                            label={`Field can store multiple objects`}
                            checkedValue={'true'}
                            uncheckedValue={'false'}
                            defaultValue={assets[side].canStoreMultiple.toString()}
                            onChange={() => handleCheckbox()}
                        />
                        <UI.Button
                            text={'Accept'}
                            onClick={() => handleAcceptAql!(side)}
                            isLoading={assets[side].acceptedLoading}
                        />
                        {assets[side].aqlError && (
                            <UI.Message
                                appearance="error-message"
                                message={assets[side].aqlError}
                                classes={`margin-top-md`}
                            />
                        )}
                    </div>
                )}
                {assets[side].accepted && (
                    <>
                        <p>
                            <span className={`font-bold`}>Selected schema: </span>
                            {findSchema!(side, assets[side].selectedSchema!)}
                        </p>
                        <p>
                            <span className={`font-bold`}>AQL:</span> {assets[side].currentAql}
                        </p>
                        <p>
                            <span className={`font-bold`}>Field can store multiple objects: </span>
                            {assets[side].canStoreMultiple.toString()}
                        </p>
                    </>
                )}
                {assets[side].schemasLoading && (
                    <p>
                        <UI.Loader visible={true} appearance={'darkgray'} /> Loading Schemas
                    </p>
                )}
            </div>
        </>
    );
}
