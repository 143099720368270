import React from 'react';

import { UI } from '..';
import {Icon20} from './icon';

type Props = {
    status: string
};

export class ExecutionStatus extends React.Component<Props> {
    render() {
        return <span className={`engrator-ui-execution-time`} data-sel={ this.props.status }>
            { (this.props.status === 'Success') &&  <UI.Icon icon={'status-success'}/> }
            { (this.props.status === 'Failure') &&  <UI.Icon icon={'status-failure'}/> }
            { (this.props.status === 'Started') &&  <UI.Icon icon20={ Icon20.StatusStarted}/> }
        </span>;
    }
}