import React from 'react';
import {Component} from 'react';
import {UI} from 'src/engrator-core';
import {Header} from '../../../engrator-core/ui';
import {ArchivedItemComment} from './archived-item-comment';
import {ArchivedItemData, ArchivedItemShortData, getItemData} from '../rest-api';
import {ArchivedItemAttachments} from './archived-item-attachments';
import {ArtifactVersionFieldValue} from '../../reporting/artifacts-versions/artifact-version-field-value';

type State = {
    itemData?: ArchivedItemData;
};
type Props = {
    shortItemData: ArchivedItemShortData;
    close: () => void;
};

export class ArchivedItemDetails extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        getItemData(this.props.shortItemData)
            .then(response => {
                this.setState({ itemData: response.itemData })
            });
    }

    render() {
        return <React.Fragment>
            <UI.FullScreenModal
                className={`large`}
                header={`Item ${ this.props.shortItemData.itemId }`}
                closeBtnHandler={() => this.props.close()}
                primaryBtnHandler={() => Promise.resolve(true)}
                showPrimaryBtn={false}
            >
                { !this.state.itemData && <UI.Loader visible={ true } appearance={'darkgray'} /> }
                { this.state.itemData && <React.Fragment>
                    <div className={`archiver-item-details`}>
                        <div className={`belongs-to`}>{ this.state.itemData.app } / { this.state.itemData.belongsTo }
                            <span className={`timestamp`}>#{ this.state.itemData.id }, { this.state.itemData.archivedAt }</span>
                        </div>
                        <Header title={ this.state.itemData.title }/>
                        {/*<label>Description</label>*/}
                        <div className={`html-content`} dangerouslySetInnerHTML={{ __html: this.state.itemData.description } } />
                        <table className={`items-data`}>
                            { this.state.itemData.fields.map((field, index) =>
                                <tr key={index}>
                                    <td>{ field.name }</td>
                                    <td>
                                        <ArtifactVersionFieldValue fieldName={ field.name } fieldValue={ field.value } />
                                    </td>
                                </tr>,
                            )}
                        </table>
                        <ArchivedItemAttachments attachments={ this.state.itemData.attachments } />
                        <h2 className={`comments-header`}>Comments ({ this.state.itemData.comments.length })</h2>
                        { this.state.itemData.comments.map((comment, index) => <ArchivedItemComment
                            comment={ comment }
                            key={index}
                        /> )}
                    </div>
                </React.Fragment> }
            </UI.FullScreenModal>
        </React.Fragment>;
    }
}