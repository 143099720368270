import {axiosWrapper, FieldError} from 'src/engrator-core';

import { ENDPOINT_URL } from '../rest-api';
import {JiraSearchIssuesJqlValidation} from './components/jira-search-issues-jql-validation';
import {JiraStepKey} from '../jira-step-key.type';

const SEARCH_ISSUES_ENDPOINT_URL = ENDPOINT_URL + '/steps/' + JiraStepKey.JiraSearchItems;

export const validateJql = (configuration: any): Promise<JiraSearchIssuesJqlValidation> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(SEARCH_ISSUES_ENDPOINT_URL + '/validate-jql', configuration)
                .then((data: any) => {
                    resolve({
                        totalNumberOfFoundIssues: data.totalNumberOfFoundIssues,
                        exceptionMessage: data.exceptionMessage,
                        jql: data.jql
                    });
                })
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const validateStepConfiguration = (configuration: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(SEARCH_ISSUES_ENDPOINT_URL + '/pre-add', configuration)
                .then((data: any) => resolve())
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};