import React from 'react';

import { Button } from './button';
import {Icon} from './icon';

type ButtonProps = {
    onClick: any,
    isLoading?: boolean,
    disabled?: boolean,
    alignment?: 'right'
};

export class DeleteButton extends React.Component<ButtonProps> {
    render() {
        return <React.Fragment>
            <Button
                onClick={ this.props.onClick }
                appearance={'danger'}
                className={'delete'}
                icon={ <Icon icon={'trash'} /> }
                text={``}/>
        </React.Fragment>
    }
}
