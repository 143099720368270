import React from 'react';
import { UI } from 'src/engrator-core';
import {Icon20} from '../../../../engrator-core/ui';

type Props = {
};
type State = {};

export class SharedMappingFlag extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <div className={`shared-mapping-flag warning-flag`}>
            <UI.Icon icon20={ Icon20.SharedMapping } />
            <div className={`text-popup`}>
                This field mapping is converted to Shared Mapping.
            </div>
        </div>;
    }
}
