import {FeatureFlag, isFeatureFlagEnabled} from '../top-navigation/rest-api';
import {UI} from '../../engrator-core';
import {Icon20} from '../../engrator-core/ui';
import React from 'react';
import {MoreOptions} from '../../designer/top-bar/more';


type Props = {
    blink?: boolean;
};
type State = {
    isEnabled?: boolean;
    isOpened: boolean;
    blink?: boolean;
};

export class HelpCallButton extends React.Component<Props, State> {
    private wrapperRef: any;

    constructor(props: Props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.state = {
            blink: this.props.blink,
            isOpened: false
        };
    }

    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    async componentDidMount() {
        const isEnabled = await isFeatureFlagEnabled(FeatureFlag.SHOW_BOOK_DEMO);
        this.setState({ isEnabled });
        if (isEnabled) {
            document.addEventListener('mousedown', this.documentClick.bind(this));
            document.addEventListener('touchstart', this.documentClick.bind(this));
        }
        if (this.state.blink) {
            // Disable blinking after 10s
            setTimeout(() => {
                this.setState({ blink: false });
            }, 10 * 1000);
        }
    }

    componentWillUnmount() {
        if (this.state.isEnabled) {
            document.removeEventListener('mousedown', this.documentClick);
            document.removeEventListener('touchstart', this.documentClick);
        }
    }

    documentClick(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpened) {
            this.setState({isOpened: false}, () => {
                // this.props.onCloseHandler();
            });
        }
    }

    private switchPopup() {
        this.setState({isOpened: !this.state.isOpened});
    }

    render() {
        if (!this.state.isEnabled) {
            return <React.Fragment />;
        }
        return <div className={`help-btn-container`} ref={this.setWrapperRef}>
            <UI.Button
                onClick={() => this.setState({ isOpened: !this.state.isOpened })}
                btnClassName={`noticeable-btn ${( this.state.blink) ? 'anim-blinking-fade anim-10-times' : '' }`}
                text={`Get Help`}
                icon={ <UI.Icon icon20={ Icon20.LifeRing} /> }
            />
            { this.state.isOpened && <div className={`context-menu-popup`}>
                <ul>
                    <li data-sel="settings" onClick={ () => this.optionClicked(MoreOptions.Settings)}>
                        <a href={'https://docs.getint.io'} target={'_blank'}>Documentation</a>
                    </li>
                    <li onClick={ () => this.optionClicked(MoreOptions.Advanced)}>
                        <a href={'https://getint.io/submit-support-ticket'} target={'_blank'}>Submit support ticket</a>
                    </li>
                    <li onClick={ () => this.optionClicked(MoreOptions.Share)}>
                        <a href={'https://getint.io/onboarding-call'} target={'_blank'}>Book free onboarding call</a>
                    </li>
                    <li onClick={ () => this.optionClicked(MoreOptions.Share)}>
                        <a href={'https://getint.io/support-call'} target={'_blank'}>Book troubleshooting call</a>
                    </li>
                </ul>
            </div> }
        </div>
    }

    private optionClicked(option: MoreOptions): void {
        // this.props.optionSelectedHandler(option);
        this.setState({ isOpened: false });
    }
}