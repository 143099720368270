import {FiltersSet, ItemFilter} from '../visual/filtering/rest-api';
import {Side} from '../../../../generic';
import {GitRepository} from '../git/git-repository';
import {JiraProjectDTO} from '../../../../software/jira/steps/common/jira-project-dto.type';

export type SmartIntTrigger = {
    app: string;
    artifactName: string;
    connectionId: number;
    className: string;
    configuration: any | GitConfiguration;
    itemsFilters?: { filters: ItemFilter[], sets: FiltersSet }
};

export type GitConfiguration = {
    jiraProjects: JiraProjectDTO[];
    repositories: GitRepository[];
};

export type SmartIntTriggersPair = {
    [Side.Left]: SmartIntTrigger;
    [Side.Right]: SmartIntTrigger;
}

export function createGitConfiguration(): GitConfiguration {
    return {
        jiraProjects: [],
        repositories: []
    };
}