import React from 'react';
import { Loader } from './loader';

type LoaderProps = {
    text?: string;
};

export class CenteredLoader extends React.Component<LoaderProps> {
    render() {
        return <div className={`ui-centered-loader`}>
            <Loader visible={ true } appearance="darkgray" />
            { this.props.text && <p>{ this.props.text }</p> }
        </div>
    }
}