import {UI} from '../../../engrator-core';
import {Component} from 'react';
import React from 'react';

export enum ArchiverTabBarItems {
    ArchivedItems = 'Archived Items',
    Configuration = 'Configuration'
}

type State = {
    isLoadingCSV: boolean;
};
type Props = {
    allItemsCount?: number;
    currentItem: ArchiverTabBarItems;
    exportToCSVHandler?: (onlyIds?: boolean) => Promise<void>;
};

export class ArchiverTabBar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isLoadingCSV: false };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.allItemsCount !== prevProps.allItemsCount) {
            this.forceUpdate();
        }
    }

    private exportClicked() {
        this.setState({ isLoadingCSV: true }, () => {
            this.props.exportToCSVHandler!()
                .then(() => this.setState({ isLoadingCSV: false }))
                .catch(() => this.setState({ isLoadingCSV: false }));
        })
    }

    private exportIdsClicked() {
        this.setState({ isLoadingCSV: true }, () => {
            this.props.exportToCSVHandler!(true)
                .then(() => this.setState({ isLoadingCSV: false }))
                .catch(() => this.setState({ isLoadingCSV: false }));
        })
    }

    render() {
        return <React.Fragment>
            <UI.TabBar
            >
                {/*<UI.TabButton*/}
                {/*    text={ ArchiverTabBarItems.ArchivedItems }*/}
                {/*    path={`/app/archiver`}*/}
                {/*    isSelected={ this.props.currentItem === ArchiverTabBarItems.ArchivedItems }*/}
                {/*/>*/}
                {/*<UI.TabButton
                    text={ ArchiverTabBarItems.Configuration }
                    path={`/app/archiver/configuration`}
                    isSelected={ this.props.currentItem === ArchiverTabBarItems.Configuration }
                />*/}
                { this.props.exportToCSVHandler && <UI.ButtonsBar
                    secondary={[
                        (this.props.allItemsCount) ? <span className={`all-items-count`}>All Items: { this.props.allItemsCount }</span> : '',
                        <UI.Button
                            disabled={ this.state.isLoadingCSV }
                            isLoading={ this.state.isLoadingCSV }
                            appearance={'secondary'}
                            onClick={ () => this.exportIdsClicked() }
                            text={`Export IDs`}
                        />,
                        <UI.Button
                            disabled={ this.state.isLoadingCSV }
                            isLoading={ this.state.isLoadingCSV }
                            appearance={'secondary'}
                            onClick={ () => this.exportClicked() }
                            text={`Export to CSV`}
                        />
                    ]}
                /> }
            </UI.TabBar>
        </React.Fragment>;
    }
}