import * as Steps from './software/step';
import * as Artifacts from './artifacts';
import * as ArtifactBuilder from './artifact-builder';
import * as Debugging from './debugging';
import * as Conditions from './conditions';
import { StepSnippet } from './step-snippet';
import {AppSmartIntsSupport} from '../app/integration/smartints/app-smart-ints-support';

export * from './steps-factory';
export * from './integrations/steped-integration';
export * from './integrations/smart-int-integration';
export * from './integrations/generic-integration';
export * from './events-bus';
export { Steps, Artifacts, StepSnippet, Debugging, ArtifactBuilder, Conditions }
export * from './pipeline-step/pipeline-step';
export interface Software {
    getName(): string;
    getID(): any;
    getConnectionFactory(): any;
    getAvailableSteps(): Array<object>;
    getSmartIntsSupport(): AppSmartIntsSupport | null;
}

export enum Side {
    'Left' = 'Left',
    'Right' = 'Right'
}

export function getOppositeSide(side: Side): Side {
    return (side == Side.Left) ? Side.Right : Side.Left;
}