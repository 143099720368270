import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';
import {InthubNewErrorConfiguration} from './inthub-new-error-configuration';

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class IntHubNewErrorNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: InthubNewErrorConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as InthubNewErrorConfiguration;
        this.configuration.onlyNewChanges = true;
        this.entityFormHelper = new EntityFormHelper((propertyName: '', newValue) => {

        });
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>

        </React.Fragment>
    }

}