import React from 'react';
import { UI } from 'src/engrator-core';
import {getScriptForExport} from './rest-api';
import {FormError} from '../../../../../engrator-core/form-error.type';
import {ConnectionModel} from '../../connection-model';

type Props = {
    connection: ConnectionModel;
    closeHandler: () => void;
};

type State = {
    isLoading: boolean;
    script?: string;
    encryptionKey: string;
    isKeyVisible: boolean;
    error?: FormError;
};

export class ConnectionExport extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isKeyVisible: false,
            isLoading: false,
            encryptionKey: ''
        };
    }

    render() {
        return <UI.FullScreenModal
            showPrimaryBtn={ false }
            header={`Export connection`}
            primaryBtnHandler={ () => Promise.resolve(true) }
            closeBtnHandler={ () => this.closeClicked() }
        >
            { this.state.isLoading && <UI.Loader appearance={'darkgray'} visible={true} /> }
            { this.state.error && <UI.Message appearance={'error'}>
                <p>Something went wrong: { this.state.error.message }</p>
            </UI.Message> }
            { !this.state.script && <React.Fragment>
                <UI.Form>
                    <UI.FormGroup
                        label={`Encryption key`}
                        isRequired={ true }
                        description={`Key that will be used to encrypt credentials data`}>
                        <UI.Input
                            type={ (this.state.isKeyVisible) ? 'text' : 'password' }
                            onChange={(newValue) => this.setState({ encryptionKey: newValue })}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup>
                        <UI.Checkbox
                            label={`Show encryption key`}
                            onChange={ () => this.switchKeyVisibility() }
                            checkedValue={`true`}
                            uncheckedValue={`false`}
                        />
                    </UI.FormGroup>
                    <UI.ButtonsBar
                        primary={<UI.Button
                            text={`Apply`}
                            disabled={ this.state.isLoading || !this.state.encryptionKey}
                            onClick={ () => this.applyClicked() }
                        /> }
                    />
                </UI.Form>
            </React.Fragment> }
            { this.state.script && <div>
                <p>Please copy below internal script</p>
                <textarea rows={20}>{ JSON.stringify(this.state.script, undefined, 2) }</textarea>
            </div> }
        </UI.FullScreenModal>
    }

    private async switchKeyVisibility() {
        await this.setState({ isKeyVisible: !this.state.isKeyVisible });
    }

    private async applyClicked() {
        if (this.state.encryptionKey) {
            try {
                await this.setState({ isLoading: true });
                const script = await getScriptForExport(this.props.connection.id, this.state.encryptionKey);
                await this.setState({ isLoading: false, script });
            } catch (error) {
                this.setState({ isLoading: false, error });
            }
        }
    }
    private closeClicked() {
        this.props.closeHandler();
    }
}
