import React from 'react';

import {UI} from 'src/engrator-core';
import {FilterChangeHandler} from './filter-change-handler.type';

type Props = {
    filterChangeHandler: FilterChangeHandler,
    title: string,
    defaultValue?: any
};

type State = {
    showPopUp: boolean;
};

export class NumberRangeFilter extends React.Component<Props, State> {
    private wrapperRef: any;

    private filterValue = {
        from: '',
        to: ''
    };

    constructor(props: Props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.state = { showPopUp: false };
        this.filterValue.from = this.props.defaultValue?.from || '';
        this.filterValue.to = this.props.defaultValue?.to || '';
    }

    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.documentClick);
        document.removeEventListener('touchstart', this.documentClick);
    }

    documentClick(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.showPopUp) {
            this.setState({ showPopUp: false});
        }
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.documentClick.bind(this));
        document.addEventListener('touchstart', this.documentClick.bind(this));
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            this.forceUpdate();
        }
    }

    filterChangeHandler(inputId: 'from' | 'to', newValue: string) {
        this.filterValue[inputId] = newValue;
        this.props.filterChangeHandler(this.filterValue);
        this.forceUpdate();
    }

    render() {
        return <div className={`search-filter number-range`} ref={ this.setWrapperRef }>
            <label
                onClick={ () => this.setState({ showPopUp: true })}
                className={`title ${ (this.getTitle() !== this.props.title) ? 'selected' : ''} `}>{ this.getTitle() }</label>
            { this.state.showPopUp && <div className={`popup`}>
                <UI.Input defaultValue={ (this.props.defaultValue) ? this.props.defaultValue['from'] : '' } type={'number'} placeholder={`From`} onChange={ (newValue) => this.filterChangeHandler('from', newValue) } />
                <UI.Input defaultValue={ (this.props.defaultValue) ? this.props.defaultValue['to'] : '' } type={'number'} placeholder={`To`} onChange={ (newValue) => this.filterChangeHandler('to', newValue) } />
                <div className={`break`}></div>
                <UI.ButtonsBar
                    primary={ <UI.Button appearance={`link-inline`} onClick={() => this.clear() } text={`clear`} /> }
                    // secondary={ [<UI.Button appearance={`link-inline`} onClick={() => this.unselectAll() } text={`clear`} /> ]}
                />
            </div> }
        </div>
    }

    private clear() {
        if (this.props.defaultValue) {
            this.props.defaultValue['from'] = '';
            this.props.defaultValue['to'] = '';
        }
        this.filterChangeHandler('from', '');
        this.filterChangeHandler('to', '');
    }

    private getTitle() {
        const parts = [];
        const emptyValue = '';
        if (this.filterValue.from) {
            parts.push(this.filterValue.from);
        } else {
            parts.push(emptyValue);
        }
        if (this.filterValue.to) {
            parts.push(this.filterValue.to);
        } else {
            parts.push(emptyValue);
        }
        if (parts[0] === emptyValue && parts[1] === emptyValue) {
            return this.props.title;
        }
        return parts.join(' - ');
    }
}