
export enum MigrationUsageState {
    FeatureEnabled = 'FeatureEnabled',
    HasRunsLeft = 'HasRunsLeft',
    Invalid = 'Invalid'
}

export type MigrationUsageInfoResponse = {
    state: MigrationUsageState;
    message: string;
};
