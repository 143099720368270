import * as UI from './ui';
import {FormError} from './form-error.type';
export * from './axios-wrapper';
export * from './field-error';
export * from './auth-token';
export { UI };
export * from './entity-status-enum.type';

export class EntityFormHelper {
    private onFormChange: (propertyName: string, newValue: any, additionalArgument?: any) => void;
    formError: FormError = {};

    constructor(onFormChange: (propertyName: any, newValue: any) => void) {
        this.onFormChange = onFormChange;
    }
    public onChangeHandler(propertyName: string) {
        return (newValue: any, additionalArgument?: any) => {
            this.onFormChange(propertyName, newValue, additionalArgument);
        };
    }
}

export function switchBodyScroll(enableScroll: boolean): void {
    if (enableScroll) {
        document.body.className = document.body.className.replace('noscroll', '');
    } else {
        if (document.body.className.indexOf('noscroll') === -1) {
            document.body.className += ' noscroll';
        }
    }
}

export function copyObject<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}
