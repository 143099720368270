import { axiosWrapper } from 'src/engrator-core';
import { createIntegrationListItem } from './integration-list-item';
import {EntityStatusValue, DropdownOption} from '../../../../../engrator-core/ui';
import { SoftwareName } from 'src/software';

const ENDPOINT_URL = '/integration/suites?suiteTypes=SmartInt&suiteTypes=Git';
export const MAIN_GROUP_NAME = '/';
const LOCAL_STORAGE_FILTER_NAME = 'integrationsFilters';

export type IntegrationFilters = {
    selectedGroupName: string;
    query: string;
    selectedStatus?: EntityStatusValue;
    groups?: string[];
    selectedApps?: string;
    selectedOwner: string | [];
};

export type FetchData = {
    headers: string[];
    data: any;
}

export function createEmptyFilters(): IntegrationFilters {
    return {
        query: '',
        selectedGroupName: MAIN_GROUP_NAME,
        selectedStatus: undefined,
        selectedApps: undefined,
        selectedOwner: []
    };
}

export function filterApps(apps: SoftwareName[]): DropdownOption[] {
    const set = new Set<SoftwareName>();
    apps.forEach((el: SoftwareName) => set.add(el));
    const result = Array.from(set);
    result.sort();
    return result.map(el => ({label: el, value: el, isSelected: false}));
}

type IntegrationsResponse = {
    integrations: any[];
    groups: any[];
    showLicenseColumn: boolean;
    showInfoAboutJiraJiraLicensing: boolean;
    owners: string[];
};

export function isAnyFilterUsed(filters: IntegrationFilters): boolean {
    return (
        !! filters.query
        || !!filters.selectedStatus
        || filters.selectedGroupName !== MAIN_GROUP_NAME
        || !!filters.selectedApps
        || filters.selectedOwner?.length > 0
    );
}

export const fetchAllSmartIntsIntegrations = (filters?: IntegrationFilters): Promise<{ headers: string[], data: any, showInfoAboutJiraJiraLicensing: boolean, groups: string[], owners: string[] }> => {
    return new Promise((resolve, reject) => {
        try {
            if (filters) {
                persistFiltersInStorage(filters);
            }
            const url = (filters)
                ? ENDPOINT_URL + '&groupName=' + (filters.selectedGroupName || '') + '&status=' + (filters.selectedStatus || '') + '&query=' + (filters.query || '') + '&apps=' + (filters.selectedApps || '') + '&owner=' + (filters.selectedOwner || '')
                : ENDPOINT_URL;
            axiosWrapper.get(url)
                .then((data: any) => {
                    const response = (data as IntegrationsResponse);
                    const headers: string[] = ['ID', 'Name', 'Apps', 'Status', 'Last run', (response.showLicenseColumn) ? 'License' : '', 'Owner', 'Actions']
                        .filter(column => !!column);
                    resolve({
                        headers,
                        data: response.integrations.map(item => createIntegrationListItem(item, response.showLicenseColumn)),
                        showInfoAboutJiraJiraLicensing: response.showInfoAboutJiraJiraLicensing,
                        groups: response.groups,
                        owners: response.owners
                    });
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchIntegrationsGroups = (): Promise<string[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL)
                .then((data: any) => {
                    resolve(data.groups);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function persistFiltersInStorage(filters: IntegrationFilters): void {
    localStorage.setItem(getFiltersStorageName(), JSON.stringify(filters));
}

export function restoreFiltersFromStorage(): IntegrationFilters {
    const filtersString = localStorage.getItem(getFiltersStorageName());
    if (!filtersString) {
        return createEmptyFilters();
    }
    return JSON.parse(filtersString);
}

function getFiltersStorageName() {
    return LOCAL_STORAGE_FILTER_NAME + '_' + getIntegrationsListUrl();
}

function getIntegrationsListUrl(): string {
    if (window.location.href.indexOf('smart-ints') >= 0) {
        return window.location.href.split('smart-ints')[0];
    }
    return '';
}