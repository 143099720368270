import {PropertyDataType, PropertyDefinition} from '../../../../../../generic/artifacts';

export enum DefaultMappingMode {
    Value = 'Value',
    Skip = 'Skip',
    ValueFromOtherSide = 'ValueFromOtherSide',
    LabelFromOtherSide = 'LabelFromOtherSide',
    Predefined = 'Predefined'
}

export type DefaultMapping = {
    value: string;
    label: string;
    mode: DefaultMappingMode;
};

export function getDefaultUseLabelMapping(): DefaultMapping {
    return { label: '', mode: DefaultMappingMode.LabelFromOtherSide, value: '' };
}

export function getDefaultSkipMapping(): DefaultMapping {
    return { label: '', mode: DefaultMappingMode.Skip, value: '' };
}

export function getDefaults(leftProperty?: PropertyDefinition, rightProperty?: PropertyDefinition) {
    const defaults = {
        left: getDefaultSkipMapping(),
        right: getDefaultSkipMapping(),
    };
    // If one property is IdLabel/IdLabelArray (Dropdown) and the other is String field
    // then set USE LABEL FROM OTHER SIDE as default value for a mapping
    if ((leftProperty?.dataType === PropertyDataType.IdLabel || leftProperty?.dataType === PropertyDataType.IdLabelArray)  && rightProperty?.dataType === PropertyDataType.String) {
        defaults.right = getDefaultUseLabelMapping();
    }
    if (leftProperty?.dataType === PropertyDataType.String && (rightProperty?.dataType === PropertyDataType.IdLabel || rightProperty?.dataType === PropertyDataType.IdLabelArray)) {
        defaults.left = getDefaultUseLabelMapping();
    }
    return defaults;
}
