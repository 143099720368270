import {VerticalMenu} from 'src/app/global-store/create-global-state';

export function getCloudSettingsMenu(): VerticalMenu {
    const menu = {
        header: 'System',
        items: [ {
            name: 'License',
            path: '/app/settings/cloud/license'
        }, {
            name: 'Data storage',
            path: '/app/settings/cloud/data-storage'
        }, {
            name: 'Account access',
            path: '/app/settings/cloud/account-access'
        }]
    };
    return menu;
}