import React, {Component} from 'react';
import {UI} from 'src/engrator-core';
import {
    fetchCustomProperties,
    saveSystemCustomProperties,
    SystemCustomProperty,
} from './rest-api';
import {FormError} from '../../../engrator-core/form-error.type';

type State = {
    properties?: SystemCustomProperty[];
    isLoading: boolean;
    isSaving: boolean;
    error?: FormError;
    showAddNewProperty: boolean;
    hasDeletedRow: boolean;
    hasSaved: boolean;
    hasAddedRow: boolean;
    newProperty: {
        name: string;
        value: string;
    }
};

export class SystemCustomProperties extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: false,
            hasDeletedRow: false,
            hasAddedRow: false,
            hasSaved: false,
            isSaving: false,
            showAddNewProperty: false,
            newProperty: {
                name: '',
                value: ''
            }
        };
    }

    async componentDidMount() {
        try {
            await this.setState({isLoading: true});
            const properties = await fetchCustomProperties();
            await this.setState({properties, isLoading: false});
        } catch (error) {
            await this.setState({isLoading: false, error});
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.hasSaved && <UI.Message appearance={'success'}>
                    <p>Custom properties saved successfully.</p>
                </UI.Message>}
                {this.state.properties &&
                <UI.TableGrid headers={['Property Name', 'Value', 'Delete']} data={this.state.properties?.map((property, index) => [
                    <UI.Input
                        onChange={(newValue) => this.changeProperty(property, 'name', newValue)}
                        defaultValue={property.name}
                    />,
                    <UI.Input
                        onChange={(newValue) => this.changeProperty(property, 'value', newValue)}
                        defaultValue={property.value}
                    />,
                    <UI.Icon icon={'trash'} onClick={() => this.deleteProperty(property)}/>
                ]
                ) as any}/>}
                <UI.Form useMaxWidthClass={false}>
                    {this.state.isLoading && <UI.Loader visible={true} appearance={`darkgray`}/>}
                    <UI.FormGroup>
                        {this.state.error && <UI.Message appearance={'error'} message={this.state.error} />}
                        {this.state.properties && <UI.ButtonsBar primary={ <UI.Button
                            onClick={this.saveCustomProperties.bind(this)}
                            disabled={ !this.state.hasAddedRow && !this.state.hasDeletedRow }
                            text={`Save`}
                            isLoading={ this.state.isSaving }
                        />} /> }
                    </UI.FormGroup>
                    { !this.state.showAddNewProperty && <UI.Button
                        onClick={ () => this.setState({ showAddNewProperty: true }) }
                        text={`Add new property`}
                    /> }
                    { this.state.showAddNewProperty && <UI.Form useMaxWidthClass={true}>
                        <UI.FormGroup
                            label={`Add new property`}
                        >
                            <UI.Input
                                defaultValue={this.state.newProperty.name}
                                onChange={this.setNewPropertyName.bind(this)}
                                label={`Name`}
                                placeholder={`Name`}
                            />
                            <UI.Input
                                defaultValue={this.state.newProperty.value}
                                onChange={this.setNewPropertyValue.bind(this)}
                                label={`Value`}
                                placeholder={`Value`}
                            />
                            <br/>
                            <UI.ButtonsBar primary={ <UI.Button
                                appearance={'secondary'}
                                disabled={!this.state.newProperty.name || !this.state.newProperty.value}
                                onClick={this.addNewProperty.bind(this)}
                                text={`Add`}
                            /> } />
                        </UI.FormGroup>
                    </UI.Form> }
                </UI.Form>
            </React.Fragment>
        );
    }

    deleteProperty(property: SystemCustomProperty) {
        const index = this.state.properties!.indexOf(property);
        const properties = this.state.properties;
        properties?.splice(index, 1);
        this.setState({properties, hasDeletedRow: true, hasSaved: false});
    }

    addNewProperty() {
        const properties = this.state.properties;
        properties?.push(this.state.newProperty);
        this.setState({newProperty: {name: '', value: ''}, hasAddedRow: true, hasSaved: false});
    }

    setNewPropertyName(value: string) {
        const newProperty = this.state.newProperty;
        newProperty.name = value;
        this.setState({newProperty});
    }

    async changeProperty(property: SystemCustomProperty, field: 'name' | 'value', value: string) {
        property[field] = value;
        await this.setState({hasAddedRow: true, hasSaved: false});
    }

    setNewPropertyValue(value: string) {
        const newProperty = this.state.newProperty;
        newProperty.value = value;
        this.setState({newProperty});
    }

    async saveCustomProperties() {
        await this.setState({ isSaving: true, hasSaved: false });
        try {
            await saveSystemCustomProperties(this.state.properties!)
            await this.setState({isSaving: false, hasSaved: true, hasAddedRow: false, hasDeletedRow: false});
        } catch (error) {
            await this.setState({error, isSaving: false});
        }
    }
}
