import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper, UI} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';

import {ServiceNowCreateItemConfiguration} from './servicenow-create-item-configuration';
import {ServicenowStepContext, ServicenowStepContextData} from '../common';

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class ServiceNowCreateItemNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: ServiceNowCreateItemConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as ServiceNowCreateItemConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: '', newValue) => {
            this.onConfigurationChanged();
        });
    }

    render() {
        return <React.Fragment>
            { !this.configuration.context && <ServicenowStepContext
                connectionId={ this.props.context.connectionId }
                onDataChanged={ this.stepContextDataChanged.bind(this) }
            /> }
            { this.configuration.context && <UI.LabelValue label={ `Table`} value={ this.configuration.context.table!.label } /> }
        </React.Fragment>
    }

    private stepContextDataChanged(contextData: ServicenowStepContextData, isValid: boolean) {
        if (isValid) {
            this.configuration.context = contextData;
            this.props.showArtifactBuilder(ArtifactDirection.In);
            this.props.showArtifactBuilder(ArtifactDirection.Out);
            this.forceUpdate();
        }
    }

    private onConfigurationChanged() {
    }
}
