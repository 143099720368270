import {VerticalMenu} from 'src/app/global-store/create-global-state';
import {Icon20} from '../../../engrator-core/ui';
import {fetchRunDetails} from '../run-results/rest-api';

export async function getSyncVerticalMenu(runId: number, syncId: number): Promise<VerticalMenu> {
    const runDetails = await fetchRunDetails(runId);
    const menu = {
        header: 'Sync details',
        items: [{
            name: 'Overview',
            path: `/app/reporting/suite-run/${ runId }/flow/${ syncId }/details`
        }, {
            name: 'Logs',
            path: `/app/reporting/suite-run/${ runId }/flow/${ syncId }/logs`
        }, {
            name: 'Run details',
            path: `/app/reporting/suite-run/${ runId }/details`
        }, {
            name: 'Edit integration',
            path: '/app/integration/smart-ints/' + runDetails.suiteId + '/edit',
            icon: Icon20.Edit
        }]
    };
    return menu;
}