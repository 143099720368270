import React, { Component } from 'react';

import {
    IntegrationDesignerContainer
} from 'src/designer';
import { IntegrationDetailsModel } from './integration-details-model';
import { fetchIntegrationDetails } from './rest-api';
import {FieldError, UI} from '../../../../engrator-core';
import {FormError} from '../../../../engrator-core/form-error.type';

type IntegrationDetailsPageState = {
    integration: IntegrationDetailsModel | null;
    error?: FormError;
};

export class IntegrationDetailsPage extends Component<{}, IntegrationDetailsPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            integration: null
        };
    }
    componentDidMount() {
        const id = (this.props as any).match.params.id;
        fetchIntegrationDetails(id).then(integration => {
            this.setState({ integration });
        }).catch((error: FieldError) => {
            const errorMessage = `Integration could not be fetched: ${error.message}`;
            this.setState({error: {['general']: errorMessage}});
        })
    }

    render() {
        if (this.state.error) {
            return <UI.Message appearance={'error'} message={ this.state.error?.general } />;
        }
        return (
            <div>
                { this.state.integration && <IntegrationDesignerContainer
                    integrationData={ this.state.integration }
                /> }
            </div>
        )
    }
}
