import React from 'react';
import {
    SmartIntAttachmentsConfiguration
} from 'src/app/integration/smartints/definition/smart-int-definition-configuration.type';
import { UI } from 'src/engrator-core';
import { SoftwareName } from 'src/software';
import {AppsSupport} from '../../../app/integration/smartints/visual/visual-integration-designer';

type Props = {
    configuration: SmartIntAttachmentsConfiguration;
    appSupport: AppsSupport;
};
type State = {};

export class AttachmentsPublicAttachmentsConfiguration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                label={`Public attachments`}
                description={`Check to sync only public comments attachments`}
            >
                <div className={`flex row two-columns`}>
                    <div>
                        { this.props.appSupport.leftApp === SoftwareName.Jira && <UI.Checkbox
                            label={`${this.props.appSupport.leftApp}`}
                            defaultValue={`${this.props.configuration.onlyPublicCommentsAttachments?.left}`}
                            onChange={ () => this.switchFlag('left') } checkedValue={`true`} uncheckedValue={`false`} /> }
                    </div>
                    <div>
                        { this.props.appSupport.rightApp === SoftwareName.Jira && <UI.Checkbox
                            label={`${this.props.appSupport.rightApp}`}
                            defaultValue={`${this.props.configuration.onlyPublicCommentsAttachments?.right}`}
                            onChange={ () => this.switchFlag('right') } checkedValue={`true`} uncheckedValue={`false`} /> }
                    </div>
                </div>
            </UI.FormGroup>
        </React.Fragment>
    }

    private switchFlag(side: 'left' | 'right') {
        if (!this.props.configuration.onlyPublicCommentsAttachments) {
            this.props.configuration.onlyPublicCommentsAttachments = {
                left: false,
                right: false
            };
        }
        this.props.configuration.onlyPublicCommentsAttachments[side] = !this.props.configuration.onlyPublicCommentsAttachments[side];
    }
}
