import React from 'react';

import {StartGuide} from '../../engagement/start-guide';

type Props = {};
type State = {
    isChatAvailable: boolean;
};

export class CreateIntegrationGuide extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            isChatAvailable: false
        };
    }

    componentDidMount(): void {
        setTimeout(() => {
            if ((window as any).Tawk_API !== undefined && (window as any).Tawk_API.maximize) {
                this.setState({isChatAvailable: true});
            }
        }, 2000);
    }

    render() {
        return <div className={`create-integration-guide`}>
            <h1>Welcome in the Getint integrations, and migrations platform.</h1>
            <StartGuide/>
            {/*<p className={`help-needed`}>*/}
            {/*    If you need help, go to the <a href={`https://docs.getint.io`}>documentation page</a>, schedule a <a*/}
            {/*    href={`https://getint.io/demo?src=app`} target={`blank`}>demo</a> , or open a <a*/}
            {/*    href={`https://getintio.atlassian.net/servicedesk/`}>support ticket</a>*/}
            {/*</p>*/}
            {/*{this.state.isChatAvailable && <UI.Message appearance={'info'}>*/}
            {/*    In case of issues write to us on chat (bottom left window corner)*/}
            {/*</UI.Message>}*/}
            {/*<div className={`create-link`}>*/}
            {/*    <UI.ButtonsBar*/}
            {/*        primary={<UI.NavLink path={`/app/integration/smart-ints/create`}>Create Integration</UI.NavLink>}*/}
            {/*        // secondary={ [*/}
            {/*        //     <UI.NavLink path={`app/integration/smart-ints/create`}>Watch video</UI.NavLink>*/}
            {/*        // ] }*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    }
}
