import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';

import {AzureStepKey} from '../azure-step-key.type';
import {AzureUpdateItemEditStep} from './azure-update-item-edit-step';
import {AzureUpdateItemNewStep} from './azure-update-item-new-step';
import {AzureUpdateItemConfiguration} from './azure-update-item-configuration';

export const AzureUpdateItemPipelineStep: PipelineStep<AzureUpdateItemConfiguration> = {
    addStepComponentClass: AzureUpdateItemNewStep,
    editStepComponentClass: AzureUpdateItemEditStep,
    specification: {
        action: 'Update Item',
        description: 'Update Azure Item',
        key: AzureStepKey.AzureUpdateItem,
        software: SoftwareName.Azure,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AzureUpdateItemConfiguration;
        return `Update item with id='${stepConfiguration.targetArtifact}'`;
    }
}