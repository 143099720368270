import React from 'react';

import {ISoftwareFactory, SoftwareName} from '../../../../software';
import {DropdownOption, Icon20} from '../../../../engrator-core/ui';
import {SmartIntDefinition} from '../definition';
import {SmartIntTrigger} from '../definition/smart-int-trigger.type';
import {createTypeMapping} from './type-mappings/type-mappings-factory';
import {FieldError, UI} from 'src/engrator-core';
import {SmartIntDefinitionTypeMapping} from '../definition/smart-int-definition--type-mapping.type';
import {AppSmartIntsSupport} from '../app-smart-ints-support';
import {SmartIntIntegration} from '../../../../generic';
import {SelectTypes} from './type-selection/select-type-box/select-types';
import {CurvyLines} from './type-mappings/curvy-lines';
import {ItemFiltering} from './filtering';
import {PropertyDefinition} from '../../../../generic/artifacts';
import {autoMapFields, fetchProperties} from './rest-api';
import {FILTER_SET_NAME, FiltersSet} from './filtering/rest-api';
import {AppSelection} from './app-selection/app-selection';
import {TypesMapping} from './type-mappings/types-mapping';
import {RelationshipConfigurationWindow} from './relationships';
import {SmartIntRelationshipConfiguration} from '../definition/smart-int-definition-configuration.type';
import {AppsSyncGuide} from '../../../engagement';
import {PluginsConfigurationComponent} from '../configuration';
import {SmartIntPlugin} from '../configuration/plugins/plugins';
import {FieldsManagerWindow} from './fields-manager/fields-manager-window';
import {AutoBuilderWindow} from './autobuilder';
import {IntegrationContext} from '../pages/integration-context';
import {SuiteType} from '../../../../generic/integrations/suite-type';
import {GitIntegrationConfiguration} from './git/git-integration-configuration';


type State = {
    error?: FieldError;
    isSaving: boolean;
    isMatchingFieldsForTypeMapping?: number;
    isAutoBuilding: boolean;
    isAutoBuildEnabled: boolean;
    itemFilteringContext?: {
        app: SoftwareName,
        side: 'left' | 'right',
        fieldsResolver: Promise<PropertyDefinition[]>
    },
    showWindow: {
        window: 'fields' | 'relationships' | 'plugins' | 'autobuilder' | '',
    }
}

type Props = {
    integration: SmartIntIntegration;
    softwareFactory: ISoftwareFactory;
}

export type AppsSupport = {
    leftApp: SoftwareName,
    left: AppSmartIntsSupport,
    rightApp: SoftwareName,
    right: AppSmartIntsSupport;
};

export class VisualIntegrationDesigner extends React.Component<Props, State> {
    static contextType = IntegrationContext;
    private leftTypeBoxes: DropdownOption[] = [];
    private rightTypeBoxes: DropdownOption[] = [];
    private typesMappings: SmartIntDefinitionTypeMapping[] = [];
    private support?: AppsSupport = undefined;
    private definition: SmartIntDefinition;
    private onResizeFn: any;

    constructor(props: Props) {
        super(props);
        this.state = {isAutoBuildEnabled: false, isAutoBuilding: false, isSaving: false, showWindow: {window: ''}};
        this.definition = this.props.integration.definition;
        if (this.definition.triggers.left.app) {
            this.setApp('left', this.definition.triggers.left.app as SoftwareName, this.definition.triggers.left.connectionId, this.definition.triggers.left);
        }
        if (this.definition.triggers.right.app) {
            this.setApp('right', this.definition.triggers.right.app as SoftwareName, this.definition.triggers.right.connectionId, this.definition.triggers.right);
        }
        this.typesMappings = this.definition.types;
    }

    render() {
        const { getIntegrationType } = this.context;
        const bothAppsConfigured = this.definition.triggers.left.app && this.definition.triggers.right.app;
        return <div className={`SceneContainer steps-list-background`}>
            <div className={`integration-designer ${(!this.isRelationshipsSupported()) ? 'no-relationships' : ''}`}>
                {/*<AppsSyncGuide*/}
                {/*    leftTrigger={this.definition.triggers.left}*/}
                {/*    rightTrigger={this.definition.triggers.right}*/}
                {/*/>*/}

                {/*<ItemFiltering*/}
                {/*    fields={ [{ id: 'title', name: 'Title', dataType: PropertyDataType.IdLabel} as PropertyDefinition] }*/}
                {/*/>*/}
                <div className={`plugins-bar`}>
                    <AppsSyncGuide
                        leftTrigger={this.definition.triggers.left}
                        rightTrigger={this.definition.triggers.right}
                    />
                    { getIntegrationType() !== SuiteType.Git && <React.Fragment>
                        { bothAppsConfigured && this.state.isAutoBuildEnabled &&
                            <UI.Button
                                onClick={() => this.autoBuildClicked()}
                                appearance={'secondary'}
                                icon={<UI.Icon icon20={Icon20.Workflows}/>}
                                text={`Quick Build`}
                            />}
                        {this.isRelationshipsSupported() && <UI.Button
                            onClick={() => this.configureRelationshipsClicked()}
                            appearance={'secondary'}
                            icon={<UI.Icon icon20={Icon20.Relationship}/>}
                            text={`Dependencies`}
                        />}
                        {bothAppsConfigured && <UI.Button
                            onClick={() => this.configurePluginsClicked()}
                            appearance={'secondary'}
                            icon={<UI.Icon icon20={Icon20.Plugin}/>}
                            text={`Plugins`}
                        />}
                        { bothAppsConfigured && <UI.Button
                            onClick={() => this.fieldsManagerClicked()}
                            appearance={'secondary'}
                            icon={<UI.Icon icon20={Icon20.Eye}/>}
                            text={`Fields Manager`}
                        />}
                    </React.Fragment> }
                </div>
                <AppSelection
                    tooltipText={`Connect to the first app (e.g. Jira)`}
                    softwareFactory={this.props.softwareFactory}
                    className={`left`}
                    trigger={this.definition.triggers.left}
                    onAppSelectedHandler={(appName, connectionId, trigger) => this.setApp('left', appName, connectionId, trigger)}
                />
                {this.typesMappings.length > 0 && <div
                    className={`items-filter-icon ${(this.areItemsFiltersDefined(this.definition.triggers.left.itemsFilters?.sets)) ? 'active' : ''}`}
                    onClick={() => this.itemFiltersClicked('left', this.definition.triggers.left)}
                >
                    <UI.Icon icon={'filter'}/>
                </div>}
                <AppSelection
                    tooltipText={`Select the second app for integration or migration`}
                    isLeftSelected={!this.definition.triggers.left?.connectionId}
                    softwareFactory={this.props.softwareFactory}
                    className={`right`}
                    trigger={this.definition.triggers.right}
                    onAppSelectedHandler={(appName, connectionId, trigger) => this.setApp('right', appName, connectionId, trigger)}
                />
                {this.typesMappings.length > 0 && <div
                    className={`items-filter-icon right ${(this.areItemsFiltersDefined(this.definition.triggers.right.itemsFilters?.sets)) ? 'active' : ''}`}
                    onClick={() => this.itemFiltersClicked('right', this.definition.triggers.right)}
                >
                    <UI.Icon icon={'filter'}/>
                </div>}
                <div className={`types-mappings`}>
                    { bothAppsConfigured && getIntegrationType() === SuiteType.Git && <>
                        <GitIntegrationConfiguration
                            gitConfig={ this.props.integration.definition.gitConfiguration }
                            jiraConnectionId={ this.getJiraConnectionId() }
                            otherAppConnectionId={ this.getOtherAppConnectionId() }
                            otherApp={ this.getOtherAppThanJira() }
                        />
                    </> }
                    {this.typesMappings.length > 0 && <CurvyLines typesNumber={this.typesMappings.length}/>}
                    {this.typesMappings.map((mapping, loopIndex, index) => <TypesMapping
                        isBeingAutoMapped={ this.state.isMatchingFieldsForTypeMapping === loopIndex }
                        support={this.support!}
                        mapping={mapping}
                        changeOrderHandler={(direction: 1 | -1) => this.changeTypeMappingsOrder(direction, mapping)}
                        isFirst={this.typesMappings[0] === mapping}
                        isLast={this.typesMappings[this.typesMappings.length - 1] === mapping}
                        deleteMapping={() => this.deleteTypeMapping(mapping)}
                        leftTrigger={this.definition.triggers.left}
                        rightTrigger={this.definition.triggers.right}
                        multiFieldsUsage={this.props.integration.settings.additionalSettings?.multiFieldsUsage || false}
                    />)}
                    {this.support && getIntegrationType() !== SuiteType.Git && <SelectTypes
                        hasOneTypeMapping={this.typesMappings.length > 0}
                        triggers={this.definition.triggers}
                        addTypeHandler={this.addTypeHandler.bind(this)}/>
                    }
                </div>
                {this.support && this.state.showWindow.window === 'relationships' && <RelationshipConfigurationWindow
                    closeHandler={() => this.closeCurrentWindow()}
                    applyHandler={(newConfiguration: SmartIntRelationshipConfiguration) => this.applyRelationshipsConfiguration(newConfiguration)}
                    appSupport={this.support}
                    configuration={this.definition.plugins?.relationships}
                    connectionIds={{
                        left: this.definition.triggers.left.connectionId,
                        right: this.definition.triggers.right.connectionId
                    }}
                />}
                {this.support && this.state.showWindow.window === 'plugins' && <PluginsConfigurationComponent
                    closeHandler={() => this.closeCurrentWindow()}
                    applyHandler={(plugins) => this.setPlugins(plugins)}
                    appSupport={this.support}
                    triggers={this.definition.triggers}
                    plugins={this.definition.plugins?.shared}
                    // configuration={ this.definition.plugins?.relationships }
                    // connectionIds={ { left: this.definition.triggers.left.connectionId, right: this.definition.triggers.right.connectionId } }
                />}
                {this.support && this.state.showWindow.window === 'fields' && <FieldsManagerWindow
                    closeHandler={() => this.closeCurrentWindow()}
                    applyHandler={() => {
                    }}
                    appSupport={this.support}
                    definition={this.definition}
                    multiFieldsUsage={this.props.integration.settings.additionalSettings?.multiFieldsUsage || false}
                />}
                {this.support && this.state.showWindow.window === 'autobuilder' && <AutoBuilderWindow
                    multiFieldsUsage={this.props.integration.settings.additionalSettings?.multiFieldsUsage || false}
                    closeHandler={() => this.closeCurrentWindow()}
                    applyHandler={(result?: SmartIntDefinition) => this.autoBuilderHandler(result)}
                    appSupport={this.support}
                    definition={this.definition}
                />}
                {this.state.itemFilteringContext && <ItemFiltering
                    filters={this.definition.triggers[this.state.itemFilteringContext.side].itemsFilters?.sets}
                    app={this.state.itemFilteringContext.app}
                    side={this.state.itemFilteringContext.side}
                    closeHandler={(filters?: FiltersSet) => this.closeItemFiltering(filters)}
                    fieldsResolver={ () => this.state.itemFilteringContext!.fieldsResolver}
                />}
            </div>
            {/*<MigrationComponent/>*/}
        </div>
    }

    private itemFiltersClicked(side: 'left' | 'right', trigger: SmartIntTrigger) {
        const types = this.typesMappings.map(mapping => fetchProperties(trigger.app as SoftwareName, this.definition.triggers[side], mapping[side]));
        const fieldsResolver = new Promise<PropertyDefinition[]>((resolve, reject) => {
            Promise.all(types)
                .then(result => {
                    const fields: PropertyDefinition[] = [];
                    result.forEach(res => {
                        res.forEach(field => {
                            fields.push(field);
                        })
                    });
                    resolve(fields);
                }).catch(error => reject(error))
        });
        this.setState({
            itemFilteringContext: {
                side, app: trigger.app as SoftwareName, fieldsResolver
            }
        });
    }

    private areItemsFiltersDefined(filtersSet?: FiltersSet) {
        if (!filtersSet) {
            return false;
        }
        return (
            filtersSet[FILTER_SET_NAME.ALL]?.length > 0
            || filtersSet[FILTER_SET_NAME.NEW]?.length > 0
            || filtersSet[FILTER_SET_NAME.UPDATED]?.length > 0
        );
    }

    /**
     * Close item filters modal. If filters provided apply them to trigger filters
     * @param filters
     * @private
     */
    private closeItemFiltering(filters?: FiltersSet) {
        if (filters !== undefined && this.state.itemFilteringContext) {
            this.definition.triggers[this.state.itemFilteringContext.side].itemsFilters = {filters: [], sets: filters};
            // console.log(this.definition.triggers[this.state.itemFilteringContext.side].itemsFilters);
        }
        this.setState({itemFilteringContext: undefined});
    }

    private addTypeHandler(side: 'left' | 'right', option: DropdownOption) {
        if (side === 'left') {
            this.leftTypeBoxes.push(option);
        } else {
            this.rightTypeBoxes.push(option);
        }
        if (this.leftTypeBoxes.length > 0 && this.rightTypeBoxes.length > 0) {
            this.connectTypesFromBothSides();
        }
        this.forceUpdate();
    }

    private deleteTypeMapping(mapping: SmartIntDefinitionTypeMapping) {
        const index = this.typesMappings.indexOf(mapping);
        if (index >= 0) {
            this.typesMappings.splice(index, 1);
            this.forceUpdate();
        }
        this.adjustIntegrationDesignerPlacement();
    }

    private changeTypeMappingsOrder(direction: 1 | -1, mapping: SmartIntDefinitionTypeMapping) {
        const indexOfTypeMapping = this.typesMappings.indexOf(mapping);
        const mappingToSwitch = this.typesMappings[indexOfTypeMapping + direction];
        this.typesMappings[indexOfTypeMapping + direction] = mapping;
        this.typesMappings[indexOfTypeMapping] = mappingToSwitch;
        this.forceUpdate();
    }

    private connectTypesFromBothSides() {
        for (let i = 0; i < this.leftTypeBoxes.length; i++) {
            const typeOnLeftSide = this.leftTypeBoxes[i];
            const typeOnRightSide = this.rightTypeBoxes[i];
            if (typeOnRightSide === undefined) {
                break;
            }
            const typeMapping = createTypeMapping(typeOnLeftSide, typeOnRightSide, this.definition.triggers.left.app, this.definition.triggers.right.app);
            this.typesMappings.push(typeMapping);
            this.leftTypeBoxes.shift();
            this.rightTypeBoxes.shift();
            this.mapFieldsForNewTypeMapping(typeMapping)
                .then(() => {

                });
        }
        this.adjustIntegrationDesignerPlacement();
    }

    private async mapFieldsForNewTypeMapping(typeMapping: SmartIntDefinitionTypeMapping): Promise<void> {
        await this.setState({ isMatchingFieldsForTypeMapping: this.typesMappings.indexOf(typeMapping) });
        try {
            const result = await autoMapFields(this.definition.triggers.left, this.definition.triggers.right, typeMapping);
            if (result.definition.types[0]) {
                // There should be just one type mapped
                const properties = result.definition.types[0].propertiesConfiguration.properties;
                if (properties && properties.length > 0) {
                    typeMapping.propertiesConfiguration.properties = properties;
                    typeMapping.statusTransitionConfiguration = result.definition.types[0].statusTransitionConfiguration;
                    typeMapping.hierarchyConfiguration = result.definition.types[0].hierarchyConfiguration;
                    // await this.leaveOnlyUnPickedOptions(properties);
                    // this.props.onMappingsChangedHandler(this.state.mappings);
                }
            }
        } catch (e) {
        } finally {
            await this.setState({ isMatchingFieldsForTypeMapping: undefined });
        }
    }

    private setApp(side: 'left' | 'right', appName: SoftwareName, connectionId: number, trigger: SmartIntTrigger) {
        this.definition.triggers[side].connectionId = connectionId;
        Object.assign(this.definition.triggers[side], trigger);
        this.definition.triggers[side].artifactName = side;
        if (this.definition.triggers.left.app && this.definition.triggers.right.app) {
            // if (!this.props.integration.id) {
            //     this.adjustApps();
            // } else {
            this.adjustApps();
            // }
        } else {
            this.forceUpdate();
        }
    }

    private adjustApps() {
        this.support = {
            leftApp: this.definition.triggers.left.app as SoftwareName,
            left: this.props.softwareFactory.getSoftwareByName(this.definition.triggers.left.app)!.getSmartIntsSupport()!,
            rightApp: this.definition.triggers.right.app as SoftwareName,
            right: this.props.softwareFactory.getSoftwareByName(this.definition.triggers.right.app)!.getSmartIntsSupport()!
        };
        this.forceUpdate();
    }

    private applyRelationshipsConfiguration(newConfiguration: SmartIntRelationshipConfiguration) {
        if (!this.definition.plugins) {
            this.definition.plugins = {
                shared: []
            };
        }
        this.definition.plugins.relationships = newConfiguration;
    }

    private configureRelationshipsClicked() {
        this.setState({showWindow: {window: 'relationships'}});
    }

    private closeCurrentWindow() {
        this.setState({showWindow: {window: ''}})
    }

    private isRelationshipsSupported(): boolean {
        const appsThatSupportRelationships = [SoftwareName.Jira, SoftwareName.Azure];
        if (this.typesMappings.length === 0) {
            return false;
        }
        if (!this.support) {
            return false;
        }
        return appsThatSupportRelationships.indexOf(this.support.leftApp) >= 0
            && appsThatSupportRelationships.indexOf(this.support.rightApp) >= 0;
    }

    private configurePluginsClicked(): void {
        this.setState({showWindow: {window: 'plugins'}});
    }

    private fieldsManagerClicked(): void {
        this.setState({showWindow: {window: 'fields'}});
    }

    private autoBuildClicked(): void {
        this.setState({showWindow: {window: 'autobuilder'}});
    }

    private setPlugins(plugins: SmartIntPlugin[]): void {
        if (!this.definition.plugins) {
            this.definition.plugins = {
                shared: []
            };
        }
        this.definition.plugins.shared = plugins;
        this.forceUpdate();
    }

    async componentDidMount() {
        this.onResizeFn = this.adjustIntegrationDesignerPlacement.bind(this);
        window.addEventListener('resize', this.onResizeFn);
        this.adjustIntegrationDesignerPlacement();
        const isAutoBuildEnabled = true;
        this.setState({isAutoBuildEnabled});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResizeFn);
    }

    adjustIntegrationDesignerPlacement() {
        const sceneHeight = document.querySelector('.SceneContainer')?.clientHeight;
        if (sceneHeight) {
            const designerEl = document.querySelector('.integration-designer') as HTMLDivElement;
            if (designerEl) {
                const height = Math.max(
                    320, document.querySelector('.integration-designer > .types-mappings')!.clientHeight
                ) + 160;
                designerEl.style.top = Math.max(0, ((sceneHeight - height) / 2)) + 'px';
            }
        }
    }

    private autoBuilderHandler(result: SmartIntDefinition | undefined) {
        try {
            if (result) {
                this.definition.types = result.types;
                this.typesMappings = this.definition.types;
                this.adjustApps();
                // this.setState({ showWindow: { window: 'fields'} });
            }
        } catch (e) {
        }
    }

    private getJiraConnectionId(): number {
        return (this.props.integration.definition.triggers.left.app === SoftwareName.Jira)
            ? this.props.integration.definition.triggers.left.connectionId
            : this.props.integration.definition.triggers.right.connectionId;
    }

    private getOtherAppConnectionId(): number {
        return (this.props.integration.definition.triggers.right.app === SoftwareName.Jira)
            ? this.props.integration.definition.triggers.left.connectionId
            : this.props.integration.definition.triggers.right.connectionId;
    }

    private getOtherAppThanJira(): SoftwareName {
        return ((this.props.integration.definition.triggers.right.app === SoftwareName.Jira)
            ? this.props.integration.definition.triggers.left.app
            : this.props.integration.definition.triggers.right.app) as SoftwareName;
    }
}
