import React from 'react';

import {WrikeFindItemConfiguration} from './wrike-find-item-configuration';
import {EditPipelineStepComponentProps} from 'src/generic/pipeline-step';
import {ArtifactDirection} from '../../../../generic/artifacts';
import {UI} from '../../../../engrator-core';
import {WrikeStepContextDisplay} from '../common';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class WrikeFindItemEditStep extends React.Component<Props, State> {
    private configuration: WrikeFindItemConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as WrikeFindItemConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <WrikeStepContextDisplay context={ this.configuration.context }  />
            <UI.LabelValue label={`Item Key`} value={ this.configuration.id } />
        </React.Fragment>;
    }
}