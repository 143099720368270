import {SoftwareName} from '../../../../../software';
import {axiosWrapper, FieldError} from '../../../../../engrator-core';
import {DropdownOption} from '../../../../../engrator-core/ui';

export type UILinkDTO = {
    name: string;
    linksIds: {
        left: string;
        right: string;
    };
}

export async function fetchLinksToMap(connectionId: number, app: SoftwareName): Promise<DropdownOption[]> {
    return new Promise((resolve, reject) => {
        try {
            const url = '/connectors/' + app.toLowerCase() + '/meta/' + connectionId + '/relationships/links';
            axiosWrapper.get(url)
                .then((data: any) => {
                    const options: DropdownOption[] = data.map((option: any) => ({
                        value: option.name,
                        label: option.name,
                        object: option
                    }));
                    resolve(options);
                })
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}