import React from 'react';
import {UI} from 'src/engrator-core';
import {IntegrationContext} from '../integration-context';
import {SuiteType} from '../../../../../generic/integrations/suite-type';
import {FeatureFlag, isFeatureFlagEnabled} from '../../../../top-navigation/rest-api';

type Props = {
    onMigrationSelected: () => void;
};
type State = {
    isGitEnabled: boolean;
    isClosed: boolean;
};

export class IntegrationMode extends React.Component<Props, State> {
    static contextType = IntegrationContext;

    constructor(props: Props) {
        super(props);
        this.state = {
            isClosed: false,
            isGitEnabled: false
        };
    }

    async componentDidMount() {
        const isGitEnabled = await isFeatureFlagEnabled(FeatureFlag.FF_GIT_REPOS);
        this.setState({ isGitEnabled });
    }

    render() {
        if (this.state.isClosed) {
            return <React.Fragment />
        }
        return <UI.Modal
            header={`What do you want to build?`}
            isWide={ true }
            isLeftAligned={ true }
        >
            <div className={`integration-mode`}>
                <div className={`modes`}>
                    <div onClick={ () => this.selected('Integration') } className={`sync`}>
                        <h2>Continuous Sync</h2>
                        <p>Sync items between apps and propagate changes</p>
                    </div>
                    <div onClick={ () => this.selected('Migration') } className={`migration`}>
                        <h2>Migration</h2>
                        <p>Migrate items from one app to another</p>
                    </div>
                    { this.state.isGitEnabled && <div onClick={ () => this.integrationTypeClicked(SuiteType.Git) } className={`git`}>
                        <h2>GIT</h2>
                        <p>Push GIT repository info to Jira</p>
                    </div> }
                </div>
                <UI.ButtonsBar
                    primary={
                        <UI.Button
                            appearance={'secondary'}
                            text={`Close`}
                            onClick={ () => this.hide() }
                        /> }
                />
            </div>
        </UI.Modal>
    }

    private selected(mode: 'Integration' | 'Migration') {
        this.hide();
        if (mode === 'Migration') {
            this.props.onMigrationSelected();
        }
    }

    private integrationTypeClicked(integrationType: SuiteType): void {
        const { setIntegrationType } = this.context;
        setIntegrationType(SuiteType.Git);
        this.hide();
    }

    private hide() {
        this.setState({ isClosed: true });
    }
}
