import React, {Component, useEffect} from 'react';

import { FieldError, UI } from 'src/engrator-core';
import { Message, TableGridData } from '../../engrator-core/ui';
import { Footer } from '../footer';
import { ArtifactVersionDataPreview } from './artifacts-versions';
import { ArtifactVersion } from './artifacts-versions/artifact-version.type';
import { convertSearchParamsToFiltersData } from './filters/filters';
import { ReportData } from './report-data.type';
import { ReportName } from './report-name.type';
import { createTableGridData } from './report-row-factory';
import { ReportingFilters } from './reporting-filters';
import { ReportsTabBar } from './reports-tab-bar';
import {fetchReportData, getParsedWindowUrl} from './rest-api';
import {SetupNotificationsReminder} from '../engagement';
import {useGlobalState} from '../global-store';
import {getReportingVerticalMenu} from './reporting-vertical-menu';
import {MainMenuItem} from '../global-store/create-global-state';

type State = {
    isLoading: boolean,
    changeParamsTime: number,
    initialFilters: any,
    pagination: {
        totalPages: number,
        currentPage: number
    },
    error?: FieldError;
    isFiltersBarVisible: boolean;
    artifactVersion?: ArtifactVersion;
};
type Props = {
    location: any;
    history: any;
}
class ReportingPageCmp extends Component<Props, State> {
    private tableGridData?: TableGridData;
    private reportName?: ReportName | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            changeParamsTime: 0,
            initialFilters: {},
            pagination: {
                totalPages: 0,
                currentPage: 0
            },
            isFiltersBarVisible: false
        };
    }

    componentDidMount(): void {
        this.extractReportName();
        const entries: string[][] = [];
        const searchEntries = new URL(getParsedWindowUrl()).searchParams.forEach((value, key) => {
            entries.push([key, value]);
        });
        const filtersData = convertSearchParamsToFiltersData(entries);
        this.fetchDataForReport(filtersData);
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<State>, snapshot?: any): void {
        this.extractReportName();
        if ((this.props as any).location.search !== (prevProps as any).location.search) {
            const entries: string[][] = [];
            const searchEntries = new URL(getParsedWindowUrl()).searchParams.forEach((value, key) => {
                entries.push([key, value]);
            });
            const filtersData = convertSearchParamsToFiltersData(entries);
            this.fetchDataForReport(filtersData);
        }
    }

    extractReportName() {
        const reportNameFromUrl = new URL(getParsedWindowUrl()).searchParams.get('report');
        this.reportName = (reportNameFromUrl) ? reportNameFromUrl as ReportName : ReportName.PipelinesRuns;
    }

    render() {
        return (
            <UI.Page>
                {this.reportName && this.state.isLoading === false && <ReportsTabBar
                    onFiltersClickHandler={this.onFiltersClickHandler.bind(this)}
                    currentReport={this.reportName}
                    filtersData={ this.state.initialFilters }
                /> }
                {this.state.isFiltersBarVisible && <ReportingFilters
                    reportName={this.reportName}
                    initialFilters={this.state.initialFilters}
                    onSearchHandler={this.onSearchHandler.bind(this)}/>
                }
                { this.state.isLoading && <UI.Loader appearance={'darkgray'} visible={true} /> }
                <SetupNotificationsReminder />
                { this.state.error && <Message appearance={'error'} message={ this.state.error?.message } /> }
                { !this.state.isLoading && this.tableGridData && <div className="connections-list-page">
                    <UI.TableGrid
                        data={ this.tableGridData?.data }
                        headers={ this.tableGridData?.headers }
                    />
                    <UI.Pagination
                        onPageChangeHandler={ this.onPageChangeHandler.bind(this) }
                        currentPage={ this.state.pagination.currentPage }
                        totalPages={ this.state.pagination.totalPages } />
                    <Footer />
                </div> }
                { this.state.artifactVersion && <ArtifactVersionDataPreview
                    artifactVersion={this.state.artifactVersion}
                    close={() => this.setState({artifactVersion: undefined})}
                /> }
            </UI.Page>
        )
    }

    private onPageChangeHandler(newPage: number) {
        const entries: string[] = [];
        let pageFound = false;
        const searchEntries = new URL(getParsedWindowUrl()).searchParams.forEach((value, key) => {
            if (key !== 'page') {
                entries.push(`${key}=${value}`);
            }
        });
        entries.push(`page=${newPage}`);
        (this.props as any).history.push({
            pathname: (this.props as any).location.pathname,
            search: `${entries.join('&')}`
        });
    }

    private onSearchHandler(filtersData?: any, combinedFilters?: string) {
        (this.props as any).history.push({
            pathname: (this.props as any).location.pathname,
            search: `report=${this.reportName}&${combinedFilters}`
        });
    }
    private fetchDataForReport(filtersData?: any): void {
        this.tableGridData = undefined;
        const pageFromUrl = new URL(getParsedWindowUrl()).searchParams.get('page');
        const currentPage = (pageFromUrl) ? parseInt(pageFromUrl) : 0;
        this.setState({ isLoading: true, initialFilters: filtersData }, () => {
            fetchReportData(this.reportName!, currentPage, filtersData)
                .then((reportData: ReportData) => {
                    if (reportData) {
                        let moreCallback = null;
                        if (this.reportName === ReportName.SyncedArtifacts) {
                            moreCallback = this.showArtifactData.bind(this);
                        } else if (this.reportName === ReportName.PipelinesRuns) {
                            moreCallback = (runId: number) => {
                                (this.props as any).history.push(`/app/reporting/suite-run/${ runId }/details`);
                            }
                        } else if (this.reportName === ReportName.PerformedFlows) {
                            moreCallback = (item: any) => {
                                (this.props as any).history.push(`/app/reporting/suite-run/${ item.runId }/flow/${ item.id }/details`);
                            }
                        }
                        this.tableGridData = createTableGridData(this.reportName!, reportData, moreCallback);
                    }
                    this.setState({
                        isLoading: false,
                        error: undefined,
                        pagination: { currentPage: reportData.currentPage, totalPages: reportData.pages }
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: false, error });
                });
        });
    }

    private onFiltersClickHandler(isFiltersBarVisible: boolean) {
        this.setState({isFiltersBarVisible: isFiltersBarVisible}, () => {
            this.forceUpdate();
        });
    }

    private showArtifactData(artifactVersion: any) {
        this.setState({artifactVersion});
    }
}

export function ReportingPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        setMainMenuItem(MainMenuItem.Reporting);
        setMenu(getReportingVerticalMenu());
    }, []);
    return <ReportingPageCmp
        location={props.location}
        history={ props.history }
    />
}

