import React from 'react';

type State = {
    hide: boolean;
};
type Props = {}

export class IntegrationSavedNotification extends React.Component<Props, State> {
    private timer?: NodeJS.Timeout = undefined;
    constructor(props: Props) {
        super(props);
        this.state = {
            hide: false
        };
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ hide: true });
        }, 2000);
    }

    render() {
        if (this.state.hide) {
            return <React.Fragment />
        }
        return <div className={`ui-notifications anim-fade-in`}>
            Integration saved.
        </div>
    }
}