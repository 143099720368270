import React from 'react';
import { EntityFormHelper, FieldError, UI } from 'src/engrator-core';
import { FormError } from 'src/engrator-core/form-error.type';
import { DropdownOption } from 'src/engrator-core/ui';
import { fetchProjectItemTypes, fetchProjects } from './../rest-api';
import { AzureProjectDTO } from './azure-project-dto.type';
import { AzureItemTypeDTO } from './azure-item-type-dto.type';
import {fetchConnection} from '../../../../app/integration/connections/rest-api';

export type AzureProjectItemTypePickerData = {
    project?: AzureProjectDTO,
    azureCollection?: string;
    itemType?: AzureItemTypeDTO
};

type Props = {
    connectionId: number;
    onDataChanged: (data: AzureProjectItemTypePickerData) => void;
    formError?: FormError;
}

type State = {
    formError: FormError;
    isLoadingProjects: boolean;
    isLoadingItemTypes: boolean;
}

export class AzureProjectItemTypePicker extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private projectOptions: DropdownOption[] = [];
    private itemTypeOptions: DropdownOption[] = [];
    private data : AzureProjectItemTypePickerData = {};
    private isSever: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = {formError: {}, isLoadingProjects: true, isLoadingItemTypes: false};
        this.entityFormHelper = new EntityFormHelper((propertyName: 'project' | 'itemType', newValue, additionalArgument?: any) => {
            if (!additionalArgument) {
                return;
            }
            if (propertyName === 'project') {
                console.log(newValue);
                this.data.project = additionalArgument as AzureProjectDTO;
                this.data.itemType = undefined;
                this.fetchIssueTypes();
            } else if (propertyName === 'itemType') {
                this.data.itemType = additionalArgument as AzureItemTypeDTO;
            }
            this.props.onDataChanged(this.data);
            this.forceUpdate();
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.forceUpdate();
        }
    }

    componentDidMount(): void {
        fetchConnection(this.props.connectionId).then((connection) => {
            this.data.azureCollection = connection.authData?.azureCollection || 'DefaultCollection';
            this.isSever = !!connection.authData?.azureServer;
            this.fetchProjects();
        });
    }

    fetchProjects(): void {
        this.setState({isLoadingProjects: true }, () => {
            fetchProjects(this.props.connectionId, this.data.azureCollection)
                .then((projects) => {
                    this.projectOptions = projects.map(project => ({
                        label: project.name,
                        value: project.id,
                        object: project
                    }));
                    this.setState({isLoadingProjects: false});
                })
                .catch((error: FieldError) => {
                    this.setState({isLoadingProjects: false, formError: {['pickerError']: error.message}});
                });
        });
    }

    render() {
        return <React.Fragment>
            { this.state.formError.pickerError && <UI.Message appearance={'error'}>{ this.state.formError.pickerError}</UI.Message> }
            {/*{ this.isSever && <React.Fragment>*/}
            {/*    <UI.Input*/}
            {/*        label={`Collection name (e.g. DefaultCollection)`}*/}
            {/*        onChange={ (newValue) => this.changeCollectionName(newValue) }*/}
            {/*        defaultValue={ this.data.azureCollection }*/}
            {/*    />*/}
            {/*    <UI.Button*/}
            {/*        appearance={"secondary"}*/}
            {/*        onClick={ () => this.fetchProjects() } text={`Reload projects`}*/}
            {/*    />*/}
            {/*</React.Fragment> }*/}
            <UI.FormGroup
                label={`Project`}
                dataSel={`project`}
                error={ this.props.formError?.project }
                description={``}
                isRequired={true}>
                { this.state.isLoadingProjects && <UI.Loader visible={true} appearance={'darkgray'} /> }
                { !this.state.isLoadingProjects && <UI.Dropdown options={ this.projectOptions } onChange={ this.entityFormHelper.onChangeHandler('project')   }/> }
            </UI.FormGroup>
            { this.data.project && <UI.FormGroup
                label={`Item Type`}
                error={ this.props.formError?.itemType }
                description={``}
                isRequired={ true }>
                { this.state.isLoadingItemTypes && <UI.Loader visible={true} appearance={'darkgray'} /> }
                { !this.state.isLoadingItemTypes &&
                <UI.Dropdown defaultValue={this.data.itemType?.id + ''} options={this.itemTypeOptions}
                    onChange={this.entityFormHelper.onChangeHandler('itemType')}/>
                }
            </UI.FormGroup> }
        </React.Fragment>;
    }

    private changeCollectionName(newName: string) {
        this.data.azureCollection = newName;
    }

    private fetchIssueTypes() {
        if (!this.data.project) {
            return;
        }
        this.setState({ isLoadingItemTypes: true }, () => {
            fetchProjectItemTypes(this.props.connectionId, this.data.project!.id)
                .then((issueTypes) => {
                    this.itemTypeOptions = issueTypes.map(issueType => ({
                        label: issueType.name,
                        value: String(issueType.id),
                        object: issueType
                    }));
                    this.setState({isLoadingItemTypes: false});
                })
                .catch((error: FieldError) => {
                    this.setState({ isLoadingItemTypes: false, formError: {[`pickerError`]: error.message}});
                });
        });
    }
}
