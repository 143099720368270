import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { Software } from 'src/generic';
import { createGenericConnectionFactory } from 'src/generic/connections';
import { AuthMethod } from 'src/generic/connections/auth-method.type';
import { PipelineGenericStep } from 'src/generic/pipeline-step';
import { SoftwareName } from '../software-name';

import { FreshdeskPipelineSteps } from 'src/software/freshdesk/freshdesk-pipeline-steps';
import {FreshserviceSmartintsSupport} from './smart-ints/freshservice-smartints-support';


export class FreshserviceSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.Freshservice, [AuthMethod.BasicAuth]);
    }

    getName(): string {
        return 'Freshservice';
    }

    getID() {
        return 'Freshservice';
    }

    getAvailableSteps(): object[] {
        return FreshdeskPipelineSteps.map(pipelineStep => new PipelineGenericStep(pipelineStep));
    }

    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new FreshserviceSmartintsSupport();
    }
}
