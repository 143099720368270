import React, {useEffect, useState} from 'react';

import {useGlobalState} from '../../../global-store';
import {MainMenuItem} from '../../../global-store/create-global-state';
import {getIntegrationVerticalMenu} from './integration-vertical-menu';
import { UI } from 'src/engrator-core';
import {fetchIntegrationDetails} from '../../integration-suites/details/rest-api';
import {MigrationComponent} from '../migration';
import {SmartIntIntegration} from '../../../../generic';

export function IntegrationMigrationPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [integration,setIntegration] = useState<SmartIntIntegration>();
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        const id = props.match.params.id;
        fetchIntegrationDetails(id)
            .then(detailsModel => {
                const integration = new SmartIntIntegration(
                    detailsModel.id,
                    detailsModel.name,
                    detailsModel.configuration,
                    detailsModel.settings,
                    detailsModel.migrationConfiguration,
                    detailsModel.accessLevel
                );
                const menu = getIntegrationVerticalMenu(id, integration);
                setMenu(menu);
                setMainMenuItem(MainMenuItem.Workflows);
                setIntegration(integration);
            });
    }, []);
    return <UI.Page>
        { integration && <React.Fragment>
            <MigrationComponent
                integration={ integration! }
            />
        </React.Fragment> }
    </UI.Page>
}