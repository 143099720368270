import {SoftwareName} from '../../../../../../software';
import {DropdownOption} from '../../../../../../engrator-core/ui';
import {CommentAction} from '../../../definition/smart-int-definition-configuration.type';

export function provideOptionsForApp(app: SoftwareName, side: 'left' | 'right'): DropdownOption[] {
    if (app == SoftwareName.Freshdesk) {
        return [
            {label: 'Skip this comment', value: CommentAction.Skip },
            {label: 'Create public reply in ' + app + ' (' + side + ')', value: CommentAction.Public },
            {label: 'Create private note in ' + app + ' (' + side + ')', value: CommentAction.Private },
            {label: 'Create public note in ' + app + ' (' + side + ')', value: CommentAction.PublicNote }
        ];
    }
    return [
        {label: 'Skip this comment', value: CommentAction.Skip },
        {label: 'Create public comment in ' + app + ' (' + side + ')', value: CommentAction.Public },
        {label: 'Create private comment in ' + app + ' (' + side + ')', value: CommentAction.Private },
    ];
}