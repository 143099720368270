import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';

import {AsanaCreateItemConfiguration} from './asana-create-item-configuration';
import {AsanaWorkspaceProjectPicker, AsanaWorkspaceProjectPickerData} from '../common';

type Props = {} & NewPipelineStepComponentProps;
type State = {};

export class AsanaCreateItemNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: AsanaCreateItemConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as AsanaCreateItemConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: '', newValue) => {
            // this.onConfigurationChanged();
        });
    }

    render() {
        return <React.Fragment>
            <AsanaWorkspaceProjectPicker
                connectionId={this.props.context.connectionId}
                onDataChanged={this.onConfigurationChanged.bind(this)}
            />
        </React.Fragment>
    }

    private onConfigurationChanged(data: AsanaWorkspaceProjectPickerData) {
        if (data.workspace) {
            this.configuration.workspace = data.workspace;
        }
        if (data.project) {
            this.configuration.project = data.project;
        }
        if (this.configuration.workspace && this.configuration.project) {
            this.props.showArtifactBuilder(ArtifactDirection.In);
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }
}
