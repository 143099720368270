import React from 'react';
import {FlowStep} from './flow-steps-factory';

type Props = {
    steps: FlowStep[];
    onStepClickHandler: (flowStep: FlowStep, index: number) => void;
    selectedStepIndex?: number
};

type State = {};

export class FlowStepsDiagram extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.selectedStepIndex !== prevProps.selectedStepIndex) {
            this.forceUpdate();
        }
    }

    render() {
        return <React.Fragment>
            <div>
                {/*<h3 className={`subheader`}>Execution steps</h3>*/}
            </div>
            <div className={`SceneContainer`}>
                <div className={`SnippetsList`}>
                    {this.props.steps.map((flowStep, index: number) => <React.Fragment>
                        <div className={`snippet-container ${this.getStepSnipperClassName(index, flowStep)}`}
                            key={index * 2}>
                            <span className="index">#{index + 1}</span>
                            <div onClick={() => this.props.onStepClickHandler(flowStep, index)}>
                                {flowStep.genericSoftwareStep.getFlowStepSnippet(flowStep.stepConfiguration)}
                            </div>
                            {/*<div className={`stats`}>*/}
                            {/*    {flowStep.execution!.persistedArtifacts > 0 && <span className={`artifacts`}>*/}
                            {/*    <UI.Icon icon={"artifact"}/>{flowStep.execution!.persistedArtifacts}*/}
                            {/*</span>}*/}
                            {/*    <UI.Icon icon={"clock"}/> {flowStep.execution!.formattedExecutionTime}*/}
                            {/*</div>*/}
                        </div>
                        {index + 1 < this.props.steps.length && <div className="join-line" key={index * 2 - 1}></div>}
                    </React.Fragment>
                    )}
                    <div style={{'clear': 'both'}}/>

                </div>
            </div>
        </React.Fragment>
    }

    private getStepSnipperClassName(index: number, flowStep: FlowStep) {
        const selectedClassName = (index === this.props.selectedStepIndex) ? 'selected' : '';
        return `${flowStep.execution?.status} ${selectedClassName}`
    }
}