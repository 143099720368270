import {VerticalMenu} from 'src/app/global-store/create-global-state';
import {fetchRunDetails} from './rest-api';
import {Icon20} from '../../../engrator-core/ui';

export async function getRunVerticalMenu(runId: number): Promise<VerticalMenu> {
    const runDetails = await fetchRunDetails(runId);
    const menu = {
        header: 'Run details',
        items: [{
            name: 'Overview',
            path: '/app/reporting/suite-run/' + runId + '/details'
        }, {
            name: 'Logs',
            path: '/app/reporting/suite-run/' + runId + '/logs'
        }, {
            name: 'Edit integration',
            path: '/app/integration/smart-ints/' + runDetails.suiteId + '/edit',
            icon: Icon20.Edit
        }]
    };
    return menu;
}