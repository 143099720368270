import {axiosWrapper, FieldError} from '../../../engrator-core';
import {JiraProjectDTO} from './common/jira-project-dto.type';
import {JiraIssueTypeDTO} from './common/jira-issue-type-dto.type';

export const ENDPOINT_URL = '/connectors/jira';

export const fetchProjects = (connectionId: number): Promise<JiraProjectDTO[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/projects')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchProjectIssueTypes = (connectionId: number, projectKey: string): Promise<JiraIssueTypeDTO[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/projects/' + projectKey + '/issueTypes')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};