import React from 'react';
import { switchBodyScroll, UI } from 'src/engrator-core';
import { ArtifactVersion } from './artifact-version.type';
import { ReSync } from './resync';
import { SubitemsData } from './subitems-data';
import {ArtifactVersionFieldValue} from './artifact-version-field-value';

type Props = {
    artifactVersion: ArtifactVersion;
    close: () => void;
}
type State = {
    selectedTab: TabButton;
};

enum TabButton {
    ItemData,
    ReSync,
    Subitems
}

export class ArtifactVersionDataPreview extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedTab: TabButton.ItemData
        };
    }

    render() {
        return <UI.FullScreenModal
            header={ `Item ${this.props.artifactVersion.identifier}, ${this.props.artifactVersion.softwareName}`}
            closeBtnHandler={ () => this.close() }
            primaryBtnHandler={ () => Promise.resolve(true) }
            showPrimaryBtn={ false }
        >
            <UI.TabBar>
                <UI.TabButton text={`Item Data`} onClick={ () => this.onTabItemChanged(TabButton.ItemData) } isSelected={ this.state.selectedTab === TabButton.ItemData } />
                <UI.TabButton text={`Subitems`} onClick={ () => this.onTabItemChanged(TabButton.Subitems) } isSelected={ this.state.selectedTab === TabButton.Subitems } />
                <UI.TabButton text={`Resync`} onClick={ () => this.onTabItemChanged(TabButton.ReSync) } isSelected={ this.state.selectedTab === TabButton.ReSync } />
            </UI.TabBar>
            <div className={`artifact-version-data-preview`}>
                <div className={`inner`}>
                    { this.state.selectedTab === TabButton.ReSync && <ReSync artifactVersionId={this.props.artifactVersion.id}/> }
                    { this.state.selectedTab === TabButton.Subitems && <SubitemsData artifactVersion={this.props.artifactVersion}/> }
                    { this.state.selectedTab === TabButton.ItemData && <UI.FormGroup
                        description={`Data collected for this item after the last performed sync:`}
                    >
                        <table className={`items-data`}>
                            <tbody>
                                {this.getKeysValuesAsArray(this.props.artifactVersion)
                                    .map((keyWithValue, index) =>
                                        <tr key={index}>
                                            <td>{keyWithValue[0]}</td>
                                            <td>
                                                <ArtifactVersionFieldValue fieldName={ keyWithValue[0] } fieldValue={ keyWithValue[1] } />
                                            </td>
                                        </tr>,
                                    )}
                            </tbody>
                        </table>
                    </UI.FormGroup> }
                </div>
            </div>
        </UI.FullScreenModal>;
    }

    private close() {
        switchBodyScroll(true);
        this.props.close();
    }

    private getKeysValuesAsArray(artifactVersion: ArtifactVersion): string[][] {
        const list: string[][] = [];
        list.push(['side', artifactVersion.side]);
        let dataSet = artifactVersion.artifactData?.fields || artifactVersion.artifactData?.data;
        if (dataSet) {
            const fieldsKeys = Object.keys(dataSet);
            fieldsKeys.forEach(key => {
                list.push([key, dataSet[key]]);
            });
        }
        return list;
    }

    private onTabItemChanged(tabButton: TabButton) {
        this.setState({ selectedTab: tabButton });
    }
}
