import React from 'react';

import {EditPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper, UI} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';
import {AzureSearchItemsConfiguration} from './azure-search-items-configuration';
import {AzureProjectItemTypeDisplay} from '../common/azure-project-item-type-display';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class AzureSearchItemsEditStep extends React.Component<Props, State> {
    private configuration: AzureSearchItemsConfiguration;
    private entityFormHelper: EntityFormHelper;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as AzureSearchItemsConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'customWIQL', newValue) => {
            this.configuration[propertyName] = newValue;
        });
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup>
                <AzureProjectItemTypeDisplay context={ this.configuration} />
                <UI.Checkbox
                    checkedValue={ 'true' }
                    uncheckedValue={ 'false' }
                    defaultValue={ this.configuration.onlyNewChanges.toString() }
                    label="Only cards that changed from last integration"
                    onChange={ (newValue) => { this.onlyNewChanged(newValue) }   } />
            </UI.FormGroup>
        </React.Fragment>;
    }

    private onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }
}