import React from 'react';

import {EditPipelineStepComponentProps} from 'src/generic';
import {UI} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';

import {JiraUpdateIssueConfiguration} from './jira-update-issue-configuration';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class JiraUpdateIssueEditStep extends React.Component<Props, State> {
    private configuration: JiraUpdateIssueConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as JiraUpdateIssueConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
        this.props.showArtifactBuilder(ArtifactDirection.In);
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup>
                <UI.LabelValue label={`Project`} value={ this.configuration.project.name } />
            </UI.FormGroup>
            <UI.FormGroup>
                <UI.LabelValue label={`Issue Type`} value={ this.configuration.issueType.name } />
            </UI.FormGroup>
            <UI.FormGroup>
                <UI.LabelValue label={`Target Artifact`} value={ this.configuration.targetArtifact } />
            </UI.FormGroup>
        </React.Fragment>;
    }
}