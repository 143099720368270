import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';

import {ServiceNowStepKey} from '../servicenow-step-key.type';
import {ServiceNowUpdateItemEditStep} from './servicenow-update-item-edit-step';
import {ServiceNowUpdateItemNewStep} from './servicenow-update-item-new-step';
import {ServiceNowUpdateItemConfiguration} from './servicenow-update-item-configuration';

export const ServiceNowUpdateItemPipelineStep: PipelineStep<ServiceNowUpdateItemConfiguration> = {
    addStepComponentClass: ServiceNowUpdateItemNewStep,
    editStepComponentClass: ServiceNowUpdateItemEditStep,
    specification: {
        action: 'Update item',
        description: 'Update selected item',
        key: ServiceNowStepKey.ServiceNowUpdateItem,
        software: SoftwareName.ServiceNow,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ServiceNowUpdateItemConfiguration;
        return `Update item '${stepConfiguration.targetArtifact}'`;
    }
}