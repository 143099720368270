import React, {Component} from 'react';
import { UI} from 'src/engrator-core';
import { changeUserPassword
} from '../../system/users/users-management-rest-api';
import {FormError} from '../../../engrator-core/form-error.type';

type Props = {
    closeHandler: () => void;
    userId: number;
};
type State = {
    success: boolean;
    isLoading: boolean;
    error?: FormError;
    newPassword?: string;
    repeatedPassword?: string;
};

export class ClusterUsersChangePassword extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false, success: false
        };
    }

    async changePasswordClicked() {
        if (this.state.newPassword && this.state.repeatedPassword) {
            try {
                await this.setState({ success: false, isLoading: true, error: undefined });
                await changeUserPassword(this.props.userId, this.state.newPassword, this.state.repeatedPassword);
                await this.setState({ success: true, isLoading: false });
            } catch (error) {
                this.setState({ error, isLoading: false });
            }
        }
    }

    render() {
        return (
            <UI.FullScreenModal
                header={`Change User Password`}
                closeBtnHandler={() => this.props.closeHandler()}
                primaryBtnHandler={() => Promise.resolve(true)}
                primaryBtnText={`Save`}
                showPrimaryBtn={false}
            >

                <UI.Form
                    error={ this.state.error?.message }
                >
                    { this.state.success && <UI.Message appearance={'success'}>Password changed successfully.</UI.Message> }
                    <UI.FormGroup
                        label={`New password`}
                        isRequired={ true }
                    >
                        <UI.Input
                            type={'password'}
                            onChange={ (newValue: string) => this.setState({ newPassword: newValue }) }
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Repeat password`}
                        isRequired={ true }
                    >
                        <UI.Input
                            type={'password'}
                            onChange={ (newValue: string) => this.setState({ repeatedPassword: newValue }) }
                        />
                    </UI.FormGroup>
                    <UI.ButtonsBar
                        primary={<UI.Button
                            isLoading={ this.state.isLoading }
                            className={`selenium-add-user`}
                            disabled={!(this.state.newPassword && this.state.repeatedPassword)}
                            onClick={() => this.changePasswordClicked()}
                            text={`Change`}
                        />}
                    />
                </UI.Form>
            </UI.FullScreenModal>
        );
    }
}