import React, { useEffect, useState } from 'react';

export type BreadcrumbStep = {
    name: string;
    index: number;
};
export type filterOptions = {
    specificStep: number;
    stepsToFilter: number[];
};
type Props = {
    currentStep: BreadcrumbStep;
    allSteps: BreadcrumbStep[];
    onChangeStepHandler: (step: ConnectionStep) => void;
    breadcrumbsSymbol: BreadcrumbsSymbol | ' / ';
    filterOptions?: filterOptions;
};
export enum BreadcrumbsSymbol {
    slash = ' / '
}
export enum ConnectionStep {
    SelectApp = 1,
    ConnectApp = 2,
    Configure = 3,
    CreateConnection = 4
}

export function initializeBreadcrumbStep() {
    return {
        name: '',
        index: 1
    }
}

export default function Breadcrumbs({ currentStep, allSteps, onChangeStepHandler, breadcrumbsSymbol, filterOptions }: Props) {
    const [stepsToRender, setStepsToRender] = useState<BreadcrumbStep[]>([]);
    const handleStepsToRender = () => {
        const filtered: BreadcrumbStep[] = [];
        allSteps.forEach(el => {
            if ((filterOptions && currentStep.index === filterOptions.specificStep && !filterOptions.stepsToFilter.includes(el.index)) || !filterOptions || currentStep.index !== filterOptions.specificStep) {
                filtered.push(el);
            }
        });
        const limitSteps = filtered.slice(0, currentStep.index);
        const stepsWithItem = limitSteps.reduce((result, element, index, array) => {
            result.push(element);
            if (index < array.length - 1) {
                const BreadcrumbStep: BreadcrumbStep = { name: breadcrumbsSymbol, index: -1 };
                result.push(BreadcrumbStep);
            }
            return result;
        }, [] as BreadcrumbStep[]);
        return stepsWithItem;
    };
    useEffect(() => {
        setStepsToRender(handleStepsToRender());
    }, [currentStep]);
    return (
        <div className={`breadcrumbs-container`}>
            {stepsToRender.map((el, idx, arr) => {
                const isBreadcrumbItem = el.name !== breadcrumbsSymbol && 'breadcrumb-item';
                const isElementAbove0 = el.name !== breadcrumbsSymbol && idx !== 0 && 'ml-5';
                const isClickable = el.name !== breadcrumbsSymbol && idx !== arr.length - 1 && 'clickable';
                const isActive = el.name !== breadcrumbsSymbol && idx === arr.length - 1 && 'active';
                const isShort = el.name.length < 11;
                return (
                    <span
                        className={`${isBreadcrumbItem || ''} ${isElementAbove0 || ''} ${isClickable || ''} ${isActive || ''} ${isShort ? 'short' : 'long'}`}
                        key={el.index}
                        onClick={isClickable ? () => onChangeStepHandler(el.index) : undefined}
                    >
                        {el.name}
                    </span>
                );
            })}
        </div>
    );
}
