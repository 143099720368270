import React from 'react';
import {
    SmartIntTrigger
} from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {UI} from 'src/engrator-core';
import {SoftwareName} from '../../software-name';
import {AzureProjectItemTypePicker, AzureProjectItemTypePickerData} from '../steps/common';
import {SuiteType} from '../../../generic/integrations/suite-type';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
    integrationType?: SuiteType;
};
type State = {};

export class AzureSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.Azure,
            artifactName: '',
            className: '',
            configuration: {
                project: undefined,
                query: ''
            },
            connectionId: this.props.connectionId
        };
        if (this.props.integrationType === SuiteType.Git) {
            this.onGitIntegrationBuilt('');
        }
    }

    render() {
        return <React.Fragment>
            {/*{ this.props.integrationType === SuiteType.Git && <React.Fragment>*/}
            {/*    <AzureGitRepositories*/}
            {/*        connectionId={this.props.connectionId}*/}
            {/*        onDataChanged={(repositories, azureCollection) => this.onRepositoriesSelected(repositories, azureCollection)}*/}
            {/*    />*/}
            {/*</React.Fragment> }*/}
            {this.props.integrationType !== SuiteType.Git && <React.Fragment>
                {!this.smartIntTrigger.configuration.project && <AzureProjectItemTypePicker
                    connectionId={this.props.connectionId}
                    onDataChanged={this.onDataChanged.bind(this)}
                />}
                {this.smartIntTrigger.configuration.project && <UI.FormGroup dataSel={`project`}>
                    <UI.LabelValue
                        label={`Project`}
                        value={this.smartIntTrigger.configuration.project.name}
                    />
                </UI.FormGroup>}
            </React.Fragment>}
        </React.Fragment>
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private onDataChanged(data: AzureProjectItemTypePickerData) {
        if (data.project) {
            this.smartIntTrigger.configuration.project = data.project;
            if (data.azureCollection) {
                this.smartIntTrigger.configuration.azureCollection = data.azureCollection;
            }
            this.smartIntTrigger.artifactName = 'issue';
            this.props.onConfigured(this.smartIntTrigger!);
            this.forceUpdate();
        }
    }

    private onGitIntegrationBuilt(azureCollection: string): void {
        if (azureCollection) {
            this.smartIntTrigger.configuration.azureCollection = azureCollection;
        }
        this.smartIntTrigger.artifactName = 'gitrepository';
        this.props.onConfigured(this.smartIntTrigger!);
        this.forceUpdate();
    }
}
