import React from 'react';

import './table.css';
import {FieldError} from '../field-error';
import {Message} from './message';
import { UI } from '..';
import { Integrations } from 'src/app/integration/smartints';
import { FetchData } from 'src/app/integration/smartints/pages/list/rest-api';


type TableProps = {
    dataSource: Integrations | FetchData,
    rowDecorator: any,
    shouldReload?: boolean,
    shouldReloadListener?: (() => boolean);
    emptyTableHolder?: any;
    onBulkSelect?: (rows: any[]) => void;
    error: FieldError | undefined;
};

type TableState = {
    headers: Array<string>,
    data: Array<Array<any>>,
}

export class Table extends React.Component<TableProps, TableState> {
    private selectedRows: any[] = [];

    constructor(props: TableProps) {
        super(props);
        this.state = {
            headers: [],
            data: [],
        };
    }

    componentDidMount() {
        this.loadDataSource();
    }

    componentDidUpdate(prevProps: TableProps) {
        if (this.props.shouldReload !== undefined && this.props.shouldReload !== prevProps.shouldReload) {
            this.loadDataSource();
        }
    }

    render() {
        if (this.props.error) {
            return <Message appearance={'error'}>{ this.props.error?.message }</Message>
        }
        if (this.state.headers.length > 0 && this.state.data.length === 0 && this.props.emptyTableHolder) {
            return this.props.emptyTableHolder;
        }
        return <React.Fragment>
            { this.state.headers && 
                <table className="engrator-table" cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            { this.state.headers && this.state.headers.map((header: string, index: number) => <th key={ index }>
                                { header }
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.data && this.state.data.map((row: Array<any>, index: number) => <tr key={ index }>
                            { this.props.onBulkSelect && <td key={-1}>
                                <UI.Checkbox checkedValue="true" uncheckedValue="false" onChange={() => 
                                    this.rowItemSelected(row)
                                } />
                            </td> }
                            { this.props.rowDecorator(row).map((cell: any, cellIndex: number) => <td key={ cellIndex }>
                                { cell }
                            </td> )}
                        </tr>)}
                    </tbody>
                </table>
            }
        </React.Fragment>;
    }

    private loadDataSource() {
        if (this.props.error) {
            return;
        }
        if (this.props.dataSource) {
            this.selectedRows = [];
            const headers = this.props.dataSource.headers;
            if (this.props.onBulkSelect) {
                headers.unshift('');
            }
            this.setState({ headers, data: this.props.dataSource.data});
        }
    }

    private rowItemSelected(row: any) {
        const index = this.selectedRows.indexOf(row);
        if (index >= 0) {
            this.selectedRows.splice(index, 1);
        } else {
            this.selectedRows.push(row);
        }
        console.log(this.selectedRows);
        this.props.onBulkSelect!(this.selectedRows);
    }
}