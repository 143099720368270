import { axiosWrapper } from 'src/engrator-core';
import { ChartData } from './chart-data.type';
import { DashboardReportType } from './dashboard-report.type';

const ENDPOINT_URL = '/reporting/dashboard';

export const getDashboardChartData = (dashboardReportType: DashboardReportType, numberOfDays: number): Promise<ChartData> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                axiosWrapper.get(`${ENDPOINT_URL}/${dashboardReportType}/${numberOfDays}`)
                    .then((data) => {
                        resolve(data as ChartData);
                    })
                    .catch(error => reject(error));
            } catch (error) {
                reject(error);
            }
        }, 300);
    });
};


export const clearDashboardCaches = (): Promise<ChartData> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/refresh`)
                .then((data) => {
                    resolve(data as ChartData);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

