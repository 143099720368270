enum PropertyDataType {
    IdLabel = 'IdLabel',
    IdLabelArray = 'IdLabelArray',
    Integer = 'Integer',
    Float = 'Float',
    String = 'String',
    Date = 'Date',
    Collection = 'Collection',
    Assets = 'Assets',
    Teams = 'Teams'
}

export default PropertyDataType;
