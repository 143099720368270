import React, {Component} from 'react';

import {UI} from 'src/engrator-core';
import {CanBeUsedForAutoCreationResponse, OptionsAutoCreationResolverConfig} from '../../rest-api';
import {DropdownOption} from '../../../../../engrator-core/ui';

type Props = {
    updateConfigDataHandler: (data: any) => Promise<void>;
    resolverConfig: OptionsAutoCreationResolverConfig;
    canBeUsedConfig: CanBeUsedForAutoCreationResponse;
};
type State = {
    boardOptions?: DropdownOption[];
    defaultBoard?: JiraBoard;
}
type JiraBoard = {
    id: string;
    name: string;
};

const BOARD_NAME: string = 'board';

export class JiraSprintsResolverConfig extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.setBoardsOptions();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    }

    private async setBoardsOptions(): Promise<void> {
        const boardConfigOptions = this.props.canBeUsedConfig.configurationOptions.filter(o => o.name === BOARD_NAME)[0];
        if (boardConfigOptions && boardConfigOptions.options.length > 0) {
            const defaultBoard = {id: boardConfigOptions.options[0].id, name: boardConfigOptions.options[0].name};
            if (this.props.resolverConfig.configData) {
                defaultBoard.id = this.props.resolverConfig.configData.boardId;
                defaultBoard.name = this.props.resolverConfig.configData.boardName;
            }
            await this.setState({
                defaultBoard,
                boardOptions: boardConfigOptions.options.map((b) => ({label: b.name, value: b.id, object: b}))
            });
            await this.updateConfigData();
        }
    }

    render() {
        return (
            <UI.FormGroup
                label={`Default Jira Board`}
                description={`Set board in which new sprints will be created.`}
            >
                {this.state.boardOptions && <UI.Dropdown
                    defaultValue={this.state.defaultBoard?.id}
                    options={this.state.boardOptions}
                    onChange={(value: string, board: JiraBoard) => this.setBoard(board)}
                    placeholder={`Default Jira Board`}
                />}
            </UI.FormGroup>
        );
    }

    private async updateConfigData() {
        const data = {
            boardId: this.state.defaultBoard?.id,
            boardName: this.state.defaultBoard?.name,
        };
        await this.props.updateConfigDataHandler(data);
    }

    private async setBoard(board: JiraBoard): Promise<void> {
        await this.setState({ defaultBoard: board });
        await this.updateConfigData();
    }
}
