import {VerticalMenu, VerticalMenuItem} from '../global-store/create-global-state';

export async function getWorkflowsVerticalMenu(): Promise<VerticalMenu> {
    return {
        header: 'Workflows',
        items: populateVerticalMenu()
    }
}
function populateVerticalMenu() {
    let items: VerticalMenuItem[] = [{
        name: 'Integrations',
        path: '/app/integration/smart-ints'
    },{
        name: 'Git Repositories',
        path: '/app/integration/git-repositories'
    },{
        name: 'Shared Mappings',
        path: '/app/integration/shared-mappings'
    },{
        name: 'Connections',
        path: '/app/integration/connections'
    }, {
        name: 'Notifications',
        path: '/app/integration/notifications'
    }];
    return items;
}