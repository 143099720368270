import React from 'react';
import { GetintArchiverStepContextData } from './monday-step-context';

type Props = {
    context: GetintArchiverStepContextData;
}

type State = {}

export class GetintArchiverStepContextDisplay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
        </React.Fragment>;
    }
}
