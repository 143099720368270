import React from 'react';
import {UI} from 'src/engrator-core';
import {ContextMenuItem, OnContextMenuItemSelected} from 'src/engrator-core/ui';
import {IntegrationListItem} from './integration-list-item';
import {IntegrationAccessLevel} from '../../../integration-suites/details/integration-details-model';

type Props = {
    onSelected: OnContextMenuItemSelected;
    listItem: IntegrationListItem;
};

type State = {};

export function getAvailableContextItems(listItem: IntegrationListItem): ContextMenuItem[] {
    const items = [];
    if (listItem.status === 'Disabled') {
        items.push({
            id: 'enable',
            name: 'Enable'
        });
    } else if (listItem.status === 'Enabled') {
        items.push({
            id: 'disable',
            name: 'Disable'
        });
    }
    items.push(...[
        {
            id: 'duplicate',
            name: 'Duplicate'
        },
        {
            id: 'export',
            name: 'Export'
        },
        {
            id: 'exportWithConnections',
            name: 'Export with connections'
        }
    ]);
    items.push({
        id: 'delete',
        name: 'Delete'
    });
    return items;
}

export class ListItemContextMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.listItem.accessLevel !== IntegrationAccessLevel.Write) {
            return <React.Fragment/>;
        }
        return <div className={`integrations-list-context-menu`}>
            <UI.ContextMenu
                disableSorting={true}
                getItems={() => getAvailableContextItems(this.props.listItem)}
                onSelected={this.onSelectedHandler.bind(this)}
            />
        </div>
    }

    private onSelectedHandler(item: ContextMenuItem) {
        this.props.onSelected(item);
    }
}
