import React from 'react';

import {UI} from 'src/engrator-core';
import {ConfiguredProperty} from '../artifacts/data-artifact';
import {PropertySourceType} from '../artifacts/property-source/property-source-type';
import {OtherArtifactPropertySource, PredefinedPropertySource} from '../artifacts/property-source';
import {OptiontPropertySource} from '../artifacts/property-source/option-property-source';

type PropertyMappingProps = {
    configuredProperty: ConfiguredProperty, 
    deleteHandler: any
};

export default class PropertyMapping extends React.Component<PropertyMappingProps> {
    constructor(props: PropertyMappingProps) {
        super(props);
    }

    render() {
        const mappedTo = getMappedToProperty(this.props.configuredProperty);
        // const className = 'valid';
        const className = `property-mapping `;
        return <div className={ className }>
            <div className="property">{ this.props.configuredProperty.property.name }</div>
            { mappedTo && <div className="mapped-to">{ mappedTo }</div> }
            {/*<UI.Button appearance="danger" onClick={ this.props.deleteHandler } text="Delete" />*/}
            <UI.DeleteButton onClick={this.props.deleteHandler} />
            { this.props.configuredProperty.source.condition && <p className={`condition`}>Condition: { this.props.configuredProperty.source.condition }</p> }
        </div>;
    }
}

function getMappedToProperty(configuredProperty: ConfiguredProperty): string | undefined {
    if (!configuredProperty) {
        return undefined;
    }
    if (configuredProperty.source.sourceType === PropertySourceType.Step) {
        return undefined;
    }
    if (configuredProperty.source.sourceType === PropertySourceType.OtherArtifact) {
        const propertySource = (configuredProperty.source as OtherArtifactPropertySource);
        return `\$(${propertySource.artifactId}.${propertySource.propertyDefinition.name})`;
    }
    if (configuredProperty.source.sourceType === PropertySourceType.Option) {
        const propertySource = (configuredProperty.source as OptiontPropertySource);
        return `${propertySource.label}`;
    }
    return `"${(configuredProperty.source as PredefinedPropertySource).value}"`;
}
