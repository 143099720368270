import {PipelineStep} from 'src/generic';
import {
    ServiceNowCreateCommentsPipelineStep,
    ServiceNowCreateItemPipelineStep,
    ServiceNowSearchItemsPipelineStep,
    ServiceNowUpdateItemPipelineStep,
    ServiceNowFindItemPipelineStep,
    ServiceNowStatusTransitionPipelineStep
} from './steps';

export const ServiceNowPipelineSteps: PipelineStep<any>[] = [
    ServiceNowSearchItemsPipelineStep,
    ServiceNowCreateItemPipelineStep,
    ServiceNowCreateCommentsPipelineStep,
    ServiceNowUpdateItemPipelineStep,
    ServiceNowFindItemPipelineStep,
    ServiceNowStatusTransitionPipelineStep
];