import { axiosWrapper } from 'src/engrator-core';

import {IntegrationError} from './integration-error.type';

const ENDPOINT_URL = '/analysis/errors';

type IntegrationErrorsResponse = {
    integrationErrorDTOS: IntegrationError[],
    pages: number,
    currentPage: number
};

export function fetchIntegrationErrors(pageToFetch: number): Promise<IntegrationErrorsResponse> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}?page=${pageToFetch}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}