import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';
import {ServiceNowStepKey} from '../servicenow-step-key.type';
import {ServiceNowFindItemNewStep} from './servicenow-find-item-new-step';
import {ServiceNowFindItemEditStep} from './servicenow-find-item-edit-step';
import {ServiceNowFindItemConfiguration} from './servicenow-find-item-configuration';


export const ServiceNowFindItemPipelineStep: PipelineStep<ServiceNowFindItemConfiguration> = {
    addStepComponentClass: ServiceNowFindItemNewStep,
    editStepComponentClass: ServiceNowFindItemEditStep,
    specification: {
        action: 'Find Item',
        description: 'Find item in specific table',
        key: ServiceNowStepKey.ServiceNowFindItem,
        software: SoftwareName.ServiceNow,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `ServiceNowItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ServiceNowFindItemConfiguration;
        return `Find item in ${stepConfiguration.context.table?.label} table with id='${stepConfiguration.id}'`;
    }
};