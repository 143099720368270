import { axiosWrapper } from 'src/engrator-core';
import { createIntegrationListItem } from './integration-list-item';

const ENDPOINT_URL = '/integration/suites?suiteType=Workflow';

export const fetchAll = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL)
                .then((data: any) => {
                    resolve({
                        headers: ['Name', 'Last run', 'Last successful run', 'Status'],
                        data: data.integrations.map(createIntegrationListItem)
                    });
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};