import React from 'react';

import {GitLabFindItemConfiguration} from './gitlab-find-item-configuration';
import {EditPipelineStepComponentProps} from 'src/generic/pipeline-step';
import {ArtifactDirection} from '../../../../generic/artifacts';
import {UI} from '../../../../engrator-core';
import {GitLabStepContextDisplay} from '../common';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class GitLabFindItemEditStep extends React.Component<Props, State> {
    private configuration: GitLabFindItemConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as GitLabFindItemConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <GitLabStepContextDisplay configuration={ this.configuration.context } />
            <UI.LabelValue label={`Item Key`} value={ this.configuration.id } />
        </React.Fragment>;
    }
}