import React from 'react';

import {AzureCreateCommentsConfiguration} from './azure-create-comments-configuration';
import {EditPipelineStepComponentProps} from 'src/generic/pipeline-step';
import {EditCommentsStep} from '../../../generic/create-comments-step';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class AzureCreateCommentsEditStep extends React.Component<Props, State> {
    private configuration: AzureCreateCommentsConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as AzureCreateCommentsConfiguration;
    }

    render() {
        return <EditCommentsStep
            configuration={ this.configuration }
            formError={ this.props.formError }
            onConfigurationChanged={ this.onConfigurationChanged.bind(this) } />;
    }

    private onConfigurationChanged(newConfiguration: AzureCreateCommentsConfiguration) {
        this.configuration = Object.assign(this.configuration, newConfiguration);
    }
}