import React, {Component} from 'react';
import {
    canAutoCreationBeUsed,
    CanBeUsedForAutoCreationResponse,
    SharedMapping
} from '../rest-api';
import {UI} from 'src/engrator-core';
import {Side} from '../../../../generic';
import {SharedMappingsAutoCreationResolverConfig} from './shared-mappings-auto-creation-resolver-config';
import {FormError} from '../../../../engrator-core/form-error.type';

type Props = {
    sharedMapping: SharedMapping;
};
type State = {
    isLoading: boolean;
    error?: FormError;
    canBeUsed?: {
        left: CanBeUsedForAutoCreationResponse;
        right: CanBeUsedForAutoCreationResponse;
    }
}

export class SharedMappingsAutoCreationConfiguration extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true
        };
        if (!this.props.sharedMapping.configuration.autoCreationResolvers.left) {
            this.props.sharedMapping.configuration.autoCreationResolvers.left = {};
        }
        if (!this.props.sharedMapping.configuration.autoCreationResolvers.right) {
            this.props.sharedMapping.configuration.autoCreationResolvers.right = {};
        }
    }

    async componentDidMount() {
        try {
            const left = await canAutoCreationBeUsed(Side.Left, this.props.sharedMapping.id);
            const right = await canAutoCreationBeUsed(Side.Right, this.props.sharedMapping.id);
            this.setState({canBeUsed: {left, right}, isLoading: false});
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    render() {
        const {canBeUsed, isLoading} = this.state;
        return (
            <div className={`auto-create-options`}>
                <div className={`left`}>
                    <h2>Auto-Create Options</h2>
                </div>
                {this.state.error && <UI.Message appearance={'error-message'} message={this.state.error}/>}
                {isLoading && <UI.Loader visible={true} appearance={'darkgray'}/>}
                {canBeUsed && <UI.Form>
                    <div className={`flex two-columns`}>
                        <div className={`left`}>
                            <SharedMappingsAutoCreationResolverConfig
                                canBeUsedConfig={canBeUsed.left}
                                resolverConfig={this.props.sharedMapping.configuration.autoCreationResolvers?.left}
                            />
                        </div>
                        <div className={`right`}>
                            <SharedMappingsAutoCreationResolverConfig
                                canBeUsedConfig={canBeUsed.right}
                                resolverConfig={this.props.sharedMapping.configuration.autoCreationResolvers?.right}
                            />
                        </div>
                    </div>
                </UI.Form>}
            </div>
        );
    }
}
