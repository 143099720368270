import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {ShotgridSmartIntsTriggerConfiguration} from './shotgrid-smart-ints-trigger-configuration';

type Props = {
    connectionId: number;
    trigger: SmartIntTrigger;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class FreshdeskSmartIntsEditTrigger extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const configuration = (this.props.trigger.configuration as ShotgridSmartIntsTriggerConfiguration);
        return <React.Fragment>
            { configuration.project && <UI.LabelValue label={`Project`} value={ configuration.project?.name } /> }
            { !configuration.project && <UI.LabelValue label={`Project`} value={`-` } /> }
        </React.Fragment>;
    }

    private onJqlInputChanged(newValue: string) {
        this.props.trigger.configuration.query = newValue;
    }
}
