import React from 'react';
import {SmartIntTrigger} from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {SoftwareName} from '../../software-name';
import { GetintArchiverStepContextData} from '../steps/common';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class GetintArchiverSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.GetintArchiver,
            artifactName: '',
            className: '',
            configuration: {
                query: '',
            },
            connectionId: this.props.connectionId,
        };
        // Trigger immediately ondatachange event because there is no need for
        // additional configuration to be provided
        this.onDataChanged({});
    }

    render() {
        return <React.Fragment>
        </React.Fragment>;
    }

    private onDataChanged(data: GetintArchiverStepContextData) {
        this.smartIntTrigger.artifactName = 'task';
        this.props.onConfigured(this.smartIntTrigger!);
        this.forceUpdate();
    }
}
