import React from 'react';

import {UI} from 'src/engrator-core';
import {DropdownOption} from 'src/engrator-core/ui';
import {PredefinedPropertyDefinition, PropertyDefinition} from '../../../../generic/artifacts';
import {getDirectionForNewMapping, PropertyMapping} from '../visual/property-mapping.type';
import {getDefaults} from '../visual/components/value-mappings/default-mappping.type';
import {AddPropertyManually} from './add-property-manually';
import {SoftwareName} from '../../../../software';
import {FieldMapping} from '../visual/fields-manager';
import {AppsSupport} from '../visual/visual-integration-designer';
import {SmartIntDefinitionTypeMapping} from '../definition/smart-int-definition--type-mapping.type';
import {SmartIntTriggersPair} from '../definition/smart-int-trigger.type';
import {ValueMappingsGroup} from '../visual/components/value-mappings/value-mappings-group.type';
import {PropertiesContext} from './property-mappings-picker';

type Props = {
    options: { left: DropdownOption[]; right: DropdownOption[] };
    addMappingHandler: (mapping: PropertyMapping) => Promise<void>;
    addMappingHeader?: string;
    appsSupport: AppsSupport;
    typeMapping: SmartIntDefinitionTypeMapping;
    triggers: SmartIntTriggersPair;
    propertiesContext: PropertiesContext;
    valueGroup?: ValueMappingsGroup;
    mappings: PropertyMapping[];
}
type State = {
    showAddFieldManually?: 'left' | 'right';
    showNewMapping: boolean;
    mappings: PropertyMapping[];
}

export class AddMapping extends React.Component<Props, State> {
    private leftProperty?: PropertyDefinition;
    private rightProperty?: PropertyDefinition;
    rightPredefinedValue: string = '';
    leftPredefinedValue: string = '';

    constructor(props: Props) {
        super(props);
        this.state = {
            mappings: JSON.parse(JSON.stringify(props.mappings)),
            showAddFieldManually: undefined,
            showNewMapping: false
        }
    }

    async componentDidMount() {
        // await this.setState({ showNewMapping: await isFeatureFlagEnabled(FeatureFlag.FF_NEW_FIELD_MAPPING) });
        await this.setState({ showNewMapping: true });
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): Promise<void> {
        if (JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)) {
            await this.forceUpdate();
        }
        if (JSON.stringify(this.props.mappings) !== JSON.stringify(this.state.mappings)) {
            await this.setState({ mappings: JSON.parse(JSON.stringify(this.props.mappings)) });
        }
    }

    render() {
        // if (this.state.showNewMapping) {
        return <FieldMapping
            propertiesContext={ this.props.propertiesContext }
            valueGroup={ this.props.valueGroup }
            addMappingHandler={ this.props.addMappingHandler }
            appsSupport={this.props.appsSupport}
            options={ this.props.options }
            triggers={ this.props.triggers }
            typeMapping={ this.props.typeMapping }
            mappings={ this.state.mappings }
        />;
        // }
        const addButtonEnabled = (this.leftProperty && this.rightProperty);
        return <UI.FormGroup
            label={(this.props.addMappingHeader) ? this.props.addMappingHeader : `Map available fields`}>
            { this.state.showAddFieldManually && <AddPropertyManually
                closeHandler={ (object) => this.addOption(object) }
            /> }
            <div className={`add-mapping`}>
                <div className={`row-two-cols`}>
                    <div className={`left`}>
                        <UI.Dropdown
                            defaultValue={ this.leftProperty?.id }
                            onChange={(value: any, object: any) => this.optionChanged(value, object, 'left')}
                            options={this.props.options.left}/>
                        { this.props.appsSupport.leftApp === SoftwareName.Jira && <UI.Button
                            appearance={'link-inline'}
                            onClick={ () => this.setState({ showAddFieldManually: 'left' })}
                            text={`Add field manually`}
                        /> }
                        <UI.Input
                            label={`or type predefined value`}
                            defaultValue={ this.leftPredefinedValue }
                            onChange={(value) => this.setPredefinedValue('left', value)}/>
                    </div>
                    <div className={`right`}>
                        <UI.Dropdown
                            defaultValue={''}
                            onChange={(value: any, object: any) => this.optionChanged(value, object, 'right')}
                            options={this.props.options.right}/>
                        { this.props.appsSupport.leftApp === SoftwareName.Jira && <UI.Button appearance={'link-inline'} onClick={ () => this.setState({ showAddFieldManually: 'right' })} text={`Add field manually`} /> }
                        <UI.Input
                            defaultValue={ this.rightPredefinedValue }
                            label={`or type predefined value`}
                            onChange={(value) => this.setPredefinedValue('right', value)}/>
                    </div>
                </div>
                <UI.ButtonsBar primary={
                    <UI.Button disabled={!addButtonEnabled} onClick={() => this.addMappingClicked()} text={`Add`}/>
                }/>
            </div>
        </UI.FormGroup>;
    }

    private addOption(object?: DropdownOption) {
        if (object && this.state.showAddFieldManually) {
            this.props.options[this.state.showAddFieldManually].push(object);
        }
        this.setState({ showAddFieldManually: undefined });
    }

    private addMappingClicked() {
        if (this.leftProperty && this.rightProperty) {
            this.props.addMappingHandler({
                left: this.leftProperty,
                right: this.rightProperty,
                direction: getDirectionForNewMapping(this.leftProperty, this.rightProperty),
                options: {
                    left: {
                        onCreate: true,
                        onUpdate: true,
                        postCreate: this.shouldPostCreateBeAutoChecked(this.leftProperty, this.rightProperty),
                        filterOptionsQuery: '',
                        predefinedValue: this.leftPredefinedValue
                    },
                    right: {
                        onCreate: true,
                        onUpdate: true,
                        postCreate: this.shouldPostCreateBeAutoChecked(this.rightProperty, this.leftProperty),
                        filterOptionsQuery: '',
                        predefinedValue: this.rightPredefinedValue
                    }
                },
                groups: [],
                defaults: getDefaults(this.leftProperty, this.rightProperty),
                empty: {
                    left: '',
                    right: ''
                },
                additional: {
                    azureEmailIsJiraUsername: false
                }
            });
        }
        this.leftProperty = undefined;
        this.leftPredefinedValue = '';
        this.rightProperty = undefined;
        this.rightPredefinedValue = '';
    }

    private optionChanged(value: any, mappingObject: PropertyDefinition, side: 'left' | 'right') {
        if (side === 'left') {
            this.leftProperty = mappingObject;
            this.leftPredefinedValue = '';
        } else if (side === 'right') {
            this.rightProperty = mappingObject;
            this.rightPredefinedValue = '';
        }
        this.forceUpdate();
    }

    private setPredefinedValue(side: 'left' | 'right', value: string) {
        if (side === 'left') {
            this.leftProperty = PredefinedPropertyDefinition;
            this.leftPredefinedValue = value;
        } else if (side === 'right') {
            this.rightProperty = PredefinedPropertyDefinition;
            this.rightPredefinedValue = value;
        }
        this.forceUpdate();
    }

    private shouldPostCreateBeAutoChecked(target: PropertyDefinition, source: PropertyDefinition) {
        if ((!target.options?.readonly) && (source.id === 'id' || source.id === '_url')) {
            return true;
        }
        return false;
    }
}
