import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';
import {WrikeStepKey} from '../wrike-step-key.type';
import {WrikeCreateCcommentsNewStep} from './wrike-create-comments-new-step';
import {WrikeCreateCommentsEditStep} from './wrike-create-comments-edit-step';
import {WrikeCreateCommentsConfiguration} from './wrike-create-comments-configuration';


export const WrikeCreateCommentsPipelineStep: PipelineStep<WrikeCreateCommentsConfiguration> = {
    addStepComponentClass: WrikeCreateCcommentsNewStep,
    editStepComponentClass: WrikeCreateCommentsEditStep,
    specification: {
        action: 'Create task comment(s)',
        description: 'Create comment(s) for selected task',
        key: WrikeStepKey.WrikeCreateComments,
        software: SoftwareName.Wrike,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `WrikeCard`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as WrikeCreateCommentsConfiguration;
        return `Create comment(s) for task '${stepConfiguration.targetArtifact}'`;
    }
};