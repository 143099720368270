import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';
import {StatusTransition, StatusTransitionConfiguration} from 'src/software/generic/status-transition';

import {JiraStatusTransitionConfiguration} from './jira-status-transition-configuration';

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class JiraStatusTransitionNewStep extends React.Component<Props, State> {
    private configuration: JiraStatusTransitionConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as JiraStatusTransitionConfiguration;
    }

    render() {
        return <React.Fragment>
            <StatusTransition
                onConfigurationChanged={ this.onConfigurationChanged.bind(this) }
                artifactsFactory={ this.props.context.artifactsFactory }
                stepSpecification={ this.props.context.stepSpecification }
                connectionId={ this.props.context.connectionId }
                softwareName={ SoftwareName.Jira }
                availableArtifacts={ this.props.context.artifactsFactory.getAvailableOutArtifactsForSoftware(SoftwareName.Jira) }
            />
        </React.Fragment>
    }

    private onConfigurationChanged(configuration: StatusTransitionConfiguration) {
        if (configuration.targetArtifact) {
            const artifactConnectionId = this.props.context.artifactsFactory.getConnectionIdForArtifact(configuration.targetArtifact);
            if (artifactConnectionId) {
                this.props.context.connectionId = artifactConnectionId;
            }
        }
        Object.assign(this.configuration, configuration);
    }
}