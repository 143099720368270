import React, {useEffect} from 'react';
import { UI } from 'src/engrator-core';
import { TenantsList } from './tenants';
import {useGlobalState} from '../global-store';
import {MainMenuItem} from '../global-store/create-global-state';

type State = {}
type Props = {};

export class ClusterTenantsPageCmp extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <UI.Page className={`cluster-tenants-page`}>
                <TenantsList />
            </UI.Page>
        )
    }
}

export function ClusterTenantsPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Workspaces);
        setMenu(null);
    }, []);
    return <ClusterTenantsPageCmp />
}