import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';

import {JiraStepKey} from '../jira-step-key.type';
import {JiraStatusTransitionEditStep} from './jira-status-transition-edit-step';
import {JiraStatusTransitionNewStep} from './jira-status-transition-new-step';
import {JiraStatusTransitionConfiguration} from './jira-status-transition-configuration';

export const JiraStatusTransitionPipelineStep: PipelineStep<JiraStatusTransitionConfiguration> = {
    addStepComponentClass: JiraStatusTransitionNewStep,
    editStepComponentClass: JiraStatusTransitionEditStep,
    specification: {
        action: 'Change issue status',
        description: 'Move issue from one status to another',
        key: JiraStepKey.JiraStatusTransition,
        software: SoftwareName.Jira,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as JiraStatusTransitionConfiguration;
        return `Change issue status`;
    }
}