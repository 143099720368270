import React from 'react';


type IntegrationModuleTabBarProps = {
    selectedIndex: number;
    className?: string;
};

type State = {
    showNotifications: boolean;
}

export class IntegrationModuleTabBar extends React.Component<IntegrationModuleTabBarProps, State> {
    constructor(props: Readonly<IntegrationModuleTabBarProps>) {
        super(props);
        this.state = {
            showNotifications: false
        }
    }

    componentDidMount() {
        
    }

    render() {
        return <div className={`tabbar ${this.props.className || ''}`}>
            {/*<UI.TabButton text="Integrations" path="/app/integration/smart-ints" isSelected={ this.props.selectedIndex === 0 } />*/}
            {/* <UI.TabButton text="Workflows" path="/app/integration/pipelines" isSelected={ this.props.selectedIndex === 1 } />*/}
            {/*<UI.TabButton text="Connections" path="/app/integration/connections" isSelected={ this.props.selectedIndex === 2 } />*/}
            {/*<UI.TabButton text="Notifications" path="/app/integration/notifications" isSelected={ this.props.selectedIndex === 3 } />*/}
            { this.props.children }
        </div>;
    }
}