import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper, UI} from 'src/engrator-core';
import {ServiceNowSearchItemsConfiguration} from './servicenow-search-items-configuration';
import {IntegrationStepConfiguration} from 'src/generic/software/step';
import { ServicenowStepContext, ServicenowStepContextData } from '../common';
import { ArtifactDirection } from 'src/generic/artifacts';
type Props = {
} & NewPipelineStepComponentProps;
type State = {
    formError: { [key: string]: string },
    canConfigureArtifact: boolean,
    isLoadingJql: boolean,
    isAdding: boolean
};

export class ServiceNowSearchItemsNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: ServiceNowSearchItemsConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as ServiceNowSearchItemsConfiguration;
        this.configuration.onlyNewChanges = true;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'boardId' | 'listId', newValue) => {
            // this.configuration[propertyName] = newValue;
            // this.checkIfArtifactCanBeCreated();
            this.onConfigurationChanged();
        });
        this.state = { isLoadingJql: false, formError: {}, canConfigureArtifact: false, isAdding: false };
    }

    onFormChange(elementThatChanged: 'customJQL', newValue: string) {
        this.configuration[elementThatChanged] = newValue;
    }

    private onConfigurationChanged() {
        // this.configuration.project = data.project!;
        // this.configuration.itemType = data.itemType!;
    }

    render() {
        return <div className={`connectors-servicenow-search-items-step`}>
            <ServicenowStepContext
                connectionId={ this.props.context.connectionId }
                onDataChanged={ this.onContextDataChanged.bind(this) }
            />
            <UI.FormGroup
                label={`Changed from last integration`}
                description={`If checked, only items that changed since last integration will be picked up.`}
            >
                <UI.Checkbox defaultValue={'true'} onChange={ this.onlyNewChanged.bind(this) } checkedValue={ 'true' } uncheckedValue={ 'false '}/>
            </UI.FormGroup>
        </div>
    }

    onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }

    private onContextDataChanged(data: ServicenowStepContextData) {
        if (data.table) {
            this.configuration.context = data;
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }

    private getStepConfiguration(configuration: ServiceNowSearchItemsConfiguration): IntegrationStepConfiguration {
        return {
            data: configuration,
            connectionId: this.props.context.connectionId,
            stepSpecification: this.props.context.stepSpecification,
            inDataArtifact: null,
            outDataArtifact: null,
            rules: []
        };
    }
}
