import React from 'react';
import {UI} from 'src/engrator-core';
import {FeatureFlag, isFeatureFlagEnabled} from '../top-navigation/rest-api';
import {HelpCallButton} from './help-call-button';

type Props = {};
type State = {
    currentStep: number;
    showBookDemo: boolean;
};

function BtnLinks(props: any) {
    return <div className={`create-link`}>
        <UI.NavLink path={`/app/integration/smart-ints/create`}>Create Integration</UI.NavLink>
        <HelpCallButton
            // bookDemoPageClicked={ () => props.bookDemoPageClicked() }
        />
    </div>;
}

export class StartGuide extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentStep: 1,
            showBookDemo: false
        };
    }

    async componentDidMount() {
        const showBookDemo = await isFeatureFlagEnabled(FeatureFlag.SHOW_BOOK_DEMO);
        this.setState({ showBookDemo });
    }

    changeStep(step: number): void {
        this.setState({currentStep: step});
    }

    render() {
        return <div className={`engagement start-guide`}>
            <BtnLinks
                bookDemoPageClicked={ () => this.bookDemoPageClicked() }
            />
            <ul>
                <li className={`${(this.state.currentStep === 1) ? 'active' : ''}`} onClick={() => this.changeStep(1)}>
                    <div>
                        <React.Fragment>
                            <h2>1. Establish Connections Between Your Apps for Seamless Synchronization/Migration</h2>
                            <p>Getint utilizes REST API for interacting with the applications you intend to synchronize or migrate. As modern APIs necessitate authenticated communication, Getint needs to incorporate your credentials in every API request to access data from your specific application.</p>
                            <p>Connect to your desired apps by supplying the necessary credentials. Make sure to use a user account with appropriate permissions to allow Getint to execute required actions such as creating or updating records.</p>
                        </React.Fragment>
                    </div>
                </li>
                <li className={`${(this.state.currentStep === 2) ? 'active' : ''}`} onClick={() => this.changeStep(2)}>
                    <div>
                        <React.Fragment>
                            <h2>2. Craft Your Initial, Simplified Integration</h2>
                            <p>Begin your journey with Getint by constructing your first integration. Initiate with type mapping such as task-to-task, bug-to-bug, and so forth. Keep it straightforward at first, focusing on mapping only the most fundamental fields and testing the integration.</p>
                            <p>Once successful synchronization is achieved, feel free to enhance your integration by introducing more type mappings. Simply click on the type mapping to configure it further, incorporating more field mappings, or enabling the synchronization of comments and attachments.</p>
                        </React.Fragment>
                    </div>
                </li>
                <li className={`${(this.state.currentStep === 3) ? 'active' : ''}`} onClick={() => this.changeStep(3)}>
                    <div>
                        <React.Fragment>
                            <h2>3. Kickstart Synchronization by Testing Your Integration</h2>
                            <p>Upon the creation of your integration, Getint will continuously monitor for modifications to the item types (e.g., task, story, incident) you've integrated. Test it out by editing or creating an item of one of these types in any of your apps.</p>
                            <p>This action should instigate the item's sync, effectively transferring its data between applications. If the synchronization doesn't occur, there may be an issue with the settings. Don't hesitate to reach out to our support team for assistance. If you're seeking data migration specifics, contact us for more information.</p>
                        </React.Fragment>
                    </div>
                </li>
                <li className={`${(this.state.currentStep === 4) ? 'active' : ''}`} onClick={() => this.changeStep(4)}>
                    <div>
                        <React.Fragment>
                            <h2>4. Remain Updated on Any Occurring Errors</h2>
                            <p>Navigate to the <UI.NavLink path={`/app/integration/notifications`}>Notifications</UI.NavLink> section to set up alerts through various channels such as Slack and Email. Any integration or data synchronization errors that arise will be promptly reported via your chosen channel.</p>
                            <p>Stay informed, respond quickly, and maintain smooth operations with Getint.</p>
                        </React.Fragment>
                    </div>
                </li>
                <li className={`${(this.state.currentStep === 5) ? 'active' : ''}`} onClick={() => this.changeStep(5)}>
                    <div>
                        <React.Fragment>
                            <h2>5. Secure Support, Consultation, or Custom Development As Required</h2>
                            <p>Should you need assistance, don't hesitate to open a support ticket or schedule a call with us. Our team is equipped to help you navigate the platform, guide you in preparing your integrations, resolve any issues you may encounter, or even provide tailored features for your specific use case.</p>
                        </React.Fragment>
                    </div>
                </li>
            </ul>
            <BtnLinks
                bookDemoPageClicked={ () => this.bookDemoPageClicked() }
            />
        </div>
    }

    private bookDemoPageClicked(): void {
        (window as any).open('https://getint.io/demo?src=app', '_blank').focus();
    }
}