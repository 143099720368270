import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';
import {JiraStepKey} from '../jira-step-key.type';
import {JiraFindIssueNewStep} from './jira-find-issue-new-step';
import {JiraFindIssueEditStep} from './jira-find-issue-edit-step';
import {JiraFindIssueConfiguration} from './jira-find-issue-configuration';


export const JiraFindIssuePipelineStep: PipelineStep<JiraFindIssueConfiguration> = {
    addStepComponentClass: JiraFindIssueNewStep,
    editStepComponentClass: JiraFindIssueEditStep,
    specification: {
        action: 'Find issue',
        description: 'Find Jira issue in specific project',
        key: JiraStepKey.JiraFindItem,
        software: SoftwareName.Jira,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `jiraIssue`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as JiraFindIssueConfiguration;
        return `Find issue in ${stepConfiguration.project.name} of ${stepConfiguration.issueType.name} type`;
    }
};