import {axiosWrapper, FieldError} from 'src/engrator-core';
import {AsanaWorkspaceTypeDTO} from './common/asana-workspace-type-dto.type';
import {AsanaProjectDTO} from './common/asana-project-dto.type';

export const ENDPOINT_URL = '/connectors/asana';

export const fetchWorkspaces = (connectionId: number): Promise<AsanaWorkspaceTypeDTO[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/workspaces')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchProjects = (connectionId: number, workspaceId: string): Promise<AsanaProjectDTO[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/workspaces/' + workspaceId + '/projects')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export async function canUsePremiumSearchEndpoint(connectionId: number, workspaceId: string, projectId: string): Promise<boolean> {
    try {
        return await axiosWrapper.get(ENDPOINT_URL + '/can-use-premium-endpoint/' + connectionId + '/' + workspaceId + '/' + projectId) as boolean;
    } catch (e) {
        return false;
    }
}