import React from 'react';
import { JiraProjectIssuetypePickerData} from '../steps/common';
import {SmartIntTrigger} from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {UI} from 'src/engrator-core';
import {SuiteType} from '../../../generic/integrations/suite-type';
import {IntegrationContext} from '../../../app/integration/smartints/pages/integration-context';

type Props = {
    connectionId: number;
    trigger: SmartIntTrigger;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

type JiraDatabase = {
    jdbc: string;
    username: string;
    password: string;
}
export class JiraSmartIntsEditTrigger extends React.Component<Props, State> {
    static contextType = IntegrationContext;

    constructor(props: Props) {
        super(props);
    }

    render() {
        const {getIntegrationType} = this.context;
        if (getIntegrationType() === SuiteType.Git) {
            return <React.Fragment/>;
        }
        return <React.Fragment>
            <UI.FormGroup>
                <UI.LabelValue
                    label={`Project`}
                    value={this.props.trigger.configuration.project.name}
                    subvalue={ `${this.props.trigger.configuration.project.key}, ${this.props.trigger.configuration.project.id}` }
                />
            </UI.FormGroup>
            <UI.FormGroup>
                <UI.Checkbox
                    label={`Fetch whole Jira instance fields (not only create meta fields)`}
                    checkedValue={`true`}
                    uncheckedValue={`false`}
                    defaultValue={`${this.props.trigger.configuration.fetchAllFields}`}
                    onChange={ this.switchAllFields.bind(this) }
                />
            </UI.FormGroup>
            <UI.FormGroup
                label={`Custom JQL`}
                description={`You can provide JQL which will be appended to the one generated when searching for issues in Jira (eg: labels = sync)`}
            >
                <UI.Input defaultValue={ this.props.trigger.configuration.jql } label={``} onChange={ this.onJqlInputChanged.bind(this)} />
            </UI.FormGroup>
        </React.Fragment>
    }

    private changeDatabaseProperty(property: keyof JiraDatabase, newValue: string) {
        let database: JiraDatabase = this.props.trigger.configuration.database;
        if (!database) {
            database =  {
                jdbc: '',
                username: '',
                password: ''
            };
        }
        database[property] = newValue;
        this.props.trigger.configuration.database = database;
    }

    private switchFetchRenderedFields(newValue: string) {
        this.props.trigger.configuration.fetchRenderedFields = (newValue === 'true') ? true : false;
    }

    private switchAllFields(newValue: string) {
        this.props.trigger.configuration.fetchAllFields = (newValue === 'true') ? true : false;
    }

    private onJqlInputChanged(newValue: string) {
        this.props.trigger.configuration.jql = newValue;
    }

    private onDataChanged(data: JiraProjectIssuetypePickerData) {
        if (data.project) {
            this.forceUpdate();
        }
    }
}
