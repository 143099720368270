import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';
import {WrikeStepKey} from '../wrike-step-key.type';
import {WrikeFindItemNewStep} from './wrike-find-item-new-step';
import {WrikeFindItemEditStep} from './wrike-find-item-edit-step';
import {WrikeFindItemConfiguration} from './wrike-find-item-configuration';


export const WrikeFindItemPipelineStep: PipelineStep<WrikeFindItemConfiguration> = {
    addStepComponentClass: WrikeFindItemNewStep,
    editStepComponentClass: WrikeFindItemEditStep,
    specification: {
        action: 'Find task',
        description: 'Find task in specific folder',
        key: WrikeStepKey.WrikeFindItem,
        software: SoftwareName.Wrike,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `WrikeTask`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as WrikeFindItemConfiguration;
        return `Find task in ${stepConfiguration.context?.space?.name}, ${stepConfiguration.context?.folder?.name} folder with id='${stepConfiguration.id}'`;
    }
};