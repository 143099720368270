import React, {Component} from 'react';

import {UI} from 'src/engrator-core';
import {stopRun} from '../rest-api';
import {IntegrationThreadPersistedArtifactInfo} from './integration-thread-persisted-artifact-info';
import {IntegrationThreadRunInfo} from './integration-thread-run-info';
import {IntegrationThreadState} from './integration-thread-state.type';

type Props = {
    threadState: IntegrationThreadState
};
type State = {
    stoppedRun: boolean;
};

export class IntegrationThreadTile extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            stoppedRun: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.threadState.runStatus !== this.props.threadState.runStatus) {
            this.setState({stoppedRun: false});
        }
    }

    render() {
        const className = (!this.props.threadState.lastStartTime) ? 'inactive' : '';
        const showStopRunBtn = this.props.threadState.runStatus === 'Running' && this.state.stoppedRun == false;
        return <div className={`integration-thread-tile ${className}`}>
            <UI.Tile>
                <div>
                    <p className={`info`}>{this.props.threadState.runStatus}</p>
                    <p className={`thread-id`}><span>thread</span><br/>#{this.props.threadState.threadId}</p>
                    {/*<p>Status: { this.props.threadState.status }</p>*/}
                    { !showStopRunBtn && <div className={`execution-time`}>
                        {this.props.threadState.formattedLastStartTime &&
                            <UI.ExecutionTime startTime={this.props.threadState.formattedLastStartTime}
                                formattedExecutionTime={this.props.threadState.formattedLastExecutionTime}/>}
                    </div> }
                    { showStopRunBtn && <UI.Button
                        onClick={() => this.stopIntegrationRun()}
                        appearance="secondary"
                        text={`Stop Run`}
                    /> }
                </div>
                <div>
                    <h4 className={`last-runs`}>Last Runs</h4>
                    {this.props.threadState.performedRuns.map((run, index) =>
                        <IntegrationThreadRunInfo run={run} key={index}/>,
                    )}
                </div>
                <div>
                    <h4>Last Synced Items</h4>
                    {this.props.threadState.persistedArtifacts.map((persistedArtifact, index) =>
                        <IntegrationThreadPersistedArtifactInfo persistedArtifact={persistedArtifact} key={index}/>,
                    )}
                </div>
            </UI.Tile>
        </div>;
    }

    private stopIntegrationRun() {
        this.setState({stoppedRun: true}, () => {
            stopRun()
                .catch(() => {
                    console.log('stopped');
                });
        });
    }
}
