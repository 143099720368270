import React from 'react';
import {AuthToken, UI} from 'src/engrator-core';
import {WorkspaceSelection} from '../auth/workspace-selection';

type Props = {
    onCloseHandler: () => void;
}

type State = {
    isOpened: boolean;
    showSwitchWorkspaceWindow: boolean;
}

export class AccountDropdown extends React.Component<Props, State> {
    private wrapperRef: any;

    constructor(props: Props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.state = {
            showSwitchWorkspaceWindow: false,
            isOpened: true
        };
    }

    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.documentClick.bind(this));
        document.addEventListener('touchstart', this.documentClick.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.documentClick);
        document.removeEventListener('touchstart', this.documentClick);
    }

    documentClick(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpened) {
            this.setState({isOpened: false}, () => {
                this.props.onCloseHandler();
            });
        }
    }

    private switchPopup() {
        this.setState({isOpened: !this.state.isOpened});
    }

    render() {
        if (!this.state.isOpened) {
            return <React.Fragment />;
        }
        console.log(AuthToken.isClusterAdmin(), AuthToken.getWorkspaces());
        return <div className={`account-dropdown`} ref={ this.setWrapperRef }>
            {/*<span className={`close`} onClick={this.props.onCloseHandler}><UI.Icon icon20={ Icon20.Close }/></span>*/}
            <ul>
                <li><UI.NavLink path={`/app/system/password`}>Account</UI.NavLink></li>
                {/*<li>*/}
                {/*    <a onClick={ () => this.switchWorkspaceClicked() }>Account</a>*/}
                {/*</li>*/}
                { (AuthToken.isClusterAdmin() || AuthToken.getWorkspaces()?.length > 1) && <li>
                    <a onClick={ () => this.switchWorkspaceClicked() }>Switch workspace</a>
                </li> }
            </ul>
            { this.state.showSwitchWorkspaceWindow && <WorkspaceSelection
                closeHandler={ () => this.switchWorkspaceClicked() }
            /> }
        </div>;
    }

    private switchWorkspaceClicked(): void {
        this.setState({ showSwitchWorkspaceWindow: !this.state.showSwitchWorkspaceWindow });
    }
}
