import React from 'react';
import {EntityFormHelper, FieldError, UI} from 'src/engrator-core';
import {FormError} from 'src/engrator-core/form-error.type';
import {DropdownOption} from 'src/engrator-core/ui';
import { fetchTables } from './../rest-api';
import {GitLabProject} from './gitlab-project.type';

export type GitLabStepContextData = {
    project?: GitLabProject;
};

type Props = {
    connectionId: number;
    onDataChanged: (data: GitLabStepContextData, isValid: boolean) => void;
    formError?: FormError;
}

type State = {
    formError: FormError;
    isLoadingTables: boolean;
}

export class GitLabStepContext extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private projectOptions: DropdownOption[] = [];
    private contextData : GitLabStepContextData = {};

    constructor(props: Props) {
        super(props);
        this.state = {formError: {}, isLoadingTables: true };
        this.entityFormHelper = new EntityFormHelper((propertyName: 'project', newValue, additionalArgument?: any) => {
            if (!additionalArgument) {
                return;
            }
            if (propertyName === 'project') {
                this.contextData.project = additionalArgument as GitLabProject;
            }
            const isValid = (!!this.contextData.project);
            this.props.onDataChanged(this.contextData, isValid);
            this.forceUpdate();
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.forceUpdate();
        }
    }

    componentDidMount(): void {
        fetchTables(this.props.connectionId)
            .then((organizations: GitLabProject[]) => {
                this.projectOptions = organizations.map(organization => ({
                    label: organization.name,
                    value: organization.id,
                    object: organization
                }));
                this.setState({isLoadingTables: false});
            })
            .catch((error: FieldError) => {
                this.setState({ isLoadingTables: false, formError: { ['pickerError']: error.message  } });
            });
    }

    render() {
        return <React.Fragment>
            { this.state.formError.pickerError && <UI.Message appearance={'error'}>{ this.state.formError.pickerError}</UI.Message> }
            <UI.FormGroup
                dataSel={`project`}
                label={`Project`}
                error={ this.props.formError?.table }
                description={``}
                isRequired={true}>
                { this.state.isLoadingTables && <UI.Loader visible={true} appearance={'darkgray'} /> }
                { !this.state.isLoadingTables && <UI.Dropdown options={ this.projectOptions } onChange={ this.entityFormHelper.onChangeHandler('project')   }/> }
            </UI.FormGroup>
        </React.Fragment>;
    }
}