import {StepsFactory} from '../steps-factory';
import {IntegrationStep} from '../software/step/inetgration-step';
import {DebuggingStep} from '../software/step';
import {Artifacts, PipelineIntegrationStep} from '../index';
import {StepDebuggingState} from '../debugging';
import {DesignerEventType, EventsBus} from '../events-bus';
import {GenericIntegration} from './generic-integration';

export class StepedIntegration extends GenericIntegration{
    steps: Array<IntegrationStep> = [];
    onStepsListChangedListener: any;
    stepsFactory: StepsFactory;
    artifactsFactory: Artifacts.ArtifactsFactory;
    eventBus: EventsBus;

    constructor(name: string, artifactsFactory: Artifacts.ArtifactsFactory, stepsFactory: StepsFactory, eventsBus: EventsBus) {
        super(name);
        this.steps = [];
        this.stepsFactory = stepsFactory;
        this.artifactsFactory = artifactsFactory;
        this.eventBus = eventsBus;
    }

    setOnStepsListChangedListener = (onStepsListChanged: any) => {
        this.onStepsListChangedListener = onStepsListChanged;
    }

    addStep(step: PipelineIntegrationStep) {
        this.steps.push(step);
        if (step.getOutArtifact()) {
            const outDataArtifact = step.getOutArtifact() as Artifacts.DataArtifact;
            this.artifactsFactory.registerArtifact(1, outDataArtifact, step.getSoftwareName(), step.configuration.data, step.configuration.connectionId);
        }
        this.eventBus.notify(DesignerEventType.StepsChangedEvent, {});
    }

    deleteStep(step: PipelineIntegrationStep) {
        if (this.steps.indexOf(step) >= 0) {
            this.steps.splice(this.steps.indexOf(step), 1);
        }
        this.eventBus.notify(DesignerEventType.StepsChangedEvent, {});
    }

    isLastStep(step: PipelineIntegrationStep): boolean {
        return this.getSteps().indexOf(step) === this.getSteps().length - 1;
    }

    getSteps(): Array<any> {
        return this.steps;
    }

    startDebugging(): void {
        // this.steps.forEach((step, index) => {
        //     step.setDebuggingState({
        //         stepIndex: index,
        //         status: StepDebuggingStatus.Idle,
        //         output: null
        //     });
        // })
    }

    getDebuggingSteps(firstStepConfiguration: any): Array<DebuggingStep> {
        const debuggingSteps: Array<DebuggingStep> = this.steps.map((step, index) => ({
            stepConfiguration: step.getConfiguration(),
            additionalConfiguration: (index === 0) ? firstStepConfiguration : null
        }));
        return debuggingSteps;
    }

    setDebuggingStateForStep(state: StepDebuggingState) {
        // this.steps[state.stepIndex].setDebuggingState(state);
    }

    getFirstTriggerStep(): IntegrationStep {
        return this.steps[0];
    }

    getStepsFactory() {
        return this.stepsFactory;
    }
}
