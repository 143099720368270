import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';

import {ZendeskCreateItemConfiguration} from './zendesk-create-item-configuration';

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class ZendeskCreateItemNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: ZendeskCreateItemConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as ZendeskCreateItemConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: '', newValue) => {
            this.onConfigurationChanged();
        });
    }

    componentDidMount() {
        this.props.showArtifactBuilder(ArtifactDirection.In);
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            
        </React.Fragment>
    }

    private onConfigurationChanged() {
        // this.props.showArtifactBuilder(ArtifactDirection.In);
        // this.props.showArtifactBuilder(ArtifactDirection.Out);
    }
}
