import {ISoftwareFactory, SoftwareName} from '../../../../../software';
import React from 'react';
import {AppSmartIntsSupport} from '../../app-smart-ints-support';
import {UI} from '../../../../../engrator-core';
import {SmartIntTrigger} from '../../definition/smart-int-trigger.type';
import {fetchConnection} from 'src/app/integration/connections/rest-api';
import {ConnectionModel} from '../../../connections/connection-model';
import {Icon20} from '../../../../../engrator-core/ui';
import {ChangeConnection} from './change-connection';
import { Route, RouteComponentProps } from 'react-router-dom';

type State = {
    connection?: ConnectionModel;
    isLoadingConnection: boolean;
    isEditingConnection: boolean;
    connectionId: number;
};

type Props = {
    softwareFactory: ISoftwareFactory;
    optionSelected: (softwareName: SoftwareName) => void;
    app: SoftwareName;
    connectionId: number;
    trigger: SmartIntTrigger;
    close: () => void;
}

export class EditApp extends React.Component<Props, State> {
    private smartIntsSupport: AppSmartIntsSupport | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoadingConnection: true,
            isEditingConnection: false,
            connectionId: this.props.connectionId
        };
        this.smartIntsSupport = this.props.softwareFactory.getSoftwareByName(this.props.app)!.getSmartIntsSupport();
    }

    async componentDidMount() {
        await this.loadConnectionData();
    }

    async loadConnectionData() {
        await this.setState({ isLoadingConnection: true });
        const connection = await fetchConnection(this.state.connectionId)
        await this.setState({ isLoadingConnection: false, connection });
    }

    render() {
        return <UI.FullScreenModal
            header={`Edit App (${ this.props.app})`}
            showPrimaryBtn={false}
            primaryBtnHandler={ () => Promise.resolve(true) }
            closeBtnHandler={ () => this.props.close() }
        >
            <UI.Form>
                { this.state.isLoadingConnection && <UI.LabelValue
                    label={`Connection`}
                    value={<UI.Loader visible={true} appearance={'darkgray'}/>}
                /> }
                { this.state.connection && <div className={`connection-details`}>
                    <UI.LabelValue
                        label={`Connection`}
                        value={ <span>
                            { this.state.connection.name } <Route render={({ history }: RouteComponentProps) => <div className={'edit-connection'} onClick={ () => history.push(`/app/integration/connections?id=${this.state.connectionId}`) }>(<span>#{this.state.connectionId}</span>)</div>}/><br/>
                            <a target={`blank`} href={this.state.connection.url}>{ this.state.connection.url }</a><br/></span>
                        } />
                    <UI.MarginTop>
                        { !this.state.isEditingConnection && <UI.Button
                            appearance={'secondary'}
                            icon={<UI.Icon icon20={Icon20.Edit}/> }
                            text={`Change Connection`}
                            onClick={() => this.changeConnectionClicked()}
                        /> }
                        { this.state.isEditingConnection && <ChangeConnection
                            changeHandler={ (newConnectionId: number) => this.changeConnectionHandler(newConnectionId)}
                            softwareName={ this.state.connection.softwareName }
                        /> }
                    </UI.MarginTop>
                </div> }
                { this.smartIntsSupport?.getEditTriggerConfigurationComponent(this.state.connectionId, this.props.trigger, this.onConfigured.bind(this))}
            </UI.Form>
        </UI.FullScreenModal>
    }

    private changeConnectionClicked() {
        this.setState({ isEditingConnection: true });
    }

    private onConfigured(configuration: SmartIntTrigger) {

    }

    private async changeConnectionHandler(newConnectionId: number) {
        await this.setState({ connectionId: newConnectionId, isEditingConnection: false });
        await this.loadConnectionData();
        this.props.trigger.connectionId = newConnectionId;
    }
}
