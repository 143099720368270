import { PropertyDataType } from 'src/generic/artifacts';
import { SmartIntDefinitionTypeMapping } from '../definition/smart-int-definition--type-mapping.type';
import { SmartIntDirection } from '../definition/smart-int-direction.type';
import { DefaultMappingMode } from '../visual/components/value-mappings/default-mappping.type';
import { PropertyMapping } from '../visual/property-mapping.type';

export function getPropertyMappingWarning(mapping: PropertyMapping): string {
    if (mapping.right.id === 'status' || mapping.left.id === 'status') {
        return `Mapping status should be setup on STATUS tab. Your integration most probably will fail syncing data.`;
    }
    if (((mapping.left.dataType === PropertyDataType.IdLabel
        && mapping.right.dataType === PropertyDataType.IdLabel)
        || ((mapping.left.options?.internalType === PropertyDataType.Assets || mapping.right.options?.internalType === PropertyDataType.Assets) && 
        (mapping.left.options?.selectable && mapping.right.options?.selectable)))
        && mapping.defaults.left.mode === DefaultMappingMode.Skip
        && mapping.defaults.right.mode === DefaultMappingMode.Skip
        && mapping.groups.length === 0) {
        return `At least one of the fields is of the 'choice' type. Please click the 'cog' icon to access details, map options, or configure field mapping. Currently, the data from these fields may not sync properly.`;
    }
    if (mapping.left.options?.readonly &&
        (mapping.direction === SmartIntDirection.ToLeft || mapping.direction === SmartIntDirection.Both)) {
        return `Left side field (${mapping.left.name}) is readonly so it won't be set up`;
    }
    if (mapping.right.options?.readonly && 
        (mapping.direction === SmartIntDirection.ToRight || mapping.direction === SmartIntDirection.Both)) {
        return `Right side field (${mapping.right.name}) is readonly so it won't be set up`;
    }
    return '';
}

export function getStatuConfigurationWarning(typeMapping: SmartIntDefinitionTypeMapping): string {
    // validate status configuration
    if (typeMapping.statusTransitionConfiguration &&
        typeMapping.statusTransitionConfiguration.enabled &&
        typeMapping.statusTransitionConfiguration.groups.length === 0) {
        return `Status syncing will not work until you map availabe statuses of both apps, using dropdown below`;
    }
    return '';
}

export function getFieldsConfigurationWarning(typeMapping: SmartIntDefinitionTypeMapping): string {
    // validate status configuration
    if (!typeMapping.propertiesConfiguration) {
        return '';
    }
    for (const mapping of typeMapping.propertiesConfiguration.properties) {
        const warning = getPropertyMappingWarning(mapping);
        if (warning) {
            return warning;
        }
    }
    return '';
}

export function getTypeMappingWarning(typeMapping: SmartIntDefinitionTypeMapping): string {
    const warnings = [getStatuConfigurationWarning(typeMapping), getFieldsConfigurationWarning(typeMapping)];
    for (const warning of warnings) {
        if (warning) {
            return warning;
        }
    }
    return '';
}
