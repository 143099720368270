import React from 'react';
import { Icon } from './icon';

export type ContextMenuItem = {
    id: string;
    name: string | JSX.Element;
};

export type OnContextMenuItemSelected = (item: ContextMenuItem) => void;

type Props = {
    disableSorting?: boolean;
    onSelected:  OnContextMenuItemSelected;
    getItems: () => ContextMenuItem[];
};

type State = {
    isOpened: boolean;
};

export class ContextMenu extends React.Component<Props, State> {
    private wrapperRef: any;

    constructor(props: Props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.state = {
            isOpened: false
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (JSON.stringify(this.props.getItems()) !== JSON.stringify(prevProps.getItems)) {
            // this.forceUpdate();
        }
    }

    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.documentClick.bind(this));
        document.addEventListener('touchstart', this.documentClick.bind(this));
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.documentClick);
        document.removeEventListener('touchstart', this.documentClick);
    }

    documentClick(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpened) {
            this.setState({ isOpened: false });
        }
    }

    render() {
        return <div className={`ui-context-menu`} ref={ this.setWrapperRef }>
            <div className={`dots`} onClick={ () => this.switchPopup() }>
                <Icon icon="ellipsis-v" />
            </div>
            { this.state.isOpened && <div className={`popup`}>
                <ul>
                    { this.getOptions().map((item, key) => <li key={ key } onClick={ () => this.itemSelectedHandler(item) }>
                        { item.name }
                    </li>) }
                </ul>
            </div> }
        </div>;
    }

    private getOptions() {
        if (this.props.disableSorting) {
            return this.props.getItems();
        }
        return this.props.getItems().sort((a,b) => (a.name > b.name) ? 1 : -1);
    }

    private itemSelectedHandler(item: ContextMenuItem) {
        this.props.onSelected(item);
        this.switchPopup();
    }

    private switchPopup() {
        this.setState({ isOpened: !this.state.isOpened });
    }
}