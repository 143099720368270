import { ContextMenuItem } from 'src/engrator-core/ui';
import { SmartIntDefinitionTypeMapping } from '../../../definition/smart-int-definition--type-mapping.type';

let contextMenuItem: ContextMenuItem | undefined = undefined;
let configurationCopy: SmartIntDefinitionTypeMapping | undefined = undefined;

export enum TypeMappingContextMenuItemsIds {
    EDIT = 'edit',
    COPY = 'copy',
    USE_CONFIGURATION = 'useConfiguration',
    DISABLE = 'disable',
    ENABLE = 'enable',
    DELETE = 'delete',
    MOVE_UP = 'moveUp',
    MOVE_DOWN = 'moveDown',
}

export function setSelectedContextMenuItem(item: ContextMenuItem, copy?: SmartIntDefinitionTypeMapping) {
    if (copy) {
        console.log('copy configuration', copy);
        configurationCopy = copy;
    }
    contextMenuItem = item;
}

export function clearCopiedConfiguration() {
    console.log('clear copied config');
    configurationCopy = undefined;
}

export function getCopiedConfiguration(): SmartIntDefinitionTypeMapping | undefined {
    return configurationCopy;
}

export function getSelectedContextMenuItem(): ContextMenuItem | undefined {
    return contextMenuItem;
}

export function getAvailableContextItems(isFirst: boolean, isLast: boolean, mapping: SmartIntDefinitionTypeMapping): ContextMenuItem[] {
    const items = [
        {
            id: 'copy',
            name: 'Copy configuration'
        },
        {
            id: TypeMappingContextMenuItemsIds.DELETE,
            name: 'Delete'
        },
        {
            id: TypeMappingContextMenuItemsIds.EDIT,
            name: 'Edit'
        },
        // {
        //     id: TypeMappingContextMenuItemsIds.MOVE_DOWN,
        //     name: 'Move down'
        // }
    ];
    if (!isFirst) {
        items.push({
            id: TypeMappingContextMenuItemsIds.MOVE_UP,
            name: 'Move up'
        });
    }
    if (!isLast) {
        items.push({
            id: TypeMappingContextMenuItemsIds.MOVE_DOWN,
            name: 'Move down'
        });
    }
    if (configurationCopy) {
        items.push({
            id: TypeMappingContextMenuItemsIds.USE_CONFIGURATION,
            name: 'Use configuration'
        });
    }
    if (mapping.status === 'Disabled') {
        items.push({
            id: TypeMappingContextMenuItemsIds.ENABLE,
            name: 'Enable'
        });
    }
    if (mapping.status === 'Enabled') {
        items.push({
            id: TypeMappingContextMenuItemsIds.DISABLE,
            name: 'Disable'
        });
    }
    return items;
}
