import React, { Component, useEffect } from 'react';
import { fetchSystemLogs } from './rest-api';
import { jiraServerSettingsVerticalMenu } from './jiraserver-settings-vertical-menu';
import { useGlobalState } from '../../global-store';
import { MainMenuItem } from '../../global-store/create-global-state';
import { LogsViewer } from '../../../engrator-core/ui';
import { Footer } from '../../footer';
import { UI } from 'src/engrator-core';

type State = {
    logs: string;
    isLoading: boolean;
};

class JiraServerSettingsSystemLogsPageCmp extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            logs: '',
            isLoading: true,
        };
    }

    componentDidMount() {
        fetchSystemLogs()
            .then((fetchedLogs) => {
                this.setState({ logs: fetchedLogs, isLoading: false });
            })
            .catch(() => {
                this.setState({ logs: '', isLoading: false });
            });
    }

    render() {
        const { logs, isLoading } = this.state;
        return (
            <UI.Page>
                {isLoading ? (
                    <UI.Loader visible={true} appearance="darkgray" />
                ) : (
                    <React.Fragment>
                        <LogsViewer logs={logs} />
                    </React.Fragment>
                )}
                <Footer />
            </UI.Page>
        );
    }
}

export function JiraServerSettingsSystemLogsPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMenu(jiraServerSettingsVerticalMenu);
        setMainMenuItem(MainMenuItem.SystemSettings);
    }, []);
    return <JiraServerSettingsSystemLogsPageCmp />;
}