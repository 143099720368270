import React from 'react';
import {AppsSupport} from '../visual-integration-designer';
import {SmartIntDefinition} from '../../definition';
import {FieldsMappings} from '../type-mapping-configuration/fields-mappings';

type State = {
};

type Props = {
    appSupport: AppsSupport;
    multiFieldsUsage: boolean;
    definition: SmartIntDefinition;
    shouldDisableAutoMap?: boolean;
}

export class FieldsSummary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <div className={`fields-manager`}>
            {this.props.definition.types.map((typeMapping) => <div className={`type-mapping-fields`}>
                <div>
                    <h1>{typeMapping.left.name} - {typeMapping.right.name}</h1>
                </div>
                <FieldsMappings
                    /* we should but the btn back */
                    shouldDisableAutoMap={ true }
                    appsSupport={this.props.appSupport}
                    mapping={typeMapping}
                    multiFieldsUsage={this.props.multiFieldsUsage}
                    leftTrigger={this.props.definition.triggers.left}
                    rightTrigger={this.props.definition.triggers.right}
                    isMangerView={true}
                />
            </div>
            )}
        </div>;
    }
}