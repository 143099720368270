import React from 'react';

import {JiraFindIssueConfiguration} from './jira-find-issue-configuration';
import {EditPipelineStepComponentProps} from 'src/generic/pipeline-step';
import {ArtifactDirection} from '../../../../generic/artifacts';
import {UI} from '../../../../engrator-core';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class JiraFindIssueEditStep extends React.Component<Props, State> {
    private configuration: JiraFindIssueConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as JiraFindIssueConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup>
                <UI.LabelValue label={`Project`} value={ this.configuration.project.name } />
            </UI.FormGroup>
            <UI.FormGroup>
                <UI.LabelValue label={`Issue Type`} value={ this.configuration.issueType.name } />
            </UI.FormGroup>
            <UI.LabelValue label={`Item Key`} value={ this.configuration.id } />
        </React.Fragment>;
    }
}