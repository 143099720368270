import React from 'react';
import { FieldError, UI } from '../../../../engrator-core';
import { ConnectionModel } from '../connection-model';
import { deleteConnection } from './rest-api';

type Props = {
    closeHandler: () => void;
    connection: ConnectionModel;
    onDeleteHandler: () => void;
};

type State = {
    error?: FieldError;
    isLoading: boolean;
}

export class DeleteConnectionModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isLoading: false };
    }

    onDeleteClicked() {
        this.setState({ isLoading: true }, () => {
            deleteConnection(this.props.connection.id)
                .then(() => {
                    this.setState({ isLoading: false }, () => {
                        this.props.closeHandler();
                        this.props.onDeleteHandler();
                    });
                })
                .catch((error) => {
                    this.setState({ error, isLoading: false });
                });
        });
    }

    isLoading(): boolean {
        return false;
    }

    render() {
        return <React.Fragment>
            <UI.Modal header={`Delete connection '${ this.props.connection.name }'`} isLeftAligned={ true }>
                Please confirm you are sure to delete "{ this.props.connection.name}" connection
                { this.state.error &&
				<UI.Message appearance={'error'}>
				    <p>Failed to delete connection: { this.state.error.message }</p>
				</UI.Message>
                }
                <UI.ButtonsBar
                    primary={
                        <UI.Button
                            text="Delete"
                            isLoading={ this.state.isLoading }
                            disabled={ this.state.isLoading }
                            appearance="danger"
                            onClick={() => this.onDeleteClicked() }
                        />
                    }
                    secondary={[
                        <UI.Button
                            text="Close"
                            appearance="link-inline"
                            onClick={() => this.props.closeHandler() }
                        />
                    ]}
                />
            </UI.Modal>
        </React.Fragment>;
    }
}
