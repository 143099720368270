import React from 'react';
import {Icon, Icon20} from './icon';

type LoaderProps = {
    visible: boolean,
    appearance?: 'blue' | 'white' | 'darkgray'
};

export class Loader extends React.Component<LoaderProps> {
    render() {
        if (!this.props.visible) {
            return <React.Fragment></React.Fragment>
        }
        if (this.props.appearance && this.props.appearance === 'darkgray') {
            return <Icon icon20={ Icon20.Spinner } />;
        }
        return <Icon icon20={ Icon20.SpinnerWhite } />;
    }
}