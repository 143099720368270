import React from 'react';

import {UI} from 'src/engrator-core';
import {DropdownOption} from 'src/engrator-core/ui';
import {PropertyDataType} from 'src/generic/artifacts';
import {PropertyMapping} from '../../property-mapping.type';
import {getEmptyAdditionalData, ValueMapping} from './index';
import {PropertyValue} from './property-value.type';
import {AppsSupport} from '../../visual-integration-designer';
import {SoftwareName} from '../../../../../../software';
import {AddOptionManually, ManualOption} from './add-option-manually';
import {SmartIntTrigger} from '../../../definition/smart-int-trigger.type';
import { Side } from 'src/generic';
import { Assets } from './jira-assets/functions';
import AssetConfiguration from './jira-assets/asset-configuration';
import { Teams } from './jira-teams/functions';
import TeamConfiguration from './jira-teams/team-configuration';

type Props = {
    appsSupport: AppsSupport;
    mapping: PropertyMapping;
    triggers: {
        left: SmartIntTrigger;
        right: SmartIntTrigger;
    };
    options: { left: DropdownOption[]; right: DropdownOption[] };
    addMappingHandler: (mapping: ValueMapping) => void;
    optionsQueryChangeHandler: (side: 'left' | 'right', newValue: string) => void;
    onValueOptionSelected: (side: 'left' | 'right', propertyValue: PropertyValue) => void;
    onClickDropdown?: () => void;
    assets?: Assets;
    findSchema?: (side: Side, id: string) => string;
    handleAcceptAql?: (side: Side) => Promise<void>;
    setState?: React.Dispatch<React.SetStateAction<any>>;
    assetsLeftAcceptedLoading?: boolean;
    assetsRightAcceptedLoading?: boolean;
    teams?: Teams;
    handleAcceptTeam?: (side:Side) => void;
};
type State = {
    manualOptionSide?: 'left' | 'right',
    manualOption?: {
        id: string;
        name: string
    };
    options: { left: DropdownOption[]; right: DropdownOption[] };
};

export class AddValueMapping extends React.Component<Props, State> {
    private left?: PropertyValue;
    private right?: PropertyValue;
    private leftCondition?: string;
    private rightCondition?: string;

    constructor(props: Props) {
        super(props);
        this.state = {
            options: this.props.options
        };
        this.leftCondition = '';
        this.rightCondition = '';
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)) {
            this.setState({
                options: this.props.options
            });
        }
    }

    render() {
        
        const isLeftPickedWithCondition = (this.left && this.leftCondition);
        const isRightPickedWithCondition = (this.right && this.rightCondition);
        const addButtonEnabled = (this.left && this.right) || isLeftPickedWithCondition || isRightPickedWithCondition;
        return <div className={`add-mapping`}>
            <UI.FormSection
                label={`Map available options`}
            >
                {/*{ this.isRelavantForAzureEmailToJiraUsername() && <UI.FormGroup>*/}
                {/*    <UI.Checkbox*/}
                {/*        label={`Automatically match Jira users by matching Jira usernames with Azure email addresses (username in Jira = email address in Azure) `}*/}
                {/*        onChange={ () => this.switchJiraUsernameAzureEmailMatching() }*/}
                {/*        checkedValue={`true`}*/}
                {/*        uncheckedValue={`false`}*/}
                {/*        defaultValue={ this.props.mapping.additional?.azureEmailIsJiraUsername === true ? 'true' : 'false' }*/}
                {/*    />*/}
                {/*</UI.FormGroup> }*/}
                <div className={`row-two-cols`}>
                    {this.props.assets && !this.props.assets[Side.Left].isConfiguration && this.props.assets[Side.Right].isConfiguration ? null : 
                        this.props.assets && this.props.assets[Side.Left].isConfiguration ? 
                            <AssetConfiguration side={Side.Left} assets={this.props.assets!} findSchema={(side: Side, id: string) => this.props.findSchema!(side, id)} setState={(value: any) => this.props.setState!(value)} handleAcceptAql={(side: Side) => this.props.handleAcceptAql!(side)}/> 
                            : 
                            this.props.teams && !this.props.teams[Side.Left].isConfiguration && this.props.teams[Side.Right].isConfiguration ? null : 
                                this.props.teams && this.props.teams[Side.Left].isConfiguration ? 
                                    <TeamConfiguration side={Side.Left} teams={this.props.teams} handleAcceptTeam={(side: Side) => this.props.handleAcceptTeam!(side)} setState={(value: any) => this.props.setState!(value)}/>
                                    :
                                    <div className={`left`} onClick={this.props.onClickDropdown ? () => this.props.onClickDropdown!() : () => {}}>
                                        <UI.Dropdown
                                            defaultValue={``}
                                            onChange={(value: any, object: any) => this.optionChanged(value, object, 'left')}
                                            options={this.state.options.left}
                                        />
                                        {this.props.appsSupport.leftApp == SoftwareName.Wrike && <UI.Button
                                            onClick={() => this.showAddOptionManually('left')}
                                            text={`Add option manually`}
                                            appearance={`link-inline`}
                                        />}
                                        {this.props.mapping.left.dataType === PropertyDataType.IdLabel && this.left && <UI.Input
                                            defaultValue={''}
                                            onChange={(newValue) => this.conditionChanged('left', newValue)}
                                            label={`Condition to be matched to set above option (optional)`}
                                            placeholder={`Logical condition to validate`}
                                        />
                                        }
                                    </div>}
                    {this.props.assets && !this.props.assets[Side.Right].isConfiguration && this.props.assets[Side.Left].isConfiguration ? null : this.props.assets && this.props.assets[Side.Right].isConfiguration ? 
                        <AssetConfiguration side={Side.Right} assets={this.props.assets!} findSchema={(side: Side, id: string) => this.props.findSchema!(side, id)} setState={(value: any) => this.props.setState!(value)} handleAcceptAql={(side: Side) => this.props.handleAcceptAql!(side)}/> 
                        : 
                        this.props.teams && !this.props.teams[Side.Right].isConfiguration && this.props.teams[Side.Left].isConfiguration ? null : 
                            this.props.teams && this.props.teams[Side.Right].isConfiguration ? 
                                <TeamConfiguration side={Side.Right} teams={this.props.teams} handleAcceptTeam={(side: Side) => this.props.handleAcceptTeam!(side)} setState={(value: any) => this.props.setState!(value)}/>
                                :
                                <div className={`right`} onClick={this.props.onClickDropdown ? () => this.props.onClickDropdown!() : () => {}}>
                                    <UI.Dropdown
                                        defaultValue={``}
                                        onChange={(value: any, object: any) => this.optionChanged(value, object, 'right')}
                                        options={this.state.options.right}
                                    />
                                    {this.props.appsSupport.rightApp == SoftwareName.Wrike && <UI.Button
                                        onClick={() => this.showAddOptionManually('right')}
                                        text={`Add option manually`}
                                        appearance={`link-inline`}
                                    />}
                                    {this.props.mapping.right.dataType === PropertyDataType.IdLabel && this.right && <UI.Input
                                        defaultValue={''}
                                        onChange={(newValue) => this.conditionChanged('right', newValue)}
                                        label={`Condition to be matched to set above option (optional)`}
                                        placeholder={`Logical condition to validate`}
                                    />
                                    }
                                </div>}
                </div>
                {this.props.teams && (this.props.teams[Side.Left].isConfiguration || this.props.teams[Side.Right].isConfiguration) && !this.props.mapping.options.left.organizationId && !this.props.mapping.options.right.organizationId && 
                    <UI.Message appearance={'info'}>
                        If you’re unsure how to find the organization ID, please refer to the documentation provided by Jira available <UI.DocumentationLink text={`here`} url={`https://confluence.atlassian.com/jirakb/what-it-is-the-organization-id-and-where-to-find-it-1207189876.html`} />.
                    </UI.Message>} 
                {(this.props.assets && !this.props.assets[Side.Left].isConfiguration && !this.props.assets[Side.Right].isConfiguration) && <UI.ButtonsBar primary={
                    <UI.Button disabled={!addButtonEnabled} onClick={() => this.addMappingClicked()} text={`Add`}/>
                }/>}
            </UI.FormSection>
            {this.state.manualOptionSide && <AddOptionManually
                wrikeConfiguration={ {
                    connectionId: this.props.triggers[this.state.manualOptionSide].connectionId,
                    folderId: this.props.triggers[this.state.manualOptionSide].configuration.folder.id
                }}
                addOptionHandler={ (manualOption?: ManualOption) => this.addManualOption(manualOption) }
            /> }
        </div>;
    }

    conditionChanged(side: 'left' | 'right', newValue: string) {
        if (side === 'left') {
            this.leftCondition = newValue;
            this.right = undefined;
        } else {
            this.rightCondition = newValue;
            this.left = undefined;
        }
        this.forceUpdate();
    }

    private setManualOptionField(field: 'id' | 'name', newValue: string): void {
        const manualOption = this.state.manualOption;
        manualOption![field] = newValue;
        this.setState({ manualOption });
    }

    private showAddOptionManually(side: 'left' | 'right'): void {
        this.setState({
            manualOptionSide: side, manualOption: {
                id: '',
                name: ''
            }
        });
    }

    private addManualOption(manualOption?: ManualOption): void {
        if (manualOption) {
            const options = this.state.options;
            options[this.state.manualOptionSide!].push({
                value: manualOption.id,
                label: manualOption.name,
                object: {
                    name: manualOption.name,
                    value: manualOption.id
                }
            });
            this.setState({manualOptionSide: undefined, manualOption: undefined, options});
        } else {
            this.setState({manualOptionSide: undefined, manualOption: undefined });
        }
    }

    private addMappingClicked() {
        const left = this.left || {name: '', value: '', additionalData: getEmptyAdditionalData()};
        const right = this.right || {name: '', value: '', additionalData: getEmptyAdditionalData()};
        if (left && right) {
            this.props.addMappingHandler({
                left,
                right,
                isDefault: false,
                defaultValueMapping: false,
                leftCondition: this.leftCondition,
                rightCondition: this.rightCondition,
            });
        }
        this.left = undefined;
        this.right = undefined;
        this.leftCondition = '';
        this.rightCondition = '';
    }

    private optionChanged(value: any, mappingObject: PropertyValue, side: 'left' | 'right') {
        if (side === 'left') {
            this.left = mappingObject;
        } else if (side === 'right') {
            this.right = mappingObject;
        }
        this.leftCondition = '';
        this.rightCondition = '';
        this.props.onValueOptionSelected(side, mappingObject);
        this.forceUpdate();
    }

    private switchJiraUsernameAzureEmailMatching() {
        if (!this.props.mapping.additional) {
            this.props.mapping.additional = { azureEmailIsJiraUsername: false };
        }
        // Lets always disable it, this feature do not bring to much value now
        this.props.mapping.additional.azureEmailIsJiraUsername = false;
        //!this.props.mapping.additional.azureEmailIsJiraUsername;
    }

    private isRelavantForAzureEmailToJiraUsername() {
        const isJiraAndAzure = (this.props.appsSupport.leftApp === SoftwareName.Azure && this.props.appsSupport.rightApp === SoftwareName.Jira)
            || (this.props.appsSupport.leftApp === SoftwareName.Jira && this.props.appsSupport.rightApp === SoftwareName.Azure);
        const isAssignee = (this.props.mapping.left.id === 'assignee' && this.props.mapping.right.id === 'System.AssignedTo') || (this.props.mapping.right.id === 'assignee' && this.props.mapping.left.id === 'System.AssignedTo');
        return isJiraAndAzure && (isAssignee);
    }
}
