import React from 'react';

export class Footer extends React.Component {
    render() {
        return <div className={`footer-links`}>
            <a href={'https://getintio.atlassian.net/servicedesk/customer/portals'} target={'_blank'}>Submit support ticket</a> |
            <a target={`_blank`} href="https://docs.getint.io">Documentation</a> |
            <a href={'https://getint.io/privacy-policy'} target={'_blank'}>Privacy policy</a> |
            <a href={'https://getint.io/terms/eula'} target={'_blank'}>EULA</a>
        </div>
    }
}
