import React from 'react';
import { UI } from 'src/engrator-core';
import { NewPipelineStepComponentProps } from 'src/generic';
import { StepArtifact } from 'src/generic/software/step';
import { CreateCommentsStep } from 'src/software/generic/create-comments-step/create-comments-step';
import { SoftwareName } from '../../../software-name';
import { MondayCreateCommentsConfiguration } from './monday-create-comments-configuration';

type Props = {} & NewPipelineStepComponentProps;
type State = {};

export class MondayCreateCcommentsNewStep extends React.Component<Props, State> {
    private configuration: MondayCreateCommentsConfiguration;
    private availableArtifacts: StepArtifact[];

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration as MondayCreateCommentsConfiguration;
        this.availableArtifacts = this.props.context.artifactsFactory.getAvailableOutArtifactsForSoftware(SoftwareName.Monday);
    }

    render() {
        return <React.Fragment>
            {this.props.formError?.general && <UI.Message
                appearance={`error`}
            >{this.props.formError.general}</UI.Message>
            }
            <CreateCommentsStep
                onConfigurationChanged={this.onConfigurationChanged.bind(this)}
                availableArtifacts={this.availableArtifacts}
                formError={this.props.formError}
            />
        </React.Fragment>;
    }

    private onConfigurationChanged(newConfiguration: MondayCreateCommentsConfiguration) {
        if (newConfiguration.targetArtifact) {
            const artifactConnectionId = this.props.context.artifactsFactory.getConnectionIdForArtifact(newConfiguration.targetArtifact);
            if (artifactConnectionId) {
                this.props.context.connectionId = artifactConnectionId;
            }
        }
        this.configuration = Object.assign(this.configuration, newConfiguration);
    }
}
