import React from 'react';

import {EditPipelineStepComponentProps} from 'src/generic';
import { UI} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';
import {GitHubSearchItemsConfiguration} from './github-search-items-configuration';
import {GitHubStepContextDisplay} from '../common';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class GitHubSearchItemsEditStep extends React.Component<Props, State> {
    private configuration: GitHubSearchItemsConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as GitHubSearchItemsConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <GitHubStepContextDisplay context={ this.configuration.context } />
            <UI.FormGroup>
                <UI.Checkbox
                    checkedValue={ 'true' }
                    uncheckedValue={ 'false' }
                    defaultValue={ this.configuration.onlyNewChanges.toString() }
                    label="Only issues that changed from last integration"
                    onChange={ (newValue) => { this.onlyNewChanged(newValue) }   } />
            </UI.FormGroup>
        </React.Fragment>;
    }

    private onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }
}