import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';
import {GitLabStepKey} from '../gitlab-step-key.type';
import {GitLabCreateItemConfiguration} from './gitlab-create-item-configuration';
import {GitLabCreateItemEditStep} from './gitlab-create-item-edit-step';
import {GitLabCreateItemNewStep} from './gitlab-create-item-new-step';

export const GitLabCreateItemPipelineStep: PipelineStep<GitLabCreateItemConfiguration> = {
    addStepComponentClass: GitLabCreateItemNewStep,
    editStepComponentClass: GitLabCreateItemEditStep,
    specification: {
        action: 'Create issue',
        description: 'Create issue in specified project',
        key: GitLabStepKey.GitLabCreateItem,
        software: SoftwareName.GitLab,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `gitLabIssue`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitLabCreateItemConfiguration;
        return `Create issue in ${stepConfiguration.context.project?.name}`;
    }
}