import React from 'react';
import { UI } from 'src/engrator-core';
import {DropdownOption} from 'src/engrator-core/ui';
import {SoftwareName} from '../../../../../../software';
import {
    CommentAction,
    createEmptyPrivatePublicConfiguration,
    PrivatePublicConfiguration
} from '../../../definition/smart-int-definition-configuration.type';
import {provideOptionsForApp} from './comments-public-private-options';

type Props = {
    leftApp: SoftwareName;
    rightApp: SoftwareName;
    configuration?: PrivatePublicConfiguration;
};
type State = {
    configuration: PrivatePublicConfiguration;
};

export class CommentsPublicPrivateConfiguration extends React.Component<Props, State> {
    private readonly leftOptions: DropdownOption[];
    private readonly rightOptions: DropdownOption[];

    constructor(props: Readonly<Props>) {
        super(props);
        this.leftOptions = provideOptionsForApp(this.props.rightApp, 'right');
        this.rightOptions = provideOptionsForApp(this.props.leftApp, 'left');
        this.state = {
            configuration: this.props.configuration || createEmptyPrivatePublicConfiguration()
        }
    }

    public render() {
        return <UI.Form>
            <UI.FormSection
                label={`Customize comments creation`}
                description={`Customize when comments should be created and what should be their visibility in destination app`}
            >
                <div className="flex row two-columns">
                    <div>
                        <UI.FormGroup
                            label={`When public ${ this.props.leftApp } (left) comment created:`}
                        >
                            <UI.Dropdown
                                defaultValue={ this.state.configuration.left.publicComment }
                                options={this.leftOptions}
                                onChange={ (newValue: CommentAction) => { this.changeAction('left', 'publicComment', newValue)} }
                            />
                        </UI.FormGroup>
                        <UI.FormGroup
                            label={`When private ${ this.props.leftApp } (left) comment created:`}
                        >
                            <UI.Dropdown
                                defaultValue={ this.state.configuration.left.privateComment }
                                options={this.leftOptions}
                                onChange={ (newValue: CommentAction) => { this.changeAction('left', 'privateComment', newValue)} }
                            />
                        </UI.FormGroup>
                    </div>
                    <div>
                        <UI.FormGroup
                            label={`When public ${ this.props.rightApp } (right) comment created:`}
                        >
                            <UI.Dropdown
                                defaultValue={ this.state.configuration.right.publicComment }
                                options={this.rightOptions}
                                onChange={ (newValue: CommentAction) => { this.changeAction('right', 'publicComment', newValue)} }
                            />
                        </UI.FormGroup>
                        <UI.FormGroup
                            label={`When private ${ this.props.rightApp } (right) comment created:`}
                        >
                            <UI.Dropdown
                                defaultValue={ this.state.configuration.right.privateComment }
                                options={ this.rightOptions }
                                onChange={ (newValue: CommentAction) => { this.changeAction('right', 'privateComment', newValue)} }
                            />
                        </UI.FormGroup>
                    </div>
                </div>
            </UI.FormSection>
        </UI.Form>;
    }

    private changeAction(side: 'left' | 'right', field: 'publicComment' | 'privateComment', newAction: CommentAction) {
        const configuration = this.state.configuration;
        configuration[side][field] = newAction;
        this.setState({ configuration });
    }
}