import React from 'react';
import {Icon} from './icon';

type Props = {
    description: string
}

type State = {
    isToolTipVisible: boolean
}

export class ShortExplanation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isToolTipVisible: false
        };
    }

    render() {
        return <div
            className={`engrator-ui-short-explanation`}
            onMouseOver={() => this.changeToolTipVisibility(true) }
            onMouseOut={() => this.changeToolTipVisibility(false) }>
            <Icon icon={'question-mark'} />
            { this.state.isToolTipVisible && <div className={`tooltip`}>
                { this.props.description }
            </div>}
        </div>;
    }

    private changeToolTipVisibility(show: boolean): void {
        if (show !== this.state.isToolTipVisible) {
            this.setState({ isToolTipVisible: show});
        }
    }
}