import React, {Component, createContext} from 'react';
import {SmartIntIntegration} from '../../../../generic';
import {SuiteType} from '../../../../generic/integrations/suite-type';

const IntegrationContext = createContext({});
type Props = {
    integration: SmartIntIntegration;
};
type State = {
    integration: SmartIntIntegration,
    getIntegrationType: () => SuiteType | undefined,
    numberOfAppsConfigured: () => number,
    setIntegrationType: (type: SuiteType) => void;
}

class IntegrationProvider extends Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            integration: this.props.integration,
            getIntegrationType: (): SuiteType | undefined => {
                if (!this.props.integration.suiteType) {
                    return SuiteType.SmartInt;
                }
                return this.props.integration.suiteType;
            }, numberOfAppsConfigured: (): number => {
                const triggers = this.props.integration.definition.triggers;
                if (triggers.left.connectionId > 0 && triggers.right.connectionId) {
                    return 2;
                } else if (triggers.left.connectionId > 0 || triggers.right.connectionId) {
                    return 1;
                }
                return 0;
            }, setIntegrationType: (type: SuiteType) => {
                const integration = this.props.integration;
                integration.suiteType = type;
                this.setState({integration})
            }
        };
    }

    render() {
        return (
            <IntegrationContext.Provider value={this.state}>
                {this.props.children}
            </IntegrationContext.Provider>
        );
    }
}

export {IntegrationContext, IntegrationProvider};
