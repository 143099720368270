import {SoftwareName} from '../../../../../software';
import {IntegrationAccessLevel} from '../../../integration-suites/details/integration-details-model';
import {SuiteType} from '../../../../../generic/integrations/suite-type';

export type LastRun = {
    id: number,
    status: string,
    date: string,
    formattedExecutionTime: string,
    formattedStartTime: string
};

export type IntegrationListItem = {
    id: number,
    name: String,
    accessLevel: IntegrationAccessLevel,
    lastRun: LastRun,
    groupName: string,
    apps: SoftwareName[],
    suiteType: SuiteType,
    lastSuccessfulRun: LastRun,
    status: 'Enabled' | 'Disabled',
    showLicense: boolean;
    ownerId: number;
    owner?: {
        identifier: string;
    },
    jiraLicenseInfo?: {
        active: boolean;
        errorMessage: string;
    };
};

export function createIntegrationListItem(itemData: any, showLicense: boolean): IntegrationListItem {
    const data: IntegrationListItem = {
        id: itemData.id,
        accessLevel: itemData.accessLevel,
        apps: itemData.apps,
        name: itemData.name,
        lastRun: itemData.lastRun,
        suiteType: itemData.suiteType,
        lastSuccessfulRun: itemData.lastSuccessfulRun,
        status: itemData.status,
        showLicense,
        groupName: itemData.groupName,
        ownerId: itemData.ownerId,
        owner: itemData.owner
    };
    if (showLicense) {
        data.jiraLicenseInfo = itemData.jiraLicenseInfo;
    }
    return data;
}
