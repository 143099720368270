import React from 'react';

type Props = {};
type State = {
};

export class ProgressSteps extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <div className={`steps`}>
            <div className={`step`}>
                <h3>1. Establish connections</h3>
                <p>Establish connections with apps you want to integrate / migrate. Getint.io needs to know connection details to constantly lookup items for a sync.</p>
                {/* <p className={`links`}>
                    Links: <a href={``}><UI.Icon icon={`video`} /></a>
                </p> */}
            </div>

            <div className={`step`}>
                <h3>2. Create integration</h3>
                <p>Build your first integration. Start with the type mapping (issue - issue, bug - bug, and so on). 
Then, go deeper within particular mapping settings. 
Click the settings icon, and set up field mapping, enable sync of attachments, comments, statuses. 
Repeat those steps for each type.</p>
                {/* <p className={`links`}>
                    Links: <a href={``}><UI.Icon icon={`video`} /></a>
                </p> */}
            </div>

            <div className={`step`}>
                <h3>3. Sync your first item - test your settings</h3>
                <p>Now it’s time to test your settings. 
Once you create integration getint.io will start to look up  for every change you made related to the types you integrated. 
Simply go to one of the apps projects and edit / create some item.
If not, something is wrong in the settings. Reach out to us for support.
If you look for migration reach out to us for details.</p>
                {/* <p className={`links`}>
                    Links: <a href={``}><UI.Icon icon={`video`} /></a>
                </p> */}
            </div>
        </div>;
    }
}