import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { Software } from 'src/generic';
import { createGenericConnectionFactory } from 'src/generic/connections';
import { AuthMethod } from 'src/generic/connections/auth-method.type';
import { SoftwareName } from '../software-name';

import {SalesforceSmartintsSupport} from './smart-ints';
import {OAuthData, OAuthSupport} from '../../generic/connections/oauth/oauth-support';


export class SalesforceSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.Salesforce, [AuthMethod.BasicAuth]);
    }

    getName(): string {
        return 'Salesforce';
    }

    getID() {
        return 'Salesforce';
    }

    getAvailableSteps(): object[] {
        return [];
    }

    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new SalesforceSmartintsSupport();
    }
}

export function getSalesforceOauth(): OAuthSupport {
    return {
        extractDataFromObject(data: any): OAuthData {
            return {
                token: data.access_token,
                refreshToken: data.refresh_token,
                instanceUrl: data.instance_url
            }
        },
        getUrl(): string {
            return 'https://app.getint.io/app/oauth/redirect?instanceUrl=https://getint-dev-ed.develop.my.salesforce.com';
        },
        getApiUrl(): string {
            return '';
        }
    };
}
