import {axiosWrapper, FieldError} from 'src/engrator-core';
import {ServiceNowTableDTO} from './common/service-now-table-dto.type';

export const ENDPOINT_URL = '/connectors/servicenow';

export const fetchTables = (connectionId: number): Promise<ServiceNowTableDTO[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/tables')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};