import React, {Component, useEffect} from 'react';
import { CreateIntegrationGuide } from 'src/app/system/user-activity';
import {FieldError, UI} from 'src/engrator-core';
import { ContextMenuItem, DropdownOption } from 'src/engrator-core/ui';
import { Footer } from '../../../footer';
import { ConnectionModel } from '../connection-model';
import { ConnectionDebugger } from '../debugger';
import { DeleteConnectionModal } from '../edit/delete-connection-modal';

import { ConnectionEdit } from './../edit/connection-edit-component';
import { ConnectionsFilters, fetchAll, FetchData, isAnyFilterUsed, restoreFiltersFromStorage } from './../rest-api';
import './connections-list-page.css';
import {useGlobalState} from '../../../global-store';
import {MainMenuItem} from '../../../global-store/create-global-state';
import {SoftwareLogo} from '../../../../designer/step-creator/software-logo';
import {OwnerTag} from '../../smartints/owner-tag';
import {ConnectionExport} from './import-export/connection-export';
import {ConnectionImport} from './import-export';
import {getAccessOptionTagName} from '../../../../software/generic/connections-rest-api';
import {ConnectionsListActions} from './connections-list-actions';
import { ConnectionsListFiltering } from './connections-list-filtering';
import { IntegrationModuleTabBar } from '../../integration-module-tab-bar';
import {getWorkflowsVerticalMenu} from '../../vertical-menu';
import { filterApps } from '../../smartints/pages/list/rest-api';


type ConnectionsListPageState = {
    connectionToEdit?: ConnectionModel;
    connectionToDebug?: ConnectionModel;
    connectionToDelete?: ConnectionModel;
    shouldReload: boolean;
    currentModal?: {
        name: 'export' | 'import';
        connection?: ConnectionModel;
    };
    connectionsList: FetchData;
    error: any;
    isLoading: boolean;
    filters: ConnectionsFilters;
    allAppsOnPage: DropdownOption[];
};

class ConnectionsListPageCmp extends Component<{}, ConnectionsListPageState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            shouldReload: false,
            connectionsList: {
                headers: [],
                data: [],
                owners: []
            },
            error: undefined,
            isLoading: true,
            filters: restoreFiltersFromStorage(),
            allAppsOnPage: []
        };
    }

    async componentDidMount(): Promise<void> {
        await this.loadDataSource(this.state.filters);
        this.checkUrlParams();
    }

    async componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<ConnectionsListPageState>, snapshot?: any): Promise<void> {
        if (prevState.shouldReload !== this.state.shouldReload) {
            await this.loadDataSource(this.state.filters);
        }
    }

    editConnection(connection: ConnectionModel) {
        this.setState({ connectionToEdit: undefined } , () => {
            setTimeout(() => {
                this.setState({connectionToEdit: connection});
            }, 200);
        });
    }

    onCloseClick() {
        this.setState({ connectionToEdit: undefined, connectionToDebug: undefined });
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if (id) {
            window.history.replaceState(null, '', window.location.href.split('?id')[0]);
        }
    }

    private async loadDataSource(filters?: ConnectionsFilters) {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});
        }
        try {
            const connections = await fetchAll(filters);
            const appsFromConnections = connections.data.map((el: any) => el.softwareName);
            this.setState({
                connectionsList: connections,
                isLoading: false,
                allAppsOnPage: filterApps(appsFromConnections)
            });
        } catch (error) {
            this.setState({ isLoading: false, error: error as FieldError });
        }
    }

    private checkUrlParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if (id) {
            const found = this.state.connectionsList.data.find((el: any) => el.id === +id);
            this.setState({ connectionToEdit: found });
        }
    }

    render() {
        return (
            <UI.Page className={`pipelines-page`}>
                { this.state.connectionToDelete && <DeleteConnectionModal
                    connection={ this.state.connectionToDelete }
                    closeHandler={ () => this.setState({ connectionToDelete: undefined }) }
                    onDeleteHandler={ () => this.onDeleteHandler() }
                /> }
                <div className={`connections-tabbar`}>
                    <UI.Message appearance={'info'}>
                        To find out more about managing, importing, exporting and other aspects related to connections, you can <UI.DocumentationLink text={`check our docs`} url={`https://docs.getint.io/getintio-platform/connections`} />.
                    </UI.Message>
                    <UI.Button
                        icon={ <UI.Icon icon={'import'} /> }
                        appearance={`secondary`}
                        onClick={() => this.setState({ currentModal: { name: 'import' }})} text={`Import`}/>
                </div>
                <div className="connections-list-page">
                    { this.state.connectionsList.owners.length > 0 && <IntegrationModuleTabBar selectedIndex={0}>
                        <ConnectionsListFiltering allAppsOnPage={this.state.allAppsOnPage} defaultFilters={this.state.filters} onFiltersChanged={(newFilters) => this.onFiltersChanged(newFilters)} owners={this.state.connectionsList.owners}/>
                    </IntegrationModuleTabBar>}
                    { isAnyFilterUsed(this.state.filters) && <UI.Message appearance={'warning'}>
                        Displaying a filtered subset of connections. Select 'Clear' to reset.
                    </UI.Message> }
                    { !this.state.isLoading || this.state.error ? <UI.Table
                        error={ this.state.error }
                        emptyTableHolder= { (isAnyFilterUsed(this.state.filters)) ? <span>No integrations found</span> : <CreateIntegrationGuide/> }
                        dataSource={ this.state.connectionsList }
                        shouldReload={ this.state.shouldReload }
                        rowDecorator={(listItem: ConnectionModel) => [
                            // <UI.Button appearance="link-inline" onClick={ () => this.editConnection(listItem)} text={ `#${listItem.id}` } />,
                            <UI.Button appearance="link-inline" onClick={ () => this.editConnection(listItem)} text={ '#' + listItem.id } />,
                            <UI.Button appearance="link-inline" onClick={ () => this.editConnection(listItem)} text={ listItem.name } />,
                            <div className={`app-with-url`}><SoftwareLogo softwareName={ listItem.softwareName } /> <a target={`_blank`} href={ listItem.url}>{ listItem.url }</a> </div>,
                            <OwnerTag owner={ listItem.owner }/>,
                            <UI.Tag transparentBg={true} name={ getAccessOptionTagName(listItem.shareAccess) } />,
                            <ConnectionsListActions
                                handler={ (item) => this.contextMenuItemSelected(item, listItem)}
                                accessLevel={listItem.accessLevel} />
                        ]} /> : <UI.CenteredLoader text={`Loading...`} />
                    }

                    { this.state.connectionToEdit &&
						<ConnectionEdit
						    closeHandler={ () => this.onCloseClick()}
						    connection={this.state.connectionToEdit}
						    refreshList={ () => this.refreshList() }
						/>
                    }

                    { this.state.connectionToDebug && <
                        ConnectionDebugger
                        closeHandler={ () => this.onCloseClick() }
                        connection={this.state.connectionToDebug}
                    /> }
                    { this.state.currentModal?.name === 'export' && <ConnectionExport
                        connection={ this.state.currentModal.connection! }
                        closeHandler={ () => this.setState({ currentModal: undefined })}
                    /> }
                    { this.state.currentModal?.name === 'import' && <ConnectionImport
                        connection={ this.state.currentModal.connection! }
                        closeHandler={ (refresh) => this.onCloseImportModal(refresh) }
                    /> }
                </div>
                <Footer/>
            </UI.Page>
        )
    }

    private onCloseImportModal(refresh: boolean): void {
        if (refresh) {
            this.setState({ currentModal: undefined, shouldReload: !this.state.shouldReload });
        } else {
            this.setState({ currentModal: undefined });
        }
    }

    private contextMenuItemSelected(item: ContextMenuItem, connectionToDebug: ConnectionModel) {
        if (item.id === 'Debug') {
            this.setState({ connectionToDebug  });
        } else if (item.id === 'Delete') {
            this.setState({ connectionToDelete: connectionToDebug });
        } else if (item.id === 'Export') {
            this.setState({ currentModal: { name: 'export', connection: connectionToDebug} });
        } else if (item.id === 'Edit') {
            this.setState({ connectionToEdit: connectionToDebug });
        }
    }

    private onDeleteHandler() {
        this.setState({ shouldReload: !this.state.shouldReload });
    }

    private onFiltersChanged(filters: ConnectionsFilters) {
        this.setState({ filters }, () => {
            this.refreshList();
        });
    }

    private refreshList() {
        this.setState({ shouldReload: !this.state.shouldReload});
    }
}

export function ConnectionsListPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        setMainMenuItem(MainMenuItem.Workflows);
        getWorkflowsVerticalMenu()
            .then(workflowsVerticalMenu => {
                setMenu(workflowsVerticalMenu);
                setMainMenuItem(MainMenuItem.Workflows);
            });
    }, []);
    return <ConnectionsListPageCmp />
}