
import { TopBarActions } from '../top-bar';
import { convertIntegrationObjectToBackendData, saveIntegration } from './integration-persistence';
import {GenericIntegration, SmartIntIntegration} from 'src/generic';
import {IntegrationAccessLevel} from '../../app/integration/integration-suites/details/integration-details-model';

export function createToolbarActions(
    integrationId: number,
    integration: GenericIntegration,
    showSettingsHandler: any,
    showMigrationConfigurationHandler: any,
    showAdvancedConfiguration: any,
    showShare: () => void
): TopBarActions {
    let currentConvertedIntegration = JSON.stringify(convertIntegrationObjectToBackendData(integration));
    return {
        showSettings: showSettingsHandler,
        disableSaveBtn: integration.accessLevel !== IntegrationAccessLevel.Write,
        save: (callback): void => {
            setTimeout(() => {
                saveIntegration(integrationId, integration)
                    .then((integrationId) => {
                        currentConvertedIntegration = JSON.stringify(convertIntegrationObjectToBackendData(integration));
                        callback(integrationId);
                    }).catch(error => {
                        callback(0, error);
                    });
            }, 300);
        },
        showAdvancedConfiguration,
        showMigrationConfiguration: showMigrationConfigurationHandler,
        showShare: () => showShare(),
        hasIntegrationConfigurationChanged: (): boolean => {
            const upToDateConfiguration = convertIntegrationObjectToBackendData(integration);
            // console.log(`upToDateConfiguration`, upToDateConfiguration, currentConvertedIntegration);
            if (JSON.stringify(upToDateConfiguration) !== currentConvertedIntegration) {
                return true;
            }
            return false;
        },
        getName: () => integration.name,
        getStatus: () => integration.settings.status,
        changeName: (newName: string) => {
            integration.name = newName;
        },
        getOwnerId: () => (integration as SmartIntIntegration).ownerId,
        getOwner: () => (integration as SmartIntIntegration).owner,
        getShareAccess: () => (integration as SmartIntIntegration).shareAccess,
        getCreatedAt: () => (integration as SmartIntIntegration).createdAt,
    };
}
