import React from 'react';
import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';

import { SmartIntTrigger } from 'src/app/integration/smartints/definition/smart-int-trigger.type';
import { DropdownOption } from '../../../engrator-core/ui';
import { GetintArchiverSmartIntsEditTrigger } from './archiver-smart-ints-edit-trigger';
import { GetintArchiverSmartIntsTrigger } from './archiver-smart-ints-trigger';
import {fetchArchiverRelationships} from './rest-api';
import {SuiteType} from '../../../generic/integrations/suite-type';

export class GetintArchiverSmartintsSupport extends AppSmartIntsSupport {
    getTriggerConfigurationComponent(connectionId: number, onConfigured: (configuration: SmartIntTrigger) => void, type?: SuiteType): any {
        return <GetintArchiverSmartIntsTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
        />;
    }

    getEditTriggerConfigurationComponent(connectionId: number, trigger: SmartIntTrigger, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <GetintArchiverSmartIntsEditTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
            trigger={trigger}
        />;
    }

    hasTriggerConfigurationComponent(): boolean {
        return false;
    }

    getEpicRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
        return fetchArchiverRelationships(trigger);
    }

    getSubtasksRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
        return fetchArchiverRelationships(trigger);
    }
}
