import React from 'react';
import {ReducedFlowStep} from './flow-details.type';
import {Steps, StepSnippet} from '../../../generic';
import {SoftwareLogo} from '../../../designer/step-creator/software-logo';

type Props = {
    steps: ReducedFlowStep[];
};

type State = {};

export class FlowReducedStepsDiagram extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <div className={`SceneContainer`}>
                <div className={`SnippetsList`}>
                    {this.props.steps.map((flowStep, index: number) => <React.Fragment>
                        <div className={`snippet-container valid Success`}
                            key={index * 2}>
                            <span className="index">#{index + 1}</span>
                            <div>
                                <StepSnippet
                                    stepType={ Steps.StepType.Action }
                                    debuggingState={ null }
                                    softwareName={ flowStep.software }
                                    software={ <SoftwareLogo softwareName={flowStep.software} /> }
                                    inArtifactDefinition={ null }
                                    outArtifactDefinition={ null }
                                    title={this.getTitle(flowStep)} >
                                </StepSnippet>
                            </div>
                        </div>
                        {index + 1 < this.props.steps.length && <div className="join-line" key={index * 2 - 1}></div>}
                    </React.Fragment>
                    )}
                    <div style={{'clear': 'both'}}/>

                </div>
            </div>
        </React.Fragment>
    }

    getTitle(flow: ReducedFlowStep): string {
        if (flow.key.indexOf('ArtifactsComparator') >= 0) {
            return 'Synchronize fields values';
        } else if (flow.key.indexOf('FindItem') >= 0) {
            return 'Find ' + flow.smartIntType + ' item';
        } else if (flow.key.indexOf('CreateItem') >= 0) {
            return 'Create ' + flow.smartIntType + ' item';
        } else if (flow.key.indexOf('SyncComments') >= 0) {
            return 'Synchronize comments';
        } else if (flow.key.indexOf('SmartIntStatus') >= 0) {
            return 'Status transition';
        } else if (flow.key.indexOf('SyncAttachments') >= 0) {
            return 'Synchronize attachments';
        } else if (flow.key.indexOf('CreateComments') >= 0) {
            return 'Create comment with counterpart link';
        }
        return flow.key;
    }
}