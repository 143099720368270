import {Software} from 'src/generic';
import {AppSmartIntsSupport} from 'src/app/integration/smartints/app-smart-ints-support';
import {createGenericConnectionFactory} from 'src/generic/connections';
import {SoftwareName} from '../software-name';
import {AuthMethod} from 'src/generic/connections/auth-method.type';
import {PipelineGenericStep} from 'src/generic/pipeline-step';

import {JiraPipelineSteps} from './jira-pipeline-steps';
import {JiraSmartIntsSupport} from './smart-ints/jira-smart-ints-support';

export class JiraSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.Jira, [AuthMethod.BasicAuth, AuthMethod.BearerToken])
    }
    getName(): string {
        return 'Jira';
    }
    getID() {
        return 'Jira';
    }
    getAvailableSteps(): object[] {
        return JiraPipelineSteps.map(pipelineStep => new PipelineGenericStep(pipelineStep));
    }
    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new JiraSmartIntsSupport();
    }
}
