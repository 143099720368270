import {VerticalMenu, VerticalMenuItem} from 'src/app/global-store/create-global-state';
import {Icon20} from '../../../../engrator-core/ui';
import {SmartIntIntegration} from '../../../../generic';
import {SuiteType} from '../../../../generic/integrations/suite-type';

let LATEST_SYNCED_ITEMS_ITEM_NAME = 'Latest synced items';
let MIGRATE_DATA_ITEM_NAME = 'Migrate data';

export function getIntegrationVerticalMenu(integrationId: number, integration?: SmartIntIntegration): VerticalMenu {
    return {
        header: 'Workflows',
        items: populateVerticalMenu(integrationId, integration)
    }
}

function populateVerticalMenu(integrationId: number, integration?: SmartIntIntegration) {
    let items: VerticalMenuItem[] = [
        {
            name: 'Editor',
            path: `/app/integration/smart-ints/${integrationId}/edit`,
            icon: Icon20.Edit,
        },
        {
            name: 'Changes history',
            path: `/app/integration/smart-ints/${integrationId}/changes`,
            icon: Icon20.Archiver,
        },
        {
            name: MIGRATE_DATA_ITEM_NAME,
            path: `/app/integration/smart-ints/${integrationId}/migration`,
            icon: Icon20.Upload,
        },
        {
            name: LATEST_SYNCED_ITEMS_ITEM_NAME,
            path: `/app/integration/smart-ints/${integrationId}/synced-items`,
        },
        {
            name: 'Latest runs',
            path: `/app/integration/smart-ints/${integrationId}/runs`,
        },
    ];
    if (integration?.suiteType === SuiteType.Git) {
        items = items.filter(item => item.name !== LATEST_SYNCED_ITEMS_ITEM_NAME && item.name !== MIGRATE_DATA_ITEM_NAME);
        items.push({
            name: 'Git Repositories',
            path: `/app/integration/smart-ints/${integrationId}/git-repositories`,
        });
    }
    return items;
}