import React, {Component, useEffect} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Footer} from 'src/app/footer';
import {FieldError, UI} from 'src/engrator-core';
import {ContextMenuItem, Icon20} from '../../../../../engrator-core/ui';
import {CreateIntegrationGuide, registerUserActivityAction, UserActivityAction} from '../../../../system/user-activity';
import {IntegrationModuleTabBar} from '../../../integration-module-tab-bar';
import {DuplicateSmartIntWizard} from '../../definition/duplicate';
import {BulkEditButtons} from './bulk-edit';
import {IntegrationListItem, LastRun} from './integration-list-item';

import {
    fetchAllSmartIntsIntegrations, filterApps,
    IntegrationFilters,
    isAnyFilterUsed,
    restoreFiltersFromStorage
} from './rest-api';
import {IntegrationExport} from './import-export';
import {IntegrationImport} from './import-export/integration-import';
import {SetupNotificationsReminder} from '../../../../engagement';
import {SystemInfo} from '../../../../top-navigation/threads-summary.type';
import {IntegrationsListFiltering} from './integrations-list-filtering';
import {IntegrationExportWithConnections} from './import-export/integration-export-with-connections';
import {StaticContext} from 'react-router';
import {DropdownOption} from 'src/app/reporting/filters/dropdown-option.type';
import {WarningFlag} from '../../configuration/warning-flag';
import {SoftwareLogo} from '../../../../../designer/step-creator/software-logo';
import {OwnerTag} from '../../owner-tag';
import {ListItemContextMenu} from './list-item-context-menu';
import {SoftwareName} from '../../../../../software';
import {BulkEditOperation} from './bulk-edit/bulkd-edit-operation.type';
import {bulkEditIntegrations} from './bulk-edit/rest-api';
import {useGlobalState} from '../../../../global-store';
import {MainMenuItem} from '../../../../global-store/create-global-state';
import {getWorkflowsVerticalMenu} from '../../../vertical-menu';

function LastRunShortSummary(props: { runDetails: LastRun }) {
    if (!props.runDetails) {
        return <div>-</div>;
    }
    return <div className={`status`}>
        <UI.ExecutionStatus status={props.runDetails.status}/>
        <UI.NavLink path={`/app/reporting/suite-run/${props.runDetails.id}/details`}>#{ props.runDetails.id }</UI.NavLink>
        {/*<UI.ExecutionTime startTime={props.runDetails.formattedStartTime}*/}
        {/*                  formattedExecutionTime={props.runDetails.formattedExecutionTime}/>*/}
    </div>;
}

type State = {
    smartIntToDuplicate?: IntegrationListItem;
    smartIntToExport?: IntegrationListItem;
    smartIntToExportWithConnections?: IntegrationListItem;
    integrationIdToDelete?: number;
    selectedIntegrationsIds: number[];
    shouldReloadTable: boolean;
    importIntegration: boolean;
    systemInfo?: SystemInfo;
    integrations: Integrations;
    filters: IntegrationFilters;
    showInfoAboutJiraJiraLicensing: boolean;
    error: FieldError | undefined;
    isLoading: boolean;
    groups: string[];
    owners: string[];
    allAppsOnPage: DropdownOption[];
}
type Props = {} & RouteComponentProps;
export type Integrations = {
    data: any;
    headers: string[];
    showInfoAboutJiraJiraLicensing: boolean;
}

class SmartIntsListPageCmp extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedIntegrationsIds: [],
            shouldReloadTable: false,
            importIntegration: false,
            showInfoAboutJiraJiraLicensing: false,
            filters: restoreFiltersFromStorage(),
            integrations: {
                data: [],
                headers: [],
                showInfoAboutJiraJiraLicensing: false
            },
            error: undefined,
            isLoading: true,
            groups: [],
            owners: [],
            allAppsOnPage: []
        };
    }

    async componentDidMount(): Promise<void> {
        registerUserActivityAction(UserActivityAction.WasOnIntegrationsList);
        await this.loadDataSource(this.state.filters);
    }

    async componentDidUpdate(prevProps: Readonly<RouteComponentProps<{}, StaticContext, unknown>>, prevState: Readonly<State>, snapshot?: any): Promise<void> {
        if (prevState.shouldReloadTable !== this.state.shouldReloadTable) {
            await this.loadDataSource(this.state.filters);
        }
    }

    render() {
        return (
            <UI.Page
                className={`pipelines-page`}
            >
                { this.state.groups.length === 0 ? null : <IntegrationModuleTabBar selectedIndex={0} className={'smart-ints-page'}>
                    <IntegrationsListFiltering
                        groups={ this.state.groups }
                        defaultFilters={ this.state.filters }
                        onFiltersChanged={ (newFilters) => this.onFiltersChanged(newFilters) }
                        owners={ this.state.owners }
                        allAppsOnPage={ this.state.allAppsOnPage }
                    />
                    <UI.Button
                        icon={ <UI.Icon icon={'import'} /> }
                        appearance={`secondary`}
                        onClick={() => this.openImportModal()} text={`Import`}/>
                    <UI.NavLink path="/app/integration/smart-ints/create">
                        <UI.Button className={`selenium-create-integration`}
                            icon={ <UI.Icon icon20={ Icon20.CirclePlusWhite } /> }
                            onClick={() => {
                            }} text={`Create Integration`}/>
                    </UI.NavLink>
                </IntegrationModuleTabBar>}
                { isAnyFilterUsed(this.state.filters) && <UI.Message appearance={'warning'}>
                    Displaying a filtered subset of integrations. Select 'Clear' to reset.
                </UI.Message> }
                { this.state.showInfoAboutJiraJiraLicensing && <UI.Message appearance={'warning'}>
                    Note: A separate app license is needed for each Jira instance. For alternatives, explore our remote license option <UI.DocumentationLink text={`here`} url={`https://getint.io/docs/jira-jira-licensing`} />.
                </UI.Message> }
                {this.state.importIntegration && <IntegrationImport
                    closeHandler={() => this.setState({importIntegration: false})}
                />}
                {this.state.smartIntToExport && <IntegrationExport
                    integration={this.state.smartIntToExport}
                    closeHandler={() => this.setState({smartIntToExport: undefined})}
                />}
                {this.state.smartIntToExportWithConnections && <IntegrationExportWithConnections
                    integration={this.state.smartIntToExportWithConnections}
                    closeHandler={() => this.setState({smartIntToExportWithConnections: undefined})}
                />}
                {this.state.selectedIntegrationsIds.length > 0 && <div className={`bulk-edit-integrations anim-fade-in`}>
                    <p>What you would like to do with selected integrations?</p>
                    <BulkEditButtons
                        refreshHandler={() => this.refreshList()}
                        selectedIds={this.state.selectedIntegrationsIds}
                    />
                </div>}
                <SetupNotificationsReminder />
                {/*<div className={`integrations-tiles`}>*/}
                {/*    { this.state.integrations?.map(item => <div className={`integration-tile`}>*/}
                {/*        <div>{ item.name }</div>*/}
                {/*        <Groups*/}
                {/*            onSelected={(groupName) => this.onGroupSelected(groupName)}*/}
                {/*        />*/}
                {/*</div>) }*/}
                {/*</div>*/}
                <div className={`groups-and-table`}>
                    { !this.state.isLoading || this.state.error ? <UI.Table
                        error={ this.state.error }
                        onBulkSelect={(selectedIntegrations) => this.onBulkSelectHandler(selectedIntegrations)}
                        emptyTableHolder={ (isAnyFilterUsed(this.state.filters)) ? <span>No integrations found</span> : <CreateIntegrationGuide/> }
                        dataSource={ this.state.integrations }
                        shouldReload={this.state.shouldReloadTable}
                        rowDecorator={(listItem: IntegrationListItem) => {
                            const rows = [
                                <UI.NavLink
                                    dataSel={`config-` + listItem.id}
                                    path={`/app/integration/smart-ints/${listItem.id}/edit`}
                                >
                                    #{listItem.id}
                                </UI.NavLink>,
                                <UI.NavLink
                                    dataSel={`config-` + listItem.id}
                                    path={`/app/integration/smart-ints/${listItem.id}/edit`}
                                >
                                    {listItem.name}
                                </UI.NavLink>,
                                <div className={`connected-apps`}>{ listItem.apps.map((app) => <React.Fragment>
                                    <SoftwareLogo softwareName={ app } />
                                    <UI.Icon icon20={ Icon20.Getint} />
                                </React.Fragment>
                                )}</div>,
                                <React.Fragment>{ listItem.status && <UI.EntityStatus status={listItem.status}/> }</React.Fragment>,
                                <LastRunShortSummary runDetails={listItem.lastRun}/>,
                            ];
                            if (listItem.showLicense) {
                                rows.push(...[
                                    <React.Fragment>
                                        {listItem.jiraLicenseInfo?.active === false &&
                                            <WarningFlag
                                                warning={`No active license was found. ${listItem.jiraLicenseInfo.errorMessage}`}/>}
                                        {listItem.jiraLicenseInfo?.active === true && <UI.EntityStatus status={`OK`}/>}
                                    </React.Fragment>
                                ]);
                            }
                            rows.push(<OwnerTag
                                maxLength={ 10 }
                                ownerId={ listItem.ownerId }
                                owner={ listItem.owner }
                            />);
                            rows.push(...[
                                <ListItemContextMenu
                                    listItem={ listItem }
                                    onSelected={(item) => this.contextMenuItemSelected(item, listItem)}
                                />
                            ]);
                            return rows;
                        }} /> : <UI.CenteredLoader text={`Loading...`} />
                    }
                </div>
                {this.state.smartIntToDuplicate && <DuplicateSmartIntWizard
                    smartIntToDuplicate={{id: this.state.smartIntToDuplicate.id}}
                    onSmartIntDuplicated={this.onSmartIntDuplicated.bind(this)}
                    closeHandler={this.closeDuplicateSmartIntWizard.bind(this)}
                />}
                { this.state.integrationIdToDelete && <UI.ConfirmationModal
                    closeHandler={ () => this.setState({ integrationIdToDelete: undefined })}
                    confirmHandler={ () => this.deleteIntegration() }
                    btnAppearance={'danger'}
                >Are you sure to delete integration?</UI.ConfirmationModal> }
                <Footer/>
            </UI.Page>
        )
    }

    private async loadDataSource(filters?: IntegrationFilters) {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});
        }
        try {
            const integrations = await fetchAllSmartIntsIntegrations(filters);
            const appsFromIntegrations: SoftwareName[] = integrations.data.map((el: any) => el.apps).flat();
            this.setState({
                isLoading: false,
                integrations: integrations,
                showInfoAboutJiraJiraLicensing: integrations.showInfoAboutJiraJiraLicensing,
                groups: integrations.groups,
                owners: integrations.owners,
                allAppsOnPage: filterApps(appsFromIntegrations)
            });
        } catch (error) {
            this.setState({ error: error as FieldError });
        }
    }

    private onFiltersChanged(filters: IntegrationFilters) {
        this.setState({ filters }, () => {
            this.refreshList();
        });
    }

    private refreshList() {
        this.setState({selectedIntegrationsIds: [], shouldReloadTable: !this.state.shouldReloadTable});
    }

    private onBulkSelectHandler(selectedIntegrations: any[]) {
        const selectedIntegrationsIds = selectedIntegrations.map((integration) => integration.id);
        this.setState({selectedIntegrationsIds});
    }

    private closeDuplicateSmartIntWizard() {
        this.setState({smartIntToDuplicate: undefined});
    }

    private openImportModal() {
        this.setState({importIntegration: true});
    }

    private onSmartIntDuplicated(newIntegrationId: number) {
        (this.props as any).history.push(`/app/integration/smart-ints/${newIntegrationId}/edit`);
    }

    private deleteIntegration(): void {
        const integrationId = this.state.integrationIdToDelete!;
        this.setState({ integrationIdToDelete: undefined}, () => {
            bulkEditIntegrations([integrationId], BulkEditOperation.Delete).then(() => {
                this.refreshList();
            });
        });
    }

    private contextMenuItemSelected(item: ContextMenuItem, listItem: IntegrationListItem) {
        if (item.id === 'duplicate') {
            this.setState({smartIntToDuplicate: listItem});
        } else if (item.id === 'export') {
            this.setState({smartIntToExport: listItem});
        } else if (item.id === 'exportWithConnections') {
            this.setState({smartIntToExportWithConnections: listItem});
        } else if (item.id === 'disable') {
            bulkEditIntegrations([listItem.id], BulkEditOperation.Disable).then(() => {
                this.refreshList();
            })
        } else if (item.id === 'delete') {
            this.setState({ integrationIdToDelete: listItem.id });
        } else if (item.id === 'enable') {
            bulkEditIntegrations([listItem.id], BulkEditOperation.Enable).then(() => {
                this.refreshList();
            })
        }
    }
}

const SmartIntsListPageWithRouter = withRouter(SmartIntsListPageCmp);

export function SmartIntsListPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        setMainMenuItem(MainMenuItem.Workflows);
        getWorkflowsVerticalMenu()
            .then(workflowsVerticalMenu => {
                setMenu(workflowsVerticalMenu);
                setMainMenuItem(MainMenuItem.Workflows);
            });
    }, []);
    return <SmartIntsListPageWithRouter />
}