import React from 'react';
import { UI } from 'src/engrator-core';

import { EditPipelineStepComponentProps } from 'src/generic';
import { ArtifactDirection } from 'src/generic/artifacts';
import { MondayStepContextDisplay } from '../common';
import { MondaySearchItemsConfiguration } from './monday-search-items-configuration';

type Props = {} & EditPipelineStepComponentProps;
type State = {};

export class MondaySearchItemsEditStep extends React.Component<Props, State> {
    private configuration: MondaySearchItemsConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as MondaySearchItemsConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <MondayStepContextDisplay context={this.configuration.context}/>
            <UI.FormGroup>
                <UI.Checkbox
                    checkedValue={'true'}
                    uncheckedValue={'false'}
                    defaultValue={this.configuration.onlyNewChanges.toString()}
                    label="Only issues that changed from last integration"
                    onChange={(newValue) => {
                        this.onlyNewChanged(newValue);
                    }}/>
            </UI.FormGroup>
        </React.Fragment>;
    }

    private onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }
}
