import React from 'react';
import {Component} from 'react';
import {UI} from '../../../engrator-core';
import {ArchivedItemsFiltersData, FiltersNames, getFiltersOptions} from '../rest-api';
import {DropdownOption} from '../../../engrator-core/ui';


type State = {
    appOptions: DropdownOption[];
    belongsToOptions: DropdownOption[];
    integrationsOptions: DropdownOption[];
    optionsLoaded: boolean;
};
type Props = {
    onSearchClicked: (filters: ArchivedItemsFiltersData) => void;
};

export class ArchiverSearch extends Component<Props, State> {
    private readonly filtersData: ArchivedItemsFiltersData;

    constructor(props: Props) {
        super(props);
        this.filtersData = {};
        this.state = {
            optionsLoaded: false,
            appOptions: [],
            belongsToOptions: [],
            integrationsOptions: []
        }
    }

    componentDidMount() {
        getFiltersOptions()
            .then(response => {
                this.setState({
                    optionsLoaded: true,
                    appOptions: response.app,
                    belongsToOptions: response.belongsTo,
                    integrationsOptions: response.integrations
                });
            });
    }

    render() {
        if (!this.state.optionsLoaded) {
            return <UI.Loader visible={ true } appearance={'darkgray'} />;
        }
        return <React.Fragment>
            <div className={`archiver-search`}>
                <UI.Form>
                    <UI.Tile
                        className={`filters`}
                    >
                        <UI.Dropdown
                            label={`App`}
                            disableEmptyOption={ true }
                            options={ this.state.appOptions }
                            defaultValue={ 'all' }
                            onChange={(newValue: any) => this.onFilterChange('app', newValue) }
                        />
                        <UI.Dropdown
                            label={`Belongs to`}
                            disableEmptyOption={ true }
                            options={ this.state.belongsToOptions }
                            defaultValue={ 'all' }
                            onChange={(newValue: any) => this.onFilterChange('belongsTo', newValue) }
                        />
                        <UI.Dropdown
                            label={`Archived by`}
                            disableEmptyOption={ true }
                            options={ this.state.integrationsOptions }
                            defaultValue={ 'all' }
                            onChange={(newValue: any) => this.onFilterChange('integrationId', newValue) }
                        />
                        <UI.Input
                            label={`Query`}
                            placeholder={`Search items by fields values`}
                            onChange={(newValue) => this.onFilterChange('query', newValue) }
                        />
                        <UI.Button
                            className={`search-btn`}
                            onClick={ () => this.props.onSearchClicked(this.filtersData) }
                            text={`Search`}
                        />
                    </UI.Tile>
                </UI.Form>
            </div>
        </React.Fragment>;
    }

    private onFilterChange(filterName: FiltersNames, value: string) {
        if (value === '') {
            this.filtersData[filterName] = undefined;
        } else {
            this.filtersData[filterName] = value;
        }
    }
}