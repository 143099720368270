import React from 'react';

import './toolbar.css';
import {UI} from 'src/engrator-core';
import {TopBarActions} from './top-bar-actions';
import {PersistenceState} from '../container/persistence-state.type';
import {registerUserActivityAction, UserActivityAction} from '../../app/system/user-activity';
import {isAdvancedConfigurationEnabled} from './rest-api';
import {SmartIntDefinition} from '../../app/integration/smartints';
import {GuideTooltip} from '../../app/engagement';
import {MoreBtn, MoreOptions} from './more';
import {EntityStatus, Icon20, TimeDisplay} from '../../engrator-core/ui';
import {IntegrationSavedNotification} from './integration-saved-notification';
import {PersistenceLoader} from '../container/persistence-loader';
import {Prompt} from 'react-router-dom';
import {HelpCallButton} from '../../app/engagement/help-call-button';
import {OwnerTag} from '../../app/integration/smartints/owner-tag';
import {getAccessOptionTagName} from '../../software/generic/connections-rest-api';

type ToolbarProps = {
    integrationId: number;
    toolbarActions: TopBarActions;
    history: any;
    definition?: SmartIntDefinition;
};

type State = {
    hasIntegrationConfigurationChanged: boolean;
    persistenceState: PersistenceState,
    persistenceError: string;
    isAdvancedEnabled: boolean;
    name: string;
    saved: boolean;
}

export class TopBar extends React.Component<ToolbarProps, State> {
    constructor(props: ToolbarProps) {
        super(props);
        this.state = {
            name: this.props.toolbarActions.getName(),
            hasIntegrationConfigurationChanged: false,
            persistenceError: '',
            persistenceState: PersistenceState.None,
            isAdvancedEnabled: false,
            saved: false
        };
    }

    componentDidMount(): void {
        this.listenForConfigurationChanges();
        // if (!isJiraServerNativeApp) {
        isAdvancedConfigurationEnabled().then((isAdvancedEnabled) => {
            this.setState({isAdvancedEnabled})
        });
        // }
        if (!this.props.toolbarActions.disableSaveBtn) {
            (window as any).onbeforeunload = (e: any) => {
                if (this.state.hasIntegrationConfigurationChanged) {
                    e.preventDefault();
                    return 'Are you sure?';
                }
            }
        }
    }

    componentWillUnmount() {
        (window as any).onbeforeunload = undefined;
    }

    componentDidUpdate(prevProps: Readonly<ToolbarProps>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(this.props.definition) !== JSON.stringify(prevProps.definition)) {
            this.forceUpdate();
        }
    }

    private bookDemoPageClicked() {
        (window as any).open('https://getint.io/demo?src=app', '_blank').focus();
    }

    save() {
        registerUserActivityAction(UserActivityAction.ClickedCreateIntegration);
        this.setState({ saved: false, persistenceState: PersistenceState.Saving }, () => {
            this.props.toolbarActions.save((integrationId: number, error?: any) => {
                if (error) {
                    this.setState({ persistenceState: PersistenceState.Failed, persistenceError: error.message });
                } else {
                    this.setState({ persistenceState: PersistenceState.None, persistenceError: '' }, () => {
                        const isIntegrationEditPage = this.props.integrationId > 0;
                        if (!isIntegrationEditPage) {
                            const module = (window.location.href.indexOf('smart-ints') >= 0) ? 'smart-ints' : 'pipelines';
                            (this.props as any).history.push(`/app/integration/${module}/${integrationId}/edit`);
                        } else {
                            this.setState({ saved: true });
                        }
                    });
                }
            });
        });
    }

    render() {
        return <div className="designer-top">
            { !!this.props.integrationId && <React.Fragment>
                <EntityStatus status={ this.props.toolbarActions.getStatus() as any} />
                <OwnerTag
                    showOwnerPrefix={ true }
                    ownerId={ this.props.toolbarActions.getOwnerId() }
                    owner={ this.props.toolbarActions.getOwner() }
                />
                { this.props.toolbarActions.getCreatedAt() && <UI.Tag name={ <span>Created: <TimeDisplay dateTime={ this.props.toolbarActions.getCreatedAt() } /></span> } /> }
                { this.props.toolbarActions.getShareAccess && <UI.Tag name={ <span>Access: { getAccessOptionTagName(this.props.toolbarActions.getShareAccess()!) }</span> } /> }
            </React.Fragment> }
            {/*<p><UI.Icon icon20={ Icon20.HourGlass } /> 15s</p>*/}
            <div className={`flex`}>
                <div className="left">
                    <div className="content inline-name">
                        <UI.Icon icon20={ Icon20.Edit } />
                        <UI.Input
                            label=""
                            placeholder="Provide a name for your integration"
                            name="name"
                            defaultValue={ this.props.toolbarActions.getName() as string }
                            onChange={ (value: any) => this.changeName(value) }
                        />
                        { !this.state.name && <GuideTooltip
                            action={ UserActivityAction.SyncGuideIntegrationName }
                            maxWidth={ 200 }
                            closeable={true}
                        >Provide a name for your integration
                        </GuideTooltip> }
                    </div>
                </div>
                <div className={`middle`}>
                </div>
                <div className="right">
                    <div className="toolbar">
                        <React.Fragment>
                            <HelpCallButton
                                // bookDemoPageClicked={ () => this.bookDemoPageClicked() }
                            />
                            {/*{ this.state.isAdvancedEnabled && <UI.Button*/}
                            {/*    icon={<React.Fragment/>}*/}
                            {/*    text="Advanced"*/}
                            {/*    appearance="secondary"*/}
                            {/*    onClick={() => this.props.toolbarActions.showAdvancedConfiguration()}*/}
                            {/*/> }*/}
                            <MoreBtn
                                optionSelectedHandler={ (option) => this.moreBtnOptionSelected(option) }
                            />
                            { !this.props.toolbarActions.disableSaveBtn && <React.Fragment>
                                { !!this.props.integrationId && <UI.Button icon={ <UI.Icon icon={`save`} /> } dataSel={`save`} text="Save" disabled={ !this.state.hasIntegrationConfigurationChanged } onClick={ () => this.save() }/> }
                                { !this.props.integrationId && <UI.Button icon={<UI.Icon icon={`save`} /> } dataSel={`save`} text="Create" onClick={ () => this.save() }/> }
                            </React.Fragment> }
                        </React.Fragment>
                        { this.state.saved && <IntegrationSavedNotification /> }
                    </div>
                </div>
            </div>

            { this.state.persistenceState !== PersistenceState.None && <PersistenceLoader
                closeHandler={ this.clearPersistenceState.bind(this) }
                error={ this.state.persistenceError }
                persistenceState={ this.state.persistenceState }
            /> }
            { this.state.hasIntegrationConfigurationChanged && !!this.props.integrationId && <Prompt message={`You have unsaved changes. Are you sure you want to leave?`} /> }
        </div>;
    }

    private moreBtnOptionSelected(option: MoreOptions) {
        if (option === MoreOptions.Settings) {
            this.props.toolbarActions.showSettings();
        } else if (option === MoreOptions.Advanced) {
            this.props.toolbarActions.showAdvancedConfiguration();
        } else if (option === MoreOptions.Share) {
            this.props.toolbarActions.showShare();
        }
    }

    private clearPersistenceState() {
        this.setState({ persistenceState: PersistenceState.None, persistenceError: '' });
    }

    private listenForConfigurationChanges() {
        setTimeout(() => {
            let hasChanged = this.props.toolbarActions.hasIntegrationConfigurationChanged();
            if (hasChanged !== this.state.hasIntegrationConfigurationChanged) {
                this.setState({ hasIntegrationConfigurationChanged: hasChanged });
            }
            this.listenForConfigurationChanges();
        }, 1 * 1000);
    }

    private changeName(value: string): void {
        this.props.toolbarActions.changeName(value);
        this.setState({ name: value });
    }
}
