import React from 'react';
import {UI} from 'src/engrator-core';
import {Icon, Icon20} from '../../../../engrator-core/ui';

type Props = {
    isAutoMapping: boolean;
    shouldDisableAutoMap?: boolean;
    addMappingClicked: () => void;
    autoMapFieldsClicked: () => void;
};
type State = {
    isEnabled: boolean;
    isAutoMapping: boolean;
};

export class MapFieldsBtn extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isAutoMapping: props.isAutoMapping,
            isEnabled: true
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.isAutoMapping !== prevProps.isAutoMapping) {
            this.setState({ isAutoMapping: this.props.isAutoMapping });
        }
    }

    render() {
        return <div className={`map-fields-btn-container`}>
            <UI.Button className={`add-field-mapping-btn`} onClick={ () => this.props.addMappingClicked() } text={`Add Field Mapping`} icon={ <Icon icon20={ Icon20.CirclePlusWhite } /> } />
            { this.state.isEnabled && !this.props.shouldDisableAutoMap && <UI.Button
                icon={ <UI.Icon icon20={Icon20.Workflows}/> }
                appearance={'secondary'}
                text={`Match Fields`}
                isLoading={ this.state.isAutoMapping }
                onClick={ () => this.props.autoMapFieldsClicked() }
            /> }
        </div>
    }
}
