import React from 'react';
import { UI } from 'src/engrator-core';
import {GitConfiguration} from '../../definition/smart-int-trigger.type';
import {DropdownOption} from '../../../../../engrator-core/ui';
import {fetchProjects} from '../../../../../software/jira/steps/rest-api';
import {FormError} from '../../../../../engrator-core/form-error.type';
import {fetchGitRepositories} from './rest-api';
import {SoftwareName} from '../../../../../software';

type Props = {
    gitConfig: GitConfiguration;
    jiraConnectionId: number;
    otherAppConnectionId: number;
    otherApp: SoftwareName;
};
type State = {
    associateWithAllJiraProjects: boolean;
    jiraProjects: DropdownOption[];
    projectsFetchingError?: FormError;
    isLoadingRepositories: boolean;
    repositoriesFetchingError?: FormError;
    isLoadingProjects: boolean;
};

export class GitIntegrationConfiguration extends React.Component<Props, State> {
    private options: DropdownOption[] = [];

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoadingRepositories: false,
            isLoadingProjects: false,
            jiraProjects: [],
            associateWithAllJiraProjects: this.props.gitConfig.jiraProjects.length === 0
        };
    }

    async componentDidMount() {
        // This will have to be made more generic if we are going to support also other Apps
        try {
            if (!this.state.associateWithAllJiraProjects) {
                await this.fetchJiraProjects();
            }
            await this.setState({ isLoadingRepositories: true });
            const repositories = await fetchGitRepositories(this.props.otherApp, this.props.otherAppConnectionId);
            this.options = repositories.map(r => ({label: r.name, value: r.id, object: r}));
            await this.setState({ isLoadingRepositories: false });
        } catch (repositoriesFetchingError) {
            this.setState({ repositoriesFetchingError, isLoadingRepositories: false });
        }
    }

    render() {
        // Below .filter(s) added for safety reasons but its a candidate to remove with time
        const options = this.props.gitConfig.repositories.filter(r => !!r).map(r => ({isSelected: true, label: r.name, value: r.id} as DropdownOption));
        const selectedJiraProjects = this.props.gitConfig.jiraProjects.filter(r => !!r).map(r => ({isSelected: true, label: r.name, value: '' + r.id} as DropdownOption));
        return <div className={`git-integration-configuration`}>
            <UI.Form>
                <UI.FormGroup
                    label={`Restrict to Jira Projects`}
                >
                    { this.state.isLoadingProjects && <UI.Loader visible={true} appearance={'darkgray'} /> }
                    { this.state.projectsFetchingError && <UI.Message appearance={'error'} message={this.state.projectsFetchingError} /> }
                    { !this.state.associateWithAllJiraProjects && !this.state.isLoadingProjects && <UI.Dropdown
                        onChange={ (value: any) => this.setProjects(value) }
                        options={ this.state.jiraProjects }
                        isMultiSelect={ true }
                        multiSelectOptions={ selectedJiraProjects }
                    /> }
                    <p><UI.Checkbox
                        label={`Associate with all projects`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                        defaultValue={ `${this.state.associateWithAllJiraProjects}` }
                        onChange={ () => this.switchAssociateWithAllProjects() }
                    /></p>
                </UI.FormGroup>
                <UI.FormGroup
                    dataSel={`git-repositories`}
                >
                    { this.state.isLoadingRepositories && <UI.Loader visible={true} appearance={'darkgray'} /> }
                    { this.state.repositoriesFetchingError && <UI.Message appearance={'error'} message={this.state.repositoriesFetchingError} /> }
                    { !this.state.isLoadingRepositories && <UI.Dropdown
                        label={`Git Repositories`}
                        onChange={ (value: any) => this.repositorySelected(value) }
                        isRequired={true}
                        isMultiSelect={true}
                        options={ this.options }
                        multiSelectOptions={ options }
                    /> }
                </UI.FormGroup>
            </UI.Form>
        </div>;
    }

    private async setProjects(projects: any[]): Promise<void> {
        console.log(projects);
        const jiraProjects = (projects) ? projects.map(v => v.object || v) : [];
        this.props.gitConfig.jiraProjects = jiraProjects;
        if (projects.length === 0 && !this.state.associateWithAllJiraProjects) {
            await this.switchAssociateWithAllProjects();
        }
    }

    private async switchAssociateWithAllProjects(): Promise<void> {
        const associateWithAllJiraProjects = !this.state.associateWithAllJiraProjects;
        await this.setState({ associateWithAllJiraProjects});
        if (!associateWithAllJiraProjects && this.state.jiraProjects.length === 0) {
            await this.fetchJiraProjects();
        }
    }

    private async fetchJiraProjects() {
        try {
            await this.setState({isLoadingProjects: true});
            const projects = await fetchProjects(this.props.jiraConnectionId);
            const jiraProjects = projects.map(p => ({
                label: p.name,
                value: p.id + '',
                object: p
            } as DropdownOption));
            this.setState({isLoadingProjects: false, jiraProjects});
        } catch (error) {
            this.setState({isLoadingProjects: false, projectsFetchingError: error});
        }
    }

    repositorySelected(value?: any[]): void {
        const repositories = (value) ? value.map(v => v.object || v) : [];
        this.props.gitConfig.repositories = repositories;
    }
}