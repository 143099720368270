import React from 'react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis, } from 'recharts';

import { FieldError, UI } from 'src/engrator-core';
import { getDashboardChartData } from './rest-api';
import { DashboardReportType } from './dashboard-report.type';

type State = {
    errors: Array<FieldError>,
    isLoading: boolean,
    chartData?: object[],
    keys: string[],
    error?: FieldError
};

type Props = {
    time: number;
    reportType: DashboardReportType
};

const linesColors = ['#4dad5d', '#ca423d', '#8dd1e1', '#82ca9d'];

export class Chart extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errors: [],
            isLoading: true,
            chartData: undefined,
            keys: [],
            error: undefined
        };
    }

    componentDidMount(): void {
        this.load();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.time !== prevProps.time) {
            this.load();
        }
    }

    load(): void {
        const NUMBER_OF_DAYS = 5;
        this.setState({ chartData: undefined}, () => {
            getDashboardChartData(this.props.reportType, NUMBER_OF_DAYS)
                .then(response => {
                    const chartData = response.labels.map(label => {
                        response.data[label]['name'] = label;
                        return response.data[label];
                    });
                    this.setState({ chartData, keys: response.keys });
                })
                .catch(error => {
                    this.setState({ error });
                });
        });
    }

    render() {
        if (this.state.error) {
            return <UI.Message appearance={`error`}>{ (this.state.error as unknown as FieldError).message }</UI.Message>;
        }
        if (!this.state.chartData) {
            return <React.Fragment>
                <UI.Loader visible={true} appearance={'darkgray'}/>
            </React.Fragment>;
        }
        return (
            <LineChart
                width={500} height={160} data={this.state.chartData}
                margin={{
                    top: 20, right: 20, left: 0, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                { this.state.keys.map((key, index) =>
                    <Line type="monotone" dataKey={ key } stroke={ linesColors[index] } />
                )}
            </LineChart>
        );
    }
}
