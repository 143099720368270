import React from 'react';
import { UI } from 'src/engrator-core';
import { NewPipelineStepComponentProps } from 'src/generic';
import { ArtifactDirection } from '../../../../generic/artifacts';
import { GetintArchiverStepContext, GetintArchiverStepContextData } from '../common';
import { GetintArchiverSearchItemsConfiguration } from './monday-search-items-configuration';

type Props = {} & NewPipelineStepComponentProps;
type State = {
    formError: { [key: string]: string },
    canConfigureArtifact: boolean,
    isLoadingJql: boolean,
    isAdding: boolean
};

export class GetintArchiverSearchItemsNewStep extends React.Component<Props, State> {
    private configuration: GetintArchiverSearchItemsConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as GetintArchiverSearchItemsConfiguration;
        this.configuration.onlyNewChanges = true;
        this.configuration.context = {};
        this.state = {isLoadingJql: false, formError: {}, canConfigureArtifact: false, isAdding: false};
    }

    private onConfigurationChanged(data: GetintArchiverStepContextData) {
        this.configuration.context.board = data.board;
        if (this.configuration.context.board) {
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }

    render() {
        return <div className={`connectors-github-search-items-step`}>
            <GetintArchiverStepContext connectionId={this.props.context.connectionId}
                onDataChanged={this.onConfigurationChanged.bind(this)}/>
            <UI.FormGroup
                label={`Changed from last integration`}
                description={`If checked, only items that changed since last integration will be picked up.`}
            >
                <UI.Checkbox defaultValue={'true'} onChange={this.onlyNewChanged.bind(this)} checkedValue={'true'}
                    uncheckedValue={'false '}/>
            </UI.FormGroup>
        </div>;
    }

    onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }
}
