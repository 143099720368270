import {ImportData} from './import-popup';
import {axiosWrapper} from '../../../engrator-core';

const ENDPOINT_URL = '/integration/import';

export function importSyncedArtifact(importData: ImportData): Promise<void> {
    importData.captureData = importData.captureData || 'false';
    return new Promise((resolve, reject) => {
        axiosWrapper.post(ENDPOINT_URL, importData)
            .then( () => resolve() )
            .catch( (err) => reject(err) );
    });
}
