import { axiosWrapper } from 'src/engrator-core';
import {NewPipelineGroup} from './group-picker/new-pipeline-group.type';
import {PipelineGroup} from './pipeline-group.type';

const ENDPOINT_URL = '/integration/groups';

export const fetchPipelinesGroups = (): Promise<PipelineGroup[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const createPipelineGroup = (newPipelineGroup: NewPipelineGroup): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL, newPipelineGroup)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};