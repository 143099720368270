import React from 'react';
import { UI} from 'src/engrator-core';

import {AsanaWorkspaceProjectPickerData} from './asana-workspace-project-picker';

type Props = {
    configuration: AsanaWorkspaceProjectPickerData;
}

type State = {
}

export class AsanaWorkspaceProjectDisplay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            { this.props.configuration.workspace &&
                <UI.LabelValue label={`Workspace`} value={this.props.configuration.workspace!.name}/>
            }
            { this.props.configuration.project &&
            <UI.LabelValue label={`Project`} value={this.props.configuration.project!.name}/>
            }
        </React.Fragment>;
    }
}