import {axiosWrapper, FieldError} from 'src/engrator-core';
import {AzureItemTypeDTO, AzureProjectDTO} from './common';

export const ENDPOINT_URL = '/connectors/azure';

export const fetchProjects = (connectionId: number, collectionName?: string): Promise<AzureProjectDTO[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/projects?collection=' + collectionName)
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchProjectItemTypes = (connectionId: number, projectKey: string): Promise<AzureItemTypeDTO[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/projects/' + projectKey + '/itemTypes')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
