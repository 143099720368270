import React from 'react';

import {createConnection} from '../../generic/connections-rest-api';
import {NewConnectionData} from '../../../connections';
import {FieldError, UI} from 'src/engrator-core';
import {SOFTWARE_NAME} from '../index';

type Props = {
    onConnectionAddedHandler: (() => void)
};

type State = {
    formError: { [key: string]: string },
    isAdding: boolean
};

export class IntHubConnection extends React.Component<Props, State> {
    formData = {
        url: '',
        login: 'api',
        password: ''
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            formError: {},
            isAdding: false
        }
    }

    onFormChange(elementThatChanged:  'url' | 'login' | 'password', newValue: string) {
        this.formData[elementThatChanged] = newValue;
    }

    addConnection() {
        this.setState({ isAdding: true, formError: {} });
        const connection: NewConnectionData = this.createNewConnectionData();
        createConnection(connection)
            .then(() => {
                this.props.onConnectionAddedHandler();
                this.clear();
            }).catch((error: FieldError) => {
                const formError = { [error.fieldName] : error.message };
                this.setState({ isAdding: false,  formError });
            });
    }

    render() {
        return <div className="jira-connection">
            <UI.Form
                errors={ [] }
                error={ this.state.formError['general'] }>
                <UI.FormGroup
                    label={`Url`}
                    error={ this.state.formError['url'] }
                    description={`Url to your Jira instance starting with http:// or https://`}
                    isRequired={true}>
                    <UI.Input onChange={ (newValue: string) => { this.onFormChange('url', newValue) }   }/>
                </UI.FormGroup>
                <UI.FormGroup
                    label={`API Key`}
                    error={ this.state.formError['password'] }
                    isRequired={true}>
                    <UI.Input type="password" onChange={ (newValue: string) => { this.onFormChange('password', newValue) }   }/>
                </UI.FormGroup>
                <UI.ButtonsBar
                    primary={ <UI.Button appearance="secondary" onClick={() => { this.addConnection() } } isLoading={ this.state.isAdding } text="Add" /> } />
            </UI.Form>
        </div>;
    }

    private clear() {
        this.formData = {
            login: '',
            password: '',
            url: ''
        };
        this.setState({ isAdding: false, formError: {} });
    }

    private createNewConnectionData(): NewConnectionData {
        return {
            softwareName: SOFTWARE_NAME,
            name: 'IntHub connection to '+ this.formData.url,
            url: this.formData.url,
            login: this.formData.login,
            password: this.formData.password
        };
    }
}