import DataArtifact, {ConfiguredProperty} from './data-artifact';
import {StepArtifact} from '../software/step';
import {PropertySourceType} from './property-source/property-source-type';
import {ArtifactDirection, PropertySource} from './index';
import {SoftwareName} from '../../software';

export class ArtifactsFactory {
    artifactsCounter: number;
    artifacts: StepArtifact[];

    constructor(artifactsCounter: number) {
        if (artifactsCounter === undefined) {
            throw '`artifactsCounter` arguemnt can not be null';
        }
        this.artifactsCounter = artifactsCounter;
        this.artifacts = [];
    }

    registerArtifact(sourceId: number, artifact: DataArtifact, softwareName: SoftwareName, stepConfigurationData: any, connectionId: number): StepArtifact {
        const isNameAlreadyTaken = this.artifacts.filter(existingArtifact => existingArtifact.getName() === artifact.getName()).length > 0;
        if (isNameAlreadyTaken) {
            // throw `Artifact with name '${artifact.getName()}' is already taken. Please name artifact different`;
        }
        const stepArtifact = new StepArtifact(
            artifact.getName(),
            artifact.direction,
            artifact.getConfiguredProperties(),
            softwareName,
            stepConfigurationData,
            connectionId
        );
        this.artifacts.push(stepArtifact);
        return stepArtifact;
    }

    getAvailableArtifacts(): StepArtifact[] {
        return this.artifacts;
    }

    getAvailableOutArtifactsForSoftware(softwareName: SoftwareName): StepArtifact[] {
        return this.artifacts.filter(
            artifact => artifact.getDirection() === ArtifactDirection.Out && artifact.getSoftwareName() === softwareName
        );
    }

    getAvailableArtifactsWithProperties(configuredProperties: ConfiguredProperty[]) {
        const idsOfPropertiesFromOtherArtifacts = configuredProperties
            .filter((property: ConfiguredProperty) => property.source.sourceType === PropertySourceType.OtherArtifact)
            .map((property: ConfiguredProperty) => ({
                artifactId: (property.source as PropertySource.OtherArtifactPropertySource).artifactId,
                propertyId: (property.source as PropertySource.OtherArtifactPropertySource).propertyDefinition.id
            }));
        const availableArtifacts: Array<Object> = [];
        const artifacts = this.getAvailableArtifacts();
        artifacts.forEach((artifact: StepArtifact) => {
            const availableArtifact: any = {
                id: artifact.name,
                propertyDefinitions: []
            };
            artifact.properties.forEach((property: any) => {
                const isMappedAlready = idsOfPropertiesFromOtherArtifacts
                    .filter(obj => obj.artifactId === availableArtifact.id && obj.propertyId === property.id)
                    .length > 0;
                if (!isMappedAlready) {
                    availableArtifact.propertyDefinitions.push(property);
                }
            });
            availableArtifacts.push(availableArtifact);
        });
        return availableArtifacts;
    }

    removeArtifactsOfSource(sourceId: any) {
        throw new Error('NOT IMPLEMENTED');
        // this.artifacts = this.artifacts.filter(artifact => artifact.name !== sourceId);
    }

    getConnectionIdForArtifact(artifactName: string): number | null {
        const pickedStepArtifact = this.artifacts.filter((artifact) => artifact.getName() === artifactName)[0];
        if (pickedStepArtifact) {
            return  pickedStepArtifact.getConnectionId();
        }
        return null;
    }
}
