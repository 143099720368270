import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';

import {GitLabStepKey} from '../gitlab-step-key.type';
import {GitLabStatusTransitionEditStep} from './gitlab-status-transition-edit-step';
import {GitLabStatusTransitionNewStep} from './gitlab-status-transition-new-step';
import {GitLabStatusTransitionConfiguration} from './gitlab-status-transition-configuration';

export const GitLabStatusTransitionPipelineStep: PipelineStep<GitLabStatusTransitionConfiguration> = {
    addStepComponentClass: GitLabStatusTransitionNewStep,
    editStepComponentClass: GitLabStatusTransitionEditStep,
    specification: {
        action: 'Change item status',
        description: 'Move GitLab item from one status to another',
        key: GitLabStepKey.GitLabStatusTransition,
        software: SoftwareName.GitLab,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitLabStatusTransitionConfiguration;
        return `Change item status`;
    }
}