import { axiosWrapper } from 'src/engrator-core';
import { FlowDetails } from './flow-details.type';

const ENDPOINT_URL = '/reporting/flows';

export const fetchFlowDetails = (flowId: number): Promise<FlowDetails> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/${flowId}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchStepLogs = (flowId: number, stepIndex: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/${flowId}/step/${stepIndex}/logs`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchFlowLogs = (flowId: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/${flowId}/logs`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

