import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { SoftwareName } from '../../software-name';
import {fetchDatabases} from './rest-api';
import { NotionDatabaseDTO } from './notion-smart-ints-trigger-configuration';
import {DropdownOption} from '../../../engrator-core/ui';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {
    isLoading: boolean;
    databaseOptions?: DropdownOption[];
};

export class NotionSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false
        };
        this.smartIntTrigger = {
            app: SoftwareName.Notion,
            artifactName: '',
            className: '',
            configuration: {
                query: '',
            },
            connectionId: this.props.connectionId,
        };
    }

    async componentDidMount(): Promise<void> {
        await this.setState({ isLoading: true });
        const results = await fetchDatabases(this.smartIntTrigger.connectionId);
        const databaseOptions: DropdownOption[] = results.map(s => ({
            value: s.id,
            label: s.name,
            object: s
        }));
        this.setState({ isLoading :false, databaseOptions });
    }

    render() {
        return <React.Fragment>
            { this.state.databaseOptions && <UI.FormGroup
                label={`Database`}
                isRequired={ true }
            >
                <UI.Dropdown
                    options={ this.state.databaseOptions }
                    onChange={ (value: string, obj: NotionDatabaseDTO) => this.setDatabase(obj) }
                />
            </UI.FormGroup> }
            { this.state.isLoading && <UI.Loader visible={ true } appearance={'darkgray'} /> }
            {/*<UI.FormGroup*/}
            {/*    label={`Additional query`}*/}
            {/*    description={``}*/}
            {/*>*/}
            {/*    <UI.Input label={``} onChange={ this.onJqlInputChanged.bind(this)} />*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>;
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private setDatabase(item: NotionDatabaseDTO) {
        this.smartIntTrigger.configuration.database = item;
        this.smartIntTrigger.artifactName = 'task';
        this.props.onConfigured(this.smartIntTrigger!);
        this.forceUpdate();
    }
}
