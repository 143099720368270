import {PipelineStep} from 'src/generic/pipeline-step';
import {SoftwareName} from 'src/software/software-name';
import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';

import {WrikeStepKey} from '../wrike-step-key.type';
import {WrikeSearchItemsConfiguration} from './wrike-search-items-configuration';
import {WrikeSearchItemsNewStep} from './wrike-search-items-new-step';
import {WrikeSearchItemsEditStep} from './wrike-search-items-edit-step';


export const WrikeSearchItemsPipelineStep :  PipelineStep<WrikeSearchItemsConfiguration> = {
    addStepComponentClass: WrikeSearchItemsNewStep,
    editStepComponentClass: WrikeSearchItemsEditStep,
    specification: {
        action: 'Search Wrike Tasks',
        description: 'Search tasks in specified project',
        key: WrikeStepKey.WrikeSearchItems,
        software: SoftwareName.Wrike,
        stepType: StepType.Trigger
    },
    preferredNameForArtifact: `WrikeItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as WrikeSearchItemsConfiguration;
        return `Search for tasks in ${stepConfiguration.context?.space?.name}, ${stepConfiguration.context?.folder?.name}`;
    }
}