import React from 'react';
import {UI} from 'src/engrator-core';
import {PersistenceState} from './persistence-state.type';

type Props = {
    error: string,
    persistenceState: PersistenceState,
    closeHandler: () => void
};

type State = {
    isLoading: boolean,
}

export class PersistenceLoader extends React.Component<Props, State> {
    state = {
        isLoading: true,
    };

    constructor(props: Props) {
        super(props);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.persistenceState !== prevProps.persistenceState) {
            this.setState({
                isLoading: this.props.persistenceState === PersistenceState.Saving,
            });
        }
    }

    render() {
        return <div className={`persistence-loader`}>
            <div className={`box`}>
                <h3>Saving</h3>
                {this.state.isLoading &&
                    <div className={`loader`}><UI.Loader appearance={'darkgray'} visible={true}/></div>
                }
                { this.props.error && <div>
                    <p>Unfortunately, your integration failed to save due to following reason:</p>
                    <UI.Message appearance={'error'}>
                        { this.props.error }
                    </UI.Message>
                    <UI.Button onClick={ this.props.closeHandler } text={`Close`} appearance={'secondary'} />
                </div>}
            </div>
        </div>
    }
}
