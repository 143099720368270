import React, {Component, useEffect} from 'react';
import {FormError} from '../../../engrator-core/form-error.type';
import {useGlobalState} from '../../global-store';
import {getSystemVerticalMenu} from '../system-vertical-menu';
import {MainMenuItem} from '../../global-store/create-global-state';
import { UI } from 'src/engrator-core';

type State = {
    isLoading: boolean;
    error?: FormError;
};

class SystemUsersPageCmp extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    render() {
        return (
            <UI.Page>
                <UI.Tile
                    header={`Add user to workspace (Jira server)`}
                >
                    <UI.Form useMaxWidthClass={true}>
                        <UI.Message appearance={'info'}>Invite your team mates to this workspace. If they are not the jira admin users share with them app url so they can access it <a>https://a4321dja.live.getint.io</a>.</UI.Message>
                        <React.Fragment>
                            <UI.FormGroup
                                label={`Jira username`}
                                isRequired={ true }
                                description={``}
                            >
                                <UI.Input
                                    defaultValue={`${ '' }`}
                                    type={'text'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Role`}
                                isRequired={ true }
                                description={``}
                            >
                                <UI.Dropdown
                                    options={[]}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                        </React.Fragment>

                        <UI.ButtonsBar
                            secondary={ [(this.state.error) ? <UI.Message appearance={'success'}>Settings saved!</UI.Message> : <React.Fragment />] }
                            primary={ <UI.Button
                                disabled={ false }
                                text={`Invite`}
                                onClick={ () => {} }
                                isLoading={ false }
                            /> }
                        />
                    </UI.Form>
                </UI.Tile>
                <br/><br/>
                <UI.Tile
                    header={`Add user to workspace (Jira Cloud/Getint Cloud)`}
                >
                    <UI.Form useMaxWidthClass={true}>
                        <UI.Message appearance={'info'}>Invite your team mates to this workspace. Invited user will be emailed with your workspace url (https://a4321dja.live.getint.io) and use a generated code to sign in to workspace.</UI.Message>
                        <React.Fragment>
                            <UI.FormGroup
                                label={`E-mail address`}
                                isRequired={ true }
                                description={``}
                            >
                                <UI.Input
                                    defaultValue={`${ '' }`}
                                    type={'text'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Role`}
                                isRequired={ true }
                                description={`Set the duration for storing integration logs. Older logs are automatically deleted after the defined period.`}
                            >
                                <UI.Dropdown
                                    options={[]}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                        </React.Fragment>

                        <UI.ButtonsBar
                            secondary={ [(this.state.error) ? <UI.Message appearance={'success'}>Settings saved!</UI.Message> : <React.Fragment />] }
                            primary={ <UI.Button
                                disabled={ false }
                                text={`Invite`}
                                onClick={ () => {} }
                                isLoading={ false }
                            /> }
                        />
                    </UI.Form>
                </UI.Tile>
                <br/><br/>
                <UI.Tile
                    header={`Add user`}
                >
                    <UI.Form useMaxWidthClass={true}>
                        <UI.Message appearance={'info'}>Add user to Getint instance deployment ...</UI.Message>
                        <React.Fragment>
                            <UI.FormGroup
                                label={`E-mail address`}
                                isRequired={ true }
                                description={``}
                            >
                                <UI.Input
                                    defaultValue={`${ '' }`}
                                    type={'text'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Require users to sign in with SSO`}
                                isRequired={ true }
                                description={``}
                            >
                                <UI.Checkbox
                                    checkedValue={`true`}
                                    uncheckedValue={`false`}
                                    defaultValue={`${ '' }`}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Password`}
                                isRequired={ true }
                                description={``}
                            >
                                <UI.Input
                                    defaultValue={`${ '' }`}
                                    type={'password'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Repeat password`}
                                isRequired={ true }
                                description={``}
                            >
                                <UI.Input
                                    defaultValue={`${ '' }`}
                                    type={'password'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Role`}
                                isRequired={ true }
                                description={`Set the duration for storing integration logs. Older logs are automatically deleted after the defined period.`}
                            >
                                <UI.Dropdown
                                    options={[]}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                        </React.Fragment>

                        <UI.ButtonsBar
                            secondary={ [(this.state.error) ? <UI.Message appearance={'success'}>Settings saved!</UI.Message> : <React.Fragment />] }
                            primary={ <UI.Button
                                disabled={ false }
                                text={`Save`}
                                onClick={ () => {} }
                                isLoading={ false }
                            /> }
                        />
                    </UI.Form>
                </UI.Tile>
                <br/><br/>
                <UI.Tile
                    header={`Users`}
                >
                    <UI.TableGrid headers={['Identifier', 'Role', 'Created at']} data={[
                        ['radek@getint.io', 'Workspace Admin', '2023-01-10 21:32:12']
                    ]} />
                </UI.Tile>
                <br/><br/>
                <UI.Tile
                    header={`Azure Active Directory`}
                >
                    <UI.Form useMaxWidthClass={true}>
                        <UI.Message appearance={'info'}>Lorem ipsum dolot sit amet consectetur sth to write here is there <UI.DocumentationLink text={`Read more`} url={``} /></UI.Message>
                        <React.Fragment>
                            <UI.LabelValue
                                label={`Tenant Id`}
                                value={`fkj2e231-dasbk-e12efbwh-312bhda`}
                            />
                            <UI.LabelValue
                                label={`Client Id`}
                                value={`rbi23-432bd-321jda-321jdk-312`}
                            />
                            <UI.LabelValue
                                label={`Client Secret`}
                                value={`43d**************32a`}
                            />
                            <UI.ButtonsBar
                                secondary={ [<UI.Button
                                    appearance={'danger'}
                                    disabled={ false }
                                    text={`Delete`}
                                    onClick={ () => {} }
                                    isLoading={ false }
                                />] }
                                primary={ <UI.Button
                                    appearance={'secondary'}
                                    disabled={ false }
                                    text={`Edit`}
                                    onClick={ () => {} }
                                    isLoading={ false }
                                /> }
                            />
                        </React.Fragment>
                        <React.Fragment>
                            <UI.FormGroup
                                label={`Tenant Id`}
                                isRequired={ true }
                                description={`Set the duration for storing integration logs. Older logs are automatically deleted after the defined period.`}
                            >
                                <UI.Input
                                    defaultValue={`${ '' }`}
                                    type={'text'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Client Id`}
                                isRequired={ true }
                                description={`Set the duration for storing integration logs. Older logs are automatically deleted after the defined period.`}
                            >
                                <UI.Input
                                    defaultValue={`${ '' }`}
                                    type={'text'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Client Secret`}
                                isRequired={ true }
                                description={`Set the duration for storing integration logs. Older logs are automatically deleted after the defined period.`}
                            >
                                <UI.Input
                                    defaultValue={`${ '' }`}
                                    type={'text'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => {} }
                                />
                            </UI.FormGroup>
                        </React.Fragment>

                        <UI.ButtonsBar
                            secondary={ [(this.state.error) ? <UI.Message appearance={'success'}>Settings saved!</UI.Message> : <React.Fragment />] }
                            primary={ <UI.Button
                                disabled={ false }
                                text={`Save`}
                                onClick={ () => {} }
                                isLoading={ false }
                            /> }
                        />
                    </UI.Form>
                </UI.Tile>
            </UI.Page>
        );
    }
}

export function SystemUsersPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.SystemSettings);
        setMenu(getSystemVerticalMenu());
    }, []);
    return <SystemUsersPageCmp />
}