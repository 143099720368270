import React, {Component, useEffect} from 'react';
import {UI} from '../../../engrator-core';
import {Footer} from '../../footer';
import {ArchiverTabBar, ArchiverTabBarItems} from '../tabbar';
import {ArchiverSearch} from '../search/archiver-search';
import {ArchivedItemDetails} from '../item';
import {
    ArchivedItemHeader,
    ArchivedItemsFiltersData,
    ArchivedItemShortData,
    ArchivedItemsResponse, exportToCSV,
    searchArchivedItems
} from '../rest-api';
import { useGlobalState} from '../../global-store';
import {MainMenuItem} from '../../global-store/create-global-state';

type State = {
    itemDetails?: ArchivedItemShortData;
    currentPage: number;
    response?: ArchivedItemsResponse;
};
type Props = {
};

export class ArchivedItemsPage2 extends Component<Props, State> {
    private data?: any[][];
    private filtersData: ArchivedItemsFiltersData;

    constructor(props: Props) {
        super(props);
        this.state = {currentPage: 0};
        this.filtersData = { page: '0' };
    }

    componentDidMount() {
        this.search(this.filtersData)
    }

    private search(filters: ArchivedItemsFiltersData) {
        if (!filters.page) {
            filters.page = '0';
        }
        this.filtersData = filters;
        this.setState({ response: undefined}, () => {
            searchArchivedItems(filters)
                .then(response => {
                    this.data = response.items.map(item => this.createItemData(item, response.headers))
                    this.setState({response});
                });
        });
    }

    render() {
        return <UI.Page
            className={`archiver-page`}
        >
            <ArchiverTabBar
                allItemsCount={ this.state.response?.totalElements }
                exportToCSVHandler={ (onlyIds) => this.exportToCSVClicked(onlyIds) }
                currentItem={ArchiverTabBarItems.ArchivedItems}
            />
            <br/>
            <ArchiverSearch
                onSearchClicked={ filters => this.search(filters) }
            />
            {this.state.itemDetails && <ArchivedItemDetails
                shortItemData={this.state.itemDetails}
                close={() => this.setState({itemDetails: undefined})}
            />}
            {!this.state.response && <UI.Loader visible={true} appearance={'darkgray'}/>}
            {this.data && this.state.response && <React.Fragment>
                <UI.TableGrid
                    data={this.data}
                    headers={['Item ID', 'Type', 'App', 'Belongs to', ...this.state.response.headers.map(header => header.label), 'Actions']}
                />
                <UI.Pagination
                    onPageChangeHandler={(page) => this.switchPage(page) }
                    currentPage={this.state.response.currentPage}
                    totalPages={this.state.response.totalPages}/>
                <Footer/>
            </React.Fragment>}
        </UI.Page>;
    }

    private exportToCSVClicked(onlyIds?: boolean): Promise<void> {
        return exportToCSV(this.filtersData, onlyIds);
    }

    private switchPage(pageNumber: number) {
        this.filtersData.page = `${pageNumber}`;
        this.search( this.filtersData );
    }

    private itemDetails(itemDetails: ArchivedItemShortData) {
        this.setState({itemDetails});
    }

    private createItemData(item: ArchivedItemShortData, headers: ArchivedItemHeader[]): any[] {
        const data: any[] = [
            <UI.Button appearance="link-inline" onClick={ () => this.itemDetails(item)} text={ item.itemId } />,
            item.itemType,
            item.app,
            item.belongsTo
        ];
        const maxLength = 50;
        for (const header of headers) {
            let value;
            for (const field of item.fields) {
                if (field.propertyId === header.fieldName) {
                    value = field.value;
                }
            }
            if (!value) {
                value = '';
            }
            if (value.length > maxLength) {
                data.push(value.substring(0, maxLength) + '...');
            } else {
                data.push(value);
            }
        }
        data.push(<UI.Button appearance={`secondary`} onClick={() => this.itemDetails(item)} text={`More`}/>);
        return data;
    }
}

export function ArchivedItemsPage() {
    const [menu, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Archiver);
        setMenu({
            header: '',
            items: []
        })
    }, []);
    return <ArchivedItemsPage2 />
}