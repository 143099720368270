import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {TreloSmartIntsTriggerConfiguration} from './trello-smart-ints-trigger-configuration';

type Props = {
    connectionId: number;
    trigger: SmartIntTrigger;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class TreloSmartIntsEditTrigger extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const configuration = (this.props.trigger.configuration as TreloSmartIntsTriggerConfiguration);
        return <React.Fragment>
            <UI.LabelValue label={`Board`} value={ configuration.board.name } />
            {/*<UI.FormGroup*/}
            {/*    label={`Additional query`}*/}
            {/*    description={``}*/}
            {/*>*/}
            {/*    <UI.Input defaultValue={configuration.query} label={``} onChange={this.onJqlInputChanged.bind(this)}/>*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>;
    }

    private onJqlInputChanged(newValue: string) {
        this.props.trigger.configuration.query = newValue;
    }
}
