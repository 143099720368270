import React, {Component, useEffect} from 'react';
import { SmartIntIntegration } from '../../../../../generic';
import { createDefaultDefinition } from '../../definition';
import SmartIntsBuilder from '../smart-ints-builder';
import { registerUserActivityAction, UserActivityAction } from '../../../../system/user-activity';
import {IntegrationMode} from './integration-mode';
import {useGlobalState} from '../../../../global-store';
import {MainMenuItem, VerticalMenu} from '../../../../global-store/create-global-state';
import { IntegrationAccessLevel } from 'src/app/integration/integration-suites/details/integration-details-model';
import {IntegrationProvider} from '../integration-context';

type Props = {};
type State = {}

class SmartIntsCreatePageCmp extends Component<Props, State> {
    integration: SmartIntIntegration;

    constructor(props: Props) {
        super(props);
        this.integration = new SmartIntIntegration(0, '', createDefaultDefinition(), undefined, undefined, IntegrationAccessLevel.Write);
    }

    componentDidMount(): void {
        registerUserActivityAction(UserActivityAction.WasOnCreateIntegrationView);
    }

    render() {
        return (<React.Fragment>
            <IntegrationProvider
                integration={ this.integration }
            >
                <IntegrationMode
                    onMigrationSelected={ () => this.integration.settings.status = 'Disabled' }
                />
                <SmartIntsBuilder integration={this.integration}/>
            </IntegrationProvider>
        </React.Fragment>
        )
    }
}

export function SmartIntsCreatePage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    const [, setBreadCrumbs] = useGlobalState('breadcrumbs');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Workflows);
        const menu: VerticalMenu = {
            header: '',
            items: []
        };
        setMenu(menu);
        setBreadCrumbs([{
            name: 'Workflows',
            path: '/'
        }, {
            name: 'Create integration',
            path: '/'
        }]);
    }, []);
    return <SmartIntsCreatePageCmp />
}