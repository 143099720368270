import React from 'react'
import { UI } from 'src/engrator-core';
import {AdvancedCustomProperties} from '../advanced-configuration';
import {SoftwareName} from '../../../../../../software';

type Props = {
    properties: AdvancedCustomProperties;
    leftApp?: string;
    rightApp?: string;
};
type State = {
};

export class CustomProperties extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return <UI.Form>
            { (this.props.leftApp == SoftwareName.ServiceNow || this.props.rightApp == SoftwareName.ServiceNow) && <UI.Input
                label={`ServiceNow inline images base url`}
                placeholder={`Provide url without / at the end`}
                defaultValue={ this.props.properties.serviceNowImageBaseUrl }
                onChange={ (value) => this.props.properties.serviceNowImageBaseUrl = value }
            /> }
        </UI.Form>;
    }
}
