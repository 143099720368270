import React from 'react';

import {EditPipelineStepComponentProps} from 'src/generic';
import {ArtifactDirection} from 'src/generic/artifacts';
import {ServiceNowCreateItemConfiguration} from './servicenow-create-item-configuration';
import {ServicenowStepContextDisplay} from '../common/servicenow-step-context-display';

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class ServiceNowCreateItemEditStep extends React.Component<Props, State> {
    private configuration: ServiceNowCreateItemConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as ServiceNowCreateItemConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
        this.props.showArtifactBuilder(ArtifactDirection.In);
    }

    render() {
        return <React.Fragment>
            <ServicenowStepContextDisplay configuration={ this.configuration.context } />
        </React.Fragment>;
    }
}