import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBox } from '@fortawesome/free-solid-svg-icons'

type ArtifactProps = {
    name: string
};

export class Artifact extends React.Component<ArtifactProps> {
    state: {};

    constructor(props: ArtifactProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <div className="artifact">
            <span className="icon"><FontAwesomeIcon icon={ faBox } /></span>
            { this.props.name }
        </div>
    }
}