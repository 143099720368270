import { useState, useEffect } from 'react';
import {Icon20} from '../../engrator-core/ui';

export enum MainMenuItem {
    Home = 'Home',
    Workflows = 'Workflows',
    Archiver = 'Archiver',
    Reporting = 'Reporting',
    Settings = 'Settings',
    SystemSettings = 'SystemSettings',
    Users = 'Users',
    Workspaces = 'Workspaces',
    Licenses = 'Licenses',
    Account = 'Account'
}

type InitialState = {
    menu?: VerticalMenu;
    breadcrumbs?: BreadCrumbItem[],
    currentUrl: string;
    mainMenuItem: MainMenuItem;
};

export type BreadCrumbItem = {
    name: string
};

export type VerticalMenuItem = {
    name: string;
    path: string;
    icon?: Icon20;
};

export type VerticalMenu = {
    header: string;
    items: VerticalMenuItem[]
};

function createGlobalState(initialState: InitialState) {
    const state: any = initialState;
    const subscribers: any = {};

    // Subscribers per state prop
    for (const i in state) {
        subscribers[i] = [];
    }

    function useGlobalState(key: keyof InitialState) {
        // To prevent getting/setting keys that aren't initialized
        if (!state.hasOwnProperty(key)) {
            throw new Error('This key does not exist in the store');
        }

        // Global state as React local state
        const [stateChunk, setStateChunk] = useState(state[key]);

        useEffect(() => {
            subscribers[key].push(setStateChunk);
            // Cleanup: subscriber removal after effect execution
            return () => {
                const index = subscribers[key].findIndex((fn: any) => fn === setStateChunk);
                subscribers[key].splice(index, 1);
            };
        }, [key]);

        return [
            stateChunk,
            (value: any) => {
                setGlobalState(key, value);
            }
        ];
    }

    // Useful for setting state outside React components
    const setGlobalState = (key: any, value: any) => {
        // To prevent setting keys that aren't initialized
        if (!state.hasOwnProperty(key)) {
            throw new Error('This key does not exist in the store');
        }

        state[key] = value;

        subscribers[key].forEach((subscriber: any) => {
            subscriber(value);
        });
    };

    // Useful for getting state outside React components
    const getGlobalState = (key: any) => {
        // To prevent getting keys that aren't initialized
        if (!state.hasOwnProperty(key)) {
            throw new Error('This key does not exist in the store');
        }
        return state[key];
    };

    return {
        useGlobalState,
        setGlobalState,
        getGlobalState
    };
}

export default createGlobalState;
