import React, {Component} from 'react';
import {UI} from 'src/engrator-core';

type State = {
    isClosed: boolean;
};

export class ExpiredInfo extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            isClosed: false
        };
    }

    componentDidMount(): void {
    }

    render() {
        if (this.state.isClosed) {
            return <React.Fragment />
        }
        return <UI.Modal
            highestIndex={ true }
            isWide={ true }
            header={`Your instance expired`}
            isLeftAligned={ true }
        >
            <p>Your current integrations <strong><u>are stopped, and no data sync</u></strong> is being performed by getint.io</p>
            <p>You can still manage integrations and check past runs</p>
            <UI.Message appearance={'warning'}>
                Contact us at <a href={`mailto: sales@getint.io`}>sales@getint.io</a> to extend the license.
            </UI.Message>
            <UI.Button
                onClick={ () => this.setState({ isClosed: true })}
                text={`I understand`}
            />
        </UI.Modal>;
    }
}
