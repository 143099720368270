import React, {Component} from 'react';

import {UI} from 'src/engrator-core';
import getintNewLogo from 'src/getint_new_logo.svg';
import signInSmallLogo from './sign_in_small_logo.png';
import signInBgGraphic from './sign_in_bg.png';
import {Footer} from '../footer';
import {FeatureFlag, fetchSystemInfo} from '../top-navigation/rest-api';

type State = {
    customLogo?: string;
}

export class AuthMainFrame extends Component<{}, State> {
    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const info = await fetchSystemInfo();
        this.setState({ customLogo: info.featureFlags[FeatureFlag.FF_LOGO] });
        document.getElementsByTagName('html')[0].className = 'sign-in-module';
    }

    render() {
        return (
            <UI.Page className="sign-in-page auth-main-frame">
                <div className={`left-side`}>
                    <div className={`slogan`}>
                        <img src={signInSmallLogo}/>
                        <img className={`bg-graphic`} src={signInBgGraphic}/>
                        <h1>Redefining Integrations</h1>
                        <h2>Integrate on a One-to-One or Many-to-One Basis.</h2>
                        <a className={`read-more`} target={'_blank'} href={`https://getint.io`}>Read More</a>
                    </div>
                </div>
                <div className={`right-side`}>
                    <div className={`form`}>
                        <div className={`logotype`}>
                            <img src={getintNewLogo} height={30}/>
                            {/*{ this.state.customLogo && <img src={ this.state.customLogo} width={ 340 }/> }*/}
                        </div>
                        { this.props.children }
                        <Footer />
                    </div>
                </div>
            </UI.Page>
        )
    }
}
