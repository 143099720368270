import React from 'react';

import { UI } from 'src/engrator-core';
import {AnalysisNavBar} from '../analysis-nav-bar';
import {LogsFilter} from './logs-filter';

type State = {

};

type Props = {};

export class LogsPage extends React.Component<Props, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            threadsStates: []
        };
    }

    render() {
        return (
            <UI.Page>
                <AnalysisNavBar selectedItem={'Logs'}/>
                <LogsFilter />
            </UI.Page>
        )
    }
}