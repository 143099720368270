import React from 'react';
import { UI} from 'src/engrator-core';
import {GitLabStepContextData} from './gitlab-step-context';

type Props = {
    configuration: GitLabStepContextData;
}

type State = {
}

export class GitLabStepContextDisplay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <UI.LabelValue label={`Project`} value={ this.props.configuration.project!.name} />
        </React.Fragment>;
    }
}