import { createEmptyAdvancedConfiguration } from '../../app/integration/smartints/configuration/advanced/advanced-configuration';
import { GenericIntegration } from './generic-integration';
import { SuiteType } from './suite-type';
import { SmartIntDefinition } from '../../app/integration/smartints/definition';
import { PipelineSettings } from '../../app/integration/integration-suites/details/pipeline-settings-type';
import { MigrationConfiguration } from '../../app/integration/smartints/migration/migration-configuration.type';
import {IntegrationAccessLevel} from '../../app/integration/integration-suites/details/integration-details-model';
import {ShareAccessOption} from '../../software/generic/connections-rest-api';

export class SmartIntIntegration extends GenericIntegration {
    private readonly _id: number;
    private _definition: SmartIntDefinition;
    public migrationConfiguration: MigrationConfiguration;
    private readonly _owner?: any;
    private _ownerId?: number;
    private readonly _createdAt?: string;
    private _shareAccess: ShareAccessOption | undefined;

    constructor(id: number, name: string, definition: SmartIntDefinition, settings?: PipelineSettings, migrationConfiguration?: MigrationConfiguration, accessLevel?: IntegrationAccessLevel,
        ownerId?: number, owner?: any, createdAt?: string, shareAccess?: ShareAccessOption) {
        super(name, accessLevel);
        this._id = id;
        this._owner = owner;
        this._ownerId = ownerId;
        this._createdAt = createdAt;
        this._shareAccess = shareAccess;
        this.migrationConfiguration = (migrationConfiguration) ? migrationConfiguration : {
            enabled: false
        };
        this._definition = definition;
        this._definition.advancedConfiguration = (definition.advancedConfiguration) ? definition.advancedConfiguration : createEmptyAdvancedConfiguration();
        if (settings) {
            this.settings = settings;
        }
    }

    get definition(): SmartIntDefinition {
        return this._definition;
    }

    get id(): number {
        return this._id;
    }


    get owner(): any  | undefined {
        return this._owner;
    }

    get ownerId(): number  | undefined {
        return this._ownerId;
    }


    set ownerId(value: number | undefined) {
        this._ownerId = value;
    }

    get createdAt(): string | undefined {
        return this._createdAt;
    }

    get suiteType() {
        return this._definition.suiteType;
    }

    set suiteType(value: SuiteType) {
        this.definition.suiteType = value;
    }

    get shareAccess(): ShareAccessOption | undefined {
        return this._shareAccess;
    }


    set shareAccess(value: ShareAccessOption | undefined) {
        this._shareAccess = value;
    }

    updateScript(script: string) {
        const definition = JSON.parse(script);
        this._definition = definition;
        this._definition.advancedConfiguration = (definition.advancedConfiguration) ? definition.advancedConfiguration : createEmptyAdvancedConfiguration();
    }
}
