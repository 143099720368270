import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';
import {GitHubStepKey} from '../github-step-key.type';
import {GitHubCreateCcommentsNewStep} from './github-create-comments-new-step';
import {GitHubCreateCommentsEditStep} from './github-create-comments-edit-step';
import {GitHubCreateCommentsConfiguration} from './github-create-comments-configuration';


export const GitHubCreateCommentsPipelineStep: PipelineStep<GitHubCreateCommentsConfiguration> = {
    addStepComponentClass: GitHubCreateCcommentsNewStep,
    editStepComponentClass: GitHubCreateCommentsEditStep,
    specification: {
        action: 'Create issue comment(s)',
        description: 'Create comment(s) for selected issue',
        key: GitHubStepKey.GitHubCreateComments,
        software: SoftwareName.GitHub,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `GitHubCard`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitHubCreateCommentsConfiguration;
        return `Create comment(s) for issue '${stepConfiguration.targetArtifact}'`;
    }
};