import React from 'react';
import { FieldError } from '../field-error';

type TableGridProps = {
    headers: Array<string>,
    data: Array<Array<any>>,
    className?: string;
    noDataText?: string;
};

type TableGridState = {
    error?: FieldError
}

export type TableGridData = {
    headers: any,
    data: any,
};

export class TableGrid extends React.Component<TableGridProps, TableGridState> {
    constructor(props: TableGridProps) {
        super(props);
    }

    render() {
        return <div>
            { this.props.headers && <React.Fragment>
                <table className={`engrator-table engrator-table-grid ${this.props.className}`} cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            {this.props.headers && this.props.headers.map((header: any, index: number) => {
                                if (typeof header == 'string') {
                                    return <th key={index} className={`th-${header.toLowerCase()}`}>
                                        {header}
                                    </th>;
                                } else if (Array.isArray(header)) {
                                    return header[0];
                                } else {
                                    return header;
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data && this.props.data.map((row: Array<any>, index: number) => <tr key={index}>
                            {row.map((cell: any, cellIndex: number) => <td
                                className={typeof this.props.headers[cellIndex] == 'string' ? `td-${getClassName(this.props.headers[cellIndex])}` : Array.isArray(this.props.headers[cellIndex]) ? `td-${getClassName(this.props.headers[cellIndex][1])}` :`td-${cellIndex}`} key={cellIndex}>
                                {cell}
                            </td>)}
                        </tr>)}
                    </tbody>
                </table>
                {this.props.data.length === 0 && <p>{ this.props.noDataText || <span>No data found.</span> }</p>}
            </React.Fragment>
            }
        </div>;
    }
}

function getClassName(headerName: string): string {
    return headerName?.replace(' ', '')
        .replace('.', '')
        .replace('#', '')
        .toLowerCase();
}
