import React from 'react';
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom';

import {AuthToken, CLUSTER_ADMIN_WORKSPACE, isInIframeApp, isJiraServerNativeApp, UI} from 'src/engrator-core';
import getintIoIcon from './getintio-icon.png';
import {HelpDropdown} from './help-dropdown';
import {FeatureFlag, fetchSystemInfo} from './rest-api';
import {ThreadsSummary} from './threads-summary.type';
import {Icon20} from '../../engrator-core/ui';
import {useGlobalState} from '../global-store';
import {MainMenuItem} from '../global-store/create-global-state';
import {AccountDropdown} from './account-dropdown';

type NavigationProps = {
    mainMenuItem: MainMenuItem;
} & RouteComponentProps;
type State = {
    threadsSummary?: ThreadsSummary,
    isHelpMenuVisible: boolean;
    isAccountMenuVisible: boolean;
    showSystem: boolean;
    showArchiver: boolean;
    sytemInfoFetched: boolean;
    customLogo?: string;
    isSaas: boolean;
    mainMenuItem: MainMenuItem;
};

class TopNavigationComponent extends React.Component<NavigationProps, State> {
    constructor(props: Readonly<NavigationProps>) {
        super(props);
        this.state = {
            sytemInfoFetched: true,
            showSystem: false,
            showArchiver: false,
            isAccountMenuVisible: false,
            isHelpMenuVisible: false,
            isSaas: false,
            mainMenuItem: this.props.mainMenuItem,
            threadsSummary: { anyWorking: false }
        };
    }

    onLogOutClick() {
        const origin = document.body.getAttribute('origin');
        if (origin && origin === 'JiraServer') {
            window.top.postMessage('signout', '*');
            return;
        }
        AuthToken.remove();
        window.location.href = '/';
    }

    componentDidMount(): void {
        // this.fetchingIntegrationThreadsSummaryFromApi();
        if (AuthToken.getWorkspace()?.workspaceId === CLUSTER_ADMIN_WORKSPACE.workspaceId) {
            this.setState({ sytemInfoFetched: true });
            return;
        }
        fetchSystemInfo().then(info => {
            try {
                const isDisabled = localStorage?.getItem('DISABLE_CHAT');
                if (info.showChat && (window as any).showChatWindow && !isDisabled) {
                    (window as any).showChatWindow();
                }
            } catch (e) {
                console.error(e);
            }
            this.setState({
                sytemInfoFetched: true,
                customLogo: info.featureFlags[FeatureFlag.FF_LOGO],
                showSystem: info.showSystem,
                showArchiver: info.archiverEnabled,
                isSaas: info.saas
            })
        });
    }

    componentDidUpdate(prevProps: Readonly<NavigationProps>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.mainMenuItem !== prevProps.mainMenuItem) {
            this.setState({ mainMenuItem: this.props.mainMenuItem });
        }
    }

    getClassName(expectedPathPrefix: string): string {
        if (expectedPathPrefix === '/' && this.props.location.pathname === '/') {
            return 'selected';
        } else if (expectedPathPrefix !== '/' && this.props.location.pathname.indexOf(expectedPathPrefix) === 0) {
            return 'selected';
        }
        if (this.state.mainMenuItem === expectedPathPrefix) {
            return 'selected';
        }
        return '';
    }


    render() {
        return <nav>
            { this.state.sytemInfoFetched && <React.Fragment>
                <ul className="nav-menu left">
                    <li className={`logo`}>
                        <div className={`logotype`}>
                            <img src={getintIoIcon} height={28}/>
                        </div>
                    </li>
                    { AuthToken.getWorkspace()?.workspaceId === CLUSTER_ADMIN_WORKSPACE.workspaceId && <React.Fragment>
                        <li data-sel="workspaces" className={this.getClassName(MainMenuItem.Workspaces)}>
                            <NavLink to="/app/cluster/tenants/list">
                                <UI.Icon icon20={ Icon20.Relationship } />
                            </NavLink>
                            <div className={`tooltip`}>Workspaces</div>
                        </li>
                        <li data-sel="users" className={this.getClassName(MainMenuItem.Users)}>
                            <NavLink to="/app/cluster/users/list">
                                <UI.Icon icon20={ Icon20.UserGroup } />
                            </NavLink>
                            <div className={`tooltip`}>Users</div>
                        </li>
                        {/*<li className={this.getClassName('/app/cluster/tenants/licenses')}><NavLink*/}
                        {/*	to="/app/cluster/tenants/licenses">Licenses</NavLink></li>*/}
                    </React.Fragment> }

                    { !(AuthToken.getWorkspace()?.workspaceId === CLUSTER_ADMIN_WORKSPACE.workspaceId) && <React.Fragment>
                        <li data-sel="dashboard" className={ this.getClassName(MainMenuItem.Home)}>
                            <NavLink to="/app/dashboard"><UI.Icon icon20={ Icon20.Home } /> </NavLink>
                            <div className={`tooltip`}>Home</div>
                        </li>
                        <li data-sel="integrations" className={ this.getClassName(MainMenuItem.Workflows)}>
                            <NavLink to="/app/integration/smart-ints">
                                <UI.Icon icon20={ Icon20.Workflows } />
                                <div className={`tooltip`}>Workflows</div>
                            </NavLink>
                        </li>
                        { this.state.showArchiver && <li data-sel="archiver" className={ this.getClassName(MainMenuItem.Archiver) }>
                            <NavLink to="/app/archiver"><UI.Icon icon20={ Icon20.Archiver } /></NavLink>
                            <div className={`tooltip`}>Archiver</div>
                        </li> }
                        <li data-sel="reporting" className={this.getClassName(MainMenuItem.Reporting)}>
                            <NavLink to="/app/reporting?report=PerformedFlows">
                                <UI.Icon icon20={ Icon20.Reporting } />
                            </NavLink>
                            <div className={`tooltip`}>Reporting</div>
                        </li>
                        { isJiraServerNativeApp && <li data-sel="settings" className={this.getClassName(MainMenuItem.Settings)}>
                            <NavLink to="/app/jira-server/settings/migration-license">
                                <UI.Icon icon20={ Icon20.Settings } />
                            </NavLink>
                            <div className={`tooltip`}>Settings</div>
                        </li> }
                        { (this.state.isSaas || !isJiraServerNativeApp) && <li data-sel="settings" className={this.getClassName(MainMenuItem.Settings)}>
                            <NavLink to="/app/settings/cloud/license">
                                <UI.Icon icon20={ Icon20.Settings } />
                            </NavLink>
                            <div className={`tooltip`}>Settings</div>
                        </li> }
                    </React.Fragment> }
                </ul>
                <ul className="nav-menu right">
                    {/*<li><TrialWarningBar/></li>*/}
                    {/*<li className={`lastly-synced-items-box`}><TopNavigationSyncedItems /></li>*/}
                    {/*{ !isJiraServerNativeApp && !AuthToken.isClusterAdmin() && <li className={ `cogs ${threadsCogsClassName}` }>*/}
                    {/*    <NavLink to="/app/analysis/threads/"><UI.Icon icon={`cogs`} /></NavLink>*/}
                    {/*</li> }*/}
                    {/*<li className={ this.getClassName('/xa') }>*/}
                    {/*    <NavLink to="/"><UI.Icon icon={"user-circle"} /></NavLink>*/}
                    {/*</li>*/}
                    { this.state.showSystem && <li data-sel="system" className={this.getClassName(MainMenuItem.SystemSettings)}>
                        <NavLink to="/app/system/general">
                            <UI.Icon icon20={ Icon20.Settings } />
                        </NavLink>
                        <div className={`tooltip`}>System Settings</div>
                    </li> }
                    { this.showAccountMenu() && <li data-sel="account" className={ this.getClassName(MainMenuItem.Account) }>
                        <a onClick={() => this.switchAccountMenuVisibility(true)}><UI.Icon icon20={ Icon20.User}/></a>
                        <div className={`tooltip`}>Account</div>
                        { this.state.isAccountMenuVisible && <AccountDropdown onCloseHandler={ () => this.switchAccountMenuVisibility(false) }/> }
                    </li> }
                    <li className={ this.getClassName('/help-component') }>
                        <a onClick={() => this.switchHelpMenuVisibility(true)}><UI.Icon icon20={ Icon20.Help}/></a>
                        <div className={`tooltip`}>Help</div>
                        { this.state.isHelpMenuVisible && <HelpDropdown onCloseHandler={ () => this.switchHelpMenuVisibility(false) }/> }
                    </li>
                    { !isInIframeApp() && !this.isGetintCloud() && !this.isJiraServerInIframe() && !isJiraServerNativeApp && <li className={this.getClassName('/reporting')}>
                        <a onClick={this.onLogOutClick.bind(this)}><UI.Icon icon20={ Icon20.SignOut }/></a>
                    </li>}
                    { this.isGetintCloud() && <li className={``}>
                        <a onClick={ () => this.exitFromGetintCloud() }>Exit</a>
                    </li>}
                </ul>
            </React.Fragment> }
        </nav>
    }

    private showAccountMenu(): boolean {
        if (AuthToken.isClusterAdmin() || AuthToken.getWorkspaces()?.length > 1) {
            return true;
        }
        if (!isInIframeApp() && !this.isGetintCloud() && !this.isJiraServerInIframe() && !isJiraServerNativeApp) {
            return true;
        }
        return false;
    }

    private exitFromGetintCloud() {
        document.body.parentNode?.parentNode?.querySelector('iframe')?.remove();
    }

    private switchHelpMenuVisibility(isVisible: boolean): void {
        this.setState({ isHelpMenuVisible: isVisible });
    }

    private switchAccountMenuVisibility(isVisible: boolean): void {
        this.setState({ isAccountMenuVisible: isVisible });
    }

    private isJiraServerInIframe(): boolean {
        const origin = document.body.getAttribute('origin');
        if (origin && origin === 'JiraServer') {
            return true;
        }
        return false;
    }

    private isGetintCloud(): boolean {
        const origin = document.body.getAttribute('origin');
        return !!(origin && origin === 'GetintCloud');

    }

    private fetchingIntegrationThreadsSummaryFromApi() {
        // fetchIntegrationThreadsSumary()
        //     .then(threadsSummary => {
        //         if (threadsSummary != this.state.threadsSummary) {
        //             this.setState({ threadsSummary }, () => {
        //                 setTimeout(() => this.fetchingIntegrationThreadsSummaryFromApi(), 700);
        //             });
        //         } else {
        //             setTimeout(() => this.fetchingIntegrationThreadsSummaryFromApi(), 700);
        //         }
        //     })
    }
}

const TopNavigationCmp = withRouter(TopNavigationComponent);

export function TopNavigation() {
    const [mainMenuItem] = useGlobalState('mainMenuItem');
    return <TopNavigationCmp
        mainMenuItem={ mainMenuItem }
    />
}