import Artifact from './artifact';
import { PropertyDefinition } from './property-definition';
import { ArtifactDirection } from './artifact-direction';
import { PropertySource} from './property-source';

export type ConfiguredProperty = {
    source: PropertySource,
    property: PropertyDefinition
};

abstract class DataArtifact extends Artifact {
    name: string;
    configureProperties: Array<ConfiguredProperty> = [];

    constructor(direction: ArtifactDirection, name: string) {
        super(direction);
        this.name = name;
    }

    abstract getPropertiesDefinitions(): Promise<Array<PropertyDefinition>>;

    getConfiguredProperties(): Array<ConfiguredProperty> {
        return this.configureProperties;
    }

    configureProperty(property: PropertyDefinition, source: any) {
        if (!property) {
            throw `'property' argument should not be defined`;
        }
        if (!source) {
            throw `'source' argument should not be defined`;
        }
        this.configureProperties.push({
            source,
            property
        });
    }

    setName(newPrefferedName: string) {
        this.name = newPrefferedName;
    }

    getName(): string {
        return this.name;
    }

    abstract getPreferredName(): string;
}

export default DataArtifact;