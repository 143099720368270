import { axiosWrapper } from 'src/engrator-core';
import {Connection, NewConnectionData} from 'src//connections/connection';
import {DropdownOption} from '../../engrator-core/ui';
import {AuthMethod} from '../../generic/connections/auth-method.type';

const ENDPOINT_URL = '/integration/connections';

export enum ShareAccessOption {
    ANYONE_IN_WORKSPACE_CAN_VIEW = 'AnyoneInWorkspaceCanView',
    ANYONE_IN_WORKSPACE_CAN_USE = 'AnyoneInWorkspaceCanUse',
    ANYONE_IN_WORKSPACE_CAN_EDIT = 'AnyoneInWorkspaceCanEdit',
}

export function getAccessOptionTagName(option: ShareAccessOption): string {
    switch (option) {
    case ShareAccessOption.ANYONE_IN_WORKSPACE_CAN_VIEW:
        return 'View';
    case ShareAccessOption.ANYONE_IN_WORKSPACE_CAN_USE:
        return 'Use';
    case ShareAccessOption.ANYONE_IN_WORKSPACE_CAN_EDIT:
        return 'Edit';
    }
}
export function getShareAccessOptionsForConnection(): DropdownOption[] {
    return [
        {label: 'Anyone in Workspace can view', value: ShareAccessOption.ANYONE_IN_WORKSPACE_CAN_VIEW},
        {label: 'Anyone in Workspace can use', value: ShareAccessOption.ANYONE_IN_WORKSPACE_CAN_USE},
        {label: 'Anyone in Workspace can edit', value: ShareAccessOption.ANYONE_IN_WORKSPACE_CAN_EDIT}
    ]
}

export function getShareAccessOptionsForIntegration(): DropdownOption[] {
    return [
        {label: 'Anyone in Workspace can view', value: ShareAccessOption.ANYONE_IN_WORKSPACE_CAN_VIEW},
        {label: 'Anyone in Workspace can edit', value: ShareAccessOption.ANYONE_IN_WORKSPACE_CAN_EDIT}
    ]
}

function createConnectionFormApiData(apiData: any): Connection {
    return {
        id: apiData.id,
        url: apiData.url,
        name: apiData.name,
        available: apiData.available,
        login: apiData.login,
        softwareName: apiData.softwareName
    };
}

export const fetchConnectionsForSoftware = (softwareName: string, authMethod?: AuthMethod): Promise<Array<Connection>> => {
    return new Promise((resolve, reject) => {
        const query = (authMethod) ? '?preferredAuthMethod=' + authMethod : '';
        const url = ENDPOINT_URL + '/' + softwareName + query;
        try {
            axiosWrapper.get(url)
                .then((data: any) => {
                    resolve(data.map(createConnectionFormApiData));
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const createConnection = (conncetion: NewConnectionData): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL, conncetion)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function editConnection(id: number, connection: NewConnectionData): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.put(ENDPOINT_URL + '/' + id, connection)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}