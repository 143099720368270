import React, {Component, useEffect} from 'react';

import {copyObject, FieldError, UI} from 'src/engrator-core';

import { IntegrationModuleTabBar } from './../integration-module-tab-bar';
import {AlertStatus, fetchNotifications, handleAlertStatus} from './rest-api';
import {ContextMenuItem, Icon20, TableGridData} from '../../../engrator-core/ui';
import {NotificationAlert} from './notification-alert.type';
import {NotificationsCreatePage} from './create/notifications-create.page';
import {DELETE_ITEM, DISABLE_ITEM, EDIT_ITEM, ENABLE_ITEM, NotificationsListItemContextMenu} from './notifications-list-item-context-menu';
import {useGlobalState} from '../../global-store';
import {MainMenuItem} from '../../global-store/create-global-state';
import {getAlertChannelName} from './create/new-alert.type';
import { NotificationsEditPage } from './edit/notifications-edit.page';
import { DeleteNotificationModal } from './edit/delete-notification-modal';
import {getWorkflowsVerticalMenu} from '../vertical-menu';

type Props = {};
type State = {
    error?: FieldError,
    isLoading: boolean,
    showAlertModal: boolean;
    allAlerts: NotificationAlert[];
    alertToEdit?: NotificationAlert;
    shouldReloadTable: boolean;
    alertToDelete?: NotificationAlert;
};

export class NotificationsListPageCmp extends Component<Props, State> {
    private tableGridData?: TableGridData = undefined;
    constructor(props: {}) {
        super(props);
        this.state = {
            showAlertModal: false,
            isLoading: false,
            allAlerts: [],
            shouldReloadTable: false
        };
    }

    componentDidMount(): void {
        this.loadData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevState.shouldReloadTable !== this.state.shouldReloadTable) {
            this.loadData();
        }
    }

    private loadData(): void {
        this.setState({ isLoading: true}, () => {
            fetchNotifications()
                .then(alerts => {
                    this.tableGridData = this.createData(alerts);
                    this.setState({ isLoading: false, allAlerts: alerts });
                })
                .catch(error => this.setState({ isLoading: false, error}));
        });
    }

    private refreshList() {
        this.setState({allAlerts: [], shouldReloadTable: !this.state.shouldReloadTable});
    }

    render() {
        return (
            <UI.Page className={`notifications-list-page`}>
                { this.state.alertToDelete && <DeleteNotificationModal
                    notification={ this.state.alertToDelete }
                    closeHandler={ () => this.setState({ alertToDelete: undefined }) }
                    onDeleteHandler={ () => this.refreshList() }
                /> }
                { !this.state.error && <IntegrationModuleTabBar selectedIndex={ 3 }>
                    <UI.Button
                        icon={ <UI.Icon icon20={ Icon20.CirclePlusWhite } /> }
                        className={`selenium-create-notification`}
                        onClick={() => this.showAlertModal() }
                        text={`Create Alert`}
                    />
                </IntegrationModuleTabBar>}
                { this.state.error && <UI.Message appearance={'error'} message={ this.state.error?.message} /> }
                { this.state.isLoading && <UI.Loader visible={ true } appearance={'darkgray'}/> }
                { this.state.showAlertModal && <NotificationsCreatePage
                    closeHandler={ (shouldRefresh) => this.closeAlertModal(shouldRefresh) }
                /> }
                { this.state.alertToEdit && <NotificationsEditPage
                    closeHandler={ (shouldRefresh) => this.closeAlertModal(shouldRefresh) }
                    alertToEdit={this.state.alertToEdit}
                /> }
                { !this.state.isLoading && this.tableGridData && <React.Fragment>
                    <UI.TableGrid
                        data={ this.tableGridData!.data }
                        headers={ this.tableGridData!.headers }
                    />
                </React.Fragment> }
            </UI.Page>
        )
    }

    private contextMenuItemSelected(item: ContextMenuItem, alert: NotificationAlert) {
        if (item.id === DELETE_ITEM.id) {
            this.setState({ alertToDelete: alert })
        } else if (item.id === EDIT_ITEM.id) {
            this.handleEdit(alert);
        } else if (item.id === DISABLE_ITEM.id) {
            handleAlertStatus(alert, AlertStatus.DISABLE).then(() => {
                this.refreshList();
            })
        } else if (item.id === ENABLE_ITEM.id) {
            handleAlertStatus(alert, AlertStatus.ENABLE).then(() => {
                this.refreshList();
            })
        }
    }

    private handleEdit(alert: NotificationAlert) {
        this.setState({ alertToEdit: copyObject(alert) });
    }

    private closeAlertModal(shouldRefresh: boolean) {
        this.setState({
            showAlertModal: false,
            alertToEdit: undefined,
            shouldReloadTable: shouldRefresh ? !this.state.shouldReloadTable : this.state.shouldReloadTable
        });
    }

    private showAlertModal() {
        this.setState({ showAlertModal: true });
    }

    private createData(notifications: NotificationAlert[]): TableGridData {
        return {
            data: notifications.map(alert => [
                // <UI.NavLink path={`/app/integration/notifications/${alert.id}`}>#{alert.id}</UI.NavLink>,
                <UI.Button appearance="link-inline" text={alert.name} onClick={ () => this.handleEdit(alert) }/>,
                alert.forFailedRuns + '',
                alert.forFailedSyncs + '',
                getAlertChannelName(alert.channel),
                <UI.EntityStatus status={alert.status}/>,
                <NotificationsListItemContextMenu
                    onSelected={(item) => this.contextMenuItemSelected(item, alert)}
                    listItem={ alert }
                />
            ]),
            headers: ['Name', 'For Runs', 'For Syncs', 'Channel', 'Status', 'Actions'],
        };
    }
}

export function NotificationsListPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        setMainMenuItem(MainMenuItem.Workflows);
        getWorkflowsVerticalMenu()
            .then(workflowsVerticalMenu => {
                setMenu(workflowsVerticalMenu);
                setMainMenuItem(MainMenuItem.Workflows);
            });
    }, []);
    return <NotificationsListPageCmp />
}