import {PipelineStep} from 'src/generic';
import {
    WrikeCreateCommentsPipelineStep,
    WrikeCreateItemPipelineStep,
    WrikeSearchItemsPipelineStep,
    WrikeUpdateItemPipelineStep,
    WrikeFindItemPipelineStep,
    WrikeStatusTransitionPipelineStep
} from './steps';

export const WrikePipelineSteps: PipelineStep<any>[] = [
    WrikeSearchItemsPipelineStep,
    WrikeCreateItemPipelineStep,
    WrikeCreateCommentsPipelineStep,
    WrikeUpdateItemPipelineStep,
    WrikeFindItemPipelineStep,
    WrikeStatusTransitionPipelineStep
];