import React from 'react';
// import clickUpLogo from 'src/software/clickup/clickup-logo.png';

import {getSoftwareName, SoftwareName} from '../../software';
import {getAppIcon} from '../../software/apps-icons';

type Props = {
    softwareName: string;
    showName?: boolean;
};

const HEIGHT = 50;
export class SoftwareLogotype extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <div className={`app-logotype`}>
            <img src={ getAppIcon(this.props.softwareName as SoftwareName) } height={ HEIGHT } />
            {(this.props.showName === undefined || this.props.showName === true) && <span>{ getSoftwareName(this.props.softwareName)}</span>}
        </div>
    }
}
