import { axiosWrapper } from 'src/engrator-core';
import { DropdownOption, EntityStatusValue } from 'src/engrator-core/ui';
import { SoftwareName } from 'src/software';
import { ValueMappingsGroup } from '../smartints/visual/components/value-mappings/value-mappings-group.type';
import { getDefaults } from '../smartints/visual/components/value-mappings/default-mappping.type';
import { PropertyMapping, PropertyMappingOptions } from '../smartints/visual/property-mapping.type';
import { PropertyDefinition } from 'src/generic/artifacts';
import { SmartIntTrigger } from '../smartints/definition/smart-int-trigger.type';
import { SmartIntDefinitionTypeMapping } from '../smartints/definition/smart-int-definition--type-mapping.type';
import { AppsSupport } from '../smartints/visual/visual-integration-designer';
import { SmartIntDirection } from '../smartints/definition/smart-int-direction.type';
import softwareFactory from 'src/software/software-factory';
import { SmartIntType } from '../smartints/definition/smart-int-type.type';
import {Side} from '../../../generic';
import {PropertyDataType} from '../../../generic/artifacts';

const ENDPOINT_URL = '/integration/shared-mappings';

export enum SharedMappingStatus {
    ENABLE = 'Enabled',
    DISABLE = 'Disabled'
}

export type SharedMappingListItem = {
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
    typeMapping: SmartIntDefinitionTypeMapping;
    mapping: PropertyMapping;
    appsSupport: AppsSupport;
} & SharedMapping;

export type Integration = {
    id: number;
    name: string;
};

export type SharedMapping = {
    id: number;
    name: string;
    connectionIds: {
        left: number;
        right: number;
    };
    apps: {
        left: SoftwareName;
        right: SoftwareName;
    };
    status: EntityStatusValue;
    groups: ValueMappingsGroup[];
    createdAt: string;
    owner: {
        id: number;
        name: string;
        identifier: string;
    };
    ownerId: number;
    propertiesData: {
        left: PropertyDefinition;
        right: PropertyDefinition;
    };
    integrations: Integration[];
    configuration: {
        groups: ValueMappingsGroup[];
        autoCreationResolvers: {
            left?: OptionsAutoCreationResolverConfig;
            right?: OptionsAutoCreationResolverConfig;
        };
        leftTriggerConfiguration: any;
        rightTriggerConfiguration: any;
        typeMappingLeft: SmartIntType;
        typeMappingRight: SmartIntType;
        mappingOptions: {
            left: PropertyMappingOptions,
            right: PropertyMappingOptions,
        };
        mappingDirection: SmartIntDirection;
        mappingIsNewlyCreated: boolean;
        azureEmailIsJiraUsername: boolean;
    };
};

export type CreateSharedMapping = {
    name: string;
    connectionsIds: {
        left: number;
        right: number;
    };
    properties: {
        left: PropertyDefinition;
        right: PropertyDefinition;
    };
    configuration: {
        groups: ValueMappingsGroup[];
        leftTriggerConfiguration: any;
        rightTriggerConfiguration: any;
        typeMappingLeft: SmartIntType;
        typeMappingRight: SmartIntType;
        mappingOptions: {
            left: PropertyMappingOptions,
            right: PropertyMappingOptions,
        };
        mappingDirection: SmartIntDirection;
        mappingIsNewlyCreated: boolean;
        azureEmailIsJiraUsername: boolean;
    };
};

export type UpdateSharedMapping = {
    name: string;
    configuration: {
        groups: ValueMappingsGroup[];
        leftTriggerConfiguration: any;
        rightTriggerConfiguration: any;
        typeMappingLeft: SmartIntType;
        typeMappingRight: SmartIntType;
        mappingOptions: {
            left: PropertyMappingOptions,
            right: PropertyMappingOptions,
        };
        mappingDirection: SmartIntDirection;
        mappingIsNewlyCreated: boolean;
        azureEmailIsJiraUsername: boolean;
        autoCreationResolvers: {
            left?: OptionsAutoCreationResolverConfig;
            right?: OptionsAutoCreationResolverConfig;
        };
    };
}

export type PropertyOption = {
    id: string;
    name: string;
    additionalData: object;
};

export type SharedMappingOptions = {
    left: PropertyOption & DropdownOption[];
    right: PropertyOption & DropdownOption[];
}

export type CanBeUsedForAutoCreationResponse = {
    canBeUsed: boolean;
    resolverId: string;
    configurationOptions: CanBeUsedForAutoCreationConfigurationOption[];
}

export type OptionsAutoCreationResolverConfig = {
    resolverId?: string;
    configData?: any;
}

export type CanBeUsedForAutoCreationConfigurationOption = {
    labelName: string;
    name: string;
    options: { id: string, name: string }[];
    propertyDataType: PropertyDataType;
}

export type IntegrationDataToCheckSharedMappingConversion = {
    connectionsIds: {
        left: number;
        right: number;
    };
    properties: {
        left: PropertyDefinition;
        right: PropertyDefinition;
    };
}

export type SharedMappingsFilters = {
    query: string;
    selectedOwner: string | [];
    selectedApps?: string;
};

export type FetchData = {
    data: SharedMappingListItem[];
    owners: string[]
}

export function createEmptyFilters(): SharedMappingsFilters {
    return {
        query: '',
        selectedOwner: [],
        selectedApps: ''
    };
}

export function isAnyFilterUsed(filters: SharedMappingsFilters): boolean {
    return (
        !!filters.query
        || filters.selectedOwner?.length > 0
        || !!filters.selectedApps
    );
}

const LOCAL_STORAGE_FILTER_NAME = 'sharedMappingsFilters';

export const fetchSharedMappings = (filters?: SharedMappingsFilters): Promise<FetchData> => {
    return new Promise((resolve, reject) => {
        try {
            if (filters) {
                persistFiltersInStorage(filters);
            }
            const url = (filters)
                ? ENDPOINT_URL + '?owner=' + (filters.selectedOwner || '') + '&query=' + (filters.query || '') + '&apps=' + (filters.selectedApps || '')
                : ENDPOINT_URL;
            axiosWrapper
                .get(url)
                .then((data: any) => {
                    const array = data.sharedMappings.map((el: SharedMapping) => {
                        return {
                            ...el,
                            leftTrigger: {
                                app: el.apps.left,
                                artifactName: el.apps.left,
                                connectionId: el.connectionIds.left,
                                className: '',
                                configuration: el.configuration.leftTriggerConfiguration
                            },
                            rightTrigger: {
                                app: el.apps.right,
                                artifactName: el.apps.right,
                                connectionId: el.connectionIds.right,
                                className: '',
                                configuration: el.configuration.rightTriggerConfiguration,
                            },
                            typeMapping: {
                                left: el.configuration.typeMappingLeft,
                                right: el.configuration.typeMappingRight
                            },
                            groups: el.configuration.groups,
                            mapping: {
                                left: el.propertiesData.left,
                                right: el.propertiesData.right,
                                direction: el.configuration.mappingDirection,
                                options: el.configuration.mappingOptions,
                                defaults: getDefaults(),
                                empty: {
                                    left: '',
                                    right: ''
                                },
                                additional: {
                                    azureEmailIsJiraUsername: el.configuration.azureEmailIsJiraUsername
                                },
                                isNewlyCreated: el.configuration.mappingIsNewlyCreated
                            },
                            appsSupport: {
                                leftApp: el.apps.left,
                                rightApp: el.apps.right,
                                left: softwareFactory.getSoftwareByName(el.apps.left)?.getSmartIntsSupport(),
                                right: softwareFactory.getSoftwareByName(el.apps.right)?.getSmartIntsSupport()
                            }
                        };
                    });
                    resolve({
                        data: array,
                        owners: data.owners
                    });
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchOneSharedMapping = (id: number): Promise<SharedMapping> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper
                .get(ENDPOINT_URL + `/${id}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const handleCreateSharedMapping = (sharedMapping: CreateSharedMapping): Promise<number> => {
    sharedMapping.configuration.groups?.forEach(g => {
        g.valueMappings?.forEach(v => {
            v.defaultValueMapping = v.isDefault;
            delete v.isDefault;
            delete (v as any).default;
        })
    })
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper
                .post(ENDPOINT_URL, sharedMapping)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const handleUpdateSharedMapping = (id: number, sharedMapping: UpdateSharedMapping): Promise<number> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper
                .post(`${ENDPOINT_URL}/${id}`, sharedMapping)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function handleSharedMappingStatus(sharedMapping: SharedMapping, action: SharedMappingStatus): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper
                .post(`${ENDPOINT_URL}/${sharedMapping.id}/${action}`, {})
                .then((data: any) => {
                    resolve(true);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function deleteSharedMapping(sharedMapping: SharedMapping): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper
                .delete(`${ENDPOINT_URL}/${sharedMapping.id}`)
                .then((data: any) => {
                    resolve(true);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export const fetchSharedMappingsForConnections = (
    leftConnectionId: number,
    rightConnectionId: number
): Promise<SharedMapping[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper
                .get(ENDPOINT_URL + `/connections/${leftConnectionId}/${rightConnectionId}`)
                .then((data: any) => {
                    const returnArray = data.map((el: SharedMapping) => ({ ...el, label: el.name, value: el.id }));
                    resolve(returnArray);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function checkIfCanBeConvertedToSharedMapping(integrationData: IntegrationDataToCheckSharedMappingConversion): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper
                .post(ENDPOINT_URL + `/can-be-converted`, integrationData)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchOptions(sharedMapping: SharedMapping): Promise<SharedMappingOptions> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper
                .get(`${ENDPOINT_URL}/${sharedMapping.id}/options`)
                .then((data: any) => {
                    const result = {
                        left: data.left.map((el: PropertyOption) => ({...el, label: el.name, value: el.id, object: {additionalData: el.additionalData, name: el.name, value: el.id}})),
                        right: data.right.map((el: PropertyOption) => ({...el, label: el.name, value: el.id, object: {additionalData: el.additionalData, name: el.name, value: el.id}})),
                    }
                    resolve(result);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function canAutoCreationBeUsed(sideOfSharedMapping: Side, sharedMappingId: number): Promise<CanBeUsedForAutoCreationResponse> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper
                .get(`${ENDPOINT_URL}/${sharedMappingId}/auto-creation/can-be-used/${sideOfSharedMapping}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function persistFiltersInStorage(filters: SharedMappingsFilters): void {
    localStorage.setItem(getFiltersStorageName(), JSON.stringify(filters));
}

export function restoreFiltersFromStorage(): SharedMappingsFilters {
    const filtersString = localStorage.getItem(getFiltersStorageName());
    if (!filtersString) {
        return createEmptyFilters();
    }
    return JSON.parse(filtersString);
}

function getFiltersStorageName() {
    return LOCAL_STORAGE_FILTER_NAME + '_' + getConnectionsListUrl();
}

function getConnectionsListUrl(): string {
    if (window.location.href.indexOf('shared-mappings') >= 0) {
        return window.location.href.split('shared-mappings')[0];
    }
    return '';
}