import React, {Component, useEffect} from 'react';
import { UI} from 'src/engrator-core';
import {Footer} from '../../footer';

import {changePassword, ChangePasswordRequest} from './rest-api';
import {FormError} from '../../../engrator-core/form-error.type';
import {useGlobalState} from '../../global-store';
import {MainMenuItem} from '../../global-store/create-global-state';

type State = {
    isLoading: boolean;
    formError?: FormError;
    success: boolean;
};

class SystemUserPasswordPageCmp extends Component<{}, State> {
    private changePasswordRequest: ChangePasswordRequest = {
        newPassword: '',
        repeated: '',
        current: ''
    };

    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: false,
            success: false
        };
    }

    changeUserPassword(): void {
        this.setState({ formError: undefined, success: false, isLoading: true }, () => {
            changePassword(this.changePasswordRequest)
                .then((license) => {
                    this.changePasswordRequest = {
                        newPassword: '', current: '', repeated: ''
                    }
                    this.setState({ success: true, isLoading: false});
                }).catch((formError) => {
                    console.log()
                    this.setState({ formError, isLoading: false});
                });
        });
    }

    render() {
        return (
            <UI.Page>
                <UI.Tile>
                    <UI.Form
                        useMaxWidthClass={ true }
                        error={ this.state.formError?.message }
                    >
                        <UI.FormGroup
                            label={`Change Password`}
                            description={`Change your current password`}
                        >
                            { this.state.success && <UI.Message appearance={'success'}>Password has been changed</UI.Message> }
                            <UI.Input
                                label={`Current password`}
                                isRequired={ true }
                                type={`password`}
                                defaultValue={ this.changePasswordRequest.current}
                                placeholder={`Current password`}
                                onChange={ (newValue: string) => this.changeField('current', newValue) }
                            />
                            <UI.Input
                                label={`New password`}
                                placeholder={`New password`}
                                isRequired={ true }
                                type={`password`}
                                defaultValue={ this.changePasswordRequest.newPassword}
                                onChange={ (newValue: string) => this.changeField('newPassword', newValue) }
                            />
                            <UI.Input
                                label={`Repeat new password`}
                                placeholder={`Repeat new password`}
                                isRequired={ true }
                                type={`password`}
                                defaultValue={ this.changePasswordRequest.repeated}
                                onChange={ (newValue: string) => this.changeField('repeated', newValue) }
                            />
                        </UI.FormGroup>
                        <UI.ButtonsBar primary={
                            <UI.Button
                                disabled={ !this.isFormValid() }
                                isLoading={ this.state.isLoading }
                                onClick={ () => this.changeUserPassword() }
                                text={`Change`}
                            />
                        } />
                    </UI.Form>
                </UI.Tile>
                <Footer/>
            </UI.Page>
        )
    }

    private changeField(field: 'newPassword' | 'repeated' | 'current', newValue: string): void {
        this.changePasswordRequest[field] = newValue;
        this.forceUpdate();
    }

    private isFormValid(): boolean {
        return !!this.changePasswordRequest.newPassword &&
            !!this.changePasswordRequest.current &&
            !!this.changePasswordRequest.repeated &&
            this.changePasswordRequest.repeated === this.changePasswordRequest.newPassword;
    }
}

export function SystemUserPasswordPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        setMainMenuItem(MainMenuItem.SystemSettings);
        setMenu(undefined);
    }, []);
    return <SystemUserPasswordPageCmp />
}