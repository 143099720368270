import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {EntityFormHelper, UI} from 'src/engrator-core';
import {ArtifactDirection} from 'src/generic/artifacts';
import {SoftwareName} from 'src/software/software-name';
import {DropdownOption} from 'src/engrator-core/ui';

import {ZendeskUpdateItemConfiguration} from './zendesk-update-item-configuration';

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class ZendeskUpdateItemNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: ZendeskUpdateItemConfiguration;
    private artifactsOptions: DropdownOption[];

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as ZendeskUpdateItemConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'targetArtifact', newValue, additionalArgument?: any) => {
            if (propertyName === 'targetArtifact' && additionalArgument) {
                this.configuration[propertyName] = newValue;
            }
            this.configurationChanged();
        });
        this.artifactsOptions = this.props.context
            .artifactsFactory
            .getAvailableOutArtifactsForSoftware(SoftwareName.Zendesk)
            .map(artifact => ({ label: artifact.getName(), value: artifact.getName(), object: artifact }));
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                description={``}
                isRequired={ true }
                label={`Select artifact to update`}
                error={ this.props.formError?.targetArtifact }
            >
                <UI.Dropdown options={ this.artifactsOptions } onChange={ this.entityFormHelper.onChangeHandler('targetArtifact')} />
            </UI.FormGroup>
        </React.Fragment>
    }

    private configurationChanged() {
        if (this.configuration.targetArtifact) {
            this.props.showArtifactBuilder(ArtifactDirection.In);
        }
        this.forceUpdate();
    }
}