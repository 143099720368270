import React from 'react';
import {UI} from 'src/engrator-core';
import {WrikeStepContextData} from './wrike-step-context';

type Props = {
    context: WrikeStepContextData;
}

type State = {
}

export class WrikeStepContextDisplay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            { this.props.context.space && <UI.LabelValue label={ `Space`} value={ this.props.context.space!.name } /> }
            { this.props.context.folder && <UI.LabelValue label={ `Folder`} value={ this.props.context.folder!.name } /> }
        </React.Fragment>;
    }
}