import React from 'react';
import { DropdownOption } from 'src/app/reporting/filters/dropdown-option.type';
import { UI } from 'src/engrator-core';
import { FormError } from 'src/engrator-core/form-error.type';
import { SoftwareName } from 'src/software';
import { SmartIntTrigger } from '../../../definition/smart-int-trigger.type';
import { SmartIntType } from '../../../definition/smart-int-type.type';
import { fetchProperties } from '../../rest-api';
import { PropertyValue } from '../value-mappings';

type Props = {
    onChange: (newFieldValue: string, obj: any) => void;
    trigger: SmartIntTrigger;
    type: SmartIntType;
    app: SoftwareName;
    defaultValue?: string;
};
type State = {
    isLoading: boolean;
    error?: FormError;
};

export class StatusFieldPicker extends React.Component<Props, State> {
    private propertiesOptions: DropdownOption[] = [];

    constructor(props: Props) {
        super(props);
        this.state = { isLoading: false };
    }

    componentDidMount() {
        this.fetchPropertiesForStatus();
    }

    fetchPropertiesForStatus() {
        this.setState({ isLoading: true}, () => {
            fetchProperties(this.props.app, this.props.trigger, this.props.type)
                .then((properties) => {
                    this.propertiesOptions = this.convertToDropDownOptions(properties);
                    this.setState({ isLoading: false });
                })
                .catch((error) => this.setState({ isLoading: false, error }) );
        });
    }

    render() {
        return <div className={`status-field-picker`}>
            { this.state.isLoading && <UI.Loader appearance={'darkgray'} visible={ true } /> }
            { this.state.error && <UI.Message appearance={'error'}>{this.state.error.message}</UI.Message> }
            { !this.state.isLoading && !this.state.error && <UI.Dropdown 
                label={ `Field that will represent item status`}
                isRequired={ true }
                options={ this.propertiesOptions }
                defaultValue={ this.props.defaultValue }
                onChange={ this.changeOption.bind(this) }
            /> }
        </div>;
    }

    private changeOption(newValue: string, obj: any) {
        this.props.onChange(newValue, obj);
    }

    private convertToDropDownOptions(options: any): DropdownOption[] {
        return options.map((option: any) => ({
            label: option.name,
            value: option.id,
            object: Object.assign(option, {
                name: option.name,
                value: option.id
            } as PropertyValue)
        }));
    }
}
