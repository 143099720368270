import React from 'react';
import { UI } from 'src/engrator-core';

import { EditPipelineStepComponentProps } from 'src/generic';
import { ArtifactDirection } from 'src/generic/artifacts';

import { MondayUpdateItemConfiguration } from './monday-update-item-configuration';

type Props = {} & EditPipelineStepComponentProps;
type State = {};

export class MondayUpdateItemEditStep extends React.Component<Props, State> {
    private configuration: MondayUpdateItemConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as MondayUpdateItemConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
        this.props.showArtifactBuilder(ArtifactDirection.In);
    }

    render() {
        return <React.Fragment>
            <UI.LabelValue label={`Target Artifact`} value={this.configuration.targetArtifact}/>
        </React.Fragment>;
    }
}
