import React from 'react';

type Props = {
    onChange: (newValue: string) => void,
    name?: string,
    label?: any,
    defaultValue?: string,
    checkedValue: string,
    uncheckedValue: string
};

type State = {
    value: string
};

export class Checkbox extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {value: (props.defaultValue !== undefined) ? props.defaultValue : ''};
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.defaultValue !== undefined && this.props.defaultValue !== prevProps.defaultValue) {
            this.setState({value: this.props.defaultValue });
        }
    }

    inputValueChanged(): void {
        const newValue = (this.state.value === this.props.checkedValue) ? this.props.uncheckedValue : this.props.checkedValue;
        this.setState({ value: newValue });
        this.props.onChange(newValue);
    }

    render() {
        const attributes: any = {
            type: 'checkbox'
        };
        if (this.props.name !== undefined) {
            attributes['name'] = this.props.name;
        }
        if (this.state.value === this.props.checkedValue) {
            attributes['checked'] = true;
        } else {
            attributes['checked'] = false;
        }
        return <div className={`engrator-ui-checkbox`}>
            <input { ...attributes } onChange={ (event) => this.inputValueChanged() } />
            { this.props.label && <label onClick={ (event) => this.inputValueChanged() }>{ this.props.label }</label> }
        </div>;
    }
}
