import React, {Component, useEffect} from 'react';
import {UI} from 'src/engrator-core';
import {useGlobalState} from '../../../../global-store';
import {MainMenuItem, VerticalMenu} from '../../../../global-store/create-global-state';
import {getIntegrationVerticalMenu} from '../integration-vertical-menu';
import {ContextMenuItem, TableGrid, TableGridData} from '../../../../../engrator-core/ui';
import {fetchIntegrationChanges} from '../../../changes/rest-api';
import {IntegrationChangesModel} from '../../../changes/integration-changes-model';
import {IntegrationChangesActions} from './integration-changes-actions';
import {IntegrationChangesModal} from './integration-changes-modal';
import {OwnerTag} from '../../owner-tag';
import {fetchIntegrationDetails} from '../../../integration-suites/details/rest-api';
import {SmartIntIntegration} from '../../../../../generic';

type State = {
    isLoading: boolean;
    changes: IntegrationChangesModel[];
    selectedVersion?: IntegrationChangesModel;
    latestVersion?: IntegrationChangesModel;
    tableGridData?: TableGridData;
    isConfigExpanded: boolean;
    isModalOpen: boolean;
};

type Props = {
    integrationId: number;
};

class IntegrationChangesPageCmp extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            changes: [],
            selectedVersion: undefined,
            latestVersion: undefined,
            isConfigExpanded: false,
            isModalOpen: false,
        };
    }

    async componentDidMount() {
        const {integrationId} = this.props;
        try {
            const changesModel = await fetchIntegrationChanges(integrationId);
            const latestVersion = changesModel.length > 0 ? changesModel[0] : undefined;
            const selectedVersion = changesModel.length > 1 ? changesModel[1] : latestVersion;
            const tableGridData = this.createTableGridData(changesModel, latestVersion);
            this.setState({
                changes: changesModel,
                selectedVersion,
                latestVersion,
                isLoading: false,
                tableGridData,
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                tableGridData: this.createTableGridData([], undefined),
            });
        }
    }

    private createTableGridData(changes: IntegrationChangesModel[], latestVersion?: IntegrationChangesModel): TableGridData {
        const headers = ['Version', 'Modified At', 'Author', 'Actions'];
        if (changes.length === 0 || !latestVersion) {
            return {headers, data: null};
        }
        const data = changes.map((change) => [
            <UI.Button
                key={`v1-${change.version}`}
                appearance='link-inline'
                onClick={() => this.handlePreviewClick(change)}
                text={`${change.version} ${change.version === latestVersion.version ? '(Current)' : ''}`}
            />,
            new Date(change.modifiedAt).toLocaleString(),
            <OwnerTag
                showOwnerPrefix={false}
                owner={{identifier: change.changeAuthorName || 'Unknown'}}
                maxLength={20}
            />,
            <IntegrationChangesActions
                onContextMenuSelectHandler={(item) => this.handleActionSelected(item, change)}
            />,
        ]);
        return {headers, data};
    }

    private handlePreviewClick(version: IntegrationChangesModel) {
        if (version.version === this.state.latestVersion?.version && this.state.changes.length > 1) {
            this.setState({selectedVersion: this.state.changes[1], isModalOpen: true});
        } else {
            this.setState({selectedVersion: version, isModalOpen: true});
        }
    }

    private handleActionSelected(item: ContextMenuItem, change: IntegrationChangesModel) {
        if (item.id === 'Preview') {
            this.handlePreviewClick(change);
        }
    }

    private toggleConfigVisibility = () => {
        this.setState((prevState) => ({isConfigExpanded: !prevState.isConfigExpanded}));
    };

    private closeModal = () => {
        this.setState({isModalOpen: false});
    };

    render() {
        const {
            isLoading,
            selectedVersion,
            latestVersion,
            tableGridData,
            isConfigExpanded,
            isModalOpen
        } = this.state;
        if (isLoading) {
            return (
                <UI.Page>
                    <UI.Loader appearance='darkgray' visible={true}/>
                </UI.Page>
            );
        }
        return (
            <UI.Page className='dashboard-page integration-changes' title='Integration Changes'>
                <br/>
                <div>
                    <div>
                        <TableGrid
                            headers={tableGridData?.headers || []}
                            data={tableGridData?.data || []}
                            noDataText="No historical changes found."
                        />
                    </div>
                    <IntegrationChangesModal
                        isOpen={isModalOpen}
                        selectedVersion={selectedVersion}
                        latestVersion={latestVersion}
                        isConfigExpanded={isConfigExpanded}
                        onClose={this.closeModal}
                        onToggleConfig={this.toggleConfigVisibility}
                        singleChange={this.state.changes.length === 1}
                    />
                </div>
            </UI.Page>
        );
    }
}

export function IntegrationChangesPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    const integrationId = props.match.params.id;
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Workflows);
        fetchIntegrationDetails(integrationId)
            .then(detailsModel => {
                const integration = new SmartIntIntegration(
                    detailsModel.id,
                    detailsModel.name,
                    detailsModel.configuration,
                    detailsModel.settings,
                    detailsModel.migrationConfiguration,
                    detailsModel.accessLevel
                );
                const menu: VerticalMenu = getIntegrationVerticalMenu(integrationId, integration);
                setMenu(menu);
            });
    }, [integrationId]);
    return <IntegrationChangesPageCmp integrationId={integrationId}/>;
}
