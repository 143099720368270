import React from 'react';

import {UI} from 'src/engrator-core';
import {ReportName} from './report-name.type';
import {FilterChangeHandler} from './filters/filter-change-handler.type';
import {FilterName} from './filter-name.type';
import {createReportingFilters} from './reporting-filters-factory';

type Props = {
    reportName?: ReportName | null,
    onSearchHandler: (filtersData: any, combinedFilters: string) => void;
    initialFilters: any
};

type State = {
    reportName: ReportName | null,
    isSearchEnabled: boolean
}
export class ReportingFilters extends React.Component<Props, State> {
    private filtersData: any = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            reportName: null,
            isSearchEnabled: false
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.initialFilters !== prevProps.initialFilters) {
            this.filtersData = this.props.initialFilters;
        }
        if (this.props.reportName !== prevProps.reportName) {
            this.forceUpdate();
        }
    }

    render() {
        if (!this.props.reportName) {
            return <React.Fragment>wrong</React.Fragment>
        }
        return <React.Fragment>
            <div className={`reporting-filters`}>
                <UI.Tile>
                    { createReportingFilters(this.props.reportName, this.props.initialFilters, this.getFilterChangeHandler.bind(this), this.clearFilterHandler.bind(this)) }
                    <UI.Button disabled={ !this.state.isSearchEnabled } onClick={ () => this.onSearchHandler() } text={ `Search `} />
                </UI.Tile>
            </div>
        </React.Fragment>
    }

    private getFilterChangeHandler(filterName: FilterName): FilterChangeHandler {
        return filterValue => {
            if (!this.filtersData) {
                this.filtersData = {};
            }
            this.filtersData[filterName] = filterValue;
            this.setState({ isSearchEnabled: true });
        };
    }

    private clearFilterHandler(filterName: FilterName, val: any): void {
        if (!this.filtersData) {
            this.filtersData = {};
        }
        this.filtersData[filterName] = val;
        this.setState({ isSearchEnabled: true });
    }

    private onSearchHandler() {
        let combinedFilters: string[] = [];
        Object.keys(this.filtersData).filter(filterName => filterName !== 'report' && filterName !== 'page').forEach(filterName => {
            Object.keys(this.filtersData[filterName]).forEach(filterPropertyName => {
                if (this.filtersData[filterName][filterPropertyName]) {
                    // const key = (filterPropertyName === filterName) ? filterPropertyName : `${filterName}${filterPropertyName}`;
                    combinedFilters.push(`${filterName}[${filterPropertyName}]=${this.filtersData[filterName][filterPropertyName]}`);
                }
            });
        });
        this.setState({ isSearchEnabled: false}, () => {
            this.props.onSearchHandler(this.filtersData, combinedFilters.join('&'));
        });
    }
}