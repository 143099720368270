import React, {Component, useEffect} from 'react';
import { CloudSetting, saveSetting, fetchLicenseInfo} from './rest-api';
import {getCloudSettingsMenu} from './cloud-settings-menu';
import {useGlobalState} from '../../global-store';
import {MainMenuItem} from '../../global-store/create-global-state';
import {DedicatedLicense} from '../dedicated-license';

type State = {
};

export class CloudSystemLicensePageCmp extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
    }

    render() {
        return <DedicatedLicense
            fetchLicense={ () => fetchLicenseInfo() }
            setLicense={ (newLicense: string) => saveSetting(CloudSetting.DedicatedLicense, newLicense) }
        />;
    }
}


export function CloudSystemLicensePage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Settings);
        setMenu(getCloudSettingsMenu());
    }, []);
    return <CloudSystemLicensePageCmp />
}

