import React from 'react';
import {EntityFormHelper, FieldError, UI} from 'src/engrator-core';
import {FormError} from 'src/engrator-core/form-error.type';
import {DropdownOption} from 'src/engrator-core/ui';
import { fetchOrganizations } from './../rest-api';
import {ZendeskOrganizationDTO} from './zendesk-organization-dto.type';
import { ZendeskOrganizationManualWay } from './zendesk-organization-manul-way';

export type ZendeskOrganizationPickerData = {
    organization?: ZendeskOrganizationDTO;
};

type Props = {
    connectionId: number;
    onDataChanged: (data: ZendeskOrganizationPickerData) => void;
    formError?: FormError;
}

type State = {
    formError: FormError;
    isLoadingOrganizations: boolean;
    provideManually: boolean;
}

export class ZendeskOrganizationPicker extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private organizationOptions: DropdownOption[] = [];
    private data : ZendeskOrganizationPickerData = {};

    constructor(props: Props) {
        super(props);
        this.state = {formError: {}, isLoadingOrganizations: true, provideManually: false };
        this.entityFormHelper = new EntityFormHelper((propertyName: 'organization', newValue, additionalArgument?: any) => {
            if (!additionalArgument) {
                return;
            }
            if (propertyName === 'organization') {
                this.data.organization = additionalArgument as ZendeskOrganizationDTO;
            }
            this.props.onDataChanged(this.data);
            this.forceUpdate();
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.forceUpdate();
        }
    }

    componentDidMount(): void {
        fetchOrganizations(this.props.connectionId)
            .then((organizations: ZendeskOrganizationDTO[]) => {
                this.organizationOptions = organizations.map(organization => ({
                    label: organization.name,
                    value: organization.id,
                    object: organization
                }));
                this.setState({isLoadingOrganizations: false});
            })
            .catch((error: FieldError) => {
                this.setState({ isLoadingOrganizations: false, formError: { ['pickerError']: error.message  } });
            });
    }

    render() {
        return <React.Fragment>
            { this.state.formError.pickerError && <UI.Message appearance={'error'}>{ this.state.formError.pickerError}</UI.Message> }
            <UI.FormGroup
                label={`Organization`}
                error={ this.props.formError?.organization }
                description={``}
                isRequired={false}>
                { this.state.isLoadingOrganizations && <UI.Loader visible={true} appearance={'darkgray'} /> }
                { !this.state.isLoadingOrganizations && !this.state.provideManually && 
                <UI.Dropdown
                    options={ this.organizationOptions }
                    onChange={ this.entityFormHelper.onChangeHandler('organization')   }
                /> }
            </UI.FormGroup>
            { this.state.provideManually && <ZendeskOrganizationManualWay 
                onProvided={ this.organizationProvidedManullayHandler.bind(this) }
            /> }
            { !this.state.provideManually && <UI.Button 
                appearance={`secondary`}
                onClick={ () => this.setState({ provideManually: true })}
                text={`Provide manually`}
            /> }
        </React.Fragment>;
    }

    organizationProvidedManullayHandler(organization: ZendeskOrganizationDTO) {
        this.entityFormHelper.onChangeHandler('organization')('', organization);
    }
}