import React from 'react';
import { FieldError, UI } from 'src/engrator-core';
import { createDuplicatedIntegration, SmartIntToDuplicate } from './duplicate-smart-int';
import { fetchIntegrationDetails } from '../../../integration-suites/details/rest-api';
import { SmartIntIntegration } from 'src/generic';
import { SelectConnectionDetailsStep } from '../../visual/app-selection/select-connection-details-step';
import { SoftwareName } from 'src/software';
import softwareFactory from 'src/software/software-factory';
import { SoftwareLogotype } from '../../../../../designer/step-creator/software-logotype';
import { SmartIntTrigger } from '../smart-int-trigger.type';
import { FormError } from '../../../../../engrator-core/form-error.type';

type Props = {
    smartIntToDuplicate: SmartIntToDuplicate;
    closeHandler: () => void;
    onSmartIntDuplicated: (newIntegrationId: number) => void;
};
type State = {
    areBothAppsConfigured: boolean;
    error?: FormError;
    isLoading: boolean;
};

export class DuplicateSmartIntWizard extends React.Component<Props, State> {
    private integration?: SmartIntIntegration;
    private newName: string = '';
    private apps: { left: any, right: any };

    constructor(props: Props) {
        super(props);
        this.state = {
            areBothAppsConfigured: false,
            isLoading: false
        };
        this.apps = {
            left: undefined,
            right: undefined
        };
    }

    async componentDidMount(): Promise<void> {
        this.newName = '';
        fetchIntegrationDetails(this.props.smartIntToDuplicate.id).then(detailsModel => {
            this.integration = new SmartIntIntegration(
                detailsModel.id,
                detailsModel.name,
                detailsModel.configuration,
                detailsModel.settings,
                detailsModel.migrationConfiguration
            );
            this.newName = `Copy of ` + this.integration.getName().toString();
            this.integration!.setName(this.newName);
            this.forceUpdate();
        }).catch((error: FieldError) => {
            const errorMessage = `Integration could not be fetched: ${error.message}`;
            this.setState({ error: { ['general']: errorMessage } });
        })
    }

    render() {
        const currentStep = {
            name: 'Configure',
            index: 3
        }
        return <UI.FullScreenModal
            header={`Duplicate Integration`}
            isPrimaryDisabled={ !this.state.areBothAppsConfigured }
            primaryBtnHandler={ () => this.doneClicked() }
            primaryBtnText={ `Duplicate` }
            closeBtnHandler={ () => this.props.closeHandler() }
        >
            {this.integration && <React.Fragment>
                <UI.Form errors={[]}>
                    <UI.FormGroup
                        description={`Duplicate integration by providing new name and configuring apps triggers`}
                    >
                        <UI.Input
                            isRequired={true}
                            label={`New name`}
                            defaultValue={this.newName}
                            onChange={this.changeName.bind(this)}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Configure apps`}
                        isRequired={true}
                        description={`Modify apps configuration and click 'Done' buttons. All type mappings including their configuration will be still copied.`}
                    ></UI.FormGroup>
                    <div className={`triggers flex two-columns`}>
                        <div className={`left`}>
                            <SoftwareLogotype
                                softwareName={this.integration!.definition.triggers.left.app as SoftwareName}
                                showName={true}
                            />
                            <SelectConnectionDetailsStep
                                onConnectionSetUp={(connectionId: number, trigger: SmartIntTrigger) => this.onAppConfigured('left', connectionId, trigger)}
                                softwareName={this.integration!.definition.triggers.left.app as SoftwareName}
                                softwareFactory={softwareFactory}
                                connectionId={this.integration!.definition.triggers.left.connectionId}
                                disableGuide={true}
                                currentStep={currentStep}
                            />
                        </div>
                        <div className={`right`}>
                            <SoftwareLogotype
                                softwareName={this.integration!.definition.triggers.right.app as SoftwareName}
                                showName={true}
                            />
                            <SelectConnectionDetailsStep
                                onConnectionSetUp={(connectionId: number, trigger: SmartIntTrigger) => this.onAppConfigured('right', connectionId, trigger)}
                                softwareName={this.integration!.definition.triggers.right.app as SoftwareName}
                                softwareFactory={softwareFactory}
                                disableGuide={true}
                                connectionId={this.integration!.definition.triggers.right.connectionId}
                                currentStep={currentStep}
                            />
                        </div>
                    </div>
                </UI.Form>
            </React.Fragment>}
        </UI.FullScreenModal>;
    }

    private changeName(newValue: string) {
        this.newName = newValue;
        this.integration!.setName(this.newName);
    }

    private onAppConfigured(side: 'left' | 'right', connectionId: number, trigger: SmartIntTrigger) {
        this.apps[side] = trigger;
        this.integration!.definition.triggers[side] = trigger;
        if (this.apps.left && this.apps.right) {
            this.setState({ areBothAppsConfigured: true });
        }
    }

    private doneClicked() {
        return new Promise<boolean>((resolve, reject) => {
            this.setState({isLoading: true}, () => {
                createDuplicatedIntegration(this.integration, (integrationId: number) => {
                    this.props.closeHandler();
                    this.props.onSmartIntDuplicated(integrationId);
                    resolve(true);
                });
            });
        });
    }
}
