import {axiosWrapper, FieldError} from '../../../engrator-core';
import {TrelloBoardDTO} from './trello-smart-ints-trigger-configuration';

export const ENDPOINT_URL = '/connectors/trello';

export function fetchBoards(connectionId: number): Promise<TrelloBoardDTO[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/boards')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}