import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';
import {JiraStepKey} from '../jira-step-key.type';
import {JiraCreateIssueConfiguration} from './jira-create-issue-configuration';
import {JiraCreateIssueEditStep} from './jira-create-issue-edit-step';
import {JiraCreateIssueNewStep} from './jira-create-issue-new-step';




export const JiraCreateIssuePipelineStep: PipelineStep<JiraCreateIssueConfiguration> = {
    addStepComponentClass: JiraCreateIssueNewStep,
    editStepComponentClass: JiraCreateIssueEditStep,
    specification: {
        action: 'Create issue',
        description: 'Create issue of specified type',
        key: JiraStepKey.JiraCreateItem,
        software: SoftwareName.Jira,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `createdIssue`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as JiraCreateIssueConfiguration;
        return `Create ${stepConfiguration.issueType.name} in ${stepConfiguration.project.name}`;
    }
}