import {PipelineStep} from 'src/generic';
import {
    AsanaCreateCommentsPipelineStep,
    AsanaCreateItemPipelineStep,
    AsanaSearchItemsPipelineStep,
    AsanaUpdateItemPipelineStep,
    AsanaFindItemPipelineStep,
    AsanaStatusTransitionPipelineStep
} from './steps';

export const AsanaPipelineSteps: PipelineStep<any>[] = [
    AsanaSearchItemsPipelineStep,
    AsanaCreateItemPipelineStep,
    AsanaCreateCommentsPipelineStep,
    AsanaUpdateItemPipelineStep,
    AsanaFindItemPipelineStep,
    AsanaStatusTransitionPipelineStep
];