import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';
import {ServiceNowStepKey} from '../servicenow-step-key.type';
import {ServiceNowCreateItemConfiguration} from './servicenow-create-item-configuration';
import {ServiceNowCreateItemEditStep} from './servicenow-create-item-edit-step';
import {ServiceNowCreateItemNewStep} from './servicenow-create-item-new-step';

export const ServiceNowCreateItemPipelineStep: PipelineStep<ServiceNowCreateItemConfiguration> = {
    addStepComponentClass: ServiceNowCreateItemNewStep,
    editStepComponentClass: ServiceNowCreateItemEditStep,
    specification: {
        action: 'Create Item',
        description: 'Create item in specified table',
        key: ServiceNowStepKey.ServiceNowCreateItem,
        software: SoftwareName.ServiceNow,
        stepType: StepType.Action
    },
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ServiceNowCreateItemConfiguration;
        return `Create item in ${stepConfiguration.context.table?.label} table`;
    }
}