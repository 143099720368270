import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { SoftwareName } from '../../software-name';
import {fetchFolders, fetchSpaces} from './rest-api';
import {ClickUpFolderDTO, ClickUpSpaceDTO} from './clickup-smart-ints-trigger-configuration';
import {DropdownOption} from '../../../engrator-core/ui';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {
    isLoading: boolean;
    spacesOptions?: DropdownOption[];
    foldersOptions?: DropdownOption[];
};

export class ClickUpSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false
        };
        this.smartIntTrigger = {
            app: SoftwareName.ClickUp,
            artifactName: '',
            className: '',
            configuration: {
                query: '',
            },
            connectionId: this.props.connectionId,
        };
    }

    async componentDidMount(): Promise<void> {
        await this.setState({ isLoading: true });
        const spaces = await fetchSpaces(this.smartIntTrigger.connectionId);
        const spacesOptions: DropdownOption[] = spaces.map(s => ({
            value: s.id,
            label: s.name,
            object: s
        }));
        this.setState({ isLoading :false, spacesOptions });
    }

    render() {
        return <React.Fragment>
            { this.state.spacesOptions && <UI.FormGroup
                label={`Space`}
                isRequired={ true }
            >
                <UI.Dropdown
                    options={ this.state.spacesOptions }
                    onChange={ (value: string, obj: ClickUpSpaceDTO) => this.setSpace(obj) }
                />
            </UI.FormGroup> }
            { this.state.foldersOptions && <UI.FormGroup
                label={`Folder`}
                isRequired={ true }
            >
                <UI.Dropdown
                    options={ this.state.foldersOptions }
                    onChange={ (value: string, obj: ClickUpFolderDTO) => this.setFolder(obj) }
                />
            </UI.FormGroup> }
            { this.state.isLoading && <UI.Loader visible={ true } appearance={'darkgray'} /> }
            {/*<UI.FormGroup*/}
            {/*    label={`Additional query`}*/}
            {/*    description={``}*/}
            {/*>*/}
            {/*    <UI.Input label={``} onChange={ this.onJqlInputChanged.bind(this)} />*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>;
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private async setSpace(space: ClickUpSpaceDTO): Promise<void> {
        this.smartIntTrigger.configuration.space = space;
        this.setState({ isLoading: true });
        const folders = await fetchFolders(this.smartIntTrigger.connectionId, space.id);
        const foldersOptions: DropdownOption[] = folders.map(f => ({
            value: f.id,
            label: f.name,
            object: f
        }));
        this.setState({ foldersOptions, isLoading: false });
    }

    private setFolder(folder: ClickUpFolderDTO) {
        this.smartIntTrigger.configuration.folder = folder;
        this.smartIntTrigger.artifactName = 'task';
        this.props.onConfigured(this.smartIntTrigger!);
        this.forceUpdate();
    }
}
