import React from 'react';
import {FILTER_OPERATOR, ItemFilter} from './rest-api';
import { UI } from 'src/engrator-core';

type State = {
};

type Props = {
    deleteHandler: () => void;
    filter: ItemFilter;
}

export class DisplayFilter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {  };
    }

    render() {
        return <div className={`display-entry-row`}>
            <div>{ this.props.filter.field.name }</div>
            <div>{ this.props.filter.operator }</div>
            { this.props.filter.operator !== FILTER_OPERATOR.CONTAINS_ONE_OF && <div className={`last-one`}>{ this.props.filter.value }</div> }
            { this.props.filter.operator === FILTER_OPERATOR.CONTAINS_ONE_OF && <ul className={`last-one`}>
                { this.props.filter.multiValues?.map((value, index) => <li key={ index }>{ value }</li> ) }
            </ul> }
            <div>
                <UI.Icon
                    icon={'trash'}
                    onClick={ () => this.props.deleteHandler() }
                />
            </div>
        </div>
    }
}