import { Software } from 'src/generic';
import {
    createDefaultPropertiesGuards,
    PipelineSettings
} from '../../app/integration/integration-suites/details/pipeline-settings-type';
import { getDefaultUpdateStrategySettings } from 'src/app/integration/integration-suites/details/update-strategy-settings.type';
import {IntegrationAccessLevel} from '../../app/integration/integration-suites/details/integration-details-model';

export class GenericIntegration {
    name: string;
    registeredSoftware: Array<Software> = [];
    private _settings?: PipelineSettings;
    private _accessLevel: IntegrationAccessLevel | undefined;

    constructor(name: string, accessLevel?: IntegrationAccessLevel) {
        this.name = name;
        this._accessLevel = accessLevel;
        this._settings = {
            workingWeekends: true,
            status: 'Enabled',
            groupName: '/Default',
            threadId: 0,
            workingHourFrom: undefined,
            workingHourTo: undefined,
            intervalInSeconds: 60,
            updateStrategySettings: getDefaultUpdateStrategySettings(),
            additionalSettings: {
                multiFieldsUsage: false,
                propertiesGuards: createDefaultPropertiesGuards()
            }
        }
    }

    registerSoftware(software: Software) {
        this.registeredSoftware.push(software);
    }

    setName(name: string) {
        this.name = name;
    }

    getName(): String {
        return this.name;
    }

    get settings(): PipelineSettings {
        return this._settings!;
    }

    set settings(value: PipelineSettings) {
        this._settings = value;
    }

    getConnectionFactories(): Array<any> {
        return this.registeredSoftware.map(software => ({
            softwareId: software.getID(),
            connectionFactory: software.getConnectionFactory()
        }));
    }

    get accessLevel(): IntegrationAccessLevel | undefined {
        return this._accessLevel;
    }
}
