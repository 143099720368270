import {PipelineStep} from 'src/generic/pipeline-step';
import {SoftwareName} from 'src/software/software-name';
import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';

import {GitLabStepKey} from '../gitlab-step-key.type';
import {GitLabSearchItemsConfiguration} from './gitlab-search-items-configuration';
import {GitLabSearchItemsNewStep} from './gitlab-search-items-new-step';
import {GitLabSearchItemsEditStep} from './gitlab-search-items-edit-step';


export const GitLabSearchItemsPipelineStep :  PipelineStep<GitLabSearchItemsConfiguration> = {
    addStepComponentClass: GitLabSearchItemsNewStep,
    editStepComponentClass: GitLabSearchItemsEditStep,
    specification: {
        action: 'Search GitLab Items',
        description: 'Search items in specified project',
        key: GitLabStepKey.GitLabSearchItems,
        software: SoftwareName.GitLab,
        stepType: StepType.Trigger
    },
    preferredNameForArtifact: `GitLabItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitLabSearchItemsConfiguration;
        return `Search for items in ${stepConfiguration.context.project?.name}`;
    }
}