import {IntegrationStepConfiguration, StepType} from 'src/generic/software/step';
import {PipelineStep} from 'src/generic';
import {SoftwareName} from 'src/software/software-name';
import {WrikeStepKey} from '../wrike-step-key.type';
import {WrikeCreateItemConfiguration} from './wrike-create-item-configuration';
import {WrikeCreateItemEditStep} from './wrike-create-item-edit-step';
import {WrikeCreateItemNewStep} from './wrike-create-item-new-step';

export const WrikeCreateItemPipelineStep: PipelineStep<WrikeCreateItemConfiguration> = {
    addStepComponentClass: WrikeCreateItemNewStep,
    editStepComponentClass: WrikeCreateItemEditStep,
    specification: {
        action: 'Create task',
        description: 'Create task in specified folder',
        key: WrikeStepKey.WrikeCreateItem,
        software: SoftwareName.Wrike,
        stepType: StepType.Action
    },
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as WrikeCreateItemConfiguration;
        return `Create task in ${stepConfiguration.context?.space?.name}, ${stepConfiguration.context?.folder?.name} folder`;
    }
}