import React from 'react';


type Props = {
    currentStepIndex: number
};
type State = {
};

export class StepsProgress extends React.Component<Props, State> {
    render() {
        return <div className={`steps-progress`}>
            <ul>
                <li className={ (this.props.currentStepIndex === 0) ? `selected` : `` }>1.<br/>Apps</li>
                <li className={ (this.props.currentStepIndex === 1) ? `selected` : `` }>2.<br/>Connections</li>
                <li className={ (this.props.currentStepIndex === 2) ? `selected` : `` }>3.<br/>Configuration</li>
            </ul>
        </div>;
    }
}