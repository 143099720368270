import React from 'react';
import { UI } from 'src/engrator-core';


type Props = {
    id: number;
};

type State = {
};

export class LoadingPageHeader extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <div className={`header`}>
            <div>
                <p className={`left-padding`}>
                    <UI.Loader visible={true} appearance={'darkgray'}  />
                </p>
            </div>
        </div>
    }
}