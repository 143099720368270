import {GitRepository} from '../../git/git-repository';
import {axiosWrapper, FieldError} from '../../../../../engrator-core';
import {SoftwareName} from '../../../../../software';

export function fetchGitRepositories(app: SoftwareName, connectionId: number): Promise<GitRepository[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get('/connectors/' + app.toLowerCase() + '/meta/' + connectionId + '/git/repositories')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}