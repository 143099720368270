import React, {Component, useEffect} from 'react';
import {FieldError, UI} from 'src/engrator-core';
import {Icon20, TableGridData} from '../../../engrator-core/ui';
import {useGlobalState} from '../../global-store';
import {getWorkflowsVerticalMenu} from '../vertical-menu';
import {MainMenuItem} from '../../global-store/create-global-state';
import {
    fetchGitRepositories,
    GitRepositoryListItemDto,
} from './rest-api';
import { SoftwareLogo } from 'src/designer/step-creator/software-logo';

type Props = {
    integrationId?: number;
};
type State = {
    error?: FieldError,
    isLoading: boolean,
    shouldReloadTable: boolean;
};

export class GitRepositoriesListPageCmp extends Component<Props, State> {
    private tableGridData?: TableGridData = undefined;
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            shouldReloadTable: false,
        };
    }

    async componentDidMount(): Promise<void> {
        await this.loadData();
    }

    private async loadData(): Promise<void> {
        this.setState({ isLoading: true}, () => {
            fetchGitRepositories(this.props.integrationId)
                .then(repositories => {
                    this.tableGridData = this.createData(repositories);
                    this.setState({ isLoading: false });
                })
                .catch(error => this.setState({ isLoading: false, error}));
        });
    }

    private refreshList() {
        this.setState({shouldReloadTable: !this.state.shouldReloadTable});
    }

    render() {
        return (
            <UI.Page className={`shared-mappings-list-page`}>
                {/* Commented for now, will enable once docs are released */}
                {/*<UI.Message appearance={'info'}>*/}
                {/*    To find out more about Git Repositories integrations, you can <UI.DocumentationLink text={`check our docs`} url={`https://docs.getint.io/getintio-platform/workflows/shared-mappings-in-getint-centralized-mapping-for-efficient-integrations`} />.*/}
                {/*</UI.Message>*/}
                { this.state.error && <UI.Message appearance={'error'} message={ this.state.error?.message} /> }
                { this.state.isLoading && <UI.Loader visible={ true } appearance={'darkgray'}/> }
                { !this.state.isLoading && this.tableGridData && <React.Fragment>
                    <UI.TableGrid
                        data={ this.tableGridData.data }
                        headers={ this.tableGridData.headers }
                    />
                </React.Fragment> }
            </UI.Page>
        )
    }

    private createData(items: GitRepositoryListItemDto[]): TableGridData {
        return {
            data: items.map(item => [
                <span>
                    <UI.NavLink
                        dataSel={``}
                        path={`/app/integration/smart-ints/${item.integrationId}/edit`}
                    >{ item.name }</UI.NavLink>
                    { item.url && <span style={{marginLeft: '10px'}}><UI.DocumentationLink text={``} url={item.url} /></span> }
                </span>,
                <div className={`connected-apps`}>
                    <SoftwareLogo softwareName={ item.app } />
                </div>,
                <UI.ExecutionTime startTime={ item.syncedAt } />,
                <div className={`git-items`}>
                    <div><UI.Icon icon20={Icon20.CodeBranch}/> { item.branchesCount }</div>
                    <div><UI.Icon icon20={Icon20.CodeCommit}/> { item.commitsCount }</div>
                    <div><UI.Icon icon20={Icon20.CodePullRequest}/> { item.pullRequestsCount }</div>
                </div>,
            ]),
            headers: ['Name', 'App', 'Last index', 'Stats'],
        };
    }
}

export function GitRepositoriesListPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        setMainMenuItem(MainMenuItem.Workflows);
        getWorkflowsVerticalMenu()
            .then(workflowsVerticalMenu => {
                setMenu(workflowsVerticalMenu);
                setMainMenuItem(MainMenuItem.Workflows);
            });
    }, []);
    return <GitRepositoriesListPageCmp />
}