import {getSoftwareName, ISoftwareFactory, SoftwareName} from '../../../../../software';
import React from 'react';
import {SoftwareLogotype} from '../../../../../designer/step-creator/software-logotype';
import {AppSelectionWizard} from './app-selection-wizard';
import {SmartIntTrigger} from '../../definition/smart-int-trigger.type';
import {EditApp} from './edit-app';
import {registerUserActivityAction, UserActivityAction} from '../../../../system/user-activity';
import {UI} from 'src/engrator-core';
import {GuideTooltip} from '../../../../engagement';
import {
    BreadcrumbsSymbol,
    BreadcrumbStep,
    ConnectionStep,
    initializeBreadcrumbStep
} from 'src/engrator-core/ui/breadcrumbs';
import {IntegrationContext} from '../../pages/integration-context';
import {SuiteType} from '../../../../../generic/integrations/suite-type';

type SelectAppState = {
    appSelected: boolean;
    showTypeSelection: boolean;
    showAppSelection: boolean;
    showAppEdition: boolean;
    showItemFiltering: boolean;
    currentStep: BreadcrumbStep;
};

type SelectAppProps = {
    tooltipText: string;
    isLeftSelected?: boolean;
    className: string;
    trigger: SmartIntTrigger;
    softwareFactory: ISoftwareFactory;
    onAppSelectedHandler: (appName: SoftwareName, connectionId: number, trigger: SmartIntTrigger) => void;
}

export class AppSelection extends React.Component<SelectAppProps, SelectAppState> {
    private data: {
        connectionId?: number;
        appName?: SoftwareName } = {};
    private trigger?: SmartIntTrigger;
    private connectionSteps = [
        {name: 'Select app', index: ConnectionStep.SelectApp}, 
        {name: 'Connect app', index: ConnectionStep.ConnectApp}, 
        {name: 'Configure', index: ConnectionStep.Configure}, 
        {name: 'Create connection', index: ConnectionStep.CreateConnection} 
    ];
    static contextType = IntegrationContext;

    constructor(props: SelectAppProps) {
        super(props);
        this.state = {
            showAppEdition: false,
            showAppSelection: false,
            appSelected: false,
            showTypeSelection: false,
            showItemFiltering: false,
            currentStep: initializeBreadcrumbStep()
        };
        if (this.props.trigger.app) {
            this.trigger = this.props.trigger;
            this.data.appName = this.props.trigger.app as SoftwareName;
            this.data.connectionId = this.props.trigger.connectionId;
        }
    }

    private itemFiltersClicked() {
        this.setState({ showItemFiltering: true });
    }

    private onChangeStepHandler(step: ConnectionStep) {
        if (step === this.state.currentStep.index) return;
        const found = this.connectionSteps.find(el => el.index === step);
        if (found) {
            this.setState({ currentStep: found });
        }
    }

    private handleOnClose() {
        setTimeout(() => {
            this.setState({ currentStep: initializeBreadcrumbStep() })
        }, 300)
    }

    render() {
        const {getIntegrationType} = this.context;
        return <div className={`select-app ${this.props.className} suite-type-${getIntegrationType().toLowerCase()}`} >

            { this.data.appName && <div className={`app-container`}>
                <div className={`inner`} onClick={ () => this.editApp(getIntegrationType()) }>
                    <SoftwareLogotype softwareName={ this.data.appName } showName={ false }/>
                    <span className={`software-name`}>{ getSoftwareName(this.data.appName.toString()) }</span>
                </div>
                <GuideTooltip
                    action={UserActivityAction.SyncGuideEditApp}
                    closeable={true}
                >Click on App to edit it</GuideTooltip>
            </div> }

            { !this.data.appName && <React.Fragment>
                <div
                    className={`select-app-btn anim-blinking`}
                    onClick={ this.showAppSelection.bind(this) }>Connect App
                </div>
                { !this.props.isLeftSelected && <GuideTooltip>{ this.props.tooltipText }</GuideTooltip> }
            </React.Fragment> }

            { this.state.showAppSelection && <UI.FullScreenModal
                onClose={ () => this.handleOnClose() }
                breadcrumbs={ true }
                breadcrumbsSymbol={ BreadcrumbsSymbol.slash }
                allSteps={ this.connectionSteps }
                filterOptions={ { specificStep: 4, stepsToFilter: [3] } }
                onChangeStepHandler={ (step: ConnectionStep) => this.onChangeStepHandler(step) }
                currentStep={ this.state.currentStep }
                showPrimaryBtn={false}
                maximized={ true }
                hideFooter={ true }
                closeBtnHandler={ () => this.setState({ showAppSelection: false }) }
                primaryBtnHandler={ () => Promise.resolve(true) }
            >
                <AppSelectionWizard
                    close={ this.closeAppSelectionWizard.bind(this) }
                    softwareFactory={ this.props.softwareFactory }
                    onAppSelected={ this.onAppSelected.bind(this) } 
                    currentStep={ this.state.currentStep }
                    onChangeStepHandler={ (step: ConnectionStep) => this.onChangeStepHandler(step)} />
            </UI.FullScreenModal> }


            { this.state.showAppEdition && this.data.appName &&
                <EditApp
                    close={ () => this.closeAppEdition() }
                    trigger={ this.trigger! }
                    app={ this.data.appName }
                    connectionId={ this.data.connectionId! }
                    softwareFactory={ this.props.softwareFactory }
                    optionSelected={ () => {} }
                /> }
        </div>
    }

    private editApp(suiteType: SuiteType) {
        if (suiteType !== SuiteType.Git) {
            this.setState({showAppEdition: true});
        }
    }

    private closeAppEdition() {
        this.setState({ showAppEdition: false });
    }

    private closeItemFiltering() {
        this.setState({ showItemFiltering: false });
    }

    private onAppSelected(appName: SoftwareName, connectionId: number, trigger: SmartIntTrigger) {
        registerUserActivityAction(UserActivityAction.SelectedApp);
        this.data.appName = appName;
        this.data.connectionId = connectionId;
        this.trigger = trigger;
        this.props.onAppSelectedHandler(appName, connectionId, trigger);
        this.setState({ showAppSelection: false });
    }

    private showAppSelection() {
        registerUserActivityAction(UserActivityAction.ClickedSelectApp);
        this.setState({ showAppSelection: true })
    }

    private closeAppSelectionWizard() {
        this.setState({ showAppSelection: false })
    }
}
