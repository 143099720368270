import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {NotionSmartIntsTriggerConfiguration} from './notion-smart-ints-trigger-configuration';

type Props = {
    connectionId: number;
    trigger: SmartIntTrigger;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class NotionSmartIntsEditTrigger extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const configuration = (this.props.trigger.configuration as NotionSmartIntsTriggerConfiguration);
        return <React.Fragment>
            <UI.LabelValue label={`Database`} value={ configuration.database.name } />
            <UI.FormGroup
                label={`Additional query`}
                description={``}
            >
                <UI.Input defaultValue={configuration.query} label={``} onChange={this.onJqlInputChanged.bind(this)}/>
            </UI.FormGroup>
        </React.Fragment>;
    }

    private onJqlInputChanged(newValue: string) {
        this.props.trigger.configuration.query = newValue;
    }
}
