import React from 'react';
import {NewPipelineStepComponentProps} from 'src/generic';
import {ZendeskCreateCommentsConfiguration} from './zendesk-create-comments-configuration';
import {CreateCommentsStep} from 'src/software/generic/create-comments-step/create-comments-step';
import {UI} from 'src/engrator-core';
import {StepArtifact} from 'src/generic/software/step';
import {SoftwareName} from '../../../software-name';

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class ZendeskCreateCcommentsNewStep extends React.Component<Props, State> {
    private configuration: ZendeskCreateCommentsConfiguration;
    private availableArtifacts: StepArtifact[];

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration as ZendeskCreateCommentsConfiguration;
        this.availableArtifacts = this.props.context.artifactsFactory.getAvailableOutArtifactsForSoftware(SoftwareName.Zendesk);
    }

    render() {
        return <React.Fragment>
            { this.props.formError?.general && <UI.Message
                appearance={`error`}
            >{ this.props.formError.general}</UI.Message>
            }
            <CreateCommentsStep
                onConfigurationChanged={ this.onConfigurationChanged.bind(this) }
                availableArtifacts={ this.availableArtifacts }
                formError={ this.props.formError }
            />
        </React.Fragment>;
    }

    private onConfigurationChanged(newConfiguration: ZendeskCreateCommentsConfiguration) {
        this.configuration = Object.assign(this.configuration, newConfiguration);
    }
}