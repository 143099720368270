import {createGitConfiguration, GitConfiguration, SmartIntTrigger} from './smart-int-trigger.type';
import {
    SmartIntDefinitionConfiguration,
    SmartIntRelationshipConfiguration
} from './smart-int-definition-configuration.type';
import {SmartIntDefinitionTypeMapping} from './smart-int-definition--type-mapping.type';
import {
    AdvancedConfiguration,
    createEmptyAdvancedConfiguration
} from '../configuration/advanced/advanced-configuration';
import {SmartIntPlugin} from '../configuration/plugins/plugins';
import {SuiteType} from '../../../../generic/integrations/suite-type';

export type SmartIntDefinition = {
    suiteType: SuiteType;
    triggers: {
        left: SmartIntTrigger;
        right: SmartIntTrigger;
    },
    types: SmartIntDefinitionTypeMapping[];
    advancedConfiguration: AdvancedConfiguration;
    gitConfiguration: GitConfiguration;
    configurations: SmartIntDefinitionConfiguration[];
    plugins: {
        relationships?: SmartIntRelationshipConfiguration;
        shared: SmartIntPlugin[];
    }
};

export function createDefaultDefinition(): SmartIntDefinition {
    return {
        suiteType: SuiteType.SmartInt,
        triggers: {
            left: {
                app: '',
                artifactName: '',
                connectionId: 0,
                className: '',
                configuration: undefined
            },
            right: {
                app: '',
                artifactName: '',
                connectionId: 0,
                className: '',
                configuration: undefined
            }
        },
        types: [],
        configurations: [],
        advancedConfiguration: createEmptyAdvancedConfiguration(),
        gitConfiguration: createGitConfiguration(),
        plugins: {
            shared: []
        }
    };
}
