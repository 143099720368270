import React from 'react';
import { UI } from 'src/engrator-core';
import { FormError } from '../../../engrator-core/form-error.type';
import {generateSupportPackage} from './rest-api';

type Props = {
    close: () => void;
}
type State = {
    error?: FormError;
    isLoading: boolean;
    data: SupportPackageData;
    isSuccess: boolean;
};

export type SupportPackageData = {
    itemsIds?: string;
};

export class SupportPackagePopup extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            data: {},
            isSuccess: false,
        };
    }

    render() {
        return <UI.FullScreenModal
            primaryBtnHandler={ () => this.import() }
            primaryBtnText={ `Generate` }
            isPrimaryDisabled={ !this.isFormValid() }
            closeBtnHandler={ () => this.props.close() }
            header={`Generate support package`}>
            { this.state.isSuccess && <UI.Message appearance="success">
					Support package generated successfully !
            </UI.Message> }
            { this.state.error?.message && <UI.Message appearance="error">
                { this.state.error?.message }
            </UI.Message> }
            <UI.Form>
                <UI.Message appearance={'info'}>Create a compressed package file (ZIP) that can be shared with the Getint support team. This package will encompass both data and logs associated with the most recent executions for items, the IDs of which can be furnished in the field provided below.</UI.Message>
                <UI.FormGroup
                    label={`Items ids data to be collected (e.g. MARKETING-4123, DEMO-91)`}
                    isRequired={ true }
                >
                    <UI.Input
                        placeholder={`MARKETING-4123, DEMO-91`}
                        onChange={ (value: any) => this.setValue('itemsIds', value) }
                    />
                </UI.FormGroup>
            </UI.Form>
            { this.state.isLoading && <p>
                <UI.Loader visible={ true } appearance={'darkgray'} />
                    Please wait while generating package
            </p> }
        </UI.FullScreenModal>
    }

    private isFormValid(): boolean {
        return !!(this.state.data.itemsIds);
    }

    private setValue(key: 'itemsIds', value: any) {
        const data = this.state.data;
        data[key] = value;
        this.setState({ data });
    }

    private import(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.setState({isLoading: true, error: undefined, isSuccess: false}, () => {
                generateSupportPackage(this.state.data)
                    .then(() => {
                        this.setState({isLoading: false, isSuccess: true});
                        resolve(false);
                    })
                    .catch((error) => {
                        this.setState({isLoading: false, error});
                        resolve(false);
                    });
            });
        });
    }
}
