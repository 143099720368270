import {DropdownOption} from '../../../../../../engrator-core/ui';
import { fetchPropertiesOptions } from '../../rest-api';
import { SoftwareName } from '../../../../../../software';
import { SmartIntTrigger } from '../../../definition/smart-int-trigger.type';
import {PropertyValue} from './property-value.type';
import {SmartIntType} from '../../../definition/smart-int-type.type';
import { PropertyMapping } from '../../property-mapping.type';
import { fetchJiraServiceDeskSchemas, testJiraServiceDeskFetchObjects, testJiraTeamsOrganizationId } from 'src/software/jira/smart-ints/rest-api';

export function fetchValueMappingsOptions(
    leftTrigger: SmartIntTrigger,
    leftType: SmartIntType,
    rightTrigger: SmartIntTrigger,
    rightType: SmartIntType,
    mapping: PropertyMapping
): Promise<{ left: DropdownOption[], right: DropdownOption[] }> {
    return new Promise((resolve, reject) => {
        fetchPropertiesOptions(leftTrigger.app as SoftwareName, mapping.left, leftTrigger, leftType, mapping.options.left).then((leftOptions) => {
            fetchPropertiesOptions(rightTrigger.app as SoftwareName, mapping.right, rightTrigger, rightType,  mapping.options.right).then((rightOptions) => {
                resolve({
                    left: convertToDropDownOptions(leftOptions),
                    right: convertToDropDownOptions(rightOptions)
                });
            }).catch(reject);
        }).catch(reject);
    });
}

export function convertToDropDownOptions(options: any): DropdownOption[] {
    return options.map((option: any) => ({
        label: option.name,
        value: option.id,
        object: {
            name: option.name,
            value: option.id,
            additionalData: option.additionalData
        } as PropertyValue
    }));
}

export function fetchValueMappingsSchemas(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
    return fetchJiraServiceDeskSchemas(trigger);
}

export function testAql(trigger: SmartIntTrigger, aql: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        testJiraServiceDeskFetchObjects(trigger, aql).then((leftOptions) => {
            resolve(true);
        }).catch(reject);
    });
}

export function testTeamsOrganizationId(trigger: SmartIntTrigger, organizationId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        testJiraTeamsOrganizationId(trigger, organizationId).then((leftOptions) => {
            resolve(true);
        }).catch(reject);
    });
}

