import React from 'react';
import { UI } from 'src/engrator-core';
import {PipelineStepRule} from './pipeline-step-rule.type';

type State = {};
type Props = {
    rule: PipelineStepRule
};

export class PipelineStepRuleRenderer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <UI.LabelValue label={`Rule: `} value={ this.props.rule.rule.description } />
            <UI.LabelValue label={`Condition: `} value={ this.props.rule.condition } />
        </React.Fragment>;
    }
}